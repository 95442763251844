import { Badge, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { ColumnType, Table } from "../../../components/table";
import { useNavigate } from "react-router";
import { IconInfoSquare } from "@tabler/icons";
import { LbfAccountApi } from "../../../apis";
import { ILbfAccount } from "../../../interfaces/ILbfAccount";
import { dateFormat } from "../../../utils/date";
import { tugrug } from "../../../utils";

export function AccountBuyerList() {
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/cf/account/BUYER/detail/${record?.id}`);
          break;
        }
      }
    },
  });

  return <Table name="buyer-led-program-credit.list" columns={columns} loadData={(data) => LbfAccountApi.list(data!)} filters={{ requestedType: "BUYER" }} />;
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ILbfAccount) => void }): ColumnType<ILbfAccount>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Үүссэн огноо #",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.resolutionRef || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {dateFormat(record?.createdAt)}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Зээлийн данс",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#317169">
            {record?.number || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.business?.partner?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.business?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.business?.partner?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.business?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.business?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.currency || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны статус",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={record?.loanStatus === "OPEN" ? "green" : "red"}>
          {record?.loanStatus === "OPEN" ? "Нээлттэй" : "Хаалттай"}
        </Badge>
      );
    },
  },
  {
    title: "Зээл авсан дүн",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.amount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Хүлээн авсан данс",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.receiverAccName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.receiverAccNum || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProgram?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.lbfProgram?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.invoiceRef || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт №",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.lbfRequest?.refCode || "-"}
        </Text>
      );
    },
  },
];
