import React, { useRef } from "react";
import { Avatar, Badge, Button, Divider, Flex, Group, Modal, Text, Tooltip } from "@mantine/core";
import { AccountApi, GeneralApi } from "../../apis";
import { FormLayout } from "../../components/layout";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IconCirclePlus, IconHistory, IconReceipt } from "@tabler/icons";
import { IAccount } from "../../interfaces/IAccount";
import { dateSecFormat } from "../../utils/date";
import { AccountForm } from "./account-form";
import { initGeneral } from "../../store/General";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

export const OtherFeeList = () => {
  const ref = useRef<ITableRef>(null);
  const navigate = useNavigate();
  const [filters] = React.useState({ query: "", type: "FEE" });
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const columns = useHeader({
    currencies,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "history": {
          navigate(`/scf-dcf-product/accounting/OTHER_FEE/transaction-history/${record.id}`);
          break;
        }
        case "statement": {
          navigate(`/scf-dcf-product/accounting/OTHER_FEE/statement/${record.id}`);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    const resInit = await GeneralApi.init();
    dispatch(initGeneral(resInit));
    ref.current?.reload();
  };

  return (
    <>
      <FormLayout
        title="Шимтгэл авах данс"
        subTitle="Шимтгэл авах дансны жагсаалт"
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isCreate).length > 0 && (
              <Button
                size="sm"
                key={1}
                onClick={() => {
                  setAction(["CREATE", "FEE"]);
                }}
                variant="light"
                leftIcon={<IconCirclePlus />}>
                Данс нэмэх
              </Button>
            )}
          </div>,
        ]}>
        <Divider mb="lg" />
        <Table ref={ref} name="account.other.fee.list" columns={columns} filters={filters} loadData={(data) => AccountApi.list(data!)} />
      </FormLayout>
      <Modal opened={action[0] === "CREATE" || action[0] === "UPDATE"} onClose={() => setAction([])} withCloseButton={false} centered>
        <AccountForm title="Шимтгэлийн орлого хүлээн авах данс" subTitle="Эргэн төлөлтийн данс нэмэх" action={action} reload={reload} onCancel={onCancel} />
      </Modal>
    </>
  );
};

const useHeader = ({ onClick, currencies, user }: { onClick: (key: string, record: IAccount) => void; currencies: any; user: any }): ColumnType<IAccount>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE_TRX_LIST").length > 0 && (
            <Tooltip label="Гүйлгээний түүх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("history", record)}>
                <IconHistory />
              </Button>
            </Tooltip>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE_STATEMENT").length > 0 ? (
            <Tooltip label="Хуулга харах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("statement", record)}>
                <IconReceipt />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Хуулга харах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconReceipt />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.bankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Оноосон нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.shortName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "accountStatus",
    render: (record) => {
      return record.accountStatus === "ACTIVE" ? (
        <Badge color="green" size="md" radius="sm" variant="outline">
          {"Идэвхтэй"}
        </Badge>
      ) : (
        <Badge color="red" size="md" radius="sm" variant="outline">
          {"Идэвхгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.regUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.regUser?.firstName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Идэвхжсэн огноо",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.updatedAt)}
        </Text>
      );
    },
  },
];
