import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/program", { offset, filter });
};

export const select = async (data: { query: string; lbfProgramStatus: string; limit: string }) => {
  return httpRequest.get(`/api/lbf/program/select`, data);
};

export const create = async (data: any) => {
  return httpRequest.post("/api/lbf/program", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/lbf/program/${id}`);
};

export const anchorList = (data: any) => {
  return httpRequest.get("/api/lbf/program/anchor", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/lbf/program/${id}`, data);
};

export const history = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/program/history", { offset, filter });
};

export const review = async (id: string, data: { review: boolean; reviewDesc: string }) => {
  return httpRequest.put(`/api/lbf/program/${id}/review`, data);
};

export const active = async (id: string, data: { statusDesc: string; activeType: string }) => {
  return httpRequest.put(`/api/lbf/program/${id}/activate`, data);
};

export const deactive = async (id: string, data: { statusDesc: string; inactiveType: string }) => {
  return httpRequest.put(`/api/lbf/program/${id}/deactivate`, data);
};
