import React, { useRef } from "react";
import { Avatar, Badge, Button, CloseButton, Divider, Flex, Group, Modal, SegmentedControl, Space, Text, Tooltip } from "@mantine/core";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { useNavigate } from "react-router";
import { FormLayout, PageFilter } from "../../../components/layout";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IReference } from "../../../models/General";
import { ILbfProgram } from "../../../interfaces/ILbfProgram";
import { IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconInfoSquare } from "@tabler/icons";
import { tugrug } from "../../../utils";
import noimage from "../../../assets/no-image.png";
import { LbfProgramApi } from "../../../apis";
import { FundingProgramBuyerLedActiveForm } from "../../../components/funding-program-buyer/active-form";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function FundingProgramSupplierLedList() {
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const [status, setStatus] = React.useState("");
  const [action, setAction] = React.useState<string[]>([]);
  const [statusDebounced] = useDebouncedValue(status, 400);
  const [registrationStatus, setRegistrationStatus] = React.useState("");
  const [registrationDebounced] = useDebouncedValue(registrationStatus, 400);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { lbfProgramStatus, lbfProgramRegistrationStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    lbfProgramStatus,
    lbfProgramRegistrationStatus,
    onClick: (key, record) => {
      switch (key) {
        case "active": {
          setAction(["active", record as any]);
          break;
        }
        case "in_active": {
          setAction(["inactive", record as any]);
          break;
        }

        case "edit": {
          navigate(`/cf/funding-program/SUPPLIER/${record?.lbfProduct?.refCode}/edit/${record.id}/INFORMATION`);
          break;
        }
        case "detail": {
          navigate(`/cf/funding-program/SUPPLIER/${record?.lbfProduct?.refCode}/detail/${record.id}/INFORMATION`);
          break;
        }

        case "review": {
          navigate(`/cf/funding-program/SUPPLIER/${record?.lbfProduct?.refCode}/review/${record.id}/INFORMATION`);
        }
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Хөтөлбөрийн статус
            </Text>
            <SegmentedControl
              key={1}
              onChange={(e) => setStatus(e)}
              value={status}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...(lbfProgramStatus || [])].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={2}>
            <Text size={14} color="#868E96">
              Бүртгэлийн статус
            </Text>
            <SegmentedControl
              key={2}
              onChange={(e) => setRegistrationStatus(e)}
              value={registrationStatus}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...(lbfProgramRegistrationStatus || [])].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />
      <Space h="lg" />
      <Table
        name="supplier-led-lbf-program-credit.list"
        columns={columns}
        filters={{ query: "", lbfProductType: "SUPPLIER_LED", registrationStatus: registrationDebounced, lbfProgramStatus: statusDebounced }}
        loadData={(data) => LbfProgramApi.list(data!)}
      />

      <Modal opened={action[0] === "active" || action[0] === "inactive"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title={action[0] === "active" ? "Хөтөлбөрийг идэвхтэй болгох" : "Хөтөлбөрийг идэвхгүй болгох"}
          subTitle="Санхүүжилтийн хөтөлбөрийн төлөвийг өөрчлөх"
          my={10}
          extra={[
            <CloseButton
              key="cancel"
              onClick={() => {
                setAction([]);
              }}
            />,
          ]}>
          <Divider />
          <FundingProgramBuyerLedActiveForm
            action={action}
            onSuccess={() => {
              reload();
              setAction([]);
            }}
            onCancel={() => setAction([])}
            type={action[0] === "active" ? "ACTIVE" : "INACTIVE"}
          />
        </FormLayout>
      </Modal>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  lbfProgramStatus,
  lbfProgramRegistrationStatus,
}: {
  user: any;
  onClick: (key: string, record: ILbfProgram) => void;
  lbfProgramStatus: IReference[];
  lbfProgramRegistrationStatus: IReference[];
}): ColumnType<ILbfProgram>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isEdit).length > 0 &&
            record.registrationStatus === "DRAFT" && (
              <Tooltip label="Засварлах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                  <IconEdit />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isReview).length > 0 &&
            record.registrationStatus === "REGISTERED" && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("review", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_STATUS_CHANGE_SL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.lbfProgramStatus === "INACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_STATUS_CHANGE_SL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.lbfProgramStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },

  {
    title: "Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProduct?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProduct?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкор нэр, ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#317169">
            {record?.anchor?.regNumber || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchor?.partner?.businessName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкор бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#317169">
            {record?.anchor?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchor?.profileName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын санхүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.anchor?.finUser?.avatar !== null && record?.anchor ? record?.anchor?.finUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchor?.finUser?.lastName ? record?.anchor?.finUser?.lastName[0] : ""}
              {record?.anchor?.finUser?.lastName ? "." : ""} {record?.anchor?.finUser?.firstName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.anchor?.finUser?.phone || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөрийн лимит",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.lbfProgramLimit || 0)}</Group>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн статус",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(lbfProgramStatus || []).find((item: IReference) => item.code === record.lbfProgramStatus)?.color}>
          {(lbfProgramStatus || []).find((item: IReference) => item.code === record.lbfProgramStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэлийн статус",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(lbfProgramRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.color}>
          {(lbfProgramRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн бизнес тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.smeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Хянасан бизнес тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.reviewedSmeCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Идэвхтэй бизнес тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.activeSmeCount || 0}
        </Text>
      );
    },
  },
];
