import React, { useRef } from "react";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { Avatar, Badge, Button, Checkbox, Divider, Drawer, Flex, Grid, Group, Input, Modal, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { IconBrandTelegram, IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconInfoSquare, IconSearch } from "@tabler/icons";
import { ProductSupplierLedDetail } from "./detail";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { ILbfProduct } from "../../../interfaces/ILbfProduct";
import { IReference } from "../../../models/General";
import { dateSecFormat } from "../../../utils/date";
import noimage from "../../../assets/no-image.png";
import { LbfProductApi } from "../../../apis";
import { useDebouncedValue } from "@mantine/hooks";
import { useNavigate } from "react-router";
import { ProductBuyerLedActiveForm } from "../../../components/product-buyer/active-form";
import { ProductBuyerLedPinForm } from "../../../components/product-buyer/pin-form";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function ProductSupplierLedList() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const [query, setQuery] = React.useState("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productTypes, productCategories, productRegistrationStatus, productStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    productStatus,
    productTypes,
    productCategories,
    productRegistrationStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["opened", record as any]);
          break;
        }
        case "control": {
          setAction(["opened", record as any, "CONTROL"]);
          break;
        }

        case "active": {
          setPayload(record as any);
          setAction(["ACTIVE"]);
          break;
        }
        case "in_active": {
          setPayload(record as any);
          setAction(["INACTIVE"]);
          break;
        }
        case "edit": {
          navigate(`/cf/product/SUPPLIER/edit/${record.id}/INFORMATION`);
          break;
        }
        case "is_compolete": {
          setAction(["is_compolete", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref?.current?.reload();
    setPayload([]);
  };

  return (
    <Flex direction={"column"} gap="xs" sx={{ minHeight: "60vh", height: "100%" }}>
      <Grid mt={5}>
        <Grid.Col span={4}>
          <Input
            iconWidth={180}
            onChange={(e) => setQuery(e.currentTarget.value)}
            placeholder="хайлт хийх"
            rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Бүтээгдэхүүний нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            key={1}
          />
        </Grid.Col>
      </Grid>

      <Divider />
      <Table
        ref={ref}
        name="supplier-led-program-credit.list"
        columns={columns}
        filters={{ query: queryDebounced, type: "SUPPLIER_LED" }}
        loadData={(data) => LbfProductApi.list(data!)}
      />
      <Drawer
        opened={action[0] === "opened"}
        onClose={() => {
          setAction([]);
        }}
        position="right"
        withCloseButton={false}
        overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        size="80%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <ProductSupplierLedDetail onCancel={onCancel} action={action} reload={reload} />
      </Drawer>
      <Modal opened={action[0] === "INACTIVE" || action[0] === "ACTIVE"} onClose={() => setAction([])} withCloseButton={false} centered>
        <ProductBuyerLedActiveForm
          title={action && action[0] === "ACTIVE" ? "Бүтээгдэхүүнийг идэвхтэй болгох" : "Бүтээгдэхүүнийг идэвхгүй болгох"}
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          onCancel={onCancel}
          payload={payload}
          reload={reload}
        />
      </Modal>
      <Modal opened={action[0] === "is_compolete"} onClose={() => setAction([])} withCloseButton={false} centered>
        <ProductBuyerLedPinForm
          title="Хяналтад илгээх"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          onCancel={onCancel}
          reload={reload}
          type="Supplier-LED"
        />
      </Modal>
    </Flex>
  );
}
const useHeader = ({
  user,
  onClick,
  productStatus,
  productTypes,
  productCategories,
  productRegistrationStatus,
}: {
  user: any;
  onClick: (key: string, record: ILbfProduct) => void;
  productStatus: IReference[];
  productTypes: IReference[];
  productCategories: IReference[];
  productRegistrationStatus: IReference[];
}): ColumnType<ILbfProduct>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_STATUS_CHANGE_SL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.lbfProductStatus === "INACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_STATUS_CHANGE_SL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.lbfProductStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 &&
            record.isCompleted &&
            !record.isSent && (
              <Tooltip label="Хяналтад илгээх" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("is_compolete", record)}>
                  <IconBrandTelegram />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                <IconEdit />
              </Button>
            </Tooltip>
          )}

          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isReview).length > 0 &&
            record.registrationStatus === "REGISTERED" &&
            record.lbfProductStatus === "INACTIVE" && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("control", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "SCF код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(productStatus || []).find((item: IReference) => item.code === record.lbfProductStatus)?.color}>
          {(productStatus || []).find((item: IReference) => item.code === record.lbfProductStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {(productTypes || []).find((item: IReference) => item.code === record.type)?.selectName}
        </Badge>
      );
    },
  },
  {
    title: "Ангилал",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {(productCategories || []).find((item: IReference) => item.code === record.category)?.name}
        </Text>
      );
    },
  },
  {
    title: "Буцаан дуудах",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record.recourseTerm}
          label={
            record.recourseTerm ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Supplier Finance",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record.borrowerType === "SUPPLIER_ONLY" || record.borrowerType === "BUYER"}
          label={
            record.borrowerType === "SUPPLIER_ONLY" || record.borrowerType === "BUYER" ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Buyer Finance",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record.borrowerType === "BUYER"}
          label={
            record.borrowerType === "BUYER" ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Бүртгэлийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(productRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.color}>
          {(productRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.regUser?.avatar !== null && record?.regUser !== null ? record?.regUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.regUser?.lastName}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.regUser?.firstName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хянасан огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.reviewedDate)}
        </Text>
      );
    },
  },
  {
    title: "Хянасан ажилтан",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.reviewedUser?.avatar !== null && record?.reviewedUser !== null ? record?.reviewedUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.reviewedUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.reviewedUser?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];
