import React from "react";
import { useParams } from "react-router";
import * as yup from "yup";
import { AuthApi, ProgramApi } from "../../apis";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { OtpField } from "../form/otp-field";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

const schemaStatus = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin?: string;
  programId: string;
  smeBusinessId: string;
};

export function BuyerLedProgramReviewSmeForm({ onCancel, action, reload }: { onCancel: () => void; action: any[]; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [data] = React.useState<IFormData>({
    pin: "",
    programId: `${params.id}`,
    smeBusinessId: action[1] && action[1]?.smeBusinessId,
  });

  const review = async (values: IFormData) => {
    setLoading(true);

    try {
      if (params.id) {
        await ProgramApi.reviewSme({
          programId: `${params.id}`,
          smeBusinessId: values?.smeBusinessId || "",
        });
        Message.success("Таны хүсэлт амжилттай.");
        reload();
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    onCancel();
    setLoading(false);
  };

  const doIsActive = async (values: IFormData) => {
    setLoading(true);
    try {
      await ProgramApi.participantInactive({
        programId: values.programId,
        smeBusinessId: values.smeBusinessId,
        isActive: action && action[0] === "inactive" ? false : true,
      });
      Message.success("Таны хүсэлт амжилттай.");
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    onCancel();
    setLoading(false);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      let res = await AuthApi.checkPin({ pin: values.pin });
      if (res?.success) {
        if (action[0] === "review_sme") {
          review(values);
        } else doIsActive(values);
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <div>
      {action[0] === "review_sme" ? (
        <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
          {() => {
            return (
              <>
                <Grid>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Input.Wrapper>
                      <Text>
                        Та <b>"{action[1] && action[1]?.refCode}"</b> дугаартай Supply Chain Finance хөтөлбөрт <b>"{action[1]?.profileName || "-"}"</b> -д лимит
                        батлах гэж байна.
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  {/* <Grid.Col span={12}>
                    <NumberCurrencyField precision={2} placeholder="0.00" min={0} max={999999999999} name="suggestAmount" label="Лимитийн дүн:" required />
                  </Grid.Col> */}
                  <Grid.Col span={12}>
                    <OtpField name="pin" label="Баталгаажуулах пин:" placeholder="******" />
                  </Grid.Col>
                  <Grid.Col>
                    <Text>Танд амжилт хүсье!</Text>
                  </Grid.Col>
                </Grid>
                <Divider mt="lg" />
                <Flex direction="row" justify="space-between" mt={20}>
                  <Button
                    variant="default"
                    loading={loading}
                    onClick={() => {
                      onCancel();
                    }}>
                    Болих
                  </Button>
                  <Flex gap="md">
                    <Button variant="light" type="submit" loading={loading} key={2}>
                      Баталгаажуулах
                    </Button>
                  </Flex>
                </Flex>
              </>
            );
          }}
        </Form>
      ) : (
        <div>
          <Form validationSchema={schemaStatus} onSubmit={onSubmit} initialValues={data}>
            {({ values, setFieldValue, errors }) => {
              return (
                <>
                  <Grid>
                    <Grid.Col span={12}>
                      <Input.Wrapper>
                        <Text>
                          Та <b>"{action[1] && action[1]?.refCode}"</b> дугаартай <b>"{action[1]?.profileName || "-"}"</b> бизнесийг Supply Chain Finance
                          хөтөлбөрийг{" "}
                          <Text tt={"uppercase"} fw={500} component="span" color="red">
                            {action[0] === "inactive" ? "идэвхгүй" : "Идэвхжүүлэх"}
                          </Text>{" "}
                          болгох гэж байна.
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <OtpField name="pin" label="Баталгаажуулах пин:" placeholder="******" />
                    </Grid.Col>
                  </Grid>
                  <Divider mt="lg" />
                  <Flex direction="row" justify="space-between" mt={20}>
                    <Button
                      variant="default"
                      loading={loading}
                      onClick={() => {
                        onCancel();
                      }}>
                      Болих
                    </Button>
                    <Flex gap="md">
                      <Button variant="light" type="submit" loading={loading} key={2}>
                        Баталгаажуулах
                      </Button>
                    </Flex>
                  </Flex>
                </>
              );
            }}
          </Form>
        </div>
      )}
    </div>
  );
}
