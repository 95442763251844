import { Button, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { IReference } from "../../models/General";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { TextareaField } from "../form/textarea-field";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { AuthApi, LbfProgramApi } from "../../apis";
import { OtpField } from "../form/otp-field";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  type: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
  type: string;
  statusDesc: string;
};

export function FundingProgramBuyerLedActiveForm({
  action,
  onSuccess,
  onCancel,
  type,
}: {
  action: any;
  onSuccess: () => void;
  onCancel: () => void;
  type: string;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { programActiveTypes, programInactiveTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [initialData] = React.useState<IFormData>({
    pin: "",
    type: "",
    statusDesc: "",
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: value?.pin });

      if (type === "ACTIVE") {
        await LbfProgramApi.active(action[1]?.id, {
          activeType: value.type,
          statusDesc: value.statusDesc,
        });
        Message.success("Амжилттай хөтөлбөрийг идэвхтэй болгон хадгаллаа.");
      } else {
        await LbfProgramApi.deactive(action[1]?.id, {
          inactiveType: value.type,
          statusDesc: value.statusDesc,
        });
        Message.success("Амжилттай хөтөлбөрийг идэвхгүй болгон хадгаллаа.");
      }
      onSuccess();
      onCancel();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initialData}>
      {() => {
        return (
          <>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12} mt={10}>
                {type === "ACTIVE" ? (
                  <Text size="sm" weight={500} c="#44566C" style={{ letterSpacing: 1 }}>
                    Та {action[1]?.refCode || "-"} дугаартай зээл суурьтай санхүүжилтийн хөтөлбөрийг идэвхтэй болгох гэж байна. ПИН кодоор баталгаажуулна уу.
                  </Text>
                ) : (
                  <Text size="sm" weight={500} c="#44566C" style={{ letterSpacing: 1 }}>
                    Та {action[1]?.refCode || "-"} дугаартай зээл суурьтай санхүүжилтийн хөтөлбөрийг идэвхтэй болгох гэж байна. ПИН кодоор баталгаажуулна уу.
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col span={12}>
                {type === "ACTIVE" ? (
                  <SelectField
                    name="type"
                    label="Идэвхтэй болгох:"
                    placeholder="Идэвхтэй болгох төрөл сонгох"
                    options={(programActiveTypes || []).map((item: IReference) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                  />
                ) : (
                  <SelectField
                    name="type"
                    label="Идэвхгүй болгох төрөл:"
                    placeholder="Идэвхгүй болгох төрөл сонгох"
                    options={(programInactiveTypes || []).map((item: IReference) => {
                      return {
                        label: item.name || "",
                        value: item.code || "",
                      };
                    })}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={12} mt={10}>
                <OtpField name="pin" label="Пин код." placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextareaField name="statusDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" maxLength={255} withAsterisk={false} />
              </Grid.Col>
            </Grid>
            <Flex justify="flex-start" align="center" direction="column" wrap="wrap" mt="xs" mb="xs">
              <Text size="sm" weight={500} c="#141517">
                Танд амжилт хүсье.
              </Text>
            </Flex>
            <Divider />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel()}>
                Буцах
              </Button>
              <Button variant="outline" color="green" type="submit">
                Хадгалах
              </Button>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
