import React, { useId } from "react";
import { Avatar, Badge, Button, Divider, Flex, Grid, Group, Input, Select, Text, Tooltip } from "@mantine/core";
import { IconBellPlus, IconFileText, IconListSearch, IconSearch } from "@tabler/icons";
import noImage from "../../assets/no-image.png";
import { ColumnType, Table } from "../table";
import { ObservationApi, ReminderApi } from "../../apis";
import { dateSecFormat } from "../../utils/date";
import { useDebouncedState } from "@mantine/hooks";
import useSwr from "swr";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { IMaskInput } from "react-imask";
import { IReminder } from "../../interfaces/IReminder";
import { IReference } from "../../models/General";
import { IAuth } from "../../interfaces/IAuth";

export function CollectionPaymentAutoNotificationList() {
  const [queryStaff, setQueryStaff] = useDebouncedState("", 600);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ listType: "SYSTEM_AUTO", programId: "", profileName: "", regNumber: "" });
  const { reminderTypes, reminderLevels, repaymentOverDueStatus, reminderConnectTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [programQuery] = React.useState("");
  const id = useId();
  const columns = useHeader({
    reminderTypes,
    repaymentOverDueStatus,
    reminderLevels,
    reminderConnectTypes,
    onClick: (key, item) => {},
  });

  const { data: programData } = useSwr(
    `/collection/observation/program_select?${programQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.program({ query: programQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ ...filters, profileName: queryStaff });
    }, 400);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [400, queryStaff]);

  return (
    <>
      <Grid mb="sm">
        {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
          <Grid.Col span={4}>
            <Input.Wrapper label="Хөтөлбөрийн нэрээр шүүх">
              <Select
                value={filters.programId}
                onChange={(e) => {
                  setFilters({ ...filters, programId: e });
                }}
                clearable
                searchable
                placeholder="Сонгох"
                data={(programData || [])?.map((c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />
            </Input.Wrapper>
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <Input.Wrapper label="Харилцагчийн нэрээр хайх">
            <Input
              onChange={(e) => setQueryStaff(e.currentTarget.value)}
              placeholder="Хайлт хийх"
              rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
            <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Divider mb="sm" />
      <Table name="collection-overdue-reminder-system-auto.list" columns={columns} filters={filters} loadData={(data) => ReminderApi.list(data!)} />
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IReminder) => void;
  reminderTypes: any;
  repaymentOverDueStatus: any;
  reminderLevels: any;
  reminderConnectTypes: any;
};

const useHeader = ({ onClick, reminderTypes, repaymentOverDueStatus, reminderLevels, reminderConnectTypes }: HeaderProps): ColumnType<IReminder>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Төлөв" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("repayment_invoice", record)}>
              <IconBellPlus
                color={
                  (record.connectType === "SYSTEM_AUTO2" && "#FCC419") ||
                  (record.connectType === "SMS" && "#FD7E14") ||
                  reminderConnectTypes.find((item: IReference) => item.code === record.connectType)?.color
                }
              />
            </Button>
          </Tooltip>
          <Tooltip label="Нэхэмжлэх" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("repayment_invoice", record)}>
              <IconFileText color="#7956EF" />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "50px",
  },
  {
    title: "Сануулгын огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Сануулсан ажилтан",
    sorter: true,
    dataIndex: "financeStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.financeStaff?.avatar !== null ? record?.financeStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.financeStaff?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.financeStaff?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Badge radius="sm" variant="outline" c="#7956EF" color="violet">
          {record?.repayment?.payerBusiness?.regNumber || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "payerBusiness",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.payerBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.repayment?.payerBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнес",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.payerBusiness?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.repayment?.payerBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "overDueStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {repaymentOverDueStatus.find((c: any) => c.code === record?.repayment?.overDueStatus)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөлт нэхэмжлэх",
    sorter: true,
    dataIndex: "repayment",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {dateSecFormat(record?.repayment?.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Имэйл гарчиг",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.emailTitle || "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл статус",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Badge color="yellow" radius="sm" variant="outline">
          {"Илгээсэн"}
        </Badge>
      );
    },
  },
  {
    title: "Сануулгын төрөл",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderTypes.find((item2: IReference) => item2?.code === record?.reminderType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сануулгын зэрэглэл",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderLevels.find((item2: IReference) => item2?.code === record?.reminderLevel)?.name || "-"}
        </Text>
      );
    },
  },
];
