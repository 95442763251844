import React from "react";
import { Form } from "../form";
import * as yup from "yup";
import { Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text, TextInput } from "@mantine/core";
import { RadioGroupField } from "../form/radio-group-field";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { ClientApi, RequestApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { TextField } from "../form/text-field";
import useSwr from "swr";
import { PageLayout } from "../layout";

const schema = yup.object({
  staffLoginType: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  status?: string;
  partnerId: string;
  staffLoginType: string;
  isApprove: boolean;
  regNumber: string;
  name: any;
};

const status = [
  { code: "success", name: "Зөв таарч байна" },
  { code: "not", name: "Таарахгүй байна" },
];

export function AncorDetailRoleForm({ onCancel, action, reloadTab }: { onCancel: any; action: any[]; reloadTab?: any }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitType, setSubmitType] = React.useState<boolean>(false);
  const { clientStaffLoginTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData | any>({
    status: "",
    partnerId: "",
    staffLoginType: "",
    isApprove: false,
    name: "",
    ...(action && action[0] === "success"
      ? {
          ...action[1],
        }
      : {}),
  });

  const { data: userData, isLoading } = useSwr(
    `/api/client/user_info?registerNo=${action && action[1]?.financeRoleStatus}`,
    async () => {
      if ((action && action[1]?.financeRoleStatus === "APPROVED") || (action && !action[1]?.registerNo)) {
        return [];
      }
      let res = await ClientApi.info({
        registerNo: action && action[1]?.registerNo,
      });
      return res;
    },
    {},
  );

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await RequestApi.respondUser(action && action[2], { userId: action && action[1].id, staffLoginType: values.staffLoginType, isApprove: submitType });
      onCancel();
      reloadTab();
      Message.success("Та харилцагчийн ажилтанд санхүүжилт модульд хандах эрх амжилттай олголоо.");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  if (isLoading || loading) return <LoadingOverlay visible={isLoading || loading} />;

  return (
    <PageLayout
      title="Харилцагчийн системийн тохиргоо"
      subTitle="Тухайн бизнесийн хувьд Finance системийн эрх авч банктай харилцах системийн тохиргоо"
      extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
      <Form
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          ...data,
          ...userData,
          customerRegisterNo: userData?.customer?.registerNo,
          customerCode: userData?.customer?.custCode,
          customerLastName: userData?.customer?.lastName,
          customerFamilyName: userData?.customer?.familyName,
          customerFirstName: userData?.customer?.firstName,
          customerEmail: userData?.phoneEmails ? userData?.phoneEmails[1]?.email : "",
        }}>
        {({ values }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column">
              <Divider />
              <Grid>
                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16} mt="xs">
                    Хэрэглэгч DeHUB платформ дээрх бүртгэл
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="registerNo" disabled label="Хэрэглэгчийн регистр дугаар: " placeholder="дугаар: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="username" disabled label="DeHUB username: " placeholder="нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="familyName" disabled label="Ургийн овог: " placeholder="нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="lastName" disabled label="Эцэг/эхийн нэр: " placeholder="нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="firstName" disabled label="Өөрийн нэр: " placeholder="нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="phone" label="Хэрэглэгчийн утас №1: " placeholder="дугаар: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="email" label="И-мэйл хаяг №1: " placeholder="мэйл хаяг: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16} mt="xs">
                    Банкны харилцагч бүртгэл дэх мэдээлэл
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled label="Хэрэглэгчийн регистр дугаар: " placeholder="дугаар: " name="customerRegisterNo" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput disabled label="Харилцагчийн CIF дугаар: " placeholder="Харилцагчийн CIF дугаар: " name="customerCode" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled label="Ургийн овог: " placeholder="нэр: " name="customerFamilyName" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled label="Эцэг/эхийн нэр: " placeholder="нэр: " name="customerLastName" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled label="Өөрийн нэр: " placeholder="нэр: " name="customerFirstName" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    value={(userData?.phoneEmails || []).find((c: any) => c.type === "PHONE")?.phone || "-"}
                    label="Хэрэглэгчийн утас №1: "
                    placeholder="дугаар: "
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    value={(userData?.phoneEmails || []).find((c: any) => c.type === "EMAIL")?.email || "-"}
                    disabled
                    label="И-мэйл хаяг №1: "
                    placeholder="нэр: "
                  />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16}>
                    Баталгаажилт хэсэг
                  </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                  <RadioGroupField
                    label="DeHUB платформ дээрх мэдээлэл нь банкны мэдээлэлтэй"
                    name="status"
                    withAsterisk={false}
                    options={(status || []).map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <RadioGroupField
                    label="Finance систем нэвтрэх"
                    name="staffLoginType"
                    options={(clientStaffLoginTypes || [])
                      .filter((c) => c.code === "FINANCE")
                      .map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                  />
                </Grid.Col>
              </Grid>
              <Divider mt="md" />
              <Flex mt="md" direction="row" justify="space-between">
                <Button onClick={() => onCancel && onCancel()} variant="outline">
                  Болих
                </Button>
                <Flex gap="md">
                  <Button variant="outline" type="submit" onClick={() => setSubmitType(false)} disabled={values.status !== "success"} color="red">
                    Татгалзах
                  </Button>
                  <Button onClick={() => setSubmitType(true)} type="submit" disabled={values.status !== "success"}>
                    Эрх зөвшөөрөх
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Form>
    </PageLayout>
  );
}
