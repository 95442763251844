import { Textarea } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  description?: string;
  maxLength?: number;
  withAsterisk?: boolean;
  maxRows?: number;
  minRows?: number;
};

export function TextareaField({ name, label, placeholder, disabled = false, description, maxLength, withAsterisk, maxRows, minRows }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Textarea
      description={description}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required
      withAsterisk={withAsterisk}
      maxLength={maxLength}
      maxRows={maxRows}
      minRows={minRows}
    />
  );
}
