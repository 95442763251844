import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Badge, Divider, Flex, Grid, Group, Paper, Space, Text, createStyles } from "@mantine/core";
import { IScfDashboard } from "../../../interfaces/IScfDashboard";
import { IconArrowDown, IconArrowUp, IconBuilding, IconCalendar, IconCalendarEvent, IconCash, IconHomeInfinity, IconMoneybag, IconX } from "@tabler/icons";
import { IScfByInterval, IScfNumberSurvey } from "../../../models/ScfDashboard";
import { tugrug } from "../../../utils";
import { DatePicker, DateRangePicker } from "@mantine/dates";
import dayjs from "dayjs";

export function ScfDashboardReal({
  data,
  rowDate,
  setRowDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  nowMoment,
}: {
  data: IScfDashboard;
  rowDate: any;
  setRowDate: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  nowMoment: Date;
}) {
  const { classes, theme } = useStyles();

  const getOptionsStacked = () => ({
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data?.byRow.map((c: any) => c.name),
    },
    yAxis: {
      min: 0,
      title: {
        text: " ",
      },
    },
    legend: {
      reversed: true,
    },
    colors: ["#9068de", "#e26bb1", "#43baf4", "#66f0d7"],
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Ирсэн",
        color: "#9068de",
        type: "column",
        data: (data?.byRow[0]?.amounts || []).map((c: any) => {
          return c.count;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Зөвшөөрсөн",
        color: "#e26bb1",
        type: "column",
        data: (data?.byRow[1]?.amounts || []).map((c: any) => {
          return c.count;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Олголт хийж дууссан",
        color: "#43baf4",
        type: "column",
        data: (data?.byRow[2]?.amounts || []).map((c: any) => {
          return c.count;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Татгалзсан",
        color: "#66f0d7",
        type: "column",
        data: (data?.byRow[3]?.amounts || []).map((c: any) => {
          return c.count;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  const getOptions = () => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: (data?.byInterval || [])?.map((item: IScfByInterval) => {
          return item?.date;
        }),
        crosshair: true,
      },
    ],
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 18,
    },
    series: [
      {
        name: "Хүлээн авсан",
        color: "#15CAB8",
        type: "column",
        data: (data?.byInterval || []).map((item: IScfByInterval) => {
          return item?.requestedAmount || 0;
        }),

        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Олголт хийсэн",
        type: "spline",
        color: "#FEC600",
        data: (data?.byInterval || []).map((item: IScfByInterval) => {
          return item?.disbursedAmount || 0;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  return (
    <Grid>
      {(data?.numberSurvey || []).map((item: IScfNumberSurvey, index: number) => {
        const DiffIcon = item.changedCount < 0 ? IconArrowDown : IconArrowUp;

        return (
          <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
            <Paper withBorder radius="md">
              <Group spacing={8} p="sm" noWrap pb="xs">
                {index === 0 && <IconCash color={theme.colors.gray[6]} />}
                {index === 1 && <IconBuilding color={theme.colors.gray[6]} />}
                {index === 2 && <IconHomeInfinity color={theme.colors.gray[6]} />}
                {index === 3 && <IconMoneybag color={theme.colors.gray[6]} />}
                <Text fz="sm" fw={600} color={theme.colors.gray[6]}>
                  {item.name || "-"}
                </Text>
              </Group>
              <Group>
                <div className={classes.grdnt}></div>
                <Space />

                {index === 0 && (
                  <Text fz="xl" fw={500}>
                    {item.count || 0}
                  </Text>
                )}
                {index === 1 && (
                  <Text fz="xl" fw={500}>
                    {tugrug(item.count || 0)}
                  </Text>
                )}
                {index === 2 && (
                  <Text fz="xl" fw={500}>
                    {item.count || 0}
                  </Text>
                )}
                {index === 3 && (
                  <Text fz="xl" fw={500}>
                    {tugrug(item.count || 0)}
                  </Text>
                )}
              </Group>
              <Group position="right" p={"sm"}>
                <Text size={"xs"} color={theme.colors.gray[6]}>
                  Өмнөх өдрөөс
                </Text>
                <Badge color={item.changedCount < 0 ? "red" : "green"} className={item.changedCount < 0 ? classes.red : classes.green}>
                  <Flex align={"center"} gap={8}>
                    <DiffIcon size={"0.8rem"} stroke={3} />
                    <Text>{Math.abs(item.changedCount || 0)} %</Text>
                  </Flex>
                </Badge>
              </Group>
            </Paper>
          </Grid.Col>
        );
      })}

      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py="xs">
          <Flex direction="row" justify="space-between" mb="sm">
            <Group>
              <div className={classes.grdnt}></div>
              <Text size={20} fw={500}>
                Санхүүжилтийн хүсэлт
              </Text>
            </Group>

            <Group>
              <DatePicker
                key={1}
                value={rowDate ? rowDate : nowMoment}
                maxDate={nowMoment || rowDate}
                onChange={setRowDate}
                placeholder="Өдөр сонгох"
                minDate={dayjs(nowMoment || rowDate)
                  .subtract(14, "days")
                  .toDate()}
                rightSection={<IconCalendarEvent color="gray" />}
              />
            </Group>
          </Flex>
          <Divider mr={15} ml={15} />
          <HighchartsReact highcharts={Highcharts} options={getOptionsStacked()} />
        </Paper>
      </Grid.Col>

      <Grid.Col md={12} lg={8} xl={12}>
        <Paper withBorder py="xs">
          <Flex justify="space-between">
            <Group>
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захиалга</Text>
            </Group>
            <Group>
              <DateRangePicker
                style={{ borderRadius: "5px" }}
                variant="filled"
                maw="300px"
                w="100%"
                bg={theme.colors.blue[0]}
                placeholder="Огноо хайх"
                icon={<IconCalendar size={"1.2rem"} />}
                inputFormat="YYYY-MM-DD"
                value={[startDate, endDate]}
                onChange={(e) => {
                  if (e[0] && e[1]) {
                    setStartDate(e[0] && e[0]);
                    setEndDate(e[1] && e[1]);
                  }
                }}
                rightSection={
                  <IconX
                    stroke={0.8}
                    size={15}
                    onClick={() => {
                      setStartDate("");
                      setEndDate("");
                    }}
                  />
                }
                mr={20}
              />
            </Group>
          </Flex>
          <Paper px="lg">
            <HighchartsReact highcharts={Highcharts} options={getOptions()} />
            <Space h={20} />
            <Group position="right" style={{ marginTop: "-24px" }}>
              <Text fz={"sm"} color="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                Хэмжих нэгж: Сая төгрөгөөр
              </Text>
            </Group>
            <Space h="xs" />
          </Paper>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#317169",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
