import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/funding_instruction", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/scf/funding_instruction/${id}`);
};

export const respond = async (id: string, data: any) => {
  return httpRequest.put(`/api/scf/funding_instruction/${id}/respond`, data);
};

export const disburse = async (id: string) => {
  return httpRequest.put(`/api/scf/funding_instruction/${id}/disburse`);
};

export const accountGet = async (id: string) => {
  return httpRequest.get(`/api/scf/funding_instruction/account/${id}`);
};
