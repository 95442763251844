import React, { useId, useRef } from "react";
import { Avatar, Badge, Button, Divider, Flex, Grid, Group, Input, Modal, Paper, Select, Text, Tooltip } from "@mantine/core";
import { IconCalendar, IconCurrencyDollar, IconDownload, IconListSearch } from "@tabler/icons";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { SettlementApi } from "../../../apis";
import { tugrug } from "../../../utils";
import { PageLayout } from "../../../components/layout";
import { CollectionPaymentSettlementForm } from "../../../components/collection-settlement/form";
import { ISettlement } from "../../../interfaces/ISettlement";
import { dateSecFormat } from "../../../utils/date";
import noimage from "../../../assets/no-image.png";
import { IMaskInput } from "react-imask";
import useSwr from "swr";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { DatePicker } from "@mantine/dates";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function CollectionPaymentSettlementList() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [businessQuery] = React.useState("");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ businessId: "", month: dayjs(nowMoment).format("YYYY-MM"), regNumber: "" });
  const { settlementStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<string[]>([]);
  const [dateMoment, setDateMoment] = React.useState<Date | null>();
  const ref = useRef<ITableRef>(null);
  const id = useId();

  const columns = useHeader({
    user,
    settlementStatus,
    onClick: (key, item) => {
      switch (key) {
        case "page": {
          setAction(["page", item as any]);
          break;
        }
        case "finance": {
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const { data: businessData } = useSwr(
    `/collection/observation/business_select?${businessQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_SETTLEMENT").length > 0) {
        try {
          let res = await SettlementApi.business({ query: businessQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  const reload = () => {
    ref.current?.reload();
  };

  const onChangeDate = (values: any) => {
    setFilters({ ...filters, month: dayjs(values).format("YYYY-MM") });
    setDateMoment(values);
    if (values === null) {
      setFilters({ ...filters, month: dayjs(nowMoment).format("YYYY-MM") });
    }
  };

  return (
    <PageLayout title="Тооцоо нийлүүлэлтийн жагсаалт" subTitle="Хугацаа хэтрэлттэй эргэн төлөлтийн нэхэмжлэхийн тооцоо нийлүүлэх" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Grid mb="sm">
          <Grid.Col span={4}>
            <DatePicker
              value={filters.month}
              onChange={(e: Date) => {
                onChangeDate(e);
              }}
              label="Тооцоо нийлэх сар:"
              placeholder="сонгох"
              icon={<IconCalendar size={16} />}
            />
          </Grid.Col>
          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_SETTLEMENT").length > 0 && (
            <Grid.Col span={4}>
              <Input.Wrapper label="Бизнесийн нэрээр шүүх">
                <Select
                  value={filters.businessId}
                  onChange={(e) => {
                    setFilters({ ...filters, businessId: e });
                  }}
                  clearable
                  searchable
                  placeholder="Сонгох"
                  data={(businessData || [])?.map((c: any) => ({
                    label: c.profileName,
                    value: c.id,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
          )}
          <Grid.Col span={4}>
            <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
              <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        <Divider mb="sm" />
        <Table ref={ref} name="collection.settlement.list" columns={columns} filters={filters} loadData={(data) => SettlementApi.list(data!)} />
      </Paper>
      <Modal opened={action[0] === "page"} onClose={() => setAction([])} withCloseButton={false} centered size="50%">
        <CollectionPaymentSettlementForm action={action} onCancel={onCancel} reload={reload} date={dateMoment} />
      </Modal>
    </PageLayout>
  );
}

const useHeader = ({
  onClick,
  settlementStatus,
  user,
}: {
  onClick: (key: string, record: ISettlement) => void;
  settlementStatus: any;
  user: any;
}): ColumnType<ISettlement>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "COLLECTION" && c.code === "COLLECTION_SETTLEMENT_SEND").length > 0 &&
          record.settlementStatus === "NONE" ? (
            <Tooltip label="Тооцооны хуудас" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("page", record)}>
                <IconCurrencyDollar color="#7956EF" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Тооцооны хуудас" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconCurrencyDollar color="#7956EF" stroke={0.4} />
              </Button>
            </Tooltip>
          )}

          {/* <Tooltip label="Файл" position="bottom">
            <Button
              variant="light"
              radius={100}
              w={35}
              h={35}
              p={0}
              onClick={() => onClick("finance", record)}
              disabled={record.settlementStatus !== "CONFIRMED"}>
              <IconFile color="#7956EF" />
            </Button>
          </Tooltip> */}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Тооцооны баримт",
    sorter: true,
    dataIndex: "file",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Button
            component="a"
            href={record?.file}
            target="_blank"
            rel="noreferrer"
            variant="subtle"
            c="#44566C"
            size="sm"
            leftIcon={<IconDownload size={18} color="#44566C" />}>
            Татах
          </Button>
        </Flex>
      );
    },
  },
  {
    title: "Тооцооны статус",
    sorter: true,
    dataIndex: "settlementStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={settlementStatus.find((c: any) => c.code === record.settlementStatus)?.color}>
          {settlementStatus.find((c: any) => c.code === record.settlementStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Илгээсэн огноо",
    sorter: true,
    dataIndex: "sentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.sentDate)}
        </Text>
      );
    },
  },
  {
    title: "Тооцоо нийлсэн",
    sorter: true,
    dataIndex: "confirmedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.confirmedDate)}
        </Text>
      );
    },
  },
  {
    title: "Санхүү ажилтан",
    sorter: true,
    dataIndex: "financeStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.financeStaff?.avatar !== null ? record?.financeStaff?.avatar : noimage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.financeStaff?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.financeStaff?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "business",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.business?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.business?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: "180px" }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.business?.refCode || "-"}, {record?.business?.regNumber || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.business?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Эхний үлдэгдэл",
    sorter: true,
    dataIndex: "firstAmount",
    render: (record) => {
      return (
        <Flex mih={10} justify="flex-end" align="flex-end" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#7956EF">
            {tugrug(record.firstAmount)}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record.firstInvCount || 0} {"нэхэмжлэх"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Тухайн сард нэмэгдсэн",
    sorter: true,
    dataIndex: "confirmedAmount",
    render: (record) => {
      return (
        <Flex mih={10} justify="flex-end" align="flex-end" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#7956EF">
            {tugrug(record.confirmedAmount)}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record.confirmedInvCount || 0} {"нэхэмжлэх"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Тухайн сард төлөгдсөн",
    sorter: true,
    dataIndex: "paidAmount",
    render: (record) => {
      return (
        <Group position="right">
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {tugrug(record.paidAmount)}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record.paidInvCount || 0} {"нэхэмжлэх"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Эцсийн үлдэгдэл",
    sorter: true,
    dataIndex: "lastAmount",
    render: (record) => {
      return (
        <Flex mih={10} justify="flex-end" align="flex-end" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#7956EF">
            {tugrug(record.lastAmount)}
          </Text>
          <Text size="sm" weight={500} c="#44566C">
            {record.lastInvCount || 0} {"нэхэмжлэх"}
          </Text>
        </Flex>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    label: "Тооцоо нийлэх",
  },
];
