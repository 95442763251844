import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: any) => {
  return httpRequest.post(`/api/program`, data);
};

export const supplierCreate = async (data: any) => {
  return httpRequest.post(`/api/program`, data);
};
export const supplierUpdate = async (id: string, data: any) => {
  return httpRequest.put(`/api/program/${id}`, data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/program/${id}`, data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/program", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/program/${id}`);
};

export const select = async (data: any) => {
  return httpRequest.get(`/api/program/select`, data);
};

export const anchorSelect = async (data: any) => {
  return httpRequest.get(`/api/program/anchor_select`, data);
};

export const anchorList = (data: any) => {
  return httpRequest.get("/api/program/anchor", data);
};

export const anchorAdd = async (id: string, data: { businessId: string; inAccId: string; outAccId: string }) => {
  return httpRequest.put(`/api/program/${id}/anchor`, data);
};

export const smeSelect = async ({ offset, filter }: IFilter) => {
  return httpRequest.get(`/api/program/sme`, { offset, filter });
};

export const participantAdd = async (id: string, data: any) => {
  return httpRequest.put(`/api/program/${id}/participant`, data);
};

export const toReview = async (id: string) => {
  return httpRequest.put(`/api/program/${id}/to_review`);
};

export const review = async (
  id: string,
  data: {
    review: boolean;
    reviewDesc: string;
  },
) => {
  return httpRequest.put(`/api/program/${id}/review`, data);
};

export const reviewSme = async (data: { smeBusinessId: string; programId: string }) => {
  return httpRequest.put(`/api/program/participant/review`, data);
};

export const participantInactive = async (data: { smeBusinessId: string; isActive: boolean; programId: string }) => {
  return httpRequest.put(`/api/program/participant/status`, data);
};

export const active = async (
  id: string,
  data: {
    activeType: string;
    statusDesc: string;
  },
) => {
  return httpRequest.put(`/api/program/${id}/active`, data);
};

export const inactive = async (
  id: string,
  data: {
    inactiveType: string;
    statusDesc: string;
  },
) => {
  return httpRequest.put(`/api/program/${id}/inactive`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/api/program/${id}`);
};

export const anchorPartner = async (data: any) => {
  return httpRequest.get(`/api/program/anchor/partner`, data);
};

export const participantList = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/program/participant", { offset, filter });
};

export const anchorStaff = async (data: any) => {
  return httpRequest.get(`/api/program/anchor/get_info`, data);
};
