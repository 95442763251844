import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/request", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/lbf/request/${id}`);
};

export const respond = async (id: string, data: any) => {
  return httpRequest.put(`/api/lbf/request/${id}/respond`, data);
};

export const disburse = async (id: string) => {
  return httpRequest.put(`/api/lbf/request/${id}/disburse`);
};
