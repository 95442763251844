import React from "react";
import { Badge, Flex, SegmentedControl, Text } from "@mantine/core";
import { useRef } from "react";
import { ColumnType, ITableRef, Table } from "../table";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { LimitUsageApi } from "../../apis";
import { tugrug } from "../../utils";
import { IReference } from "../../models/General";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { PageFilter } from "../layout";
import { useDebouncedValue } from "@mantine/hooks";

export const ScfProgramUsageBuyerList = () => {
  const refTable = useRef<ITableRef>(null);
  const { programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [status, setStatus] = React.useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);

  const columns = useHeader({
    programRegistrationStatus,
    programStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          break;
        }
      }
    },
  });

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Төлөв
            </Text>
            <SegmentedControl
              key={1}
              onChange={(e) => setStatus(e)}
              value={status}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...programStatus].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />
      <Table
        ref={refTable}
        name="program.useage.list"
        columns={columns}
        filters={{
          query: "",
          productType: "BUYER_LED",
          borrowerType: "",
          anchorPartnerId: "",
          anchorBusinessId: "",
          productId: "",
          excel: `${false}`,
          status: statusDebounced,
        }}
        loadData={(data) => LimitUsageApi.list(data!)}
      />
    </>
  );
};

const useHeader = ({
  onClick,
  programRegistrationStatus,
  programStatus,
}: {
  onClick: (key: string, record: IScfProgram) => void;
  programRegistrationStatus: IReference[];
  programStatus: IReference[];
}): ColumnType<IScfProgram>[] => [
  // {
  //   title: "Үйлдэл",
  //   render: (record) => {
  //     return (
  //       <RowActionIcon
  //         onClick={(key) => onClick(key, record)}
  //         extra={[
  //           {
  //             text: "",
  //             icon: <IconFileDollar stroke={0.4} />,
  //             key: "detail",
  //           },
  //           {
  //             text: "",
  //             icon: <IconClipboard stroke={0.4} />,
  //             key: "detail",
  //           },
  //         ]}
  //       />
  //     );
  //   },
  // },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.product?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.product?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(programRegistrationStatus || []).find((item: IReference) => item.code === record?.registrationStatus)?.color}>
          {(programRegistrationStatus || []).find((item: IReference) => item.code === record?.registrationStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(programStatus || []).find((c: IReference) => c?.code === record.programStatus)?.color} 1px solid`,
            color: `${(programStatus || []).find((c: IReference) => c?.code === record.programStatus)?.color}`,
          }}>
          {(programStatus || []).find((item: IReference) => item.code === record?.programStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Анкор партнер",
    sorter: true,
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.partner?.refCode || "-"}, {record?.anchor?.partner?.businessName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор бизнес",
    sorter: true,
    dataIndex: "unit",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.anchor?.refCode || "-"}, {record?.anchor?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "SME тоо",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.participantCount || "0"}
        </Text>
      );
    },
  },
  {
    title: "Баталсан лимит",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.programLimit || 0)}
        </Text>
      );
    },
  },
  {
    title: "Боломжит лимит",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Ашигласан лимит",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.utilizedAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Олголт хүлээж буй",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.toDisburseAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт гаргасан",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.requestedAmount || 0)}
        </Text>
      );
    },
  },
];
