import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Box, Button, Checkbox, Divider, Flex, Grid, Input, Modal, Text } from "@mantine/core";
import { ILbfProduct } from "../../interfaces/ILbfProduct";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { ProductBuyerLedContorForm } from "./control-form";
import { IconSend } from "@tabler/icons";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function ProductBuyerLedFinanceDetail({
  action,
  data,
  back,
  next,
  onSuccess,
}: {
  action: string;
  back: () => void;
  next: () => void;
  data: ILbfProduct;
  onSuccess: () => void;
}) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { borrowerTypes, lbfProductInterestTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [opened, setOpened] = React.useState<string[]>([]);
  const [value] = React.useState<IFormData>({
    name: data?.borrowerType,
  });

  const onSubmit = (values: any) => {
    setOpened(["pin", values]);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={value}>
        {() => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Input.Wrapper label="Санхүүжилт авах тал: ">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {(borrowerTypes || []).find((item: IReference) => item?.code === data?.borrowerType)?.blName}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧИЙН ТӨЛӨХ, ШИМТГЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилтийн хүү:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {data?.suppInterestPercent || 0} %
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хүү тооцох хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {(lbfProductInterestTypes || []).find((item: IReference) => item?.code === data?.suppInterestType)?.name}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(data?.suppInterestMinAmount || 0)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Өргөдлийн хураамж:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(data?.suppAppFee || 0)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Олголтын шимтгэл:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {data?.suppDisbursementFee || 0} %
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Буцаан дуудах эсэх:">
                    <Box style={{ alignItems: "center" }}>
                      <Checkbox
                        checked={data?.recourseTerm ? true : false}
                        label={
                          data?.recourseTerm ? (
                            <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              Тийм
                            </Text>
                          ) : (
                            <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              Үгүй
                            </Text>
                          )
                        }
                        onChange={() => {}}
                      />
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Буцаан дуудах хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {data?.recourseDays || 0}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт зогсоох хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {data?.suppTerminatePeriod || 0}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                {data?.borrowerType === "BUYER" && (
                  <>
                    <Grid.Col span={12}>
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ, ШИМТГЭЛ" />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Санхүүжилтийн хүү:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {data?.buyerInterestPercent || 0} %
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Хүү тооцох хугацаа:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {(lbfProductInterestTypes || []).find((item: IReference) => item?.code === data?.buyerInterestType)?.name}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(data?.buyerInterestMinAmount || 0)}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Өргөдлийн хураамж:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(data?.buyerAppFee || 0)}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Олголтын шимтгэл:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {data?.buyerDisbursementFee || 0} %
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Худалдан авагчид өгөх хоног:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {data?.buyerTermDays || 0}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                  </>
                )}
              </Grid>
              <Divider color="gray" />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => back()}>
                  Болих
                </Button>
                <Flex gap="md">
                  <Button variant="outline" onClick={() => next()}>
                    Дараагийнх
                  </Button>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_BL" && c.isReview).length >
                    0 && (
                    <Button rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />} type="submit" disabled={action[2] !== "CONTROL"}>
                      Хянах
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Form>
      <Modal
        withCloseButton={false}
        opened={opened && opened[0] === "pin"}
        onClose={() => {
          setOpened([]);
        }}
        centered>
        <ProductBuyerLedContorForm
          action={action}
          title="Бүтээгдэхүүнийг хянах"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          onSuccess={() => {
            onSuccess();
            setOpened([]);
          }}
          onClose={() => setOpened([])}
        />
      </Modal>
    </>
  );
}
