import React from "react";
import * as yup from "yup";
import useUser from "../../hooks/user";
import { Box, Button, Divider, Flex, Grid, Input, Radio, Text } from "@mantine/core";
import { Form } from "../form";
import { TextareaField } from "../form/textarea-field";
import { CheckboxField } from "../form/checkbox-field";
import { dateSecFormat } from "../../utils/date";
import { numberRegex, tugrug } from "../../utils";

const schema = yup.object({
  approvedAmount: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup
      .string()
      .min(1, "Хэтэрхий бага утга байна!")
      .max(16, "Хэтэрхий их утга байна!")
      .matches(numberRegex, "Тоо оруулна уу!")
      .required("Заавал бөглөнө үү!")
      .nullable(),
  }),
  respondDesc: yup.string().required("Заавал бөглөнө!").nullable(),
  toDisburseDate: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
});

type IFormData = {
  isApproved: boolean;

  respondDesc: string;
  approvedAmount: string;
  toDisburseDate: string;
  finance_board: boolean;
  finance: string;
};

type Props = {
  action: any;
  onTabChange: any;
  setAction: (e: any) => void;
};

export function ScfSupplierFinanceDecisionDetail({ action, onTabChange, setAction }: Props) {
  const nowMoment = new Date();
  const { user } = useUser();
  const [data] = React.useState<IFormData>({
    isApproved: false,
    approvedAmount: "",
    toDisburseDate: "",
    respondDesc: "",
    ...(action && action[0] === "detail" ? action[1] : {}),
  });

  const onSubmit = async (values: any) => {};

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values, errors }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТ АВАГЧ ТАЛ" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч талын партнер:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.partner?.refCode || "-"}, {action[1]?.invoice?.senderBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.regNumber || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч бизнес:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.refCode || "-"} {action[1]?.invoice?.senderBusiness?.profileName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн огноо:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {dateSecFormat(nowMoment)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн ажилтан:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {user?.firstName || "-"} {user?.lastName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.program?.resolutionNo || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШИЙДВЭРИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Flex gap="xs" align="center">
                    <Radio checked={action[1]?.isApproved === true && true} onChange={() => {}} label="Санхүүжилтийг зөвшөөрөх" />
                    <Radio checked={action[1]?.isApproved === false && true} onChange={() => {}} label="Санхүүжилтийг татгалзах" />
                  </Flex>
                </Grid.Col>

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action[1]?.program?.disburseAcc?.number || "-"}, {action[1]?.program?.disburseAcc?.name}, {action[1]?.program?.disburseAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын дансны үлдэгдэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action[1]?.program?.disburseAcc?.balance || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын данс шаардлага:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action[1]?.program?.disburseAcc?.balanceAcceptable ? "Тийм" : "Үгүй"}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Санхүүжилт олгох дүн:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action[1]?.approvedAmount || 0)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголт хийх, огноо:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {dateSecFormat(action[1]?.toDisburseDate)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Банк шимтгэлийн дүн:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action[1]?.bankFeeAmount || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Өргөдлийн хураамж:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action[1]?.appFee || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын шимтгэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action[1]?.disbursementFee || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Санхүүжилт авах данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action[1]?.invoice?.senderAcc?.number}, {action[1]?.invoice?.senderAcc?.name}, {action[1]?.invoice?.senderAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="SCF орлого төвлөрүүлэх данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action[1]?.program?.feeAcc?.number || "-"}, {action[1]?.program?.feeAcc?.name}, {action[1]?.program?.feeAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action[1]?.isApproved && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Эргэн төлөх данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action[1]?.program?.repayAcc?.number || "-"}, {action[1]?.program?.repayAcc?.name}, {action[1]?.program?.repayAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                <Grid.Col span={12}>
                  <CheckboxField name="finance_board" label="">
                    {values.finance_board ? "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан" : "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан"}
                  </CheckboxField>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="respondDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" disabled withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button
                  variant="default"
                  onClick={() => {
                    onTabChange("3");
                  }}>
                  Буцах
                </Button>
              </Flex>
            </Flex>
          );
        }}
      </Form>
    </>
  );
}
