import { Badge, Box, Button, Divider, Flex, Text } from "@mantine/core";
import { tugrug } from "../../utils";

type Props = {
  action: any;
  onCancel: () => void;
  setTabIndex: any;
  setAction: any;
};

export function LimitForm({ action, onCancel, setAction, setTabIndex }: Props) {
  return (
    <>
      <Divider color="gray" mt="xs" mb="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="Харилцагчийн лимит ашиглалт" />
      <Box sx={(theme) => ({ border: `1px solid ${theme.colors.gray[3]}` })}>
        <Flex gap={0} justify="space-between">
          <Box w="50%">
            <Flex direction="column">
              <Text
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                size="sm">
                {" "}
              </Text>
              <Text
                align="center"
                sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                size="sm"
                fw={600}
                color="indigo.6">
                Дүн
              </Text>
              <Text
                size="sm"
                sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}
                align="center"
                h={50}
                fw={600}
                color="indigo.6">
                Тоо
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Баталсан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.requestedBusinessLimitInfo?.subLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Боломжит лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.requestedBusinessLimitInfo?.availableLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {"-"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Лимит шаардлага
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.requestedBusinessLimitInfo?.smeAmountAcceptable ? (
                  <Badge color="green" radius="xs">
                    {"Хангаж байна"}
                  </Badge>
                ) : (
                  <Badge color="red" radius="xs">
                    {"Хангахгүй байна"}
                  </Badge>
                )}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Ашигласан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.requestedBusinessLimitInfo?.utilizedLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.requestedBusinessLimitInfo?.utilizedCount || "0"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Олголт хүлээж буй
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.requestedBusinessLimitInfo?.disbursePendingLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.requestedBusinessLimitInfo?.disbursePendingCount || "0"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Хүсэлт илгээсэн
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.requestedBusinessLimitInfo?.pendingLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.requestedBusinessLimitInfo?.pendingCount || "0"}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Divider color="gray" mt="xs" mb="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХӨТӨЛБӨРИЙН ЛИМИТ" />
      <Box sx={(theme) => ({ border: `1px solid ${theme.colors.gray[3]}` })}>
        <Flex gap={0} justify="space-between">
          <Box w="50%">
            <Flex direction="column">
              <Text
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                size="sm">
                {" "}
              </Text>
              <Text
                align="center"
                sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                size="sm"
                fw={600}
                color="indigo.6">
                Дүн
              </Text>
              <Text
                size="sm"
                sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}
                align="center"
                h={50}
                fw={600}
                color="indigo.6">
                Тоо
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Баталсан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.programLimitInfo?.limit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Боломжит лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.programLimitInfo?.availableLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {"-"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Лимит шаардлага
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.programLimitInfo?.programAmountAcceptable ? (
                  <Badge color="green" radius="xs">
                    {"Хангаж байна"}
                  </Badge>
                ) : (
                  <Badge color="red" radius="xs">
                    {"Хангахгүй байна"}
                  </Badge>
                )}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Ашигласан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.programLimitInfo?.utilizedLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.programLimitInfo?.utilizedCount || "0"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Олголт хүлээж буй
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.programLimitInfo?.disbursePendingLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.programLimitInfo?.disbursePendingCount || "0"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Хүсэлт илгээсэн
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action?.programLimitInfo?.pendingLimit || "0")}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action?.programLimitInfo?.pendingCount || "0"}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} mt="xl" mb="xl" />
      <Flex direction="row" justify="space-between">
        <Button
          variant="default"
          onClick={() => {
            setTabIndex("2");
          }}>
          Буцах
        </Button>
        <Flex gap="md">
          <Button
            variant="filled"
            onClick={() => {
              setTabIndex("4");
            }}
            type="submit">
            Үргэлжлүүлэх
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
