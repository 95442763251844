import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/program/participant", { offset, filter });
};

export const selectList = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/program/participant/select", { offset, filter });
};

export const add = async (
  id: string,
  data: // { smeBusinessId: string; subLimit: number }
  any,
) => {
  return httpRequest.post(`/api/lbf/program/${id}/participant`, data);
};

export const toReview = async (data: { lbfProgramId: string; smeBusinessId: string }) => {
  return httpRequest.put("/api/lbf/program/participant/to_review", data);
};

export const review = async (data: { lbfProgramId: string; smeBusinessId: string }) => {
  return httpRequest.put("/api/lbf/program/participant/review", data);
};

export const status = async (data: { lbfProgramId: string; smeBusinessId: string; isActive: boolean }) => {
  return httpRequest.put("/api/lbf/program/participant/status", data);
};

export const remove = async (id: string, smeBusinessId: string) => {
  return httpRequest.del(`/api/lbf/program/${id}/participant/${smeBusinessId}`);
};
