import { Avatar, Checkbox, Flex, Group, Text } from "@mantine/core";
import { ProgramApi } from "../../apis";
import { ColumnType, Table } from "../table";
import noimage from "../../assets/no-image.png";

export function BuyerLedProgramAnchorList({ filters, values, setFieldValue }: { filters: any; values: any; setFieldValue: any }) {
  const columns = useHeader({ values, setFieldValue });

  return <Table name="scf.sme.add.anchor.listg" columns={columns} filters={filters} loadData={(data) => ProgramApi.anchorList(data!)} />;
}

const useHeader = ({ values, setFieldValue }: any): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Сонгох",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Checkbox
            checked={record?.id === values?.anchor?.id}
            onChange={(e) => {
              if (record?.id === values?.anchor?.id) {
                setFieldValue("businessId", undefined);
                setFieldValue("outAccId", undefined);
                setFieldValue("inAccId", undefined);
                setFieldValue("anchor", undefined);
                setFieldValue("bankAccounts", undefined);
                setFieldValue("checkedAnchor", undefined);
              } else {
                setFieldValue("businessId", e?.currentTarget?.value);
                setFieldValue("anchor", record);
                setFieldValue("bankAccounts", record?.bankAccounts);
                setFieldValue("outAccId", undefined);
                setFieldValue("inAccId", undefined);
                setFieldValue("checkedAnchor", `${record?.profileName || "-"}, #${record?.refCode || "-"}`);
              }
            }}
            value={record?.id}
          />
        </div>
      );
    },
  },
  {
    title: "Партнер ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C">
            {record.partnerName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#141517">
            {record.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Бизнес код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#141517">
          #{record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.finUser?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүү имэйл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.finUser?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүү утас",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.finUser?.phone}
        </Text>
      );
    },
  },
];
