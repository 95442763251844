import React from "react";
import { Avatar, Badge, Button, CloseButton, Flex, Group, Modal, Paper, Text, Tooltip } from "@mantine/core";
import noimage from "../../../assets/no-image.png";
import { IconInfoSquare } from "@tabler/icons";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { FormLayout, PageLayout } from "../../../components/layout";
import { IApprovelRuleLevel } from "../../../interfaces/IApprovelRuleLevel";
import { dateSecFormat } from "../../../utils/date";
import { ApprovelApi } from "../../../apis";
import { ApprovelRuleForm } from "../../../components/approvel/form";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../../models/General";

export function UserControlApprovelList() {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const { actions, modules } = useSelector((state: { general: IGeneral }) => state.general);
  const ref = React.useRef<ITableRef>(null);
  const [filters] = React.useState({ query: "" });

  const columns = useHeader({
    actions,
    modules,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          setAction(["detail", item as any]);
        }
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Хяналтын дүрэм"
        subTitle="Хяналтын түвшинтэй үйлдлүүдэд хяналтын дүрэм тохируулгын жагсаалт."
        breadcrumb={breadcrumbs}
        extra={[
          <Button key={1} onClick={() => setAction(["new"])}>
            Дүрэм тохируулах
          </Button>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Table ref={ref} name="approval.rule.list" columns={columns} filters={filters} loadData={(data) => ApprovelApi.list(data!)} />
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "new" || action[0] === "detail"} onClose={() => setAction([])} withCloseButton={false} size="50%" centered>
        <FormLayout
          my={0}
          title={action[0] === "new" ? "Хяналтын дүрэм тохируулах" : "Хяналтын дүрэмийн дэлгэрэнгүй"}
          subTitle="Хяналтын эрх хамаарах бизнес, системийн модуль"
          extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
          <ApprovelRuleForm action={action} reload={reload} setAction={setAction} />
        </FormLayout>
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  modules,
  actions,
}: {
  onClick: (key: string, record: IApprovelRuleLevel) => void;
  actions: IReference[];
  modules: IReference[];
}): ColumnType<IApprovelRuleLevel>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
    width: "30px",
  },

  {
    title: "Систем модуль",
    sorter: true,
    dataIndex: "module",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {(modules || []).find((c: IReference) => c.code === record?.module)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Системийн үйлдэл",
    sorter: true,
    dataIndex: "systemAction",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {(actions || []).find((c: IReference) => c.module === record.systemAction)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Дүрмийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Батлах үе шат",
    sorter: true,
    dataIndex: "isDefault",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.level || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record.isActive ? (
        <Badge size="md" color="green" radius="sm" variant="outline">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge size="md" color="red" radius="sm" variant="outline">
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "Тохируулсан админ",
    sorter: true,
    dataIndex: "regUser",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.regUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.regUser?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Огноо цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Хяналтын дүрэм",
  },
];
