import { createStyles, Input } from "@mantine/core";
import { useField } from ".";
import OtpInput from "react18-input-otp";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: string;
  isInputNum?: boolean;
  isInputSecure?: boolean;
  shouldAutoFocus?: boolean;
  isDisabled?: boolean;
  separator?: any;
  numInputs?: number;
  description?: string;
};

export function OtpField({
  name,
  label,
  placeholder,
  disabled = false,
  required = false,
  isInputNum = true,
  isInputSecure = false,
  shouldAutoFocus = false,
  separator = <></>,
  numInputs = 6,
  description,
}: Props) {
  const { value, error, onChange } = useField(name);
  const { classes } = useStyle();

  return (
    <div>
      <Input.Wrapper description={description} label={label} required={required} error={error}>
        <OtpInput
          separator={separator}
          shouldAutoFocus={shouldAutoFocus}
          isInputSecure={isInputSecure}
          isInputNum={isInputNum}
          isDisabled={disabled}
          containerStyle={classes.containerStyle}
          inputStyle={classes.inputStyle}
          focusStyle={classes.focusStyle}
          disabledStyle={classes.disabledStyle}
          value={value}
          onChange={onChange}
          numInputs={numInputs}
          errorStyle={classes.errorStyle}
          placeholder={placeholder}
          hasErrored={error}
        />
      </Input.Wrapper>
    </div>
  );
}

const useStyle = createStyles((theme) => ({
  containerStyle: {
    // background: "blue!important",
    display: "flex",
    gap: "8px",
    width: "max-content",
    marginBottom: "5px",
    marginTop: "5px",
  },
  inputStyle: {
    // background: "red !important",
    height: "36px",
    appearance: "none",
    resize: "none",
    boxSizing: "border-box",
    fontSize: "14px",
    width: "100%",
    color: "#000",
    display: "block",
    textAlign: "left",
    minHeight: "36px",
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "4px",
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    transition: "border-color 100ms ease",
    minWidth: "40px",
    "&:active": {},
    "&:focus": {
      border: `1px solid ${theme.colors.indigo[6]} !important`,
    },
  },
  focusStyle: {
    border: "1px solid red !important",
  },
  activeStyle: {},
  disabledStyle: {},
  errorStyle: {
    // background: "red !important",
    border: `1px solid ${theme.colors.red[6]} !important`,
  },
}));
