import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/line_limit", { offset, filter });
};

export const check = async (data: { clientRegNumber: number }) => {
  return httpRequest.get(`/api/lbf/line_limit/check_limit`, data);
};
