import { Checkbox, Group, Input } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  position?: "right" | "left";
  children?: any;
  customOnChange?: (e: any) => void;
  checked?: boolean;
  onClick?: (e: any) => void;
};

export function CheckboxField({ name, label, position, children, customOnChange, checked, onClick }: Props) {
  const { error, value, onChange } = useField(name);

  return (
    <Input.Wrapper label={label} error={error}>
      <Group pt={"xs"}>
        <Checkbox
          onClick={onClick && onClick}
          checked={value}
          label={children}
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.checked);
            customOnChange && customOnChange(e.currentTarget.checked);
          }}
        />
      </Group>
    </Input.Wrapper>
  );
}
