import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { Form } from "../form";
import { Badge, Box, Button, Divider, Flex, Grid, Input, Modal, Text } from "@mantine/core";
import { RadioField } from "../form/radio-field";
import { tugrug } from "../../utils";
import { IconArrowLeft, IconSend } from "@tabler/icons";
import { ControlForm } from "./control-form";
import { IAuth } from "../../interfaces/IAuth";

const schema = yup.object().shape({});

type IFormData = {
  borrowerType: string;
};

export function BuyerProductDetailFinanceForm({
  action,
  onSuccess,
  onCancel,
  reload,
}: {
  action: any[];
  onSuccess: () => void;
  onCancel: () => void;
  reload: any;
}) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { borrowerTypes, productSuppFeeRules, productBuyerPenaltyTypes, productSuppPenaltyTypes, productBuyerFeeRules } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [data] = React.useState<IFormData>({
    borrowerType: "",
    ...(action && action[0] === "DETAIL" ? action[1] : {}),
  });

  const [opened, setOpened] = React.useState<string[]>([]);

  const onSubmit = (values: any) => {
    setOpened(["pin", values]);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <RadioField
                    label="Санхүүжилт авагч тал"
                    name="borrowerType"
                    options={(borrowerTypes || []).map((c: any) => ({
                      label: c.blName,
                      value: c.code,
                    }))}
                    withAsterisk={false}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Буцаан дуудах хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.recourseDays || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Худалдан авагчид өгөх хоног:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.buyerTerm || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт зогсоох хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.buyerTerminatePeriod || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="НИЙЛҮҮЛЭГЧИЙН ТӨЛӨХ ШИМТГЭЛИЙН ТОХИРГОО" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Нийлүүлэгчийн шимтгэл:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppFee || "-"} {"%"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шимтгэлийг тооцох хоног:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppCalculateDay || "0"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Өргөдлийн хураамж:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(action[1]?.suppAppFee || "0")}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(action[1]?.suppMinFee || "0")}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Олголтын шимтгэл:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppDisbursementFee || "0"} {"%"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шимтгэл авах дүрэм:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        <Badge size="md" radius="sm" variant="outline">
                          {(productSuppFeeRules || [])?.find((c) => c.code === action[1]?.suppFeeRule)?.name}
                        </Badge>
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Хугацаа хэтэрсний алданги:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppPenaltyPercent || "0"} {"%"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Алданги тооцох дүрэм:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        <Badge size="md" radius="sm" variant="outline">
                          {(productSuppPenaltyTypes || [])?.find((c) => c.code === action[1]?.suppPenaltyType)?.name}
                        </Badge>
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
                {action[1]?.borrowerType !== "SUPPLIER_ONLY" && (
                  <>
                    <Grid.Col span={12}>
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ ШИМТГЭЛ ТОХИРУУЛАХ" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Худалдан авагчийн шимтгэл:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerFee || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Шимтгэлийг тооцох хоног:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerCalculateDay || "-"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Өргөдлийн хураамж:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(action[1]?.buyerAppFee || "0")}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(action[1]?.buyerMinFee || "0")}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Олголтын шимтгэл:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerDisbursementFee || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Шимтгэл авах дүрэм:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            <Badge size="md" radius="sm" variant="outline">
                              {(productBuyerFeeRules || [])?.find((c) => c.code === action[1]?.buyerFeeRule)?.name}
                            </Badge>
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Хугацаа хэтэрсний алданги:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerPenaltyPercent || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Алданги тооцох дүрэм:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            <Badge size="md" radius="sm" variant="outline">
                              {(productBuyerPenaltyTypes || [])?.find((c) => c.code === action[1]?.buyerPenaltyType)?.name}
                            </Badge>
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}></Grid.Col>
                  </>
                )}
                <Grid.Col span={12}>
                  <Divider color="gray" />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button
                  variant="default"
                  leftIcon={<IconArrowLeft />}
                  onClick={() => {
                    onSuccess();
                  }}>
                  Өмнөх
                </Button>

                {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isReview).length > 0 &&
                  action &&
                  action[2] === "CONTROL" && (
                    <Flex gap="md">
                      <Button rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />} type="submit">
                        Хянах
                      </Button>
                    </Flex>
                  )}
              </Flex>
            </Flex>
            <Modal
              withCloseButton={false}
              opened={opened && opened[0] === "pin"}
              onClose={() => {
                setOpened([]);
              }}>
              <ControlForm
                title="Баталгаажуулах пин код"
                subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
                onCancel={onCancel}
                action={opened}
                reload={reload}
                setOpened={setOpened}
              />
            </Modal>
          </>
        );
      }}
    </Form>
  );
}
