import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const review = async (data: { programId: string; smeBusinessId: string }) => {
  return httpRequest.put("/api/program/participant/review", data);
};
export const remove = async (id: string, smeBusinessId: string) => {
  return httpRequest.del(`/api/program/${id}/participant/${smeBusinessId}/remove`);
};
export const status = async (data: { programId: string; smeBusinessId: string; isActive: boolean }) => {
  return httpRequest.put("/api/program/participant/status", data);
};
export const toReview = async (data: { programId: string; smeBusinessId: string }) => {
  return httpRequest.put("/api/program/participant/to_review", data);
};
export const update = async (data: { programId: string; smeBusinessId: string; subLimit: number }) => {
  return httpRequest.put("/api/program/participant", data);
};
