import React from "react";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconFileText, IconHistory, IconPackage } from "@tabler/icons";
import { LbfProgramApi } from "../../../apis";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { FundingProgramBuyerLedDetailInformation } from "../../../components/funding-program-buyer/detail-information-form";
import { FundingProgramBuyerLedHistoryList } from "../../../components/funding-program-buyer/history-list";
import { FundingProgramBuyerLedBusinessList } from "../../../components/funding-program-buyer/business-list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingProgramBuyerLedDetail() {
  const params = useParams();
  const { refCode, type, id } = params;
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(type || "INFORMATION");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading, mutate } = useSwr(`/api/program/buyer-led/detail/${params.id}`, async () => await LbfProgramApi.get(id as string));

  const onTabChange = (_index: string) => {
    navigate(`/cf/funding-program/BUYER/${refCode}/detail/${id}/${_index}`);
    setTabKey(_index);
  };

  return (
    <PageLayout
      title="Хөтөлбөрийн дэлгэрэнгүй мэдээлэл"
      subTitle="Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр"
      breadcrumb={breadcrumbs}
      extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={onTabChange}>
          <LoadingOverlay visible={isLoading} />
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="BUSINESS" icon={<IconPackage color={tabKey === "BUSINESS" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Нийлүүлэгч бизнесүүд
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Системийн түүх
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">
            {data && (
              <FundingProgramBuyerLedDetailInformation
                data={data}
                programSector1={data?.sector1?.name}
                programSector2={data?.sector2?.name}
                programSector3={data?.sector3?.name}
                onSuccess={() => setTabKey("BUSINESS")}
              />
            )}
          </Tabs.Panel>
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="BUSINESS">
              {data && (
                <FundingProgramBuyerLedBusinessList
                  data={data}
                  onSuccess={async () => {
                    await mutate();
                  }}
                />
              )}
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="HISTORY">
              <FundingProgramBuyerLedHistoryList />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    to: "/cf/funding-program/BUYER",
    label: "Buyer-LED хөтөлбөрийн жагсаалт",
  },
  {
    label: "Дэлгэрэнгүй",
  },
];
