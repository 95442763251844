import React from "react";
import { Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IconCheck, IconX } from "@tabler/icons";
import { dateSecFormat, dateTimeFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { IReference } from "../../models/General";

const schema = yup.object({});

type IFormData = {
  name: string;
};

type Props = {
  action: any;
  onTabChange: any;
};

export function ScfSupplierFinanceRequestForm({ action, onTabChange }: Props) {
  const { currencies, productSuppFeeRules, requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХҮСЭЛТИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.refCode}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн төрөл:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {action[1]?.type === "SUPPLIER" ? "Supplier-Эрт санхүүжилт" : "Buyer-Дараа төлөлт"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт гаргасан бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.requestedBusiness?.refCode || "-"}, {action[1]?.requestedBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action[1]?.createdAt)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт гаргасан хэрэглэгч:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.requestedUser?.lastName || "-"} {action[1]?.requestedUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(requestStatus || [])?.find((c: any) => c?.code === action[1]?.requestStatus)?.color} 1px solid`,
                      color: `${(requestStatus || [])?.find((c: any) => c?.code === action[1]?.requestStatus)?.color}`,
                    }}>
                    {(requestStatus || [])?.find((c: any) => c?.code === action[1]?.requestStatus)?.name || "-"}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.program?.refCode}, {action[1]?.program?.name}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.product?.refCode || "-"}, {action[1]?.product?.name || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invRefCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.invAmountToPay || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
                  <Flex gap="xs" align="center">
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action[1]?.invPaymentDate)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт хүссэн дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.requestedAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн валют:">
                  <div>
                    <Badge size="md" radius="sm" variant="outline">
                      {currencies.find((item) => item?.code === action[1]?.currency)?.name || "-"}
                    </Badge>
                  </div>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт төлөх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action[1]?.repaymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              {/* <Grid.Col span={4}>
                <Input.Wrapper label="Хүү тооцож эхлэх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action[1]?.interestStartDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col> */}
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүү тооцох хоног:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.financePeriod || 0}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Тооцсон SCF-ийн хүү:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.calculatedFeeAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Олголтын шимтгэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.disbursementFee || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Өргөдлийн хураамж:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.appFee || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжих боломжит дүн:">
                  <Flex gap="xs" align="center">
                    {action[1]?.finAmountAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.finMinAmount)} {"-"} {tugrug(action[1]?.finMaxAmount)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх хугацаа:">
                  <Flex gap="xs" align="center">
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.passedDays || 0}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх тенор:">
                  <Flex gap="xs" align="center">
                    {action[1]?.tenorAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.remainingDays || 0}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх доод үлдэгдэл:">
                  <Flex gap="xs" align="center">
                    {action[1]?.balanceAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.minInvBalance)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Шимтгэл доод дүн:">
                  <Flex gap="xs" align="center">
                    {action[1]?.minFeeAmount <= action[1]?.maxFeeAmount ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.minFeeAmount || "0")}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Шимтгэл суутгах дүрэм:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {productSuppFeeRules.find((item: IReference) => item.code === action[1]?.feeRule)?.name}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Буцаан дуудах эсэх:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.recourseTerm ? "Тийм" : "Үгүй"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Буцаан дуудах огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateTimeFormat(action[1]?.recourseDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт зогсоох огноо:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {dateSecFormat(action[1]?.restrictDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт авах данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.requestedBusinessAcc?.number}, {action[1]?.requestedBusinessAcc?.name}, {action[1]?.requestedBusinessAcc?.bankName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button
                variant="default"
                onClick={() => {
                  onTabChange("1");
                }}>
                Буцах
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  onClick={() => {
                    onTabChange("3");
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
