import React from "react";
import useSwr from "swr";
import { Button, CloseButton, LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { FormLayout, PageLayout } from "../../../components/layout";
import { useNavigate, useParams } from "react-router";
import { ProgramApi } from "../../../apis";
import { BuyerLedProgramReviewInformationForm } from "../../../components/buyer-led-program/review-information-form";
import { BuyerProgramReviewForm } from "../../../components/buyer-led-program/review-form";
import { BuyerLedProgramReviewCustomerForm } from "../../../components/buyer-led-program/review-customer-form";
import { IconFileText, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function BuyerLedProgramReview() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const params = useParams();
  const { step } = params;
  const [tabKey, setTabKey] = React.useState<string>((step as string) || "1");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading, mutate } = useSwr(`/api/program/buyer-led/${params.id}/reviewed`, async () => await ProgramApi.get(params.id!), {
    fallbackData: null,
  });

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf/dcf-program/${params.id}/review/${_index}`);
    setTabKey(_index);
  };

  return (
    <>
      <PageLayout
        title={tabKey === "1" ? "Үндсэн мэдээлэл хянах" : "Анкор партнерийн мэдээлэл"}
        subTitle={tabKey === "1" ? "SCF хөтөлбөрны үндсэн мэдээлэл хянах" : "Сонгосон анкор партнерийн мэдээлэл"}
        breadcrumb={breadcrumbs}
        bgColor="#F8F9FA"
        extra={[
          <Button variant="outline" key="back" onClick={() => navigate(`/scf-dcf/dcf-program`)}>
            {"Буцах"}
          </Button>,
          tabKey === "1" && (
            <>
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isReview).length > 0 && (
                <Button
                  disabled={(data && data?.registrationStatus === "BASIC_INFO_REVIEWED") || (data && data?.registrationStatus === "REVIEWED")}
                  onClick={() => setAction(["review", data])}
                  key="confirm">
                  {(data && data?.registrationStatus === "BASIC_INFO_REVIEWED") || (data && data?.registrationStatus === "REVIEWED") ? "Хянагдсан" : "Хянах"}
                </Button>
              )}
            </>
          ),
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs
            onTabChange={(e: string) => {
              onTabChange(e);
            }}
            value={tabKey}
            defaultValue={tabKey}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabKey === "1" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хөтөлбөрийн мэдээлэл
                </Text>
              </Tabs.Tab>

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
                <Tabs.Tab value="2" icon={<IconTruckLoading color={tabKey === "2" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Нийлүүлэгч бизнесүүд
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            {isLoading || loading ? (
              <LoadingOverlay visible />
            ) : (
              <>
                <Tabs.Panel value="1">
                  <BuyerLedProgramReviewInformationForm
                    data={data}
                    reload={async () => {
                      setLoading(true);
                      await mutate();
                      setTabKey("2");
                      setLoading(false);
                    }}
                    basicSector={(data?.programSectors || []).filter((c: any) => c.level === 1)}
                    sector={(data?.programSectors || []).filter((c: any) => c.level === 2)}
                    subSector={(data?.programSectors || []).filter((c: any) => c.level === 3)}
                  />
                </Tabs.Panel>

                {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
                  <Tabs.Panel value="2">
                    <BuyerLedProgramReviewCustomerForm
                      data={data}
                      reload={async () => {
                        setLoading(true);
                        await mutate();
                        setLoading(false);
                      }}
                    />
                  </Tabs.Panel>
                )}
              </>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "review"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="Үндсэн мэдээлэл хянах"
          subTitle="Supply Chain Finance хөтөлбөрийн үндсэн бүртгэл хянах"
          my={0}
          extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
          <BuyerProgramReviewForm data={data} reload={mutate} onCancel={() => setAction([])} type="buyer_led" />
        </FormLayout>
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/dcf-program",
    label: "Buyer LED хөтөлбөр",
  },
  {
    label: "Хөтөлбөр хянах",
  },
];
