import React from "react";
import * as yup from "yup";
import { Avatar, Button, Divider, Flex, Grid, Group, LoadingOverlay, Text } from "@mantine/core";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";
import { Form } from "../form";
import { TextareaField } from "../form/textarea-field";
import { ICollection } from "../../interfaces/ICollection";
import noimage from "../../assets/no-image.png";
import useSwr from "swr";
import { ObservationApi, StaffApi } from "../../apis";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { SelectItemField } from "../form/select-item-field";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";

const schema = yup.object({
  userId: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  // user2Id: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  // description: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
});

type Props = {
  action: string[];
  onCancel: () => void;
  reload: any;
  checked?: any;
  setChecked?: any;
};

type IFormData = {
  userId: string;
  user2Id: string;
  description: string;
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  item: any;
}

export function CollectionAssignedForm({ action, onCancel, reload, checked, setChecked }: Props) {
  const actionData = action && action[0] === "assigned" ? [action && action[1]] : checked;
  const [query] = React.useState("");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [datas] = React.useState<IFormData>({
    userId: "",
    user2Id: "",
    description: "",
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await ObservationApi.assign({
        userId: data.userId,
        user2Id: data.user2Id,
        businessIds: action && action[0] === "assigned" ? [action && action[2]] : checked.map((c: any) => c.id),
        description: data.description,
      });
      setChecked([]);
      setLoading(false);
      Message.success("Таны хүсэлт амжилттай.");
      reload();
      onCancel && onCancel();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const columns = useHeader({
    onClick: (key, item) => {},
  });

  const { data: userData } = useSwr(`/collection/staff/select?${query}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_STAFF").length > 0) {
      try {
        let res = await StaffApi.select({ query: query, limit: 1 });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
        return err;
      }
    }
    return [];
  });

  const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(({ image, label, description, item, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={item?.avatar !== null ? item.avatar : noimage} />

        <div>
          <Text size="sm">{item?.lastName}</Text>
          <Text size="xs" opacity={0.65}>
            {item?.firstName}
          </Text>
        </div>
      </Group>
    </div>
  ));

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
        {({ values, setFieldValue }) => {
          return (
            <FormLayout
              my={20}
              title="Хугацаа хэтрэлт хариуцах ажитны мэдээлэл"
              extra={[
                <Button key={1} variant="outline" onClick={() => onCancel && onCancel()}>
                  Буцах
                </Button>,
                <Button key={2} type="submit">
                  Хадгалах
                </Button>,
              ]}>
              <Divider mb="lg" />
              <Flex sx={{ position: "relative" }} direction="column">
                <Grid>
                  {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_STAFF").length > 0 && (
                    <Grid.Col span={4}>
                      <SelectItemField
                        name="userId"
                        searchable
                        required
                        label="Хариуцсан ажилтан сонгох"
                        placeholder="Сонгох"
                        options={(userData || [])?.map((item: any) => {
                          return {
                            label: `${item.lastName}, ${item.firstName}`,
                            value: item.id,
                            item,
                          };
                        })}
                        clearable
                        itemComponent={SelectItem}
                        onChange={(e) => {
                          setFieldValue("user2Id", undefined);
                        }}
                        filter={(value: any, item: any) => {
                          return (
                            item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                            item.item.lastName.toLowerCase().includes(value.toLowerCase().trim())
                          );
                        }}
                      />
                    </Grid.Col>
                  )}
                  {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_STAFF").length > 0 && (
                    <Grid.Col span={4}>
                      <SelectItemField
                        name="user2Id"
                        searchable
                        label="Орлох ажилтан сонгох"
                        placeholder="Сонгох"
                        disabled={!values.userId}
                        clearable
                        options={(userData || [])
                          ?.filter((item: any) => item.id !== values.userId)
                          ?.map((item: any) => {
                            return {
                              label: `${item.lastName}, ${item.firstName}`,
                              value: item.id,
                              item,
                            };
                          })}
                        itemComponent={SelectItem}
                        filter={(value: any, item: any) => {
                          return (
                            item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                            item.item.lastName.toLowerCase().includes(value.toLowerCase().trim())
                          );
                        }}
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col span={4}>
                    <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар оруулах." withAsterisk={false} />
                  </Grid.Col>
                </Grid>
                <Divider mt="lg" mb="lg" />
                <Table name="collection-observation-assigned.list" columns={columns} dataSource={actionData} pagination={false} />
              </Flex>
            </FormLayout>
          );
        }}
      </Form>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ICollection) => void;
};

const useHeader = ({ onClick }: HeaderProps): ColumnType<ICollection>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
          {record.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text c="#7956EF">{record?.partner?.refCode}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.partner?.businessName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text c="#7956EF">{record?.refCode}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.profileName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүү роль",
    sorter: true,
    dataIndex: "financeStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.financeStaff?.avatar !== null ? record?.financeStaff?.avatar : noimage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.financeStaff?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.financeStaff?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүү имэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
          {record?.financeStaff?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Гар утас",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
          {record?.financeStaff?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Борлуулалт роль",
    sorter: true,
    dataIndex: "purchaseStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.purchaseStaff?.avatar !== null ? record?.purchaseStaff?.avatar : noimage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.purchaseStaff?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.purchaseStaff?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Борлуулалт имэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
          {record?.purchaseStaff?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Борлуулалт утас",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return (
        <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
          {record?.purchaseStaff?.phone || "-"}
        </Text>
      );
    },
  },
];
