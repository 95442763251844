import React from "react";
import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { SupplierProgramMainForm } from "../../../components/supplier-led-program/main-form";
import { ProductsApi, ProgramApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { SupplierProgramPinForm } from "../../../components/supplier-led-program/pin-form";
import { IconFileText, IconShoppingCart } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function SupplierLedProgramNew() {
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const [action, setAction] = React.useState<any>([]);
  const [index, setIndex] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data: productData } = useSwr(`/buyer-led-program/list/${params.code}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_SL" && c.isCreate).length > 0) {
      try {
        if (!params.code) {
          return [];
        }

        let res = await ProductsApi.select({ query: params.code, type: "SUPPLIER_LED" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onMainSubmit = async (value: any) => {
    setIsLoading(true);
    try {
      if (index) {
        setAction(["pin", value, productData[0].id]);
      } else {
        await ProgramApi.create({
          type: "CURRENT_ASSET",
          productType: "SUPPLIER_LED",
          productId: productData[0].id,
          name: value.name,
          resolutionNo: value.resolutionNo,
          description: value.description,
          programSector1Ids: value?.programSector1Ids || [],
          programSector2Ids: (value?.programSector2Ids || []).map((c: any) => c?.id),
          programSector3Ids: (value?.programSector3Ids || []).map((c: any) => c?.id),
          disburseAccId: value.disburseAccId,
          feeAccId: value.feeAccId,
          repayAccId: value.repayAccId,
          businessId: value.businessId,
          programLimit: value.programLimit,
          rechargeable: !value.rechargeable ? false : value.rechargeable === "FALSE" ? false : true,
          finUserId: value?.anchor?.finUser?.id,
          toReview: false,
        });
        Message.success(`Та SCF хөтөлбөр амжилттай бүртгэж хадгаллаа.`);
        navigate(`/scf-dcf/scf-program`);
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <>
      <PageLayout
        title="Supplier LED хөтөлбөр"
        subTitle="Шинээр SCF хөтөлбөр бүртгэх, Бүтээгдэхүүний төрөл(Supplier LED)"
        breadcrumb={breadcrumbs}
        bgColor="#F8F9FA">
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={tabIndex}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хөтөлбөрийн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="2" icon={<IconShoppingCart color={tabIndex === "2" ? "#317169" : "#44566C"} />} disabled>
                <Text size="sm" weight={500} c="#44566C">
                  Худалдан авагчийн бизнесүүд
                </Text>
              </Tabs.Tab>
              {/* <Tabs.Tab value="3" icon={<IconReceiptTax color={tabIndex === "3" ? "#317169" : "#44566C"} />} disabled>
                <Text size="sm" weight={500} c="#44566C">
                  Төлөлтийн хувиар
                </Text>
              </Tabs.Tab> */}
            </Tabs.List>
            <Tabs.Panel value="1">
              <SupplierProgramMainForm onSubmit={onMainSubmit} action={[]} setIndex={setIndex} />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal withCloseButton={false} opened={action[0] === "pin"} onClose={() => setAction([])} size={"md"} centered>
        <SupplierProgramPinForm
          action={action}
          onCancel={() => setAction([])}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/scf-program",
    label: "Supplier LED хөтөлбөр",
  },
  {
    label: "Шинэ",
  },
];
