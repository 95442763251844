import React from "react";
import { Badge, Button, Checkbox, Drawer, Flex, Group, Text, Tooltip } from "@mantine/core";
import { ColumnType, Table } from "../../../components/table";
import { IconInfoSquare } from "@tabler/icons";
import { LbfRequest } from "../../../models/LbfRequest";
import { IReference } from "../../../models/General";
import { useRef } from "react";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { LbfRequestApi } from "../../../apis";
import { dateFormat } from "../../../utils/date";
import { tugrug } from "../../../utils";
import { FundingRequestBuyerDetail } from "./detail";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function FundingRequestBuyerList() {
  const ref = useRef<any>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { currencies, requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    user,
    currencies,
    requestStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <Table
        ref={ref}
        name="lbf-supplier-led.list"
        columns={columns}
        filters={{ type: "BUYER", query: "", status: "" }}
        loadData={(data) => LbfRequestApi.list(data!)}
      />
      <Drawer
        opened={action[0] === "detail"}
        onClose={() => {
          setAction([]);
        }}
        position="right"
        withCloseButton={false}
        styles={{ drawer: { overflowY: "scroll" } }}
        size="80%">
        <FundingRequestBuyerDetail
          action={action}
          onSuccess={() => {
            reload();
            onCancel();
          }}
          onCancel={onCancel}
        />
      </Drawer>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  currencies,
  requestStatus,
}: {
  user: any;
  onClick: (key: string, record: LbfRequest) => void;
  currencies: IReference[];
  requestStatus: IReference[];
}): ColumnType<LbfRequest>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "LBF" && c.code === "LBF_REQUEST_BUYER" && c.isView).length > 0 ? (
            <Tooltip label="Хүсэлтийн дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Хүсэлтийн дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {dateFormat(record.createdAt)}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "CIF",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.requestedClientCif || "-"}
        </Text>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.requestedBusiness?.partner?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.requestedBusiness?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.requestedBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.requestedBusiness?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.requestedBusiness?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагч анкор эсэх",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Checkbox
          checked={record.lbfProductType === "BUYER_LED" ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {record.lbfProductType === "BUYER_LED" ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.requestedAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {(currencies || []).find((c: IReference) => c.code === record.currency)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record.lbfRequestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record.lbfRequestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: IReference) => c.code === record.lbfRequestStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хөтөлбөрий нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProgram?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.lbfProgram?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge color="yellow" size="md" radius="sm" variant="outline">
          {record?.lbfProductType || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.lbfProduct?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.lbfProduct?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.invoice?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.invoice?.amountToPay || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateFormat(record?.invoice?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хоног",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.remainingDays || 0}
        </Text>
      );
    },
  },
];
