import React from "react";
import { ActionIcon, Space, Text } from "@mantine/core";
import { ColumnType, Table } from "../table";
import { OverdueApi } from "../../apis";
import { dateSecFormat } from "../../utils/date";
import { IconCheck, IconMinus } from "@tabler/icons";
import { IActivityLog } from "../../interfaces/IActivityLog";

type Props = {
  action: any;
};

export function CollectionOverdueActiviteLogList({ action }: Props) {
  const [filters] = React.useState<any>({ repaymentId: action && action[1]?.id });
  const columns = useHeader({
    onClick: (key, item) => {},
  });

  return (
    <>
      <Space mt="md" />
      <Table name="collection-overdue-activite-log.list" columns={columns} filters={filters} loadData={(data) => OverdueApi.repaymentList(data!)} />
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IActivityLog) => void }): ColumnType<IActivityLog>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "50px",
  },
  {
    title: "",
    dataIndex: "logType",
    render: (record) => {
      return (
        <>
          {record.logType === "OVERDUE" ? (
            <ActionIcon color="red.5" size="md" radius="xl" variant="filled">
              <IconMinus size="1rem" />
            </ActionIcon>
          ) : (
            <ActionIcon color="green.5" size="md" radius="xl" variant="filled">
              <IconCheck size="1rem" />
            </ActionIcon>
          )}
        </>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "logType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.logType}
        </Text>
      );
    },
  },
  {
    title: "Холбоотой хэрэглэгч",
    sorter: true,
    dataIndex: "actionUserId",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.actionUser?.lastName[0] || "-"}. {record?.actionUser?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
];
