import React from "react";
import { Form } from "../form";
import * as yup from "yup";
import { TextField } from "../form/text-field";
import { Button, Flex, Grid, LoadingOverlay, Divider, Text, Badge } from "@mantine/core";
import { SelectField } from "../form/select-field";
import { useSelector } from "react-redux";
import { IconCheck, IconLock, IconSettings, IconX } from "@tabler/icons";
import { DatePickerField } from "../form/date-picker-field";
import useUser from "../../hooks/user";

type IFormData = {
  businessId: string | undefined;
  familyName: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  registerNo: string | undefined;
  departmentUnitId: string | undefined;
  employeeUnitId: string | undefined;
  phone: string | undefined;
  phone2: string | undefined;
  email: string | undefined;
  email2: string | undefined;
  socialFbLink: string | undefined;
  roleId: string | undefined;
  roleEnableDate: string | undefined;
  roleDisableDate: string | undefined;
  send: any;
  regUserName: string | undefined;
  userName: string | undefined;
};

const schema = yup.object({
  businessId: yup.string().nullable(),
  familyName: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  firstName: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  lastName: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  registerNo: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  departmentUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  employeeUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  phone: yup
    .string()
    .matches(/^[0-9]{8}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
  phone2: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Зөвхөн дугаар оруулна уу")
    .nullable(true),
  email: yup.string().typeError("Заавал бөглөнө!").email("Цахим шуудан байна!").required("Заавал бөглөнө!"),
  email2: yup.string().email("Цахим шуудан байна!").nullable(),
  // socialFbLink: yup
  //   .string()
  //   .transform((value) => (value ? value : null))
  //   .matches(/^http(s*):\/\/(www.)*facebook\.com\/[a-zA-Z0-9.]+$/i, "Facebook url биш байна!")
  //   .nullable(true),
  roleId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  roleEnableDate: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  roleDisableDate: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  send: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

export function MeForm({ onClose, action, reload, changePassword }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const { departmentUnits, employeeUnits } = useSelector((state: any) => state.general);
  const { user } = useUser();

  const [data] = React.useState<IFormData>({
    businessId: undefined,
    familyName: undefined,
    firstName: undefined,
    lastName: undefined,
    registerNo: undefined,
    departmentUnitId: undefined,
    employeeUnitId: undefined,
    phone: undefined,
    phone2: undefined,
    email: undefined,
    email2: undefined,
    socialFbLink: undefined,
    roleId: undefined,
    roleEnableDate: undefined,
    roleDisableDate: undefined,
    send: false,
    regUserName: undefined,
    userName: undefined,
    ...user,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(false);
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, errors }) => {
        return (
          <Grid sx={{ position: "relative" }}>
            <LoadingOverlay visible={loading} />
            <Grid.Col lg={4} md={6}>
              <TextField disabled name="regUserName" placeholder="Хэрэглэгчийн нэр" label="Хэрэглэгчийн нэр" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <DatePickerField disabled name="createdAt" required={false} placeholder="Бүртгэсэн огноо" label="Бүртгэсэн огноо" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="registerNo" placeholder="Ажилтны регистр №" label="Ажилтны регистр №" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="familyName" placeholder="Ажилтны ургийн овог" label="Ажилтны ургийн овог" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="lastName" placeholder="Ажилтны эцэг эхийн нэр" label="Ажилтны эцэг эхийн нэр" />
            </Grid.Col>

            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="firstName" placeholder="Ажилтны өөрийн нэр" label="Ажилтны өөрийн нэр" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              {values?.departmentUnitId ? (
                <SelectField
                  options={(departmentUnits || []).map((c: any) => ({ label: c?.name, value: c?.id }))}
                  disabled
                  name="departmentUnitId"
                  placeholder="Харъяалах нэгжийн нэр"
                  label="Харъяалах нэгжийн нэр"
                />
              ) : (
                <TextField disabled name="employeeUnitId" placeholder="-" label="Харъяалах нэгжийн нэр" />
              )}
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              {values?.employeeUnitId ? (
                <SelectField
                  options={(employeeUnits || [])
                    ?.filter((c: any) => c?.departmentUnitId === values?.departmentUnitId)
                    ?.map((c: any) => ({ label: c?.name, value: c?.id }))}
                  disabled
                  name="employeeUnitId"
                  placeholder="Албан тушаалын нэр"
                  label="Албан тушаалын нэр"
                />
              ) : (
                <TextField disabled name="employeeUnitId" placeholder="-" label="Албан тушаалын нэр" />
              )}
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ" />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="phone" placeholder="Утасны дугаар №1" label="Утасны дугаар №1" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="phone2" placeholder="Утасны дугаар №2" label="Утасны дугаар №2" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="email" placeholder="И-мэйл хаяг №1" label="И-мэйл хаяг №1" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="email2" placeholder="И-мэйл хаяг №2" label="И-мэйл хаяг №2" />
            </Grid.Col>
            <Grid.Col lg={4} md={6}>
              <TextField disabled={!isEdit} name="socialFbLink" placeholder="Facebook хаяг" label="Facebook хаяг" />
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="НУУЦЛАЛЫН ТОХИРГОО" />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col lg={4} md={4} sm={4}>
              <Flex direction="column" gap="sm">
                <Text fw={500} c="gray">
                  Нууц үг үүсгэсэн эсэх
                </Text>
                <Badge w={140} h={30} variant="outline" size="md" radius="md" color={user?.hasPassword ? "teal" : "red"}>
                  {user?.hasPassword ? "Үүсгэсэн" : "Үүсгээгүй"}
                </Badge>
                <Button disabled w={180} onClick={() => changePassword(true)} color="red" leftIcon={<IconLock size={20} />} mt={20}>
                  {user?.hasPassword ? " Нууц үг солих" : " Нууц үг үүсгэх"}
                </Button>
              </Flex>
            </Grid.Col>
            <Grid.Col lg={4} md={4} sm={2} />
            <Grid.Col lg={4} md={4} sm={4}>
              <Flex direction="column" gap="sm">
                <Text fw={500} c="gray">
                  ПИН Код үүсгэсэн эсэх
                </Text>
                <Badge w={140} h={30} variant="outline" size="md" radius="md" color={user?.hasPin ? "teal" : "red"}>
                  {user?.hasPin ? "Үүсгэсэн" : "Үүсгээгүй"}
                </Badge>
                <Button w={180} onClick={() => changePassword([true, "PIN"])} color="red" leftIcon={<IconLock size={20} />} mt={20}>
                  {user?.hasPin ? " ПИН Код солих" : " ПИН Код үүсгэх"}
                </Button>
              </Flex>
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex mt={20} justify="space-between">
                {isEdit ? (
                  <Button variant="outline" onClick={() => setIsEdit(false)} leftIcon={<IconX size={20} />} mt={20}>
                    Болих
                  </Button>
                ) : (
                  <Button disabled onClick={() => changePassword(true)} leftIcon={<IconLock size={20} />} mt={20}>
                    Төлөв солих
                  </Button>
                )}

                {isEdit ? (
                  <div>
                    <Button type="submit" color="teal" leftIcon={<IconCheck size={20} />} mt={20}>
                      Хадгалах
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => setIsEdit(true)} variant="outline" leftIcon={<IconSettings size={20} />} mt={20}>
                    Мэдээлэл засах
                  </Button>
                )}
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
