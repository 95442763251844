import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/product/transaction", { offset, filter });
};

export const accList = async (data: any) => {
  return httpRequest.get("/api/product/transaction/account", data);
};

export const statList = async ({ filter }: IFilter) => {
  return httpRequest.get("/api/product/transaction/account", { filter });
};
