import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/financeable", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/scf/financeable/${id}`);
};
