import { Anchor, Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Space, Table, Text, createStyles } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconArrowDown, IconArrowUp, IconBuilding, IconBuildingCommunity, IconBuildingSkyscraper, IconCalendarEvent, IconCash } from "@tabler/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { IPByPie, IPRecentActivated, IPRecentTrx } from "../../../models/ProductDashboard";
import { dateFormat } from "../../../utils/date";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IReference } from "../../../models/General";
import { tugrug } from "../../../utils";
import dayjs from "dayjs";

const pieData = [
  {
    name: "Хянасан, идэвхтэй",
    count: 40,
    color: "#15CAB8",
  },
  {
    name: "Хянасан, идэвхгүй",
    count: 30,
    color: "#44A6E9",
  },
  {
    name: "Үндсэн мэдээлэл хянасан",
    count: 10,
    color: "#FEC600",
  },
  {
    name: "Бүртгэсэн",
    count: 2,
    color: "#FF8548",
  },
  {
    name: "Түр төлөв",
    count: 10,
    color: "#E2362F",
  },
];

const recentActivated = [
  {
    activatedDate: "2024-02-26T06:14:55.780Z",
    name: "Supplier LED Product",
    programName: "Supplier LED progran",
    programRefCode: "SCF-PG-100007",
    refCode: "SCF-PD-100002",
  },
  {
    activatedDate: "2024-02-21T03:04:26.990Z",
    name: "санхүүжилт №12",
    programName: "Program name 1",
    programRefCode: "SCF-PG-100010",
    refCode: "SCF-PD-100003",
  },
  {
    activatedDate: "2024-02-13T03:55:27.383Z",
    name: "Нийлүүлэлтийн санхүүжилт",
    programName: "Supplier LED",
    programRefCode: "SCF-PG-100003",
    refCode: "SCF-PD-100001",
  },
  {
    activatedDate: "2024-02-02T06:16:12.124Z",
    name: "Санхүүжилт -123",
    programName: "АПУ 2024",
    programRefCode: "SCF-PG-100001",
    refCode: "SCF-PD-100000",
  },
];

const recentTrx = [
  { amount: 5000000, bankName: "Богд", date: "2024-03-17T06:01:48.040Z", number: "1105523925", payerAccountNumber: "1105523948", type: "REPAYMENT" },
  {
    amount: 4000000,
    bankName: "Богд",
    date: "2024-03-16T06:01:48.040Z",
    number: "1105524204",
    payerAccountNumber: "1105524223",
    type: "REPAYMENT",
  },
  { amount: 3000000, bankName: "Богд", date: "2024-03-15T06:01:48.040Z", number: "1105523927", payerAccountNumber: "1105523950", type: "REPAYMENT" },
  {
    amount: 2000000,
    bankName: "Богд",
    date: "2024-03-14T06:01:48.040Z",
    number: "1105524205",
    payerAccountNumber: "1105524224",
    type: "REPAYMENT",
  },
  {
    amount: 1000000,
    bankName: "Богд",
    date: "2024-03-13T06:01:48.040Z",
    number: "1105523929",
    payerAccountNumber: "1105523951",
    type: "REPAYMENT",
  },
];

export function ScfDcfProductDashboardStatic() {
  const { classes, theme } = useStyles();
  const nowMoment = new Date();
  const { accountTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const stats = [
    {
      changedCount: 20,
      count: 20,
      name: "БҮТЭЭГДЭХҮҮНИЙ ТОО",
      key: 0,
      icon: IconCash,
    },
    { changedCount: 10, count: 10, name: "АНКОР БИЗНЕС", key: 1, icon: IconBuilding },
    { changedCount: 0, count: 0, name: "ЖДБ - SUPPLIER", key: 2, icon: IconBuildingCommunity },
    { changedCount: 20, count: 20, name: "ЖДБ - BUYER", icon: IconBuildingSkyscraper },
  ].map((stat, index) => {
    const DiffIcon = stat.changedCount < 0 ? IconArrowDown : IconArrowUp;

    return (
      <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
        <Paper withBorder key={stat.key} radius="md">
          <Group spacing={8} p="sm" noWrap pb="xs">
            <stat.icon color={theme.colors.gray[6]} />
            <Text fz={"sm"} fw={600} color={theme.colors.gray[6]}>
              {stat.name}
            </Text>
          </Group>
          <Group>
            <div className={classes.grdnt}></div>
            <Space />
            <Text fz="xl" fw={500}>
              {stat.count || 0}
            </Text>
          </Group>
          <Group position="right" p={"sm"}>
            <Text size={"xs"} color={theme.colors.gray[6]}>
              Өмнөх өдрөөс
            </Text>
            <Badge color={stat.changedCount < 0 ? "red" : "green"} className={stat.changedCount < 0 ? classes.red : classes.green}>
              <Flex align={"center"} gap={8}>
                <DiffIcon size={"0.8rem"} stroke={3} />
                <Text>
                  {stat.changedCount < 0 && "-"}
                  {Math.abs(stat.changedCount)} %
                </Text>
              </Flex>
            </Badge>
          </Group>
        </Paper>
      </Grid.Col>
    );
  });

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      valueDecimals: 0,
      valueSuffix: " ",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (pieData || []).map((i) => ({
          name: i?.name,
          y: i?.count,
          color: i?.color,
        })),
      },
    ],
  });

  return (
    <Grid>
      {stats}
      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Бүтээгдэхүүний статус</Text>
            </Group>
            <DatePicker
              value={nowMoment}
              maxDate={nowMoment}
              minDate={dayjs(nowMoment).subtract(90, "days").toDate()}
              rightSection={<IconCalendarEvent color="gray" />}
              placeholder="Өдөр сонгох"
              mr={15}
            />
          </Group>
          <Divider mr={15} ml={15} />
          <Flex justify="space-around" align="center">
            <Group w="100%">
              <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(pieData || []).map((c: IPByPie, index: number) => {
                return (
                  <Group position="apart" key={index} align="start" ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <Text fw={500} color="#14182d">
                        {c?.name || "-"}
                      </Text>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} color="#557d94">
                        {c?.count || 0}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={4} xl={3}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд идэвхжсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(recentActivated || []).map((c: IPRecentActivated, index: number) => {
              return (
                <Group position="apart" key={index} align="start" style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <div>
                      <Text fw={500} color="#14182d">
                        {c?.name || "-"}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.refCode || "-"}
                      </Text>
                    </div>
                  </Group>
                  <Text fw={500} color="#557d94" mr={5}>
                    {dateFormat(c?.activatedDate)}
                  </Text>
                </Group>
              );
            })}
          </Flex>
        </Paper>
      </Grid.Col>
      <Grid.Col md={12} lg={8} xl={9}>
        <Paper withBorder py="4px">
          <Group position="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Сүүлд орсон бүтээгдэхүүний орлого</Text>
            </Group>
            <Anchor size="sm" px="xs" fw={500} onClick={() => {}} c="#317169">
              Бүгдийг харах
            </Anchor>
          </Group>
          <Paper px="lg" style={{ overflow: "auto" }}>
            <Table miw="max-content">
              <thead>
                <tr>
                  <th>Огноо, цаг</th>
                  <th>Дансны дугаар</th>
                  <th>Банкны нэр</th>
                  <th>Орлогын дүн</th>
                  <th>Гүйлгээний төрөл</th>
                  <th>Шилжүүлсэн нэр</th>
                </tr>
              </thead>
              <tbody>
                {(recentTrx || []).map((item: IPRecentTrx, index: number) => (
                  <tr key={index}>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {dateFormat(item.date)}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.number}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.bankName}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {tugrug(item.amount)}
                      </Text>
                    </td>
                    <td>
                      <Badge size="md" radius="sm" variant="outline">
                        {(accountTypes || []).find((c: IReference) => c.code === item.type)?.name || "-"}
                      </Badge>
                    </td>
                    <td>
                      <Text size="sm" weight={500} c="#44566C">
                        {item.payerAccountNumber}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: "#317169",
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
