import React from "react";
import { Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfFundingProvidedList } from "./list";
import { ScfFundingProvidedSupplierList } from "../../../components/scf-funding-provided/supplier-list";
import { useNavigate, useParams } from "react-router";
import { IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function ScfFundingProvidedTabs() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "FINANCE");
  const titles = (type: string) => {
    switch (type) {
      case "FINANCE": {
        return "Олгосон санхүүжилт";
      }
      case "PAY": {
        return "Олгосон санхүүжилт";
      }
      case "SUPPLIER": {
        return "Олгосон санхүүжилт";
      }
    }
  };

  const title = (type: string) => {
    switch (type) {
      case "FINANCE": {
        return "Supplier LED олгосон санхүүжилт жагсаалт";
      }
      case "PAY": {
        return "Дараа төлөх олгосон санхүүжилт жагсаалт";
      }
      case "SUPPLIER": {
        return "Supplier LED олгосон санхүүжилт жагсаалт";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/scf/funding-provided/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <PageLayout title={title(tabKey as string)} subTitle="Нийлүүлэгчээс ирсэн эрт санхүүжилтийн олгосон санхүүжилтын жагсаалт." breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_COMPLETED_REQUEST_BL").length > 0 && (
              <Tabs.Tab value="FINANCE" icon={<IconShoppingCart color={tabKey === "FINANCE" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Buyer LED
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="PAY" icon={<IconCreditCard color={tabKey === "PAY" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Дараа төлөх
              </Text>
            </Tabs.Tab> */}

            {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_COMPLETED_REQUEST_SL").length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Supplier LED
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Space h={10} />
          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_COMPLETED_REQUEST_BL").length > 0 && (
            <Tabs.Panel value="FINANCE">
              <ScfFundingProvidedList />
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="PAY">
            <ScfFundingRequestBuyerList />
          </Tabs.Panel> */}

          {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_COMPLETED_REQUEST_SL").length > 0 && (
            <Tabs.Panel value="SUPPLIER">
              <ScfFundingProvidedSupplierList />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: type,
  },
];
