import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MantineProvider } from "@mantine/core";
import { store, persistor } from "./store";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle, themeConfig } from "./mantine";
import "./styles/base.css";
import App from "./App";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MantineProvider theme={themeConfig} withGlobalStyles withNormalizeCSS>
            <ModalsProvider>
              <GlobalStyle />
              <NotificationsProvider position="top-right">
                <App />
              </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
