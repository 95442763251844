import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { Form } from "../form";
import { currencyRegex, percent, percentZero } from "../../utils";
import { Button, Divider, Flex, Grid, Text } from "@mantine/core";
import { NumberPercentField } from "../form/number-percent-field";
import { TextField } from "../form/text-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { IconSend } from "@tabler/icons";
import { IReference } from "../../models/General";
import { IAuth } from "../../interfaces/IAuth";

export type IBuyerFeeFormData = {
  suppFeeRule: string;
  buyerPenaltyType: string;
  buyerFeeRule: string;
  suppPenaltyType: string;
  borrowerType: string;
  suppFee: number;
  suppCalculateDay: number;
  suppAppFee: number;
  suppDisbursementFee: number;
  suppPenaltyPercent: number;
  buyerFee: number;
  buyerCalculateDay: number;
  buyerAppFee: number;
  buyerDisbursementFee: number;
  buyerPenaltyPercent: number;
  toReview: boolean;
};

const schema = yup.object({
  suppFeeRule: yup.string().required("Заавал бөглөнө!").nullable(),
  buyerPenaltyType: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerFeeRule: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
  suppPenaltyType: yup.string().required("Заавал бөглөнө!").nullable(),
  suppMinFee: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppFee: yup.string().matches(percent, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppCalculateDay: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppAppFee: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppDisbursementFee: yup.string().matches(percentZero, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(), // aa хамгийн багадаа 0 түүнээс дээш
  suppPenaltyPercent: yup.string().matches(percentZero, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(), // aa 0 их байха ёстой
  buyerFee: yup
    .string()
    .matches(percent, "Та дахин шалгана уу!")
    .when("borrowerType", {
      is: "BUYER",
      then: yup.string().matches(percent, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
    }),
  buyerCalculateDay: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerAppFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerDisbursementFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(percentZero, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerPenaltyPercent: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(percentZero, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerMinFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
});

export function BuyerProductFeeForm({ onSubmit: _onSubmit, action }: { onSubmit: (values: any, type: boolean) => void; action: any }) {
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productBuyerFeeRules, productSuppFeeRules, productBuyerPenaltyTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [type, setType] = React.useState(false);
  const [data] = React.useState<IBuyerFeeFormData>({
    suppFee: "",
    suppCalculateDay: "",
    suppAppFee: "",
    suppDisbursementFee: "",
    suppFeeRule: "",
    suppPenaltyType: "",
    suppPenaltyPercent: "",
    buyerFee: "",
    buyerCalculateDay: "",
    buyerAppFee: "",
    buyerDisbursementFee: "",
    buyerFeeRule: "",
    buyerPenaltyType: "",
    buyerPenaltyPercent: "",

    suppMinFee: "",
    toReview: false,

    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = (value: IBuyerFeeFormData) => {
    _onSubmit(value, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="lg">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="НИЙЛҮҮЛЭГЧИЙН ТӨЛӨХ ШИМТГЭЛИЙН ТОХИРГОО" />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberPercentField name="suppFee" label="Нийлүүлэгчийн шимтгэл:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="suppCalculateDay"
                  label="Шимтгэлийг тооцох хоног:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  precision={2}
                  min={0}
                  max={999999999999}
                  name="suppAppFee"
                  label="Өргөдлийн хураамж:"
                  placeholder="Үнийн дүнг оруулна уу"
                  required
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberPercentField
                  name="suppDisbursementFee"
                  label="Олголтын шимтгэл:"
                  placeholder="Хувийг оруулна уу"
                  max={100}
                  min={0}
                  precision={2}
                  required
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SelectField
                  name="suppFeeRule"
                  label="Шимтгэл авах дүрэм:"
                  placeholder="Сонгох"
                  options={(productSuppFeeRules || [])
                    .filter((c) => c.code === "DISBURSEMENT")
                    .map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField
                  name="suppPenaltyPercent"
                  label="Хугацаа хэтэрсний алданги:"
                  placeholder="Хувийг оруулна уу"
                  max={100}
                  min={0.01}
                  precision={2}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="suppPenaltyType"
                  label="Алданги тооцох дүрэм:"
                  placeholder="Сонгох"
                  options={(productBuyerPenaltyTypes || []).map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  precision={2}
                  min={0}
                  max={999999999999}
                  name="suppMinFee"
                  label="Миннимум шимтгэлийн дүн:"
                  placeholder="Дүнг оруулна уу"
                  required
                />
              </Grid.Col>

              {data.borrowerType !== "SUPPLIER_ONLY" && (
                <>
                  <Grid.Col span={12} mt="lg">
                    <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ ШИМТГЭЛ ТОХИРУУЛАХ" />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberPercentField
                      name="buyerFee"
                      label="Худалдан авагчийн шимтгэл:"
                      placeholder="Хувийг оруулна уу"
                      max={100}
                      min={0}
                      precision={2}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="buyerCalculateDay"
                      label="Шимтгэлийг тооцох хоног:"
                      placeholder="Хоногийг оруулна уу"
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NumberCurrencyField
                      precision={2}
                      min={0}
                      max={999999999999}
                      name="buyerAppFee"
                      label="Өргөдлийн хураамж:"
                      placeholder="Үнийн дүнг оруулна уу"
                      required
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberPercentField
                      name="buyerDisbursementFee"
                      label="Олголтын шимтгэл:"
                      placeholder="Хувийг оруулна уу"
                      max={100}
                      min={0}
                      precision={2}
                      required
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberCurrencyField
                      precision={2}
                      min={0}
                      max={999999999999}
                      name="buyerMinFee"
                      label="Миннимум шимтгэлийн дүн:"
                      placeholder="Дүнг оруулна уу"
                      required
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <SelectField
                      name="buyerFeeRule"
                      label="Шимтгэл авах дүрэм:"
                      placeholder="Сонгох"
                      options={(productBuyerFeeRules || [])
                        .filter((c: IReference) => c.code === "DISBURSEMENT")
                        .map((c) => ({
                          label: c.name,
                          value: c.code,
                        }))}
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberPercentField
                      name="buyerPenaltyPercent"
                      label="Хугацаа хэтэрсний алданги:"
                      placeholder="Дүнг оруулна уу"
                      max={100}
                      min={0}
                      precision={2}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      name="buyerPenaltyType"
                      label="Алданги тооцох дүрэм:"
                      placeholder="Сонгох"
                      options={(productBuyerPenaltyTypes || []).map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                    />
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/scf-dcf-product/dcf-product")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" disabled={data.borrowerType === null} onClick={() => setType(false)}>
                  Хадгалах
                </Button>

                {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isEdit).length > 0 && (
                  <Button
                    rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />}
                    type="submit"
                    disabled={data.borrowerType === null}
                    onClick={() => setType(true)}>
                    Хяналтад илгээх
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
