import { useState } from "react";
import * as yup from "yup";
import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import { AuthApi, RequestApi } from "../../apis";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: any;
};

type Props = {
  action: any;
  onCancel: (e: any) => void;
  title: string;
  subTitle: string;
  reload: () => void;
  setPinCheck: any;
  pinCheck: any;
};

export function FinanceRequestPinForm({ action, onCancel, title, subTitle, reload, setPinCheck, pinCheck }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData>({
    pin: null,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: values?.pin });

      if (pinCheck[1]?.finance === "CONFIRM") {
        await RequestApi.respond(action?.id, {
          respondDesc: pinCheck[1].respondDesc,
          approvedAmount: pinCheck[1].approvedAmount,
          isApproved: pinCheck[1]?.finance === "CONFIRM" ? true : false,
        });
        Message.success("Санхүүжилтын хүсэлтийг амжилттай зөвшөөрсөн.");
      } else {
        await RequestApi.respond(action?.id, { respondDesc: pinCheck[1].respondDesc, isApproved: pinCheck[1]?.finance === "CONFIRM" ? true : false });
        Message.success("Санхүүжилтын хүсэлтийг татгалзсан.");
      }
      onCancel && onCancel([]);
      reload();
      setPinCheck([]);
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setPinCheck([])} />]}>
            <LoadingOverlay visible={loading} />
            <Divider mb="sm" />
            <Grid>
              <Grid.Col span={12}>
                <Alert
                  styles={{ message: { color: "#44566c", fontWeight: 500, textAlign: "justify" } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Таны анхааралд">
                  Та {`${action?.requestedBusiness?.profileName}`}, {`${action?.requestedBusiness?.refCode}`}-с ирсэн
                  {pinCheck[1]?.finance === "CONFIRM" ? " хүсэлтийг зөвшөөрөх гэж байна." : " хүсэлтийг татгалзах гэж байна."}
                </Alert>
              </Grid.Col>
              <Grid.Col span={12}>
                <OtpField name="pin" label="ПИН код" placeholder="" required />
              </Grid.Col>

              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
            </Grid>
            <Divider my="lg" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => setPinCheck([])}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit">
                  Баталгаажуулах
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
