/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Avatar, Button, Checkbox, CloseButton, Divider, Flex, Grid, Group, Input, LoadingOverlay, Modal, Select, Text, TextInput } from "@mantine/core";
import { TextareaField } from "../form/textarea-field";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { SelectField } from "../form/select-field";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import useUser from "../../hooks/user";
import { dateSecFormat } from "../../utils/date";
import useSwr, { mutate } from "swr";
import { ReminderApi } from "../../apis";
import { TextField } from "../form/text-field";
import { IconPlus } from "@tabler/icons";
import { FormLayout } from "../layout";
import noimage from "../../assets/no-image.png";
import { IReference } from "../../models/General";

const schema = yup.object({});

type Props = {
  onCancel?: () => void;
  action: any;
  reload?: any;
};

type IFormData = {
  receiverUserId: string;
};

export function CollectionPaymentForm({ action, onCancel, reload }: Props) {
  const { user } = useUser();
  const nowMoment = new Date();
  const { reminderTypes, reminderResults, reminderLevels, reminderResponses, reminderConnectTypes, repaymentOverDueStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [loading] = React.useState<boolean>(false);
  const [datas] = React.useState<IFormData>({
    receiverUserId: "",
  });

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={datas}>
      {() => {
        return (
          <FormLayout
            title="Дэлгэрэнгүй"
            subTitle="Төлөлт сануулгын дэлгэрэнгүй мэдээлэл"
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Divider mb="lg" />
            <Grid>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүү ажилтан">
                  <Text color="indigo" fw={600}>
                    {action[1]?.financeStaff?.lastName || "-"}, {action[1]?.financeStaff?.firstName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар">
                  <Text color="indigo" fw={600}>
                    {action[1]?.repayment?.payerBusiness?.regNumber || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бизнесийн нэр">
                  <Text color="indigo" fw={600}>
                    {action[1]?.repayment?.payerBusiness?.refCode || "-"}, {action[1]?.repayment?.payerBusiness?.profileName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнерийн нэр">
                  <Text color="indigo" fw={600}>
                    {action[1]?.repayment?.payerBusiness?.partner?.refCode || "-"}, {action[1]?.repayment?.payerBusiness?.partner?.businessName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтрэлт">
                  <Text color="indigo" fw={600}>
                    {repaymentOverDueStatus.find((c: any) => c.code === action[1]?.repayment?.overDueStatus)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Эргэн төлөлт нэхэмжлэх">
                  <Text color="indigo" fw={600}>
                    {action[1]?.repayment?.refCode || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Холболт төрөл">
                  <Text color="indigo" fw={600}>
                    {reminderConnectTypes.find((item2: IReference) => item2?.code === action[1]?.connectType)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Сануулгын төрөл">
                  <Text color="indigo" fw={600}>
                    {reminderTypes.find((item2: IReference) => item2?.code === action[1]?.reminderType)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Сануулгын зэрэглэл">
                  <Text color="indigo" fw={600}>
                    {reminderLevels.find((item2: IReference) => item2?.code === action[1]?.reminderLevel)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Холбогдсон үр дүн">
                  <Text color="indigo" fw={600}>
                    {reminderResults.find((item2: IReference) => item2?.code === action[1]?.result)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Амлалтын хариу">
                  <Text color="indigo" fw={600}>
                    {reminderResponses.find((c: any) => c.code === action[1]?.response)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагчид очих тэмдэглэл">
                  <Text color="indigo" fw={600}>
                    {action[1]?.clientText || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Дотоод тэмдэглэл">
                  <Text color="indigo" fw={600}>
                    {action[1]?.internalText || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </FormLayout>
        );
      }}
    </Form>
  );
}
