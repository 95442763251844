import { createStyles, Grid, Image } from "@mantine/core";
import { GridCard } from "../components/home/card";
import Layout from "../components/home/layout";
import BackgroundLine from "../assets/background-line.svg";
import { navigationData, NavigationItem } from "../constants/navigation";
import { useSelector } from "react-redux";
import { IAuth } from "../interfaces/IAuth";
import { IPermissions } from "../interfaces/IPermissions";

export default function Homepage() {
  const { classes } = useStyles();

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const permissions = (user?.permissions || []).map((a: IPermissions) => a.module);
  const granttedModules: string[] = (permissions || [])?.filter(function (item: any, index: any) {
    return permissions.indexOf(item) === index;
  });

  return (
    <Layout>
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <Grid className={classes.grid}>
          {(navigationData || []).map((data: NavigationItem, index) => {
            return (
              <Grid.Col xs={6} md={4} lg={4} xl={3} key={index}>
                <GridCard
                  data={{
                    ...data,
                    disabled: granttedModules?.includes(data?.key as any) ? false : true,
                  }}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    marginTop: 8,
    maxWidth: "1000px",
    fontSize: 14,
    color: "white",
  },
  grid: {
    marginTop: 30,
  },
  video: {
    width: "600px",
    backgroundColor: "gray",
    height: 150,
  },
  text: {
    marginTop: -5,
    fontSize: 64,
    fontWeight: 800,
    color: "white",
  },
}));
