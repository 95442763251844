import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";
import { IStaffs } from "../models/General";

const initialState: IGeneral = {
  accountStatus: [],
  accountTypes: [],
  accounts: [],
  businessSectors: [],
  productActiveTypes: [],
  productBorrowerTypes: [],
  productBuyerFeeRules: [],
  productBuyerPenaltyTypes: [],
  productCategories: [],
  productEffectiveTypes: [],
  productExpiresTypes: [],
  productFundTypes: [],
  productInactiveTypes: [],
  productOverDueLimitTypes: [],
  productRegistrationStatus: [],
  productStatus: [],
  productSuppFeeRules: [],
  productSuppPenaltyTypes: [],
  productTypes: [],
  programActiveTypes: [],
  programAnchorRoles: [],
  programFeeDeductionRules: [],
  programFundTypes: [],
  programInactiveTypes: [],
  programParticipantStatus: [],
  programRegistrationStatus: [],
  programRepaymentRules: [],
  programRestDayRules: [],
  programReviewStatus: [],
  programStatus: [],
  programTypes: [],
  userStatus: [],
  departmentUnits: [],
  employeeUnits: [],
  borrowerTypes: [],
  lbfProductInterestTypes: [],
  roles: [],
  invoiceOverdueStatus: [],
  programHistoryTypes: [],
  references: [],
  businessTypes: [
    {
      code: "SUPPLIER",
      name: "Нийлүүлэгч",
    },
    {
      code: "BUYER",
      name: "Нийлүүлэгч",
    },
  ],
  currencies: [],
  invitationStatus: [],
  cpEmployeeUnits: [],
  userRegisterStatus: [],

  invoicePaymentStatus: [],
  invoicePaymentDueStatus: [],
  invoiceTypes: [],
  invoiceStatus: [],
  repaymentOverDueStatus: [],
  repaymentPaymentStatus: [],
  repaymentStatus: [],
  fundingInstructionTypes: [],
  partnerClassifications: [],
  partnerTypes: [],
  partnerLegalEntityTypes: [],

  mapped_by_modules_permissions: {},
  permissions: [],
  modules: [],

  paymentMethod: [],
  transactionTypes: [],
  transactionStatus: [],
  repaymentHistoryPaymentTypes: [],
  networkDiscountTypes: [],
  networkPenaltyTypes: [],
  requestStatus: [],
  actions: [],
  users: [],
  reminderTypes: [],
  reminderResults: [],
  reminderResponses: [],
  reminderLevels: [],
  reminderConnectTypes: [],
  paymentTermRules: [],
  fundingInstructionStatus: [],
  staffs: [],
  reminderListTypes: [],
  settlementStatus: [],
  partnerEquityTypes: [],
  productHistoryTypes: [],
  clientTypes: [],
  clientStaffLoginTypes: [],
  clientFinanceRoleStatus: [],
  golomtLegalEntityTypes: [],

  lbfProductActiveTypes: [],
  lbfProductInactiveTypes: [],

  lbfProgramStatus: [],
  lbfProgramTypes: [],
  lbfProgramRegistrationStatus: [],
  lbfParticipantStatus: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        accountStatus,
        accountTypes,
        accounts,
        lbfProductInterestTypes,
        businessSectors,
        invoiceOverdueStatus,
        productActiveTypes,
        productBorrowerTypes,
        references,
        productBuyerFeeRules,
        productBuyerPenaltyTypes,
        productCategories,
        productEffectiveTypes,
        productExpiresTypes,
        productFundTypes,
        borrowerTypes,
        productInactiveTypes,
        productOverDueLimitTypes,
        productRegistrationStatus,
        productStatus,
        productSuppFeeRules,
        productSuppPenaltyTypes,
        productTypes,
        programActiveTypes,
        programAnchorRoles,
        programFeeDeductionRules,
        programFundTypes,
        programInactiveTypes,
        programParticipantStatus,
        programRegistrationStatus,
        programRepaymentRules,
        programRestDayRules,
        programReviewStatus,
        programStatus,
        programTypes,
        userStatus,
        departmentUnits,
        employeeUnits,
        roles,
        currencies,
        invitationStatus,
        cpEmployeeUnits,
        invoicePaymentStatus,
        invoicePaymentDueStatus,
        invoiceTypes,
        invoiceStatus,
        repaymentOverDueStatus,
        repaymentPaymentStatus,
        repaymentStatus,
        fundingInstructionTypes,
        permissions,
        partnerClassifications,
        partnerTypes,
        partnerLegalEntityTypes,
        modules,
        paymentMethod,
        transactionTypes,
        transactionStatus,
        repaymentHistoryPaymentTypes,
        networkDiscountTypes,
        networkPenaltyTypes,
        requestStatus,
        actions,
        users,
        reminderTypes,
        reminderResults,
        reminderResponses,
        reminderLevels,
        reminderConnectTypes,
        paymentTermRules,
        fundingInstructionStatus,
        staffs,
        reminderListTypes,
        settlementStatus,
        partnerEquityTypes,
        programHistoryTypes,
        productHistoryTypes,
        clientTypes,
        clientStaffLoginTypes,
        clientFinanceRoleStatus,
        userRegisterStatus,
        golomtLegalEntityTypes,

        lbfProductActiveTypes,
        lbfProductInactiveTypes,
        lbfProgramStatus,
        lbfProgramTypes,
        lbfProgramRegistrationStatus,
        lbfParticipantStatus,
      } = payload;

      return {
        ...state,
        accountStatus: accountStatus,
        accountTypes: accountTypes,
        accounts: accounts,
        businessSectors: businessSectors,
        productActiveTypes: productActiveTypes,
        productBorrowerTypes: productBorrowerTypes,
        productBuyerFeeRules: productBuyerFeeRules,
        productBuyerPenaltyTypes: productBuyerPenaltyTypes,
        productCategories: productCategories,
        productEffectiveTypes: productEffectiveTypes,
        lbfProductInterestTypes: lbfProductInterestTypes,
        productExpiresTypes: productExpiresTypes,
        productFundTypes: productFundTypes,
        productInactiveTypes: productInactiveTypes,
        productOverDueLimitTypes: productOverDueLimitTypes,
        productRegistrationStatus: productRegistrationStatus,
        productStatus: productStatus,
        productSuppFeeRules: productSuppFeeRules,
        productSuppPenaltyTypes: productSuppPenaltyTypes,
        productTypes: productTypes,
        programActiveTypes: programActiveTypes,
        programAnchorRoles: programAnchorRoles,
        programFeeDeductionRules: programFeeDeductionRules,
        programFundTypes: programFundTypes,
        programInactiveTypes: programInactiveTypes,
        borrowerTypes: borrowerTypes,
        programParticipantStatus: programParticipantStatus,
        programRegistrationStatus: programRegistrationStatus,
        programHistoryTypes: programHistoryTypes,
        programRepaymentRules: programRepaymentRules,
        programRestDayRules: programRestDayRules,
        programReviewStatus: programReviewStatus,
        programStatus: programStatus,
        programTypes: programTypes,
        userStatus: userStatus,
        departmentUnits: departmentUnits,
        employeeUnits: employeeUnits,
        roles: roles,
        currencies: currencies,
        invitationStatus: invitationStatus,
        cpEmployeeUnits: cpEmployeeUnits,
        invoicePaymentStatus: invoicePaymentStatus,
        repaymentOverDueStatus: repaymentOverDueStatus,
        repaymentPaymentStatus: repaymentPaymentStatus,
        repaymentStatus: repaymentStatus,
        fundingInstructionTypes: fundingInstructionTypes,
        invoicePaymentDueStatus: invoicePaymentDueStatus,
        invoiceTypes: invoiceTypes,
        invoiceStatus: invoiceStatus,
        references: references,

        partnerClassifications: partnerClassifications,
        partnerTypes: partnerTypes,
        partnerLegalEntityTypes: partnerLegalEntityTypes,
        paymentMethod: paymentMethod,
        transactionTypes: transactionTypes,
        transactionStatus: transactionStatus,
        repaymentHistoryPaymentTypes: repaymentHistoryPaymentTypes,
        networkDiscountTypes: networkDiscountTypes,
        networkPenaltyTypes: networkPenaltyTypes,
        requestStatus: requestStatus,
        actions: actions,
        users: users,
        reminderTypes: reminderTypes,
        reminderResults: reminderResults,
        reminderResponses: reminderResponses,
        reminderLevels: reminderLevels,
        reminderConnectTypes: reminderConnectTypes,
        paymentTermRules: paymentTermRules,
        fundingInstructionStatus: fundingInstructionStatus,
        reminderListTypes: reminderListTypes,
        clientTypes: clientTypes,
        clientStaffLoginTypes: clientStaffLoginTypes,
        clientFinanceRoleStatus: clientFinanceRoleStatus,
        userRegisterStatus: userRegisterStatus,
        golomtLegalEntityTypes: golomtLegalEntityTypes,
        lbfProductActiveTypes: lbfProductActiveTypes,
        lbfProductInactiveTypes: lbfProductInactiveTypes,

        lbfProgramStatus: lbfProgramStatus,
        lbfProgramTypes: lbfProgramTypes,
        lbfProgramRegistrationStatus: lbfProgramRegistrationStatus,
        lbfParticipantStatus: lbfParticipantStatus,

        modules: modules,
        permissions: permissions,
        mapped_by_modules_permissions: permissions.reduce((acc, it) => {
          acc[it.module] = [
            ...(acc[it.module] || []),
            {
              ...it,
              isCreate: false,
              isDelete: false,
              isEdit: false,
              isReview: false,
              isView: false,
            },
          ];
          return acc;
        }, {}),

        staffs: staffs,
        settlementStatus: settlementStatus,
        partnerEquityTypes: partnerEquityTypes,
        productHistoryTypes: productHistoryTypes,
        invoiceOverdueStatus: invoiceOverdueStatus,
      };
    },

    updateStaffs: (state, { payload }: { payload: IStaffs[] }) => {
      state.staffs = payload;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral, updateStaffs } = generalSlice.actions;
