import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/request/buyer_led", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/scf/request/buyer_led/${id}`);
};

export const respond = async (id: string, data: any) => {
  return httpRequest.put(`/api/scf/request/buyer_led/${id}/respond`, data);
};

export const completed = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/request/buyer_led/completed", { offset, filter });
};

export const getSupplier = async (id: string) => {
  return httpRequest.get(`/api/scf/request/supplier_led/${id}`);
};

export const listSupplier = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/request/supplier_led", { offset, filter });
};

export const request = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/request/supplier_led/requests", { offset, filter });
};

export const respondSupplier = async (id: string, data: any) => {
  return httpRequest.put(`/api/scf/request/supplier_led/${id}/respond`, data);
};

export const requestCompolete = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/scf/completed_request", { offset, filter });
};

export const invite = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/client/request", { offset, filter });
};

export const gets = async (id: string) => {
  return httpRequest.get(`/api/client/request/${id}`);
};

export const responds = async (data: { partnerId: string; clientType: string }) => {
  return httpRequest.put(`/api/client/request/respond`, data);
};

export const respondUser = async (id: string, data: { userId: string; staffLoginType: string; isApprove: boolean }) => {
  return httpRequest.put(`/api/client/${id}/user_respond`, data);
};

export const userInvite = async (id: string, data: { userId: string }) => {
  return httpRequest.put(`/api/client/${id}/user_invite`, data);
};

export const info = async (regNumber: string) => {
  return httpRequest.get(`/api/client/request/${regNumber}/bogd_info`);
};

export const business = async (query: string) => {
  return httpRequest.get(`/api/scf/request/supplier_led/business_select/${query}`);
};
export const program = async (query: string) => {
  return httpRequest.get(`/api/scf/request/supplier_led/program_select/${query}`);
};
