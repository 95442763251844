import { useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Paper } from "@mantine/core";
import { ScfDcfReferenceLevel1 } from "../../../components/scf-dcf-reference/level-1-list";
import { ScfDcfReferenceLevel2 } from "../../../components/scf-dcf-reference/level-2-list";
import { ScfDcfReferenceLevel3 } from "../../../components/scf-dcf-reference/level-3-list";

export function ScfDcfReferenceLevel() {
  const params = useParams();
  const titles = (val: string) => {
    switch (val) {
      case "1": {
        return "Экономикал Сектор 1";
      }

      case "2": {
        return "Экономикал Сектор 2";
      }
      case "3": {
        return "Экономикал Сектор 3";
      }
    }
  };

  const subTitle = (val: string) => {
    switch (val) {
      case "1": {
        return "Экономикал Сектор 1-ийн нийт мэдээлэл";
      }

      case "2": {
        return "Экономикал Сектор 2-ийн нийт мэдээлэл";
      }
      case "3": {
        return "Экономикал Сектор 3-ийн нийт мэдээлэл";
      }
    }
  };

  const breadcrumbs = useBreadcrumb(titles(params.step as string));

  return (
    <PageLayout title="Экономикал Сектор" subTitle={subTitle(params.step as string)} breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        {params?.step === "1" && <ScfDcfReferenceLevel1 />}
        {params?.step === "2" && <ScfDcfReferenceLevel2 />}
        {params?.step === "3" && <ScfDcfReferenceLevel3 />}
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн хөтөлбөр" },
  { label: "Лавлах мэдээлэл", to: "/scf-dcf/reference" },
  {
    label: type,
  },
];
