/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from "react";
import { ITableRef } from "../../../components/table";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Button, LoadingOverlay, Paper, Text, createStyles } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons";
import useSwr from "swr";
import { AccountApi, TransactionApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { AccountStatementList } from "../../../components/account/statement-list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function AccountStatement() {
  const params = useParams();
  const { theme } = useStyle();
  const nowMoment = new Date();
  const ref = useRef<ITableRef>(null);
  const [isUrl, setIsUrl] = React.useState<string>("DEFAULT");
  const breadcrumbs = useBreadcrumb(params.type);
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const { data } = useSwr(
    `/api/account/get/${params.id}`,
    async () => {
      if (
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0
      ) {
        setLoading(true);
        try {
          let res = await AccountApi.get(params.id as string);
          return res;
        } catch (err) {
          Message.error((err as HttpHandler).message!);
        }
        setLoading(false);
      }
      return [];
    },
    {},
  );

  var pastDate = nowMoment;
  var weekend = new Date(new Date().setDate(pastDate.getDate() - 7));

  const { data: accList } = useSwr(`/api/product/transaction/account/${params?.id}`, async () => {
    setLoading(true);
    try {
      let res = await TransactionApi.accList({
        filter: {
          accountId: `${params.id}`,
          inOutType: "ALL",
          startDate: weekend,
          endDate: nowMoment,
        },
      });
      if (res?.hasUrl) {
        window.open(res?.url);
        setIsUrl("TRUE");
      } else {
        setIsUrl("FALSE");
      }
    } catch (err) {
      Message.error((err as HttpHandler).message!);
    }
    setLoading(false);
  });

  return (
    <PageLayout
      title="Хуулга"
      subTitle="Сонгосон дансны хуулга."
      breadcrumb={breadcrumbs}
      extra={[
        <Button size="sm" key={3} onClick={() => navigate(`/scf-dcf-product/accounting/${params.type}`)} variant="outline" leftIcon={<IconArrowNarrowLeft />}>
          Данс жагсаалт
        </Button>,
      ]}>
      <LoadingOverlay visible={isUrl === "DEFAULT" && isLoading} />
      <Paper p="sm" withBorder>
        {isUrl !== "DEFAULT" && <AccountStatementList data={data} url={isUrl} />}
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн бүтээгдэхүүн" },
  {
    to: `/scf-dcf-product/accounting/${type}`,
    label: "Данс тохиргоо",
  },
  {
    label: "Хуулга",
  },
];

const useStyle = createStyles((theme) => ({}));
