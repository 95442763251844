import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Alert, Badge, Box, Button, Divider, Flex, Grid, Group, Input, Paper, Text, Textarea } from "@mantine/core";
import { ILbfProgram } from "../../interfaces/ILbfProgram";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../models/General";
import { dateFormat } from "../../utils/date";
import { IconAlertCircle } from "@tabler/icons";
import { useNavigate } from "react-router";
import { tugrug } from "../../utils";

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function FundingProgramBuyerLedDetailInformation({
  data,
  programSector1,
  programSector2,
  programSector3,
  onSuccess,
}: {
  data: ILbfProgram;
  programSector1: string;
  programSector2: string;
  programSector3: string;
  onSuccess: () => void;
}) {
  const navigate = useNavigate();
  const { programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [value] = React.useState<IFormData>({
    name: "",
  });

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={value}>
      {() => {
        return (
          <>
            <Grid>
              <Grid.Col span={12} mt={10}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн код:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.refCode || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Төрөл:">
                  <Flex align="center" gap={8}>
                    <Text fw={500} color="indigo" fz={"sm"}>
                      Buyer-LED,
                    </Text>
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      Зээл суурьтай
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Санхүүжилтийн бүтээгдэхүүн:">
                  <Flex align="center" gap={8}>
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {data?.lbfProduct?.name || "-"}, # {data?.lbfProduct?.refCode || "-"}
                    </Text>
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      ({"BL"})
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Flex align="center" gap={8}>
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {data?.regUser?.lastName !== null ? <>{data?.regUser?.lastName[0]}.</> : ""} {data?.regUser?.firstName || "-"}
                    </Text>
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      {dateFormat(data?.createdAt)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.color}>
                      {(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programStatus || []).find((item: IReference) => item.code === data?.lbfProgramStatus)?.color}>
                      {(programStatus || []).find((item: IReference) => item.code === data?.lbfProgramStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХӨТӨЛБӨРИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр: ">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хорооны шийдвэрийн дугаар: ">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.resolutionNo || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Шийдвэрийн огноо: ">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {dateFormat(data?.resolutionDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={12}>
                <Textarea value={data?.description} label="Тайлбар: " onChange={() => {}} />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Үндсэн сектор:">
                  <Group mt={"xs"}>
                    <Badge key={1} radius="sm" variant="outline" color="indigo">
                      {programSector1 || "-"}
                    </Badge>
                  </Group>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Сектор:">
                  <Group mt={"xs"}>
                    <Badge key={1} radius="sm" variant="outline" color="indigo">
                      {programSector2 || "-"}
                    </Badge>
                  </Group>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Дэд сектор:">
                  <Group mt={"xs"}>
                    <Badge key={1} radius="sm" variant="outline" color="indigo">
                      {programSector3 || "-"}
                    </Badge>
                  </Group>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider h={"lg"} />
                <Flex align="center" justify="space-between" pb={12}>
                  <Text color="dimmed" tt="uppercase" fw={500}>
                    АНКОРЫН МЭДЭЭЛЭЛ
                  </Text>
                </Flex>
                {!data.hasAnchor && (
                  <Paper>
                    <Alert
                      hidden={showAlert}
                      withCloseButton={true}
                      onClose={() => {
                        setShowAlert(true);
                      }}
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      icon={<IconAlertCircle size={16} />}
                      title="Таны хянах мэдээлэл!"
                      color="indigo">
                      Анкорын мэдээлэл байхгүй байна.
                    </Alert>
                  </Paper>
                )}
              </Grid.Col>

              {data?.anchor && (
                <>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Анкор партнерийн нэр:">
                      <Text color="indigo">
                        {data?.anchor?.partner?.businessName || "-"}, #{data?.anchor?.partner?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Анкор бизнесийн нэр:">
                      <Text color="indigo">
                        {data?.anchor?.profileName || "-"}, #{data?.anchor?.regNumber || "-"}
                        <></>
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөр дэх анкорын роль:">
                      <Text color="indigo">{data?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Харилцагч CIF">
                      <Text color="indigo">{data?.anchor?.clientCode || "-"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Нэхэмжлэх батлах санхүү">
                      <Text color="indigo">
                        {data?.anchor?.finUser?.lastName || "-"}, {data?.anchor?.finUser?.firstName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөрийн лимит дүн">
                      <Text color="indigo">{tugrug(data?.lbfProgramLimit || 0)}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Divider h="lg" mt="lg" />
            <Flex direction="row" justify="space-between">
              <Button onClick={() => navigate("/cf/funding-program/BUYER")} variant="default">
                Болих
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  navigate(`/cf/funding-program/BUYER/${data?.lbfProduct?.refCode}/detail/${data.id}/BUSINESS`);
                  onSuccess();
                }}
                variant="outline">
                Үргэлжлүүлэх
              </Button>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
