import React from "react";
import { Badge, ActionIcon, Button, Modal, Text, Paper } from "@mantine/core";
import { UserApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../table";
import { IconCircleCheck, IconX } from "@tabler/icons";
import { FormLayout } from "../layout";
import { UserControlRoleForm } from "./role-form";
import { IUserRole } from "../../interfaces/IUserRole";
import { dateFormat } from "../../utils/date";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";

export function UserRoleList({ data }: { data: any }) {
  const ref = React.useRef<ITableRef>(null);
  const [result, setResult] = React.useState<any>();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = React.useState<string[]>([]);
  const columns = useHeader();

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  return (
    <>
      <FormLayout
        title="Систеийн хэрэглэгч"
        subTitle="Систеийн хэрэглэгчийн эрхийн түүх"
        extra={[
          <>
            {(user?.permissions || []).filter((c) => c.module === "USER" && c.code === "USER_ROLE_SET").length > 0 && (
              <Button
                key={2}
                onClick={() => {
                  setAction(["add"]);
                }}>
                Эрх нэмэх
              </Button>
            )}
          </>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          {data && data[1]?.id ? (
            <Table
              ref={ref}
              name="user.control.role.list"
              columns={columns}
              filters={{ userId: data && data[1]?.id }}
              loadData={(data) => UserApi.history(data!)}
              onResult={(setData: string) => setResult(setData)}
              pagination={false}
            />
          ) : (
            ""
          )}
        </Paper>
      </FormLayout>
      <Modal opened={action[0] === "add"} onClose={() => setAction([])} withCloseButton={false} size="60%" centered>
        <FormLayout
          my={0}
          title={action[0] === "add" ? "Хэрэглэгчийн шинэ роль олгох" : "Системийн хэрэглэгч бүртгэх"}
          subTitle=""
          extra={[
            <ActionIcon onClick={() => setAction([])} key={1}>
              <IconX />
            </ActionIcon>,
          ]}>
          <UserControlRoleForm onCancel={onCancel} action={action} item={data} reload={reload} result={result} />
        </FormLayout>
      </Modal>
    </>
  );
}

const useHeader = (): ColumnType<IUserRole>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Роль",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.role?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return record.isActive ? (
        <Badge size="md" radius="sm" variant="outline" color="green">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge size="md" radius="sm" variant="outline" color="red">
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "Эхэлсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateFormat(record?.startDate)}
        </Text>
      );
    },
  },
  {
    title: "Дуусах",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateFormat(record?.endDate)}
        </Text>
      );
    },
  },
  {
    title: "Үүсгэсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.adminUserName || "-"}
        </Text>
      );
    },
  },
  {
    title: "",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <ActionIcon>
          <IconCircleCheck color="green" />
        </ActionIcon>
      );
    },
  },
];
