import React, { useEffect } from "react";
import { Avatar, Button, Divider, Flex, Grid, Group, Input, Paper, Text, Tooltip } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconInfoSquare, IconSearch } from "@tabler/icons";
import { AncorApi } from "../../../apis";
import { ColumnType, Table } from "../../../components/table";
import { useLocation, useNavigate } from "react-router";
import { useDebouncedState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IAncor } from "../../../interfaces/IAncor";
import noimage from "../../../assets/no-image.png";
import { IBusiness } from "../../../interfaces/IBusiness";
import { IReference } from "../../../models/General";
import { dateSecFormat } from "../../../utils/date";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function AncorCorporateList() {
  const breadcrumbs = useBreadcrumb();
  const [query, setQuery] = useDebouncedState("", 600);
  const { golomtLegalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/customer") && !location.pathname.includes("/customer/anchor")) {
      navigate("/customer/anchor", { replace: true });
    }
  }, [location, navigate]);

  const columns = useHeader({
    user,
    golomtLegalEntityTypes,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/customer/anchor/detail/${record?.id}`);
          break;
        }
        default:
      }
    },
  });

  return (
    <PageLayout title="Анкор корпорэйт" subTitle="Анкор корпорэйт харилцагчдын жагсаалт" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Flex direction="column" gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
          <Grid>
            <Grid.Col span={3}>
              <Input
                iconWidth={120}
                onChange={(e) => setQuery(e.currentTarget.value)}
                placeholder="хайлт хийх"
                rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
                icon={
                  <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                    <Text size="sm" weight={450} c="#44566C">
                      {"Бизнес нэр"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
                key={1}
              />
            </Grid.Col>
          </Grid>
          <Divider />
          <Table
            name="ancor-corporate-customer.list"
            columns={columns}
            filters={{
              query: query,
              type: "ANCHOR",
            }}
            loadData={(data) => AncorApi.business(data!)}
          />
        </Flex>
      </Paper>
    </PageLayout>
  );
}

const useHeader = ({
  user,
  onClick,
  golomtLegalEntityTypes,
}: {
  user: any;
  onClick: (key: string, record: IAncor) => void;
  golomtLegalEntityTypes: any;
}): ColumnType<IAncor>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_ANCHOR").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    width: 100,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчийн нэр",
    sorter: true,
    dataIndex: "regNumber",
    width: 100,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.clientName || "-"}
        </Text>
      );
    },
  },
  {
    title: "deHUB код, нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {record?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "ААН хэлбэр",
    sorter: true,
    dataIndex: "legalEntityType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {(golomtLegalEntityTypes || []).find((c: IReference) => c.code === record?.legalEntityType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "businessSubSector",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {dateSecFormat(record.createdAt) || "-"}
        </Text>
      );
    },
  },
  {
    title: "Зөвшөөрсөн",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.approvedUser?.lastName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {record?.approvedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Харилцагч удирдлага" },
  {
    label: "Анкор корпорэйт харилцагчид",
  },
];
