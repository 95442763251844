import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/product", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/lbf/product/${id}`);
};
export const create = async (data: {
  type: string | undefined;
  name: string;
  resolutionNo: string;
  description: string;
  minInvAmount: number;
  minOfInv: number;
  maxOfInv: number;
  minTenor: number;
  maxTenor: number;
  financeMinLimit: number;
  minDays: number;
  maxDays: number;
  bankProductTypeId: string;
}) => {
  return httpRequest.post("/api/lbf/product", data);
};

export const update = async (
  id: string,
  data: {
    name: string;
    resolutionNo: string;
    description: string;
    minInvAmount: number;
    minOfInv: number;
    maxOfInv: number;
    minTenor: number;
    maxTenor: number;
    financeMinLimit: number;
    minDays: number;
    maxDays: number;
    bankProductTypeId: string;
  },
) => {
  return httpRequest.put(`/api/lbf/product/${id}`, data);
};

export const both = async (id: string, data: any) => {
  return httpRequest.put(`/api/lbf/product/${id}/commission`, data);
};

export const toreview = async (id: string) => {
  return httpRequest.put(`/api/lbf/product/${id}/to_review`);
};

export const review = async (id: string, data: { review: boolean; reviewDesc: string }) => {
  return httpRequest.put(`/api/lbf/product/${id}/review`, data);
};

export const active = async (id: string, data: { activeType: string; statusDesc: string }) => {
  return httpRequest.put(`/api/lbf/product/${id}/activate`, data);
};

export const inactive = async (id: string, data: { inactiveType: string; statusDesc: string }) => {
  return httpRequest.put(`/api/lbf/product/${id}/deactivate`, data);
};

export const select = async (data: { query: string; type: string; category: string; limit: string }) => {
  return httpRequest.get(`/api/lbf/product/select`, data);
};

export const history = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/product/history", { offset, filter });
};
