import { Divider, LoadingOverlay, SegmentedControl } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCalendarEvent } from "@tabler/icons";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import useSwr from "swr";
import { ProductDashboardApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { ScfDcfProductDashboardReal } from "./real";
import { ScfDcfProductDashboardStatic } from "./static";

export function ScfDcfProductDashboard() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [value, setValue] = React.useState("static");
  const [date, setDate] = React.useState<Date | null>(null);
  const [debouncedDate] = useDebouncedValue(date, 600);
  const [pieDate, setPieDate] = React.useState<Date | null>(null);
  const [debouncedPieDate] = useDebouncedValue(pieDate, 600);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/scf-dcf-product") && !location.pathname.includes("/scf-dcf-product/dashboard")) {
      navigate("/scf-dcf-product/dashboard", { replace: true });
    }
  }, [location, navigate]);

  const { data, isLoading } = useSwr(`/api/product/dashboard/${debouncedDate}/${debouncedPieDate}`, async () => {
    try {
      let res = await ProductDashboardApi.get({
        date: date ? date.toISOString() : nowMoment.toISOString(),
        pieDate: pieDate ? pieDate.toISOString() : nowMoment.toISOString(),
      });

      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Тоон тойм"
      subTitle=""
      breadcrumb={breadcrumbs}
      extra={[
        <DatePicker
          key={1}
          value={date ? date : nowMoment}
          maxDate={nowMoment || date}
          onChange={setDate}
          placeholder="Өдөр сонгох"
          minDate={dayjs(nowMoment || date)
            .subtract(90, "days")
            .toDate()}
          rightSection={<IconCalendarEvent color="gray" />}
        />,
      ]}>
      <Divider />
      <>
        <SegmentedControl
          mt={3}
          mb={3}
          value={value}
          onChange={setValue}
          w={200}
          data={[
            { label: "Static", value: "static" },
            { label: "Real", value: "real" },
          ]}
          color="cyan[4]"
        />
        {value === "static" ? <ScfDcfProductDashboardStatic /> : <ScfDcfProductDashboardReal data={data} pieDate={pieDate} setPieDate={setPieDate} />}
      </>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн бүтээгдэхүүн",
  },
  {
    label: "Дашбоард",
  },
];
