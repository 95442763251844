import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/user/approval_rule", { offset, filter });
};

export const create = async (data: { module: string; systemAction: string; name: string; description: string; levels: any }) => {
  return httpRequest.post("/api/user/approval_rule", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/user/approval_rule/${id}`);
};

export const active = async (id: string) => {
  return httpRequest.put(`/api/user/approval_rule/${id}/active`);
};

export const inactive = async (id: string) => {
  return httpRequest.put(`/api/user/approval_rule/${id}/inactive`);
};
