import { DatePicker } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: any;
  nullable?: boolean;
  minDate?: any;
  maxDate?: any;
  required?: boolean;
  styles?: any;
  rightSection?: React.ReactNode;
  noError?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  error?: string;
};

export function DatePickerField({
  name,
  label,
  placeholder,
  disabled = false,
  icon,
  nullable,
  minDate,
  maxDate,
  required,
  rightSection,
  styles,
  noError = false,
  error,
  size,
}: Props) {
  const { value, error: err, onChange } = useField(name);

  return (
    <DatePicker
      size={size}
      maxDate={maxDate}
      minDate={minDate}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      inputFormat="MM-DD-YYYY"
      error={err || error}
      clearable={false}
      rightSection={rightSection}
      value={value ? new Date(value) : null}
      // onChange={(e: Date) => {
      //   onChange(new Date(e));
      // }}
      onChange={(e: Date) => {
        onChange(`${new Date(e)}`);
      }}
      required={required}
    />
  );
}
