/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Alert, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import { TextareaField } from "../form/textarea-field";
import { IconAlertCircle, IconCalendar, IconCurrencyTugrik } from "@tabler/icons";
import { DatePickerField } from "../form/date-picker-field";
import { RadioGroupField } from "../form/radio-group-field";
import { CheckboxField } from "../form/checkbox-field";
import { dateSecFormat } from "../../utils/date";
import { numberRegex, tugrug } from "../../utils";
import { NumberCurrencyField } from "../form/number-currency-field";
import swr from "swr";

const schema = yup.object({
  approvedAmount: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup
      .string()
      .min(1, "Хэтэрхий бага утга байна!")
      .max(16, "Хэтэрхий их утга байна!")
      .matches(numberRegex, "Тоо оруулна уу!")
      .required("Заавал бөглөнө үү!")
      .nullable(),
  }),
  respondDesc: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  finance: string;
  respondDesc: string;
  isApproved: boolean;
  approvedAmount: string;
  finance_board: boolean;
};

const financeType = [
  {
    name: "Санхүүжилтийг зөвшөөрөх",
    code: "CONFIRM",
  },
  {
    name: "Санхүүжилтийг татгалзах",
    code: "REJECT",
  },
];

export function ScfFinanceDecisionForm({
  action,
  setTabIndex,
  type,
  onSubmit: _onSubmit,
}: {
  action: any;
  onCancel?: () => void;
  setTabIndex: any;
  type: string;
  onSubmit: (values: any) => void;
}) {
  const [calculatedAmount, setCalculatedAmount] = React.useState<number>();
  const [calculatedRepaymentAmount, setCalculatedRepaymentAmount] = React.useState<number>();
  const [calculatedDisbursementFee, setCalculatedDisbursementFee] = React.useState<number>();
  const [calculatedScfFee, setCalculatedScfFee] = React.useState<number>();
  const [datas] = React.useState<IFormData>({
    isApproved: false,
    approvedAmount: "",
    respondDesc: "",
    finance: "",
    finance_board: false,
  });

  const { data } = swr(
    `/decision/data/${calculatedAmount}`,
    async () => {
      if (calculatedAmount === undefined) {
        return [];
      } else if (type === "BUYER") {
        setCalculatedDisbursementFee((calculatedAmount / 100) * action?.product?.buyerDisbursementFee);
        setCalculatedScfFee(calculatedAmount * action?.calculatedFeePercent * action?.financePeriod);
      } else if (type === "SUPPLIER") {
        setCalculatedRepaymentAmount(calculatedAmount);
        setCalculatedDisbursementFee((calculatedAmount / 100) * action?.product?.suppDisbursementFee);
        setCalculatedScfFee(calculatedAmount * action?.calculatedFeePercent * action?.financePeriod);
      }
    },
    {},
  );

  const onSubmit = (values: IFormData) => {
    _onSubmit(values);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
        {({ values }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТ АВАГЧ ТАЛ" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч партнер:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.partner?.businessName || "-"}, {action?.requestedBusiness?.partner?.refCode || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.regNumber || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч бизнес:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.profileName || "-"}, {action?.requestedBusiness?.refCode || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн огноо:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {dateSecFormat(action.respondedDate)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн ажилтан:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.respondedUser?.lastName || "-"} {action?.respondedUser?.firstName}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.program?.resolutionNo || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШИЙДВЭРИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                {action?.invoice?.paymentStatus !== "CLOSED" ? (
                  <>
                    <Grid.Col span={12}>
                      <RadioGroupField
                        name="finance"
                        label=""
                        options={financeType.map((c) => ({
                          label: c.name,
                          value: c.code,
                        }))}
                      />
                    </Grid.Col>

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.program?.disburseAcc?.number || "-"}, {action?.program?.disburseAcc?.name}, {action?.program?.disburseAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын дансны үлдэгдэл:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {tugrug(action?.program?.disburseAcc?.balance || "0")}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын данс шаардлага:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.program?.disburseAcc?.balanceAcceptable ? "Тийм" : "Үгүй"}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Өргөдлийн хураамж:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {tugrug(action?.appFee || "0")}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <>
                        <Grid.Col span={4}>
                          <NumberCurrencyField
                            name="approvedAmount"
                            label="Санхүүжилт олгох дүн:"
                            max={action?.requestedAmount}
                            placeholder="Санхүүжүүлэх дүн"
                            onChangeCustom={(e: number) => setCalculatedAmount(e)}
                            required
                          />
                          <Text c="dimmed" fz="xs" fw={500} style={{ color: "#FA5252" }}>
                            {tugrug(action?.requestedAmount || 0)} {"Та санхүүжилтийн хүсэлтийн дүнгээс бага дүн оруулах боломжтой."}
                          </Text>
                        </Grid.Col>
                      </>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Эргэн төлөлтийн нэхэмжлэх:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.repaymentInv?.refCode || "-"}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Санхүүжилтийн шимтгэл:">
                          <Box style={{ alignItems: "center" }}>
                            {values.approvedAmount === undefined ? (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(0)}
                              </Text>
                            ) : (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(calculatedScfFee as any)}
                              </Text>
                            )}
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Банкны шимтгэл:">
                          <Box style={{ alignItems: "center" }}>
                            {/* {!values?.approvedAmount ? ( */}
                            {!values.approvedAmount ? (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(0)}
                              </Text>
                            ) : (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {calculatedScfFee + action.appFee || "-"}
                              </Text>
                            )}
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Эргэн төлөх дүн:">
                          <Box style={{ alignItems: "center" }}>
                            {values.approvedAmount === undefined ? (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(0)}
                              </Text>
                            ) : (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {type === "BUYER"
                                  ? tugrug(
                                      values.approvedAmount +
                                        calculatedScfFee +
                                        calculatedDisbursementFee +
                                        action?.product?.buyerTrxFee +
                                        action?.product?.buyerAppFee,
                                    )
                                  : tugrug(calculatedRepaymentAmount as any)}
                              </Text>
                            )}
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Санхүүжилт авах данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.invoice?.senderAcc?.number}, {action?.invoice?.senderAcc?.name}, {action?.invoice?.senderAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="SCF орлого төвлөрүүлэх данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.program?.feeAcc?.number || "-"}, {action?.program?.feeAcc?.name}, {action?.program?.feeAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Эргэн төлөх данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action?.program?.repayAcc?.number || "-"}, {action?.program?.repayAcc?.name}, {action?.program?.repayAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    <Grid.Col span={12}>
                      <CheckboxField name="finance_board" label="">
                        {values.finance_board ? "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан" : "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан"}
                      </CheckboxField>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextareaField name="respondDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" />
                    </Grid.Col>
                  </>
                ) : (
                  <Grid.Col span={4}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Анхааруулга!"
                      color="red"
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      withCloseButton={false}>
                      Энэхүү хүсэлтийн нэхэмжлэх нь хаагдсан төлөвтэй тул санхүүжүүлэх боломжгүй байна.
                    </Alert>
                  </Grid.Col>
                )}
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button
                  variant="default"
                  onClick={() => {
                    setTabIndex("3");
                  }}>
                  Буцах
                </Button>
                <Flex gap="md">
                  {values.finance === "REJECT" && (
                    <Button variant="filled" color="red" disabled={!values.finance_board} type="submit">
                      Татгалзах
                    </Button>
                  )}
                  {values.finance === "CONFIRM" && (
                    <Button variant="filled" disabled={!values.finance_board} type="submit">
                      Зөвшөөрөх
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Form>
    </>
  );
}
