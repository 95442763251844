import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/product/account", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/product/account/${id}`);
};

export const update = async (
  id: string,
  data: {
    shortName: string;
    name: string;
    regNumber: string;
    accountStatus: string;
  },
) => {
  return httpRequest.put(`/api/product/account/${id}`, data);
};

export const create = async (data: { type: string; shortName: string; number: string; regNumber: string; accountStatus: string }) => {
  return httpRequest.post(`/api/product/account`, data);
};

export const checked = async (
  data: // { type: string; number: string; regNumber: string }
  any,
) => {
  return httpRequest.get(`/api/product/account/check`, data);
};
