import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/user", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/user/${id}`);
};

export const create = async (data: {
  familyName: string;
  lastName: string;
  firstName: string;
  registerNo: string;
  departmentUnit: string;
  employeeUnit: string;
  phone: string;
  phone2: string;
  email: string;
  email2: string;
  socialFbLink: string;
  send: boolean;
}) => {
  return httpRequest.post("/api/user", data);
};

export const update = async (
  id: string,
  data: {
    familyName: string;
    lastName: string;
    firstName: string;
    registerNo: string;
    departmentUnit: string;
    employeeUnit: string;
    phone: string;
    phone2: string;
    email: string;
    email2: string;
    socialFbLink: string;
    send: boolean;
  },
) => {
  return httpRequest.put(`/api/user/${id}`, data);
};

export const invite = async (id: string) => {
  return httpRequest.put(`/api/user/${id}/invite`);
};

export const history = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/user/role_history", { offset, filter });
};

export const assign = async (data: { userId: string; roleId: string; startDate: string; endDate: any }) => {
  return httpRequest.put("/api/user/role_assign", data);
};

export const active = async (id: string) => {
  return httpRequest.put(`/api/user/${id}/active`);
};

export const in_active = async (id: string) => {
  return httpRequest.put(`/api/user/${id}/inactive`);
};
