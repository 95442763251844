import React, { useEffect } from "react";
import { Divider, LoadingOverlay, SegmentedControl } from "@mantine/core";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import useSwr from "swr";
import { useDebouncedValue } from "@mantine/hooks";
import { CollectionDashboardApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { IconCalendarEvent } from "@tabler/icons";
import { CollectionDashboardStatic } from "./static";
import { CollectionDashboardReal } from "./real";
import { useNavigate, useLocation } from "react-router";

export function CollectionDashboard() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [value, setValue] = React.useState("static");
  const [date, setDate] = React.useState<Date | null>(null);
  const [debouncedDate] = useDebouncedValue(date, 600);
  const [columnDate, setColumnDate] = React.useState<Date | null>(null);
  const [debouncedColumnDate] = useDebouncedValue(date, 600);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [debouncedStartDate] = useDebouncedValue(date, 600);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [debouncedEndDate] = useDebouncedValue(date, 600);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/collection") && !location.pathname.includes("/collection/dashboard")) {
      navigate("/collection/dashboard", { replace: true });
    }
  }, [location, navigate]);

  const { data, isLoading } = useSwr(
    `/api/collection/dashboard/${debouncedDate ? debouncedDate : nowMoment}/${debouncedColumnDate ? debouncedColumnDate : nowMoment}/${
      debouncedStartDate ? debouncedStartDate : nowMoment
    }/${debouncedEndDate ? debouncedEndDate : nowMoment}`,
    async () => {
      try {
        let res = await CollectionDashboardApi.get({
          date: date ? date.toISOString() : nowMoment.toISOString(),
          columnDate: nowMoment,
          startDate: nowMoment,
          endDate: nowMoment,
        });

        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    },
  );

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Тоон тойм"
      subTitle=""
      breadcrumb={breadcrumbs}
      extra={[
        <DatePicker
          key={1}
          value={date ? date : nowMoment}
          maxDate={nowMoment || date}
          onChange={setDate}
          placeholder="Өдөр сонгох"
          minDate={dayjs(nowMoment || date)
            .subtract(90, "days")
            .toDate()}
          rightSection={<IconCalendarEvent color="gray" />}
        />,
      ]}>
      <Divider />

      <SegmentedControl
        mt={3}
        mb={3}
        value={value}
        onChange={setValue}
        w={200}
        data={[
          { label: "Static", value: "static" },
          { label: "Real", value: "real" },
        ]}
        color="cyan[4]"
      />
      {value === "static" ? (
        <CollectionDashboardStatic nowMoment={nowMoment} />
      ) : (
        <CollectionDashboardReal
          data={data}
          columnDate={columnDate}
          setColumnDate={setColumnDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          nowMoment={nowMoment}
        />
      )}
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Дашбоард",
  },
];
