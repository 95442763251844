import React, { useId } from "react";
import { Avatar, Badge, Divider, Flex, Grid, Group, Input, Paper, Select, Text } from "@mantine/core";
import noImage from "../../../assets/no-image.png";
import { ColumnType, Table } from "../../../components/table";
import { CompromiseApi, ObservationApi } from "../../../apis";
import { dateSecFormat } from "../../../utils/date";
import { PageLayout } from "../../../components/layout";
import { tugrug } from "../../../utils";
import { ICompromise } from "../../../interfaces/ICompromise";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import useSwr from "swr";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { IMaskInput } from "react-imask";
import { IconListSearch, IconSearch } from "@tabler/icons";
import { useDebouncedState } from "@mantine/hooks";
import { IAuth } from "../../../interfaces/IAuth";

export function CollectionPaymentConditionList() {
  const breadcrumbs = useBreadcrumb();
  const [queryStaff, setQueryStaff] = useDebouncedState("", 600);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ programId: "", profileName: "", regNumber: "" });
  const { repaymentOverDueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [programQuery] = React.useState("");
  const id = useId();

  const columns = useHeader({
    repaymentOverDueStatus,
    onClick: (key, item) => {},
  });

  const { data: programData } = useSwr(
    `/collection/observation/program_select?${programQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.program({ query: programQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ ...filters, profileName: queryStaff });
    }, 400);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [400, queryStaff]);

  return (
    <PageLayout title="Төлөлтийн мэдээлэл" subTitle="Хугацаа хэтэрсэн нэхэмжлэхийн амлалт тохирсон хуваарь" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Grid mb="sm">
          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
            <Grid.Col span={4}>
              <Input.Wrapper label="Хөтөлбөрийн нэрээр шүүх">
                <Select
                  value={filters.programId}
                  onChange={(e) => {
                    setFilters({ ...filters, programId: e });
                  }}
                  clearable
                  searchable
                  placeholder="Сонгох"
                  data={(programData || [])?.map((c: any) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
          )}
          <Grid.Col span={4}>
            <Input.Wrapper label="Харилцагчийн нэрээр хайх">
              <Input
                onChange={(e) => setQueryStaff(e.currentTarget.value)}
                placeholder="Хайлт хийх"
                rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
              <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        <Divider mb="sm" />
        <Table name="collection-overdue-payment-condition.list" columns={columns} filters={filters} loadData={(data) => CompromiseApi.list(data!)} />
      </Paper>
    </PageLayout>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ICompromise) => void;
  repaymentOverDueStatus: any;
};

const useHeader = ({ onClick, repaymentOverDueStatus }: HeaderProps): ColumnType<ICompromise>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "50px",
  },
  {
    title: "Амлалт код",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `1px solid  ${"#44566C"}`,
            color: "#44566C",
          }}>
          {record.refCode || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлөлт огноо, цаг",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.date)}
        </Text>
      );
    },
  },
  {
    title: "Төлөх боломжит дүн",
    sorter: true,
    dataIndex: "amount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.amount)}
        </Text>
      );
    },
  },
  {
    title: "Амласан ажилтан",
    sorter: true,
    dataIndex: "actionUser",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.actionUser?.avatar !== null ? record?.actionUser?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.actionUser?.lastName !== null ? record?.actionUser?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.actionUser?.firstName !== null ? record?.actionUser?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.business?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.business?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.business?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: "180px" }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.business?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.business?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "repaymentOverDueStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c={repaymentOverDueStatus.find((c: any) => c.code === record?.repayment?.overDueStatus)?.color}>
          {repaymentOverDueStatus.find((c: any) => c.code === record?.repayment?.overDueStatus)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөлт нэхэмжлэх",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.repayment?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хариуцсан ажилтан",
    sorter: true,
    dataIndex: "collectionStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.collectionStaff?.avatar !== null ? record?.collectionStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.collectionStaff?.lastName !== null ? record?.collectionStaff?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.collectionStaff?.firstName !== null ? record?.collectionStaff?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    label: "Амлалт тохирсон",
  },
];
