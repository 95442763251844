import React from "react";
import { Form } from "../form";
import * as yup from "yup";
import { Button, Flex, Grid } from "@mantine/core";
import { TextField } from "../form/text-field";

const schema = yup.object({
  oldPassword: yup.string().required("Заавал бөглөнө!"),
  newPassword: yup.string().min(6, "Багадаа 6 тэмдэгттэй байх ёстой").max(16, "Ихдээ 16 тэмдэгттэй байх ёстой").required("Нууц үг оруулана уу"),
  checkPassword: yup
    .string()
    .min(6, "Багадаа 6 тэмдэгттэй байх ёстой")
    .max(16, "Ихдээ 16 тэмдэгттэй байх ёстой")
    .required("Нууц үг оруулана уу")
    .oneOf([yup.ref("newPassword")], "Нууц үг таарахгүй байна"),
});

export function ChangePasswordForm({ onClose }: { onClose: () => void }) {
  const onSubmit = async (values: any) => {};

  const [data] = React.useState<any>({
    oldPassword: undefined,
    newPassword: undefined,
    checkPassword: undefined,
  });

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid>
            <Grid.Col span={12}>
              <TextField name="oldPassword" label="Хуучин нууц үг" placeholder="Хуучин нууц үг" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextField name="newPassword" label="Шинэ нууц үг" placeholder="Шинэ нууц үг" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextField name="checkPassword" label="Давтан нууц үг" placeholder="Давтан нууц үг" />
            </Grid.Col>
            <Grid.Col span={12}>
              <></>
              <Flex w="100%" direction="row" justify="space-between" mt={20}>
                <Button variant="default" onClick={() => onClose && onClose()}>
                  Болих
                </Button>
                <Button variant="outline" color="teal" type="submit">
                  Нууц үг үүсгэх
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
