import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { Button, Divider, Grid, Input, LoadingOverlay, Paper, Space, Text, TextInput, Switch, Table, Textarea } from "@mantine/core";
import { IAuth } from "../../interfaces/IAuth";
import { dateFormat } from "../../utils/date";
import { IReference } from "../../models/General";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { RoleApi } from "../../apis";
import { useNavigate } from "react-router";

const schema = yup.object({
  name: yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable().trim(),
  description: yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable().trim(),
  permissions: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

export function RoleForm({ action }: { action: any }) {
  const naviagate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions, modules } = useSelector((state: { general: IGeneral }) => state.general);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      permissions: permissions.map((it, index) => ({
        ...it,
        keyIndex: index,
        isCreate: false,
        isDelete: false,
        isEdit: false,
        isReview: false,
        isView: false,
      })),
    },
    resolver: yupResolver(schema),
  });

  const values = getValues();

  const onSubmit = async (value: any) => {
    setLoading(true);
    try {
      await RoleApi.create({
        name: value.name,
        description: value.description,
        permissions: value.permissions.map((item: any) => {
          return {
            code: item.code,

            isView: item.isView,
            isCreate: item.isCreate,
            isEdit: item.isEdit,
            isReview: item.isReview,
            isDelete: item.isDelete,
          };
        }),
      });

      Message.success(`Роль амжилттай үүсгэж хадгаллаа.`);
      naviagate("/user/role-list");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={loading} />
        <Divider mb="lg" />
        <Paper>
          <Grid>
            <Grid.Col md={6} lg={4}>
              <Input.Wrapper label="Роль бүртгэсэн: ">
                <Text fz={"sm"} fw={600} color="blue.9">
                  {action && action[0] === "edit" ? (
                    <>
                      {user?.lastName || user?.lastName || "-"} {user?.firstName || user?.firstName || "-"}
                    </>
                  ) : (
                    <>
                      {user?.lastName || user?.lastName || "-"} {user?.firstName || user?.firstName || "-"}
                    </>
                  )}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col md={6} lg={4}>
              <Input.Wrapper label="Бүртгэсэн огноо, цаг: ">
                <Text fz={"sm"} fw={600} color="blue.9">
                  {action && action[0] === "edit" ? <></> : <>{dateFormat(new Date())}</>}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col md={12} lg={4}></Grid.Col>
            <Grid.Col md={6} lg={4}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label="Нэр: "
                    value={field.value}
                    error={errors.name ? "Заавал бөглөнө!" : ""}
                    placeholder="Нэр өгнө үү."
                    {...register("name")}
                    withAsterisk={true}
                    color="gray"
                  />
                )}
              />
            </Grid.Col>

            <Grid.Col md={6} lg={4}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea label="Тайлбар: " placeholder="Тайлбар өгнө үү." value={field.value} maxLength={255} withAsterisk={false} color="gray" />
                )}
              />
            </Grid.Col>
          </Grid>

          <Space h="lg" />
        </Paper>
        <Paper>
          {modules.map((item: IReference, index: number) => {
            return (
              <div key={index}>
                <Text fz={"lg"} fw={600} my="sm">
                  {item?.name || "-"}
                </Text>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "400px" }}>Үйлдэл</th>
                      <th style={{ width: "600px" }}>Тайлбар</th>
                      <th style={{ width: "140px" }}>Full access</th>
                      <th style={{ width: "140px" }}>View</th>
                      <th style={{ width: "140px" }}>Create</th>
                      <th style={{ width: "140px" }}>Edit</th>
                      <th style={{ width: "140px" }}>Review</th>
                      <th style={{ width: "140px" }}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.permissions
                      .filter((a: any) => a.module === item.code)
                      .map((item: any, ind: number) => {
                        return (
                          <tr key={`${index}_${ind}`}>
                            <td>{item?.name}</td>
                            <td>{item?.description}</td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isFull`}
                                control={control}
                              />
                            </td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isCreate`}
                                control={control}
                              />
                            </td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isEdit`}
                                control={control}
                              />
                            </td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isReview`}
                                control={control}
                              />
                            </td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isView`}
                                control={control}
                              />
                            </td>
                            <td>
                              <Controller
                                render={({ field }) => (
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.currentTarget.checked);
                                    }}
                                  />
                                )}
                                name={`permissions.${item?.keyIndex}.isDelete`}
                                control={control}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            );
          })}
        </Paper>
        <Button type="submit">Save</Button>
      </form>
    </>
  );
}
