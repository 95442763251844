import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const get = async (id: string) => {
  return httpRequest.get(`/api/program/reference/economical_sector/${id}`);
};

export const select = async (data: any) => {
  return httpRequest.get(`/api/program/reference/economical_sector/select`, data);
};
