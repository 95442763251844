import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/client/bank_account", { offset, filter });
};

export const verify = async (id: string, data: { isVerified: boolean }) => {
  return httpRequest.put(`/api/client/bank_account/${id}/verify`, data);
};
