import React from "react";
import { Badge, Button, Checkbox, Drawer, Flex, Group, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import { tugrug } from "../../utils";
import { ColumnType, Table } from "../table";
import { PaymentInformationOpenInvoiceTabs } from "./tabs";
import { IOpenInvoice } from "../../interfaces/IOpenInvoice";
import { useParams } from "react-router";
import { dateSecFormat } from "../../utils/date";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { ObservationApi } from "../../apis";
import { IReference } from "../../models/General";

export function PaymentInformationOpenInvoiceList() {
  const params = useParams();
  const { repaymentStatus, repaymentOverDueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters] = React.useState({ businessId: `${params.id}` });
  const [action, setAction] = React.useState<string[]>([]);

  const onCancel = async () => {
    setAction([]);
  };

  const columns = useHeader({
    repaymentStatus,
    repaymentOverDueStatus,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          setAction(["detail", item as any]);
          break;
        }
      }
    },
  });

  return (
    <>
      <Table name="collection.repayment.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.repaymentList(data!)} />
      <Drawer
        opened={action[0] === "detail"}
        onClose={() => {
          setAction([]);
        }}
        position="right"
        withCloseButton={false}
        size="60%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <PaymentInformationOpenInvoiceTabs action={action} onCancel={onCancel} setAction={setAction} />
      </Drawer>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IOpenInvoice) => void;
  repaymentStatus: IReference[];
  repaymentOverDueStatus: IReference[];
};

const useHeader = ({ onClick, repaymentStatus, repaymentOverDueStatus }: HeaderProps): ColumnType<IOpenInvoice>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare color="#7956EF" />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөлт нэхэмлэх №",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт хийх огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.amountToPay || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.paidAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.repaymentAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color} 1px solid`,
            color: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color}`,
          }}>
          {(repaymentStatus || []).find((c: IReference) => c.code === record.repaymentStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${repaymentOverDueStatus.find((item: IReference) => item.code === record.overdueStatus)?.color} 1px solid`,
            color: `${repaymentOverDueStatus.find((item: IReference) => item.code === record.overdueStatus)?.color}`,
          }}>
          {repaymentOverDueStatus.find((c: IReference) => c.code === record.overdueStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Амлалт өгсөн эсэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {"Үгүй"}
            </Text>
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Oгноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 140 }}>
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжсэн нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.invReceiverBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.invReceiverBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.invReceiverBusiness?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.invReceiverBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];
