import { Text } from "@mantine/core";
import { ReferenceApi } from "../../apis";
import { ColumnType, Table } from "../table";
import { IEconomicalSector } from "../../interfaces/IEconomicalSector";

export function ScfDcfReferenceLevel2() {
  const columns = useHeader();

  return (
    <Table name="scf-program-reference-level-2.list" columns={columns} filters={{ query: "", level: "2" }} loadData={(data) => ReferenceApi.list(data!)} />
  );
}

const useHeader = (): ColumnType<IEconomicalSector>[] => [
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
    width: "30px",
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "code",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.code || "-"}
        </Text>
      );
    },
  },
  {
    title: "Лавлах нэр",
    sorter: true,
    dataIndex: "name",
    width: 450,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Түвшин",
    sorter: true,
    dataIndex: "level",
    width: 240,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.level || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үндсэн ангилал",
    sorter: true,
    dataIndex: "parent",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.parent?.name || "-"}
        </Text>
      );
    },
  },
];
