import React from "react";
import { Avatar, Checkbox, Flex, Text } from "@mantine/core";
import { LbfParticipantApi } from "../../apis";
import { ColumnType, Table } from "../table";
import noimage from "../../assets/no-image.png";
import { IconPhoto } from "@tabler/icons";
import { IParticipant } from "../../interfaces/IParticipant";

export function FundingProgramBuyerLedSmeStep({ filters, setSelectedSmes }: any) {
  const [selection, setSelection] = React.useState<any[]>([]);

  const columns = useHeader({
    onChangeChecked: (checked: boolean, record: any) => {
      let selecteds = selection;

      if (checked) {
        selecteds = [...selecteds, record];
      } else {
        selecteds = selecteds.filter((s) => s.id !== record.id);
      }

      setSelection(selecteds);
      setSelectedSmes(selecteds);
    },
  });

  return <Table name="sme.program.onboarding.list" columns={columns} filters={filters} loadData={(data) => LbfParticipantApi.selectList(data!)} />;
}

const useHeader = ({ onChangeChecked }: any): ColumnType<IParticipant>[] => [
  {
    title: "",
    render: (record, index) => (
      <div>
        <Checkbox name={`smes[${index}].checked`} onChange={(e) => onChangeChecked(e.target.checked, record)} />
      </div>
    ),
    width: "30px",
  },
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
    width: "30px",
  },
  {
    title: "CIF",
    sorter: true,
    dataIndex: "clientCode",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={110}>
          <Text size="sm" weight={500} c="#44566C">
            {record?.clientCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={70}>
          <Text size="sm" weight={500} c="#44566C">
            {record?.regNumber}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Text w={220}>
            <Text size="sm" weight={500} c="#317169">
              {record?.partnerName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.partnerRefCode || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Text w={220}>
            <Text size="sm" weight={500} c="#317169">
              {record?.profileName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.refCode || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн санхүү",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={180}>
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Text w={300}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.lastName ? record?.finUser?.lastName[0].toUpperCase() : ""}
              {record?.finUser?.lastName ? "." : ""} {record?.finUser?.firstName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.phone || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн имэйл",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Text size="sm" weight={500} c="#44566C">
            {record?.finUser?.email || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeInAcc?.icon !== null ? record?.smeInAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Text w={220}>
            <Text size="sm" weight={500} c="#317169">
              {record?.smeInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeInAcc?.number || "-"}1
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeOutAcc?.icon !== null ? record?.smeOutAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Text w={220}>
            <Text size="sm" weight={500} c="#317169">
              {record?.smeOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
];
