import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { IStaffs } from "../../models/General";
import { FormLayout } from "../layout";
import { useDispatch } from "react-redux";
import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import { TextField } from "../form/text-field";
import { updateStaffs } from "../../store/General";

const schema = yup.object({
  name: yup.string().max(45, "Хэтэрхий урт байна!").required("Заавал бөглөнө үү!").nullable(),
  email: yup.string().typeError("Заавал бөглөнө!").email("Цахим шуудан байна!").required("Заавал бөглөнө!"),
  phone: yup
    .string()
    .matches(/^[0-9]{8}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
});

type Props = {
  onCancel?: () => void;
  action: string[];
};

export function PaymentStaffForm({ onCancel, action }: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IStaffs>({
    name: "",
    phone: "",
    email: "",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    dispatch(
      updateStaffs({
        ...data,
        name: data.name,
        email: data.email,
        phone: data.phone,
      }),
    );
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title="Ажилтан" subTitle="Ажилтны мэдээлэл оруулах." extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Divider my="sm" />
            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <TextField name="phone" label="Утасны дугаар" placeholder="Утас" />
              <TextField name="email" label="И-мэйл хаяг" placeholder="Имэйл" />
            </Flex>
            <Divider my="sm" />

            <Flex direction="row" justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
