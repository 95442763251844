import React from "react";
import useSwr from "swr";
import { LbfRequestApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { PageLayout } from "../../../components/layout";
import { ActionIcon, Button, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconArticle, IconCreditCard, IconFileInvoice, IconX } from "@tabler/icons";
import { FundingRequestSupplierInvoiceForm } from "../../../components/funding-request-supplier/invoice-form";
import { FundingRequestSupplierRequest } from "../../../components/funding-request-supplier/request-form";
import { FundingRequestSupplierSolution } from "../../../components/funding-request-supplier/solution";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingRequestSupplierDetail({ action, onSuccess, onCancel }: { action: any; onSuccess: () => void; onCancel: () => {} }) {
  const [tabIndex, setTabIndex] = React.useState<string>("1");
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading, mutate } = useSwr(`/api/program/supplier-led/${action[1]}`, async () => {
    try {
      if (!action[1]?.id) {
        return [];
      }

      let res = await LbfRequestApi.get(action[1]?.id as string);
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onTabChange = (_index: string) => {
    setTabIndex(_index);
  };

  const onDisburse = async () => {
    setLoading(true);
    try {
      await LbfRequestApi.disburse(data?.id);
      Message.success("Амжилттай олголт хийсэн!");
      onSuccess();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout
      title={`Санхүүжих хүсэлт ${data?.refCode}`}
      extra={[
        <>
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_RESPOND_SUPPLIER").length > 0 &&
            data?.lbfRequestStatus === "APPROVED" &&
            tabIndex === "3" && <Button onClick={() => onDisburse()}>Олголт хийх</Button>}
        </>,
        <ActionIcon onClick={() => onCancel()} key={1}>
          <IconX />
        </ActionIcon>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <LoadingOverlay visible={isLoading || loading} />
        {data && (
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab icon={<IconFileInvoice color={tabIndex === "1" ? "#317169" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Нэхэмжлэх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconCreditCard color={tabIndex === "2" ? "#317169" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Хүсэлт
                </Text>
              </Tabs.Tab>
              <Tabs.Tab
                icon={<IconArticle color={tabIndex === "3" ? "#317169" : "#44566C"} />}
                value="3"
                disabled={data?.lbfRequestStatus === "REQUESTED" || data?.lbfRequestStatus === "PENDING" || data?.lbfRequestStatus === "REJECTED"}>
                <Text size="sm" weight={500} c="#44566C">
                  Шийдвэр
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">
              {tabIndex === "1" && <FundingRequestSupplierInvoiceForm data={data} onBack={() => onCancel()} onNext={() => setTabIndex("2")} />}
            </Tabs.Panel>
            <Tabs.Panel value="2">
              {tabIndex === "2" && (
                <FundingRequestSupplierRequest
                  data={data}
                  onCancel={() => onCancel()}
                  onBack={() => setTabIndex("1")}
                  onNext={() => setTabIndex("3")}
                  reload={() => onSuccess()}
                  onSuccess={() => {
                    mutate();
                  }}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="3">
              {(data?.lbfRequestStatus === "APPROVED" || data?.lbfRequestStatus === "COMPLETED") && tabIndex === "3" && (
                <FundingRequestSupplierSolution data={data} onBack={() => setTabIndex("2")} />
              )}
            </Tabs.Panel>
          </Tabs>
        )}
      </Paper>
    </PageLayout>
  );
}
