import React from "react";
import { ColumnType, Table } from "../table";
import { ObservationApi } from "../../apis";
import { ActionIcon, Text } from "@mantine/core";
import { IActivityLog } from "../../interfaces/IActivityLog";
import { dateSecFormat } from "../../utils/date";
import { IconCheck, IconMinus } from "@tabler/icons";

type Props = {
  action: any;
};

export function PaymentObservationActionLogList({ action }: Props) {
  const [filters] = React.useState({ repaymentId: action && action[1]?.id });

  const columns = useHeader();

  return <Table name="payment-observation-action-log.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.repayment(data!)} />;
}

const useHeader = (): ColumnType<IActivityLog>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "50px",
  },
  {
    title: "",
    dataIndex: "logType",
    render: (record) => {
      return (
        <>
          {record.logType === "OVERDUE" ? (
            <ActionIcon color="red.5" size="md" radius="xl" variant="filled">
              <IconMinus size="1rem" />
            </ActionIcon>
          ) : (
            <ActionIcon color="green.5" size="md" radius="xl" variant="filled">
              <IconCheck size="1rem" />
            </ActionIcon>
          )}
        </>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "logType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.logType}
        </Text>
      );
    },
  },
  {
    title: "Холбоотой хэрэглэгч",
    sorter: true,
    dataIndex: "actionUserId",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.actionUser?.lastName[0] || "-"}. {record?.actionUser?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
];
