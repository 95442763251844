import { MantineSizes, TextInput } from "@mantine/core";
import { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  onChangeCustom?: any;
  rightSection?: React.ReactNode;
  classNames?: Partial<Record<BaseSelectStylesNames, string>> | undefined;
  size?: any;
  variant?: any;
  className?: any;
  styles?: any;
  radius?: any;
  withAsterisk?: boolean;
  icon?: React.ReactNode;
  readOnly?: boolean;
  w?: string | number | MantineSizes;
};

export function TextField({
  name,
  label,
  placeholder,
  disabled = false,
  onChangeCustom,
  rightSection,
  classNames,
  size,
  variant,
  className,
  styles,
  radius,
  withAsterisk = true,
  icon,
  readOnly = false,
  w,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <TextInput
      w={w}
      readOnly={readOnly}
      icon={icon}
      variant={variant}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      size={size}
      radius={radius}
      rightSection={rightSection}
      classNames={classNames}
      onChange={(e) => {
        onChange(e.target.value);
        onChangeCustom && onChangeCustom(e.target.value);
      }}
      className={className}
      required
      styles={styles}
      withAsterisk={withAsterisk}
    />
  );
}
