import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { IGeneral } from "../../interfaces/IGeneral";
import { Message } from "../../utils/message";
import { AuthApi, ProgramApi } from "../../apis";
import { FormLayout } from "../layout";
import { Button, CloseButton, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { IReference } from "../../models/General";
import { TextareaField } from "../form/textarea-field";
import { OtpField } from "../form/otp-field";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  activeType: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin?: string;
  statusDesc: string;
  activeType: string;
};

export function BuyerLedActiveForm({ onCancel, reload, data }: { onCancel: () => void; data: IScfProgram[]; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const { programActiveTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [initialData] = React.useState<IFormData>({
    pin: "",
    statusDesc: "",
    activeType: "",
  });

  const active = async (values: IFormData) => {
    setLoading(true);

    try {
      if (data[1]?.id) {
        await ProgramApi.active(data[1].id, {
          activeType: values.activeType,
          statusDesc: values.statusDesc,
        });
        Message.success("Таны хүсэлт амжилттай.");
        reload();
      }
      onCancel();
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      let res = await AuthApi.checkPin({ pin: values.pin });
      if (res?.success) {
        active({
          activeType: values.activeType,
          statusDesc: values.statusDesc,
        });
      }
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <>
      <FormLayout title="Хөтөлбөрийг идэвхтэй болгох" subTitle="Хөтөлбөр идэвхтэй болгох." extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
        <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initialData}>
          {() => {
            return (
              <>
                <Grid>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Input.Wrapper>
                      <Text size="md" weight={500} c="#44566C">
                        Та {data[1]?.refCode || "-"} дугаартай Supply Chain Finance хөтөлбөрийг идэвхтэй болгох гэж байна.
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <SelectField
                      name="activeType"
                      label="Идэвхтэй болгох:"
                      placeholder="Идэвхтэй болгох төрөл сонгох"
                      options={(programActiveTypes || []).map((item: IReference) => {
                        return {
                          label: item.name || "",
                          value: item.code || "",
                        };
                      })}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextareaField name="statusDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" maxLength={255} withAsterisk={false} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <OtpField name="pin" label="Баталгаажуулах пин:" placeholder="******" />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text size="md" weight={500} c="#44566C">
                      Танд амжилт хүсье!
                    </Text>
                  </Grid.Col>
                </Grid>
                <Divider mt="lg" />
                <Flex mt={20} direction="row" justify="space-between">
                  <Button variant="outline" onClick={() => onCancel && onCancel()} key={0}>
                    Болих
                  </Button>
                  <Button type="submit" loading={loading} key={1} variant="filled">
                    Идэвхтэй болгох
                  </Button>
                </Flex>
              </>
            );
          }}
        </Form>
      </FormLayout>
    </>
  );
}
