import React from "react";
import { Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { IconDownload } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function ScfSupplierFinanceRequestInvoiceForm({ action, onTabChange, onCancel }: { action: any; onTabChange: any; onCancel: any }) {
  const { invoiceStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" mt="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НЭХЭМЖЛЭХИЙН ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Илгээсэн хэрэглэгч:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderUser?.lastName || "-"} {action[1]?.invoice?.senderUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан хэрэглэгч:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.confirmedUser?.lastName || "-"} {action[1]?.invoice?.confirmedUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.invoice?.confirmedAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийт төлсөн дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.invoice?.paidAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Үлдэгдэл төлбөр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.invoice?.amountToPay || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх статус:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoiceStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.invoiceStatus)?.color} 1px solid`,
                      color: `${(invoiceStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.invoiceStatus)?.color}`,
                    }}>
                    {(invoiceStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.invoiceStatus)?.name || "-"}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.paymentStatus)?.color} 1px solid`,
                      color: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.paymentStatus)?.color}`,
                    }}>
                    {(invoicePaymentStatus || []).find((c: IReference) => c?.code === action[1]?.invoice?.paymentStatus)?.name || "-"}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх хугацаа:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {action && action[1]?.passedDays}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн нөхцөл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.paymentTermDesc || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action[1]?.invoice?.paymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн баримт:">
                  <Box style={{ alignItems: "center" }}>
                    <Button
                      component="a"
                      href={action[1]?.contractFile}
                      target="_blank"
                      rel="noreferrer"
                      variant="subtle"
                      color="indigo"
                      size="sm"
                      leftIcon={<IconDownload size={18} color="#4263EB" />}>
                      Татах
                    </Button>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ТӨЛБӨР НЭХЭМЖЛЭГЧ - НИЙЛҮҮЛЭГЧ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнер нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderBusiness?.partner?.refCode || "0"}, {action[1]?.invoice?.senderBusiness?.partner?.businessName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderBusiness?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгч бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderBusiness?.refCode}, {action[1]?.invoice?.senderBusiness?.profileName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчийн данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.receiverAcc?.number}, {action[1]?.invoice?.receiverAcc?.name}, {action[1]?.invoice?.receiverAcc?.bankName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Борлуулалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.salesOrder?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderFinUser?.lastName || "-"}, {action[1]?.invoice?.senderFinUser?.firstName || "-"},{" "}
                      {action[1]?.invoice?.senderFinUser?.phone || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ТӨЛБӨР ТӨЛӨГЧ - ХУДАЛДАН АВАГЧ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнерийн нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.receiverBusiness?.partner?.refCode || "-"}, {action[1]?.invoice?.receiverBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Flex>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.receiverBusiness?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагч бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.receiverBusiness?.refCode || "-"}, {action[1]?.invoice?.receiverBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчийн данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.senderAcc?.number}, {action[1]?.invoice?.senderAcc?.name}, {action[1]?.invoice?.senderAcc?.bankName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.purchaseOrder?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.invoice?.receiverFinUser?.lastName || "-"}, {action[1]?.invoice?.receiverFinUser?.firstName || "-"},{" "}
                      {action[1]?.invoice?.receiverFinUser?.phone || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button
                variant="default"
                onClick={() => {
                  onCancel();
                }}>
                Буцах
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  onClick={() => {
                    onTabChange("2");
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
