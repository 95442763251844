import { ActionIcon, BackgroundImage, Divider, Flex, Text, createStyles } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import Background from "../../assets/background-bogd.svg";
import Header from "./header";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTwitter } from "@tabler/icons";

export const Layout = ({ children }: { children: any }) => {
  const { classes } = useStyles();
  const { height } = useViewportSize();

  return (
    <BackgroundImage src={Background}>
      <div className={classes.container} style={{ minHeight: height }}>
        <Header />
        <div className={classes.child}>{children}</div>
        <div className={classes.footerText}>
          <Divider my="sm" />
          <Flex direction="row" justify="space-between" mt="lg">
            <Text className={classes.text}>© 2024 Funder</Text>

            <Flex mih={50} gap="md" justify="flex-end" align="flex-start" direction="row" wrap="wrap">
              <ActionIcon>
                <IconBrandTwitter />
              </ActionIcon>
              <ActionIcon>
                <IconBrandFacebook />
              </ActionIcon>
              <ActionIcon>
                <IconBrandInstagram />
              </ActionIcon>
            </Flex>
          </Flex>
        </div>
      </div>
    </BackgroundImage>
  );
};

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  child: {
    position: "relative",
    zIndex: 1,
    height: "calc(100% - 80px)",
  },
  footerText: {
    marginLeft: 60,
    marginRight: 60,
    marginBottom: 20,
  },
  text: {
    fontSize: 24,
    color: "white",
  },
}));

export default Layout;
