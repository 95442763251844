import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Paper, Space, Tabs, Text } from "@mantine/core";
import { IconPackage, IconShoppingCart } from "@tabler/icons";
import { SupplierLedLimit } from "../../../components/supplier-led-limit/list";
import { BuyerLedLimit } from "../../../components/buyer-led-limit/list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function CfLimitTab() {
  const params = useParams();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "BUYER");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const titles = (val: string) => {
    switch (val) {
      case "SUPPLIER": {
        return "Supplier-LED лимит";
      }

      case "BUYER": {
        return "Buyer-LED лимит";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/cf/limit/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <>
      <PageLayout
        title={tabKey === "BUYER" ? "Buyer-LED лимит ашиглалт жагсаалт" : "Supplier-LED лимит ашиглалт жагсаалт"}
        subTitle="Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр лимит ашиглалт"
        breadcrumb={breadcrumbs}
        extra={[]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
            <Tabs.List>
              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_LINE_LIMIT_USAGE_BL").length > 0 && (
                <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={tabKey === "BUYER" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Buyer-LED
                  </Text>
                </Tabs.Tab>
              )}

              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_LINE_LIMIT_USAGE_SL").length > 0 && (
                <Tabs.Tab value="SUPPLIER" icon={<IconPackage color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Supplier-LED
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Space h={10} />

            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_LINE_LIMIT_USAGE_BL").length > 0 && (
              <Tabs.Panel value="BUYER">{<BuyerLedLimit />}</Tabs.Panel>
            )}
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_LINE_LIMIT_USAGE_SL").length > 0 && (
              <Tabs.Panel value="SUPPLIER">{<SupplierLedLimit />}</Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: type,
  },
];
