import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { AuthApi, ProductsApi } from "../../apis";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { OtpField } from "../form/otp-field";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  type: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
  statusDesc: string;
  type: string;
};

export function ActiveForm({
  title,
  onCancel,
  action,
  subTitle,
  payload,
  reload,
}: {
  title: string;
  subTitle: string;
  onCancel: () => void;
  action: string[];
  payload: any;
  reload: () => void;
}) {
  const { productActiveTypes, productInactiveTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    pin: "",
    statusDesc: "",
    type: "",
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: data.pin });

      try {
        if (action[0] === "ACTIVE") {
          await ProductsApi.active(payload.id, { activeType: data.type, statusDesc: data.statusDesc });
        } else if (action[0] === "INACTIVE") {
          await ProductsApi.inactive(payload.id, { inactiveType: data.type, statusDesc: data.statusDesc });
        } else if (action[0] === "ISCOMPOLETE") {
          await ProductsApi.toreview(payload.id);
        }
        reload();
        setLoading(false);
        onCancel();
      } catch (err) {
        setLoading(false);
        Message.error((err as HttpHandler)?.message!);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ errors }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              {action[0] === "ACTIVE" && (
                <Grid.Col span={12}>
                  <Alert styles={{ message: { color: "#44566c", fontWeight: 500 } }} radius="md" color="indigo" withCloseButton={false} title="Таны анхааралд">
                    {`Та ${payload?.refCode} кодтой SCF бүтээгдэхүүнийг идэвхтэй болгох гэж байна.`}
                  </Alert>
                </Grid.Col>
              )}

              {action[0] === "INACTIVE" && (
                <Grid.Col span={12}>
                  <Alert styles={{ message: { color: "#44566c", fontWeight: 500 } }} radius="md" color="indigo" withCloseButton={false} title="Таны анхааралд">
                    {`Та ${payload?.refCode} кодтой SCF бүтээгдэхүүнийг идэвхгүй болгох гэж байна.`}
                  </Alert>
                </Grid.Col>
              )}

              {action[0] === "ACTIVE" && (
                <Grid.Col span={12}>
                  <SelectField
                    label="Идэвхжүүлэх төрөл"
                    name="type"
                    placeholder="Сонгох"
                    options={productActiveTypes.map((type) => ({ label: type.name, value: type.code }))}
                  />
                </Grid.Col>
              )}

              {action[0] === "INACTIVE" && (
                <Grid.Col span={12}>
                  <SelectField
                    label="Идэвхгүй болгох төрөл"
                    name="type"
                    placeholder="Сонгох"
                    options={productInactiveTypes.map((type) => ({ label: type.name, value: type.code }))}
                  />
                </Grid.Col>
              )}

              {action[0] !== "ISCOMPOLETE" && (
                <Grid.Col span={12}>
                  <TextareaField name="statusDesc" label="Тайлбар бичнэ үү." placeholder="Тайлбар оруулах..." maxLength={255} withAsterisk={false} />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <OtpField name="pin" label="Үйлдлийг баталгаажуулах бол ПИН кодоо оруулна уу." placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit">
                  {action[0] === "ACTIVE" ? "Идэвхжүүлэх" : "Идэвхгүй болгох"}
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
