import React from "react";
import { Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconCreditCard, IconReceipt } from "@tabler/icons";
import { useNavigate, useParams } from "react-router";
import { FundingRequestSupplierList } from "../funding-request-supplier/list";
import { FundingRequestBuyerList } from "../funding-request-buyer/list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingRequestTabs() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(
    params.type || (user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_RESPOND_SUPPLIER" && c.isView).length > 0
      ? "SUPPLIER"
      : "BUYER",
  );

  const titles = (val: string) => {
    switch (val) {
      case "SUPPLIER": {
        return "Зээлийн хүсэлт";
      }

      case "BUYER": {
        return "Зээлийн хүсэлт";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/cf/funding-request/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <PageLayout
      title={tabKey === "SUPPLIER" ? "Нийлүүлэгчийн жагсаалт" : "Худалдан авагчийн жагсаалт"}
      subTitle={tabKey === "SUPPLIER" ? "Зээл суурьтай санхүүжилтийн хөтөлбөрөөр ирсэн хүсэлт" : "Зээл суурьтай санхүүжилтийн хөтөлбөрөөр ирсэн хүсэлт"}
      breadcrumb={breadcrumbs}
      extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_SUPPLIER" && c.isView).length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconCreditCard color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Нийлүүлэгчээс
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_BUYER" && c.isView).length > 0 && (
              <Tabs.Tab value="BUYER" icon={<IconReceipt color={tabKey === "BUYER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Худалдан авагчаас
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Space h={10} />

          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_SUPPLIER" && c.isView).length > 0 && (
            <Tabs.Panel value="SUPPLIER">{<FundingRequestSupplierList />}</Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_BUYER" && c.isView).length > 0 && (
            <Tabs.Panel value="BUYER">{<FundingRequestBuyerList />}</Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: type,
  },
];
