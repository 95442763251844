import React from "react";
import useSWR from "swr";
import { Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { useNavigate, useParams } from "react-router";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { ProductsApi, ProgramApi } from "../../../apis";
import { BuyerLedMainForm, IBuyerProgramFormData } from "../../../components/buyer-led-program/main-form";
import { dateTimeFormat } from "../../../utils/date";
import { IconFileText, IconTruckLoading } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function BuyerLedProgramNew() {
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const [type, setType] = React.useState<string>("");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data: productData } = useSWR(`/buyer-led-program/list/${params.code}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0) {
      try {
        if (!params.code) {
          return [];
        }

        let res = await ProductsApi.select({ query: params.code, type: "BUYER_LED" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onMainSubmit = async (data: IBuyerProgramFormData, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    try {
      let res = await ProgramApi.create({
        type: "INVESTMENT",
        productType: "BUYER_LED",
        productId: productData[0].id,
        name: data.name,
        resolutionNo: data.resolutionNo,
        fundType: data.fundType,
        description: data.description,
        programLimit: data?.programLimit,
        disburseAccId: data.disburseAccId,
        feeAccId: data.feeAccId,
        repayAccId: data.repayAccId,
        programSector1Ids: data?.programSector1Ids || [],
        programSector2Ids: (data?.programSector2Ids || []).map((c: any) => c?.id) || [],
        programSector3Ids: (data?.programSector3Ids || []).map((c: any) => c?.id) || [],
        businessId: data.businessId,
        rechargeable: !data.rechargeable ? false : data.rechargeable === "FALSE" ? false : true,
      });

      if (type === "NEXT") {
        navigate(`/scf-dcf/dcf-program/${params.code}/edit/${res.id}/2`);
      } else {
        navigate(`/scf-dcf/dcf-program`);
      }
      Message.success(`Та SCF хөтөлбөр амжилттай бүртгэж хадгаллаа. ${dateTimeFormat(new Date())}`);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Buyer LED хөтөлбөр" subTitle="Шинээр SCF хөтөлбөр бүртгэх, Бүтээгдэхүүний төрөл(Buyer LED)" breadcrumb={breadcrumbs} bgColor="#F8F9FA">
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex}>
          <Tabs.List>
            <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Хөтөлбөрийн мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="2" icon={<IconTruckLoading color={tabIndex === "2" ? "#317169" : "#44566C"} />} disabled>
              <Text size="sm" weight={500} c="#44566C">
                Нийлүүлэгч бизнесүүд
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            <BuyerLedMainForm onSubmit={onMainSubmit} action={[]} setType={setType} />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/dcf-program",
    label: "Buyer LED хөтөлбөр",
  },
  {
    label: "Шинэ",
  },
];
