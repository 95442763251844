import React from "react";
import { createStyles, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { AllowanceAccountList } from "../../../components/account/allowance-list";
import { FocusAccountList } from "../../../components/account/focus-list";
import { OtherFeeList } from "../../../components/account/other-fee";
import { useNavigate, useParams } from "react-router";
import { IconCashBanknote, IconRotate, IconWallet } from "@tabler/icons";
import { IAuth } from "../../../interfaces/IAuth";
import { useSelector } from "react-redux";

export const Account = () => {
  const { theme } = useStyle();
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "DISBURSEMENT");

  const onChange = (value: string) => {
    navigate(`/scf-dcf-product/accounting/${value}`);
    setTabKey(value);
  };

  return (
    <PageLayout title="Тохируулсан дансны жагсаалт" subTitle="Тохируулсан дансны дэлгэрэнгүй мэдээлэл" breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List p={0}>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0 && (
              <Tabs.Tab value="DISBURSEMENT" icon={<IconWallet color={tabKey === "DISBURSEMENT" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Олголтын данс
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0 && (
              <Tabs.Tab value="OTHER_FEE" icon={<IconCashBanknote color={tabKey === "OTHER_FEE" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Шимтгэл авах данс
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="3">Банк шимтгэл төвлөрүүлэх</Tabs.Tab> */}
            {/* <Tabs.Tab value="4">Эргэн төлөлт хүлээн авах</Tabs.Tab> */}
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0 && (
              <Tabs.Tab value="REPAYMENT" icon={<IconRotate color={tabKey === "REPAYMENT" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Эргэн төлөлт хүлээн авах
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="5">Эх үүсвэрийн данс</Tabs.Tab> */}
          </Tabs.List>
          {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0 && (
            <Tabs.Panel value="DISBURSEMENT">
              <AllowanceAccountList />
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0 && (
            <Tabs.Panel value="OTHER_FEE">
              <OtherFeeList />
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="3">
            <FeeAccountList />
          </Tabs.Panel> */}
          {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0 && (
            <Tabs.Panel value="REPAYMENT">
              <FocusAccountList />
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="5">
            <AccountSourceList />
          </Tabs.Panel> */}
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн бүтээгдэхүүн" },
  {
    label: "Данс тохиргоо",
  },
];
