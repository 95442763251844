import { Avatar, Button, Divider, Flex, Grid, Group, Input, Text, Tooltip } from "@mantine/core";
import { Table, ColumnType } from "../table";
import { AncorApi } from "../../apis";
import { IAncor } from "../../interfaces/IAncor";
import { dateSecFormat } from "../../utils/date";
import { IconInfoSquare, IconSearch } from "@tabler/icons";
import { useDebouncedState } from "@mantine/hooks";
import { IReference } from "../../models/General";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { useNavigate } from "react-router";
import { IBusiness } from "../../interfaces/IBusiness";
import { IPermissions } from "../../interfaces/IPermissions";
import { IAuth } from "../../interfaces/IAuth";

export function SmeList() {
  const [query, setQuery] = useDebouncedState("", 600);
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { golomtLegalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    golomtLegalEntityTypes,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/customer/sme-customer/detail/${record.id}`);
          break;
        }
        default:
      }
    },
  });

  return (
    <Flex direction={"column"} gap="xs" sx={{ minHeight: "60vh", height: "100%" }}>
      <Grid>
        <Grid.Col span={3}>
          <Input
            iconWidth={120}
            onChange={(e) => setQuery(e.currentTarget.value)}
            placeholder="хайлт хийх"
            rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Бизнес нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            key={1}
          />
        </Grid.Col>
      </Grid>
      <Divider />
      <Table
        name="sme.business.tabs.list"
        columns={columns}
        filters={{
          query: query,
          type: "SME",
        }}
        loadData={(data) => AncorApi.business(data!)}
      />
    </Flex>
  );
}

const useHeader = ({
  user,
  onClick,
  golomtLegalEntityTypes,
}: {
  user: any;
  onClick: (key: string, record: IAncor) => void;
  golomtLegalEntityTypes: IReference[];
}): ColumnType<IAncor>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_SME").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {/* CLIENT_SME */}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
    width: "30px",
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    width: 100,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчийн нэр",
    sorter: true,
    dataIndex: "regNumber",
    width: 100,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.clientName || "-"}
        </Text>
      );
    },
  },
  {
    title: "deHUB код, нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {record?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "ААН хэлбэр",
    sorter: true,
    dataIndex: "legalEntityType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {(golomtLegalEntityTypes || []).find((c: IReference) => c.code === record?.legalEntityType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "businessSubSector",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {dateSecFormat(record.createdAt) || "-"}
        </Text>
      );
    },
  },
  {
    title: "Зөвшөөрсөн",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.approvedUser?.lastName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d">
            {record?.approvedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
];
