import { useParams } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { Flex, Text } from "@mantine/core";
import { ProgramHistoryApi } from "../../apis";
import { IReference } from "../../models/General";
import { ColumnType, Table } from "../table";
import { ISystemHistory } from "../../interfaces/ISystemHistory";
import { dateSecFormat } from "../../utils/date";

export function BuyerLedHistoryList() {
  const params = useParams();
  const { programHistoryTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ programHistoryTypes });

  return (
    <Flex sx={{ position: "relative" }} direction="column" mt={15}>
      <Table
        name="buyer-led-program-history.list"
        columns={columns}
        filters={{ type: "", actionUserId: "", programId: `${params.id}` }}
        loadData={(data) => ProgramHistoryApi.list(data!)}
      />
    </Flex>
  );
}

const useHeader = ({ programHistoryTypes }: { programHistoryTypes: IReference[] }): ColumnType<ISystemHistory>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "productRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.productRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Системд хийсэн үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {programHistoryTypes.find((c: any) => c?.code === record?.type)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {"-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгч",
    sorter: true,
    dataIndex: "actionUser",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.actionUser?.lastName[0] || "-"}. {record?.actionUser?.firstName || "-"}
        </Text>
      );
    },
  },
];
