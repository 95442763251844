import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/receivable_limit", { offset, filter });
};

export const sub = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/lbf/receivable_limit/sub", { offset, filter });
};
