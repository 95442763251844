import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Button, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { SelectField } from "../form/select-field";
import { DatePickerField } from "../form/date-picker-field";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { UserApi } from "../../apis";
import { dateSecFormat } from "../../utils/date";

const schema = yup.object({
  roleId: yup.string().required("Заавал бөглөнө үү!").nullable(),
  startDate: yup.string().required("Заавал бөглөнө үү!").nullable(),
});

type IFormData = {
  roleId: string;
  startDate: string;
  endDate: string;
};

export function UserControlRoleForm({ onCancel, item, reload, result }: { item: any; onCancel?: () => void; action: string[]; reload: any; result: any }) {
  const nowMoment = new Date();
  var today = new Date();
  today.setHours(today.getHours() + 8);
  const [loading, setLoading] = React.useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const { roles } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    roleId: "",
    startDate: "",
    endDate: "",
  });

  const arr1 = roles?.map((c: any) => c.id);
  const arr2 = result?.rows?.map((c: any) => c.roleId);
  const arr3 = arr1?.filter(function (obj) {
    return arr2?.indexOf(obj) === -1;
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    const start = new Date(data.startDate);
    const end = new Date(data?.endDate);
    try {
      await UserApi.assign({
        userId: item && item[1].id,
        roleId: data.roleId,
        startDate: start.toISOString(),
        endDate: data?.endDate ? end?.toISOString() : "",
      });
      reload();
      Message.success("Шинэ роль амжилттай нэмлээ!");
      onCancel && onCancel();
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            <Divider />
            <Grid>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Админ хэрэглэгч">
                  <Text color="indigo" fw={600}>
                    {(user && user?.firstName) || "-"} {(user && user?.lastName) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Роль тохируулсан огноо, цаг">
                  <Text color="indigo" fw={600}>
                    {dateSecFormat(nowMoment)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хэрэглэгчийн нэр">
                  <Text color="indigo" fw={600}>
                    {(item && item[1]?.firstName) || "-"} {(item && item[1]?.lastName) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <SelectField
                  name="roleId"
                  label="Хэрэглэгчид олгох роль"
                  placeholder="Сонгох"
                  searchable
                  options={
                    arr2 === undefined
                      ? roles.map((c: any) => ({ label: c.name, value: c.id }))
                      : roles.filter((f: any) => arr3.includes(f.id)).map((c: any) => ({ label: c.name, value: c.id }))
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <DatePickerField name="startDate" maxDate={new Date(values.endDate)} required={true} placeholder="Роль эхлэх огноо, цаг" label="Эхлэх огноо" />
              </Grid.Col>
              <Grid.Col span={4}>
                <DatePickerField minDate={today} name="endDate" required={false} placeholder="Дуусах огноо, цаг" label="Дуусах огноо" />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>

              <Grid.Col span={12}>
                <Flex direction="row" justify="space-between">
                  <Button variant="default" onClick={() => onCancel && onCancel()}>
                    Буцах
                  </Button>
                  <Flex gap="md">
                    <Button variant="outline" type="submit">
                      Роль тохируулах
                    </Button>
                  </Flex>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
        );
      }}
    </Form>
  );
}
