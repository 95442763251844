import React from "react";
import { ActionIcon, Badge, Divider, Flex, Grid, Group, Input, SegmentedControl, Text } from "@mantine/core";
import { ProductRequestApi } from "../../apis";
import { ColumnType, Table } from "../table";
import { IRequest } from "../../interfaces/IRequest";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { useDebouncedValue } from "@mantine/hooks";
import { DateRangePicker } from "@mantine/dates";
import { IconX } from "@tabler/icons";

const productTypes = [
  { name: "Бүгд", color: "yellow", code: "" },
  { name: "BUYER_LED", color: "green", code: "BUYER_LED" },
  { name: "SUPPLIER_LED", color: "blue", code: "SUPPLIER_LED" },
];

export function ScfFinanceList() {
  const [type, setType] = React.useState("");
  const [typeDebounced] = useDebouncedValue(type, 400);
  const [partner, setPartner] = React.useState("");
  const [partnerDebounced] = useDebouncedValue(partner, 400);
  const [business, setBusiness] = React.useState("");
  const [businessDebounced] = useDebouncedValue(business, 400);
  const [program, setProgram] = React.useState("");
  const [programDebounced] = useDebouncedValue(program, 400);
  const [product, setProduct] = React.useState("");
  const [productDebounced] = useDebouncedValue(product, 400);
  const [startDate, setStartDate] = React.useState<any>("");
  const [debouncedStartDate] = useDebouncedValue(startDate, 600);
  const [endDate, setEndDate] = React.useState<any>("");
  const [debouncedEndDate] = useDebouncedValue(endDate, 600);
  var today = new Date();

  const columns = useHeader();

  return (
    <>
      <Grid mb={2}>
        <Grid.Col span={3}>
          <Input
            value={partner}
            iconWidth={130}
            placeholder="хайх"
            maxLength={100}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Партнер нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setPartner(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={business}
            iconWidth={130}
            placeholder="хайх"
            maxLength={100}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Бизнес нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setBusiness(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={program}
            iconWidth={140}
            placeholder="хайх"
            maxLength={100}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Хөтөлбөр нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setProgram(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input
            value={product}
            iconWidth={165}
            placeholder="хайх"
            maxLength={100}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Бүтээгдэхүүн нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setProduct(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size="sm" weight={450} c="#44566C">
              Бүтээгдэхүүн төрөл :
            </Text>
            <SegmentedControl
              value={type}
              onChange={(e) => setType(e)}
              data={(productTypes || []).map((item: any, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size="sm" weight={450} c="#44566C">
              Огноо :
            </Text>
            <DateRangePicker
              value={[startDate, endDate]}
              placeholder="сонгох"
              iconWidth={100}
              onChange={(e) => {
                if (e[0] && e[1]) {
                  setStartDate(e[0] && e[0]);
                  setEndDate(e[1] && e[1]);
                }
              }}
              maxDate={today}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px", width: "100%" }}
              rightSection={
                <ActionIcon
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                  }}>
                  <IconX size={15} stroke={1.2} />
                </ActionIcon>
              }
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
      </Grid>

      <Table
        name="scf-product.list"
        columns={columns}
        filters={{
          productType: typeDebounced,
          anchorPartnerId: partnerDebounced,
          anchorBusinessId: businessDebounced,
          productId: productDebounced,
          programId: programDebounced,
          startDate: debouncedStartDate,
          endDate: debouncedEndDate,
          excel: `${false}`,
        }}
        loadData={(data) => ProductRequestApi.list(data!)}
      />
    </>
  );
}

const useHeader = (): ColumnType<IRequest>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: 40,
  },
  {
    title: "Гүйлгээний огноо",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record?.disbursedDate)}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.repaymentAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр №",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.program?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.program?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.product?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.product?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Badge radius="sm" variant="outline">
          {record.productType || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүсэлт код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор партнер нэр",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchor?.partner?.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.anchor?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Анкор бизнесийн нэр",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchor?.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.anchor?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлтийн код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.trxDesc || "-"}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.trxType || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжигч партнер",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness.partner.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.requestedBusiness.partner.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүүжигч бизнес",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.requestedBusiness.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн төрөл",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return record?.requestedBusiness?.type === "BUYER" ? (
        <Badge radius="sm" variant="outline">
          {"Худалдан авагч"}
        </Badge>
      ) : (
        <Badge radius="sm" variant="outline">
          {"Нийлүүлэгч"}
        </Badge>
      );
    },
  },
  {
    title: "Хүлээн авсан данс",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.requestedBusinessAcc.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Шилжүүлэх данс",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.program?.disburseAcc?.number || "-"}
        </Text>
      );
    },
  },
];
