import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/overdue", { offset, filter });
};

export const contactList = async (id: any) => {
  return httpRequest.get(`/api/collection/overdue/${id}/contact_info`);
};

export const reminderList = async (data: any) => {
  return httpRequest.get("/api/collection/overdue/reminder_history", data);
};

export const repaymentList = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/overdue/activity_log", { offset, filter });
};
