import React from "react";
import { FormLayout, PageLayout } from "../../../components/layout";
import { Button, CloseButton, LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { IconFileText, IconHistory, IconShoppingCart } from "@tabler/icons";
import { LbfProgramApi } from "../../../apis";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { FundingProgramBuyerLedHistoryList } from "../../../components/funding-program-buyer/history-list";
import { FundingProgramSupplierLedBusinessList } from "../../../components/funding-program-supplier/business-list";
import { FundingProgramSupplierLedDetailInformation } from "../../../components/funding-program-supplier/detail-information-form";
import { FundingProgramSupplierLedReviewForm } from "../../../components/funding-program-supplier/review-form";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingProgramSupplierLedReview() {
  const params = useParams();
  const { refCode, type, id } = params;
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(type || "INFORMATION");
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, isLoading, mutate } = useSwr(`/api/program/supplier-led/review/${params.id}`, async () => await LbfProgramApi.get(id as string));

  const onTabChange = (_index: string) => {
    navigate(`/cf/funding-program/SUPPLIER/${refCode}/review/${id}/${_index}`);
    setTabKey(_index);
  };

  return (
    <>
      <PageLayout
        title="Хөтөлбөрийн хянах"
        subTitle="Санхүүжилтийн хөтөлбөрийн үндсэн бүртгэлийг хянах"
        breadcrumb={breadcrumbs}
        extra={[
          <>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isReview).length > 0 &&
              tabKey === "INFORMATION" && (
                <Button onClick={() => setAction(["review", data])} key="confirm">
                  {"Хянах"}
                </Button>
              )}
          </>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs value={tabKey} onTabChange={onTabChange}>
            <LoadingOverlay visible={isLoading} />
            <Tabs.List>
              <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="BUSINESS" icon={<IconShoppingCart color={tabKey === "BUSINESS" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Худалдан авагч бизнес
                </Text>
              </Tabs.Tab>

              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Системийн түүх
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="INFORMATION">
              {data && (
                <FundingProgramSupplierLedDetailInformation
                  data={data}
                  programSector1={data?.sector1?.name}
                  programSector2={data?.sector2?.name}
                  programSector3={data?.sector3?.name}
                  onSuccess={() => setTabKey("BUSINESS")}
                />
              )}
            </Tabs.Panel>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Panel value="BUSINESS">
                {data && (
                  <FundingProgramSupplierLedBusinessList
                    data={data}
                    onSuccess={async () => {
                      await mutate();
                    }}
                  />
                )}
              </Tabs.Panel>
            )}
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Panel value="HISTORY">
                <FundingProgramBuyerLedHistoryList />
              </Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "review"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="Үндсэн мэдээлэл хянах"
          subTitle="Санхүүжилтийн хөтөлбөрийн үндсэн бүртгэлийг хянах"
          my={0}
          extra={[
            <CloseButton
              key="cancel"
              onClick={() => {
                setAction([]);
              }}
            />,
          ]}>
          <FundingProgramSupplierLedReviewForm
            data={data}
            success={() => {
              mutate();
            }}
            onCancel={() => setAction([])}
            type="SUPPLIER_LED"
          />
        </FormLayout>
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    to: "/cf/funding-program/SUPPLIER",
    label: "Supplier-LED хөтөлбөрийн жагсаалт",
  },
  {
    label: "Хянах",
  },
];
