import React from "react";
import { ColumnType, Table } from "../table";
import { Badge, Divider, Text } from "@mantine/core";

type Props = {
  contactData?: any;
};

type IData = {
  action: string;
  departmentUnit: string;
  email: string;
  employeeUnit: string;
  firstName: string;
  lastName: string;
  phone: string;
  registerNo: string;
};

export function PaymentContactInformationList({ contactData }: Props) {
  const columns = useHeader({
    onClick: (key, item) => {},
  });
  const column = useHeaders({
    onClick: (key, item) => {},
  });

  return (
    <>
      <Text mt="md" mb="xs" c="dimmed">
        {"Санхүүжилтийн харилцаанд supplier ажилтан"}
      </Text>
      <Table name="collection-overdue-paid-users.list" columns={columns} pagination={false} filters={{}} dataSource={contactData?.paidUsers} />
      <Divider mb="sm" />
      <Text mt="md" mb="xs" c="dimmed">
        {"Гэрээний харилцаанд оролцсон"}
      </Text>
      <Table name="collection-overdue-paid-staffs.list" columns={column} pagination={false} filters={{}} dataSource={contactData?.contractStaffs} />
      <Divider mb="sm" />
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IData) => void }): ColumnType<IData>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record?.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр",
    sorter: true,
    dataIndex: "registerNo",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {record?.registerNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {record?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "employeeUnit",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {record?.action || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Имэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {record?.email || "-"}
        </Text>
      );
    },
  },
];

const useHeaders = ({ onClick }: { onClick: (key: string, record: IData) => void }): ColumnType<IData>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record?.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {record?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр",
    sorter: true,
    dataIndex: "registerNo",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {record?.registerNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {record?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "employeeUnit",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {record?.action || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Имэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {record?.email || "-"}
        </Text>
      );
    },
  },
];
