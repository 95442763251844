import React, { useRef, useId } from "react";
import { ColumnType, ITableRef, Table } from "../table";
import { RequestApi } from "../../apis";
import { Avatar, Badge, Button, Divider, Drawer, Flex, Grid, Group, Input, Select, Text, Tooltip } from "@mantine/core";
import { IconCalendar, IconCheck, IconInfoSquare, IconListSearch, IconMailForward, IconMinus } from "@tabler/icons";
import { ScfSupplierFinanceRequestTabs } from ".";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { useNavigate, useParams } from "react-router";
import noimage from "../../assets/no-image.png";
import { DateRangePicker } from "@mantine/dates";
import { IMaskInput } from "react-imask";
import { IReference } from "../../models/General";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

export function ScfSupplierRequestList({ setIndex, data }: { data: any; setIndex: any }) {
  const id = useId();
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<string[]>([]);
  const [filters, setFilter] = React.useState<any>({
    parentId: `${params?.id}`,
    status: "",
    responseStartDate: "",
    responseEndDate: "",
    minAmount: "",
    maxAmount: "",
    businessId: "",
    businessRegNumber: "",
  });

  const columns = useHeader({
    user,
    requestStatus,
    onClick: (key, record) => {
      switch (key) {
        case "request": {
          setAction(["request", record as any]);
          break;
        }
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    ref.current?.reload();
  };

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilter({ ...filters, businessRegNumber: e });
    }
  };

  return (
    <>
      <Flex sx={{ position: "relative" }} direction="column">
        <Grid>
          <Grid.Col span={4}>
            <Select
              label="Хүсэлтийн төлөв"
              onChange={(e: any) => {
                setFilter({ ...filters, status: e });
              }}
              key={1}
              placeholder="Сонгох"
              clearable
              data={(requestStatus || []).map((c: IReference) => ({
                label: c.name,
                value: c.code,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <DateRangePicker
              value={[filters.responseStartDate, filters.responseEndDate]}
              onChange={(e) => {
                setFilter({ ...filters, responseStartDate: e[0] && e[0], responseEndDate: e[1] && e[1] });
              }}
              label="Хариу өгөх огноо:"
              placeholder="сонгох"
              icon={<IconCalendar size={16} />}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Хүсэлтийн дүн" onChange={() => {}} key={3} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Хөтөлбөрийн дүн" onChange={() => {}} key={4} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Худалдан авагчийн" onChange={() => {}} key={5} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
              <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>

        <Divider my="sm" mb="sm" mt="sm" />
        <Table ref={ref} name="scf.funding.supplier.request.list" columns={columns} filters={filters} loadData={(data) => RequestApi.request(data!)} />
        <Divider my="sm" mb="sm" mt="sm" />
        <Flex direction="row" justify="space-between">
          <Button
            variant="default"
            onClick={() => {
              navigate(`/scf/request/SUPPLIER/${params.id}/1`);
              setIndex("1");
            }}>
            Буцах
          </Button>
          <Flex gap="md">
            <Button onClick={() => navigate("/scf/request")}>Жагсаалтруу шилжих</Button>
          </Flex>
        </Flex>
      </Flex>
      <Drawer
        opened={(action && action[0] === "request") || (action && action[0] === "detail")}
        onClose={() => onCancel()}
        withCloseButton={false}
        position="right"
        size="80%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <ScfSupplierFinanceRequestTabs action={action} onCancel={onCancel} setAction={setAction} reload={reload} />
      </Drawer>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  requestStatus,
}: {
  user: any;
  onClick: (key: string, record: IFundingRequest) => void;
  requestStatus: IReference[];
}): ColumnType<IFundingRequest>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isReview).length > 0 &&
          record.requestStatus === "REQUESTED" ? (
            <Tooltip label="Санхүүжилт олгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("request", record)}>
                <IconMailForward size={20} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Санхүүжилт олгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => {}}>
                <IconMailForward size={20} stroke={0.4} />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {dateSecFormat(record.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <Flex style={{ alignItems: "center", width: 150 }} gap="xs">
            <IconCheck color="green" size={16} />
            <Text size="sm" weight={500} c="#44566C">
              <Group position="right">{tugrug(record.requestedAmount || 0)}</Group>
            </Text>
          </Flex>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || [])?.find((c: any) => c.code === record?.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || [])?.find((c: any) => c.code === record?.requestStatus)?.color}`,
          }}>
          {(requestStatus || [])?.find((c: any) => c.code === record?.requestStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Эргэн төлөх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Хүү тооцох хоног",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.financePeriod || "0"}
        </Text>
      );
    },
  },
  {
    title: "Тооцсон SCF хүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <Flex style={{ alignItems: "center", width: 200 }} gap="xs">
            <IconCheck color="green" size={16} />
            <Text size="sm" weight={500} c="#44566C">
              <Group position="right">{tugrug(record.calculatedFeeAmount || 0)}</Group>
            </Text>
          </Flex>
        </Group>
      );
    },
  },
  {
    title: "Олголтын шимтгэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.disbursementFee || 0)}
        </Text>
      );
    },
  },
  {
    title: "Өргөдлийн хураамж",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.appFee || 0)}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.invoice?.receiverBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.invoice?.receiverBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Худ.авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.invoice?.receiverBusiness?.refCode || "-"}, {record?.invoice?.receiverBusiness?.regNumber || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.invoice?.receiverBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record.invRefCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {dateSecFormat(record.invCreatedAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.invAmountToPay || 0)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх тенор",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <Flex style={{ alignItems: "center" }} gap="xs">
            {record.tenorAcceptable ? <IconCheck color="green" size={16} /> : <IconMinus color="red" size={16} />}
            <Text size="sm" weight={500} c="#44566C">
              <Group position="right">{record.remainingDays || "0"}</Group>
            </Text>
          </Flex>
        </Group>
      );
    },
  },
  {
    title: "Буцаан дуудах огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.recourseTerm ? dateSecFormat(record.recourseDate) : "Хүсэлтийг автоматаар зөвшөөрсөн"}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт зогсоох огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.restrictDate)}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
              <div>
                <Text size="sm" weight={500} c="#44566C">
                  {record?.requestedUser?.lastName || "-"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#317169">
                    {record?.requestedUser?.firstName || "-"}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
];
