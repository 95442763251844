import { createStyles, Navbar, Text } from "@mantine/core";
import { IconReceipt } from "@tabler/icons";
import { NavLink, useLocation } from "react-router-dom";
import { CommingSoon } from "./comming-soon";
import { navigationDataMappedByLink, navigationDataMappedByParentLink } from "../../constants/navigation";

export function Navigation() {
  const location = useLocation();
  const currentSection = location.pathname.split("/")[1];
  const currentNavigation = navigationDataMappedByLink[`/${currentSection}`] || {};
  const navigation = navigationDataMappedByParentLink[`/${currentSection}`] || [];

  return (
    <Navbar width={{ sm: 130 }} p="md" style={{ gap: 20, overflowY: "scroll" }}>
      {(navigation || []).map((item, index) => (
        <NavItem item={item} key={index} color={currentNavigation.color} />
      ))}
    </Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, color }: { item: INavItem; color: string }) {
  const { classes, cx } = useStyles({ color });

  return (
    <CommingSoon position="right" visible={item.disabled}>
      <NavLink className={cx(classes.item, item.disabled && "disabled")} to={!item.disabled ? item.link : "#"}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={7} lh="16px" fw="400">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }, getRef) => {
  const icon = getRef("icon");

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors.golomt[0]}!important`,
        color: `${theme.colors.golomt[6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors.golomt[6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors.golomt[6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors.golomt[6],
      backgroundColor: theme.colors.golomt[0],
      borderRadius: 4,
      padding: 8,
    },
  };
});
