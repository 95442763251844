import { Box, Divider, Grid, Tabs, Text, createStyles } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import React from "react";
import { IconAlarm, IconMailForward } from "@tabler/icons";
import { CollectionPaymentReminderList } from "../../../components/collection-payment-reminder/payment-reminder-list";
import { CollectionPaymentEmailNotificationList } from "../../../components/collection-payment-reminder/email-notification-list";
import { CollectionPaymentAutoNotificationList } from "../../../components/collection-payment-reminder/auto-notification-list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function CollectionPaymentReminder() {
  const { classes } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const [activeTab, setActiveTab] = React.useState<string>("1");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  return (
    <PageLayout title="Төлөлт сануулга жагсаалт" subTitle="Хугацаа хэтэрсэн нэхэмжлэхэд төлөлтийг сануулсан жагсаалтууд" breadcrumb={breadcrumbs} extra={[]}>
      <Divider mb="lg" />
      {/* <pre>
        {JSON.stringify(
          (user?.permissions || []).filter((c) => c.module === "COLLECTION"),
          null,
          2,
        )}
      </pre> */}
      <Grid gutter="md">
        <Grid.Col>
          <Box px={20} py={16} className={classes.gridBox}>
            <Tabs h="100%" value={activeTab} onTabChange={(value: any) => setActiveTab(value)}>
              <Tabs.List>
                {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER").length > 0 && (
                  <Tabs.Tab icon={<IconAlarm color={activeTab === "1" ? "#317169" : "#44566C"} />} value="1">
                    <Text size="sm" weight={500} c="#44566C">
                      Төлөлт сануулга
                    </Text>
                  </Tabs.Tab>
                )}
                {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER_EMAIL").length > 0 && (
                  <Tabs.Tab icon={<IconMailForward color={activeTab === "2" ? "#317169" : "#44566C"} />} value="2">
                    <Text size="sm" weight={500} c="#44566C">
                      Имэйл сануулга
                    </Text>
                  </Tabs.Tab>
                )}
                {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER_AUTO_REMINDED").length > 0 && (
                  <Tabs.Tab icon={<IconAlarm color={activeTab === "3" ? "#317169" : "#44566C"} />} value="3">
                    <Text size="sm" weight={500} c="#44566C">
                      Авто сануулга
                    </Text>
                  </Tabs.Tab>
                )}
              </Tabs.List>
              <Divider mt="md" mb="md" />
              {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER").length > 0 && (
                <Tabs.Panel value="1">
                  <CollectionPaymentReminderList />
                </Tabs.Panel>
              )}

              {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER_EMAIL").length > 0 && (
                <Tabs.Panel value="2">
                  <CollectionPaymentEmailNotificationList />
                </Tabs.Panel>
              )}

              {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_REMINDER_AUTO_REMINDED").length > 0 && (
                <Tabs.Panel value="3">
                  <CollectionPaymentAutoNotificationList />
                </Tabs.Panel>
              )}
            </Tabs>
          </Box>
        </Grid.Col>
      </Grid>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    label: "Төлөлт сануулга",
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
  systemUserBtn: {
    height: "48px",
  },

  stars: { display: "flex", alignItems: "center", color: "#868e96", fontWeight: 700, fontSize: 14 },
  adminIcon: {
    color: theme.colors.red[8],
  },
  preInfo: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    color: theme.colors.gray[6],
  },
  preInfoIcon: {
    height: 24,
    width: 24,
    padding: 0,
  },
  preInfoButton: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    cursor: "pointer",
    color: theme.colors.gray[6],
    backgroundColor: "white",
    "&:hover": {
      color: theme.colors.indigo[8],
      backgroundColor: "white",
    },
  },
}));
