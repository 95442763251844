import React from "react";
import { Badge, Flex, SegmentedControl, Text } from "@mantine/core";
import { SubLimitApi } from "../../apis";
import { ColumnType, Table } from "../table";
import { ISubLimit } from "../../interfaces/ISubLimit";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { PageFilter } from "../layout";
import { useDebouncedValue } from "@mantine/hooks";

export function ScfDcfSupplierLimit() {
  const { programParticipantStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [status, setStatus] = React.useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);

  const columns = useHeader({ programParticipantStatus, programStatus });

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Төлөв
            </Text>
            <SegmentedControl
              key={1}
              onChange={(e) => setStatus(e)}
              value={status}
              data={[{ name: "Бүгд", code: "", color: "yellow" }, ...programStatus].map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />

      <Table
        name="sme-dcf-supplier-limit.list"
        columns={columns}
        filters={{ productType: "BUYER_LED", programId: "", buyerId: "", supplierId: "", excel: `${false}`, status: statusDebounced }}
        loadData={(data) => SubLimitApi.list(data!)}
      />
    </>
  );
}

const useHeader = ({
  programParticipantStatus,
  programStatus,
}: {
  programParticipantStatus: IReference[];
  programStatus: IReference[];
}): ColumnType<ISubLimit>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.program?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.program?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.product?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.product?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "participantStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.color} 1px solid`,
            color: `${programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.color}`,
          }}>
          {programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(programStatus || []).find((c: IReference) => c?.code === record?.program?.programStatus)?.color} 1px solid`,
            color: `${(programStatus || []).find((c: IReference) => c?.code === record?.program?.programStatus)?.color}`,
          }}>
          {(programStatus || []).find((item: IReference) => item.code === record?.program?.programStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Анкор худалдан авагч",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.anchorBusiness?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.anchorBusiness?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч партнер",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.smeBusiness?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.smeBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.smeBusiness?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.smeBusiness?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн санхүү",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.smeBusiness?.finUser?.lastName || "-"} {record?.smeBusiness?.finUser?.firstName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#141517">
            {record?.smeBusiness?.finUser?.email || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Баталсан лимит",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.subLimit || 0)}
        </Text>
      );
    },
  },
  {
    title: "Боломжит лимит",
    sorter: true,
    dataIndex: "availableAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Ашигласан лимит",
    sorter: true,
    dataIndex: "utilizedAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.utilizedAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт гаргасан",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.pendingAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Олголт хүлээж буй",
    sorter: true,
    dataIndex: "toDisburseAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.toDisburseAmount || 0)}
        </Text>
      );
    },
  },
];
