import { Button, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { TextareaField } from "../form/textarea-field";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { AuthApi, LbfParticipantApi, LbfProgramApi } from "../../apis";
import { useNavigate } from "react-router";
import { tugrug } from "../../utils";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
  reviewDesc: string;
  review: boolean;
  type: boolean;
};

export function FundingProgramSupplierLedReviewForm({ data, onCancel, success, type }: { data: any; onCancel: () => void; success: any; type: string }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<boolean>(false);
  const [initialData] = React.useState<IFormData>({
    pin: "",
    reviewDesc: "",
    review: false,
    type: false,
    ...(type === "SUPPLIER_LED" ? { type: true } : {}),
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: value?.pin });

      switch (type) {
        case "SUPPLIER_LED": {
          await LbfProgramApi.review(data?.id, {
            review: status,
            reviewDesc: value?.reviewDesc,
          });
          Message.success("Амжилттай хөтөлбөрийн үндсэн мэдээллийг хянасан.");
          navigate("/cf/funding-program/SUPPLIER");
          break;
        }
        case "PARTICIPANT-REVIEW": {
          await LbfParticipantApi.review({ lbfProgramId: data.lbfProgramId, smeBusinessId: data.smeBusinessId });
          Message.success("Амжилттай хянаж зөвшөөрсөн.");
          break;
        }
        case "PARTICIPANT-TO_REVIEW": {
          await LbfParticipantApi.toReview({ lbfProgramId: data.lbfProgramId, smeBusinessId: data.smeBusinessId });
          Message.success("Амжилттай хяналтад илгээлээ.");
          break;
        }
      }
      onCancel();
      success();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initialData}>
      {({ values }) => {
        return (
          <>
            <LoadingOverlay visible={loading} />
            <Divider />
            <Grid grow gutter={1}>
              <Grid.Col span={12} mt={10}>
                {type === "SUPPLIER_LED" && (
                  <Text size="sm" weight={500} c="#44566C" style={{ letterSpacing: 1 }}>
                    Та {data?.refCode || "-"} дугаартай хөтөлбөрийг хянасан төлөвт оруулж байна. ПИН кодоор баталгаажуулна уу. Хөтөлбөрийн нийлүүлэгчийн лимит
                    хянасны дараа хөтөлбөрийг идэвхжүүлэх боломжтойг анхаарна уу.
                  </Text>
                )}
                {type === "PARTICIPANT-REVIEW" && (
                  <Text size="sm" weight={500} c="#44566C" style={{ letterSpacing: 1 }}>
                    Та нийлүүлэгчийн лимит хянасан төлөвт оруулж байна. Пин кодоор баталгаажуулна уу.
                  </Text>
                )}
                {type === "PARTICIPANT-TO_REVIEW" && (
                  <Text size="sm" weight={500} c="#44566C" style={{ letterSpacing: 1 }}>
                    Та {data?.programName || "-"} хөтөлбөрт {data?.clientCode || "-"} CIF дугаартай `{data?.profileName || "-"}`-т {tugrug(data?.subLimit || 0)}{" "}
                    төгрөгийн лимит бүртгэлээ. Хяналтад илгээх бол ПИН кодоор баталгаажуулна уу.
                  </Text>
                )}
              </Grid.Col>
              <Grid.Col span={12} mt={10}>
                <OtpField name="pin" label="Пин код." placeholder="" required />
              </Grid.Col>
              {values.type && (
                <Grid.Col span={12} mb={10}>
                  <TextareaField name="reviewDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" withAsterisk={false} maxLength={255} />
                </Grid.Col>
              )}
            </Grid>
            <Flex justify="flex-start" align="center" direction="column" wrap="wrap" mb={10}>
              <Text size="sm" weight={500} c="#141517">
                Танд амжилт хүсье.
              </Text>
            </Flex>
            <Divider />
            <Flex mt={20} direction="row" justify="space-between">
              {type === "SUPPLIER_LED" ? (
                <>
                  <Button variant="outline" onClick={() => setStatus(false)} color="red" type="submit">
                    Татгалзах
                  </Button>
                  <Button variant="outline" onClick={() => setStatus(true)} color="green" type="submit">
                    Баталгаажуулах
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="default" onClick={() => onCancel()}>
                    Буцах
                  </Button>
                  <Button variant="outline" color="green" type="submit">
                    Батлах
                  </Button>
                </>
              )}
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
//
