import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { ColumnType, ITableRef, Table } from "../table";
import { ProgramApi } from "../../apis";
import { Avatar, Badge, Button, Checkbox, Flex, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import noimage from "../../assets/no-image.png";
import { Dialog } from "../../utils/confirm-modal";
import { Message } from "../../utils/message";
import { IconEye, IconPlayerStop, IconSend } from "@tabler/icons";
import { SupplierProgramSuggestionForm } from "./suqqestion-form";
import HttpHandler from "../../utils/http-handler";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

export function SupplierProgramBuyerList() {
  const params = useParams();
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { programParticipantStatus, networkPenaltyTypes, networkDiscountTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    loading,
    programParticipantStatus,
    networkPenaltyTypes,
    networkDiscountTypes,
    onClick: (key, record) => {
      switch (key) {
        case "request": {
          setAction(["request", record as any]);
          break;
        }
        case "active": {
          Dialog.confirm("Та хамтрагч buyer-уудын саналыг зогсоох гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await ProgramApi.participantInactive({
                    smeBusinessId: record.smeBusinessId,
                    programId: `${params.id}`,
                    isActive: false,
                  });
                  Message.success("Та худалдан авагчийг идэвхгүй болголоо.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "inactive": {
          Dialog.confirm("Та хамтрагч buyer-уудын саналыг идэвхтэй болгох гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await ProgramApi.participantInactive({
                    smeBusinessId: record.smeBusinessId,
                    programId: `${params.id}`,
                    isActive: true,
                  });
                  Message.success("Та хамтрагч buyer-уудын саналыг идэвхтэй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Table
        ref={ref}
        name="supplier-led-program-buyer.list"
        columns={columns}
        filters={{ query: "", programId: `${params.id}`, productType: "SUPPLIER_LED" }}
        loadData={(data) => ProgramApi.participantList(data!)}
      />
      <Modal opened={action[0] === "request"} onClose={() => setAction([])} withCloseButton={false} size="60%" centered>
        <SupplierProgramSuggestionForm
          title="Саналыг зөвшөөрөх"
          subTitle="Харилцагч buyer-ийн авлагын лимит тохируулах."
          action={action}
          onCancel={onCancel}
          reload={reload}
        />
      </Modal>
    </div>
  );
}

const useHeader = ({
  user,
  onClick,
  loading,
  networkPenaltyTypes,
  programParticipantStatus,
  networkDiscountTypes,
}: {
  user: any;
  loading: boolean;
  onClick: (key: string, record: any) => void;
  programParticipantStatus: IReference[];
  networkPenaltyTypes: IReference[];
  networkDiscountTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_SL" && c.isCreate).length >
            0 && record.participantStatus === "PENDING" ? (
            <Tooltip label="Саналыг зөвшөөрөх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("request", record)}>
                <IconSend size={20} style={{ transform: "rotate(45deg)" }} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Саналыг зөвшөөрөх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconSend size={20} style={{ transform: "rotate(45deg)" }} stroke={0.4} />
              </Button>
            </Tooltip>
          )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_SL").length > 0 &&
          record.programStatus !== "INACTIVE" &&
          record.participantStatus === "ACTIVE" ? (
            <Tooltip label="Зогсоох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)} loading={loading}>
                <IconPlayerStop size={20} color="red" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Зогсоох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} loading={loading}>
                <IconPlayerStop size={20} stroke={0.4} />
              </Button>
            </Tooltip>
          )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_SL").length > 0 &&
          record.programStatus !== "INACTIVE" &&
          record.participantStatus === "RESTRICTED" ? (
            <Tooltip label="Идэвхтэй болгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("inactive", record)} loading={loading}>
                <IconEye size={20} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Идэвхтэй болгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} loading={loading}>
                <IconEye size={20} stroke={0.4} />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record?.partner?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            background: `${programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.color}`,
            border: `${programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.color}`,
            color: "white",
          }}>
          {programParticipantStatus.find((item: IReference) => item.code === record.participantStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {record?.paymentTerm?.description || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нийлүүлэгчээс олгосон авлага лимит",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.debtLimitAmount || "0")}
        </Text>
      );
    },
  },
  {
    title: "Лимит эхлэх огноо",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.debtLimitStartDate)}
        </Text>
      );
    },
  },
  {
    title: "Лимит дуусах огноо",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.debtLimitEndDate)}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлттэй эсэх",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return <Checkbox checked={record.earlyDiscount} label={record.earlyDiscount ? "Тийм" : "Үгүй"} onChange={() => {}} />;
    },
  },
  {
    title: "Хөнгөлөлт хувь",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.discountPercent || "0"} {"%"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт арга",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {networkDiscountTypes.find((item: IReference) => item.code === record.discountType)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Алдангатай эсэх",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return <Checkbox checked={record.countPenalty} label={record.countPenalty ? "Тийм" : "Үгүй"} onChange={() => {}} />;
    },
  },
  {
    title: "Алданга хувь",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.penaltyPercent || 0} {"%"}
        </Text>
      );
    },
  },
  {
    title: "Алданга арга",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {networkPenaltyTypes.find((item: IReference) => item.code === record.penaltyType)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 7 хоног",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.weekAvgDebtAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 2 хоног",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.halfMonthAvgDebtAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл сар",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.monthAvgDebtAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Буцаалтын дундаж дүн сар",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.monthAvgRefundAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Сарын дундаж татан авалт",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.monthAvgBidAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Сарын дундаж орлого тушаал",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.monthAvgPurchaseAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Ангилал",
    sorter: true,
    dataIndex: "programStatus",
    render: (record) => {
      return (
        <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
          <Text size="sm" weight={500} c="#44566C">
            {record.classificationCategory?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169">
            {record.classificationCategory?.refCode || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хариуцсан санхүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.finUser?.avatar === null || !record?.finUser ? noimage : record?.finUser?.avatar} />
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.lastName === null || !record?.finUser ? "" : record?.finUser?.lastName[0]}
              {record?.finUser?.lastName && "."} {record?.finUser?.firstName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.email || "-"}
            </Text>
          </Flex>
        </Group>
      );
    },
  },
];
