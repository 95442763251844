import React from "react";
import { Badge, Button, Drawer, Flex, Group, Paper, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import { tugrug } from "../../../utils";
import { ColumnType, Table } from "../../../components/table";
import { RepaymentApi } from "../../../apis";
import { IRepayment } from "../../../interfaces/IRepayment";
import { dateSecFormat } from "../../../utils/date";
import { PageLayout } from "../../../components/layout";
import { ScfFinanceRefundDetail } from "./detail";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../../models/General";

export const ScfFinanceRefundList = () => {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const { programRepaymentRules, repaymentPaymentStatus, repaymentOverDueStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    tugrug,
    repaymentPaymentStatus,
    programRepaymentRules,
    repaymentOverDueStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["information", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout
        title="Буцаан дуудсан нэхэмжлэх"
        subTitle="Харилцагчдын эргэн төлөлтийн буцаан дуудах нэхэмжлэхийн жагсаалт."
        breadcrumb={breadcrumbs}
        extra={[]}>
        <Paper radius="sm" p="md" withBorder>
          <Table
            name="scf.repayment.list"
            columns={columns}
            filters={{ isRecalled: `${true}`, productType: "BUYER_LED", type: "SUPPLIER" }}
            loadData={(data) => RepaymentApi.list(data!)}
          />
        </Paper>
        <Drawer
          opened={action[0] === "information"}
          onClose={() => onCancel && onCancel()}
          position="right"
          withCloseButton={false}
          size="80%"
          styles={{ drawer: { overflowY: "scroll" } }}>
          <ScfFinanceRefundDetail action={action} onCancel={onCancel} />
        </Drawer>
      </PageLayout>
    </>
  );
};

const useHeader = ({
  onClick,
  tugrug,
  repaymentPaymentStatus,
  programRepaymentRules,
  repaymentOverDueStatus,
}: {
  onClick: (key: string, record: IRepayment) => void;
  tugrug: any;
  repaymentPaymentStatus: IReference[];
  programRepaymentRules: IReference[];
  repaymentOverDueStatus: IReference[];
}): ColumnType<IRepayment>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "ЭТН код",
    sorter: true,
    dataIndex: "scfRequestRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 140 }}>
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "ЭТН төлөх дүн",
    sorter: true,
    dataIndex: "repaymentAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.repaymentAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "paidAmount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.paidAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    sorter: true,
    dataIndex: "amountToPay",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.amountToPay || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн төлөв",
    sorter: true,
    dataIndex: "paymentStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentPaymentStatus || []).find((c: any) => c?.code === record.paymentStatus)?.color} 1px solid`,
            color: `${(repaymentPaymentStatus || []).find((c: any) => c?.code === record.paymentStatus)?.color}`,
          }}>
          {(repaymentPaymentStatus || []).find((item: IReference) => item.code === record.paymentStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "overDueStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentOverDueStatus || []).find((c: any) => c?.code === record.overdueStatus)?.color} 1px solid`,
            color: `${(repaymentOverDueStatus || []).find((c: any) => c?.code === record.overdueStatus)?.color}`,
          }}>
          {(repaymentOverDueStatus || []).find((item: IReference) => item.code === record.overdueStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "invRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 120 }}>
          {record.scfRequestRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Олголт код",
    sorter: true,
    dataIndex: "disbursementCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 140 }}>
          {record.disbursementRef || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үүсэгсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэгч тал",
    sorter: true,
    dataIndex: "receiver",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.receiver || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээж буй хугацаа",
    sorter: true,
    dataIndex: "remainingDays",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.remainingDays || "0"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    sorter: true,
    dataIndex: "invDueDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.invDueDate)}
        </Text>
      );
    },
  },
  {
    title: "Буцаан дуудах",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          {record?.recourseTerm ? (
            <Badge color="green" radius="sm" variant="outline">
              {"Тийм"}
            </Badge>
          ) : (
            <Badge color="red" radius="sm" variant="outline">
              {"Үгүй"}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    title: "Алданги",
    sorter: true,
    dataIndex: "penaltyPercent",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.penaltyPercent || "0"} {"%"}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх дүрэм",
    sorter: true,
    dataIndex: "repaymentRule",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {programRepaymentRules.find((c: any) => c.code === record.repaymentRule)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.payerBusiness?.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.payerBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.payerBusiness?.partner?.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.payerBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.payerBusiness?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт авах данс",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.receiverAcc?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт татах данс",
    sorter: true,
    dataIndex: "payerAcc",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.payerAcc?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.payerAcc?.number || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.receiverAcc?.bankName || "-"}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Буцаан дуудсан",
  },
];
