import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Badge, Box, Button, Checkbox, Divider, Flex, Grid, Input, LoadingOverlay, NumberInput, Switch, Text, Textarea } from "@mantine/core";
import { IconCheck, IconCurrencyTugrik, IconX } from "@tabler/icons";
import { numberRegex, tugrug } from "../../utils";
import { CheckboxField } from "../form/check-box";
import { RadioGroupField } from "../form/radio-group-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { TextareaField } from "../form/textarea-field";
import { LbfRequest } from "../../models/LbfRequest";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { LbfRequestApi } from "../../apis";
import { IReference } from "../../models/General";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { dateFormat, dateSecFormat } from "../../utils/date";
import { IAuth } from "../../interfaces/IAuth";

const schema = yup.object({
  approve: yup.string().required("Заавал бөглөнө!").nullable(),
  approvedAmount: yup.string().when("approve", {
    is: "SUCCESS",
    then: yup
      .string()
      .min(1, "Хэтэрхий бага утга байна!")
      .max(16, "Хэтэрхий их утга байна!")
      .matches(numberRegex, "Тоо оруулна уу!")
      .required("Заавал бөглөнө үү!")
      .nullable(),
  }),
});

type IFormData = {
  approve: string;
  finance_board: boolean;
  respondDesc: string;
  approvedAmount: string;
};

const clientStaffLoginTypes = [
  {
    name: "Санхүүжилт зөвшөөрөх",
    code: "SUCCESS",
  },
  {
    name: "Санхүүжилтийг татгалзах",
    code: "FAILED",
  },
];

export function FundingRequestSupplierRequest({
  data,
  onNext,
  onBack,
  onSuccess,
  onCancel,
  reload,
}: {
  data: LbfRequest;
  onCancel: () => void;
  onNext: () => void;
  onBack: () => void;
  onSuccess: () => void;
  reload: () => void;
}) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [initial] = React.useState<IFormData>({
    approvedAmount: "",
    approve: "",
    respondDesc: "",
    finance_board: false,
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      if (value.approve === "SUCCESS") {
        await LbfRequestApi.respond(data.id, {
          approve: true,
          approvedAmount: value.approvedAmount,
          respondDesc: value.respondDesc,
        });
        onNext();
        Message.success("Амжилттай санхүүжилтийн хүсэлтийг зөвшөөрсөн!");
      } else {
        await LbfRequestApi.respond(data.id, {
          approve: false,
          respondDesc: value.respondDesc,
        });
        Message.success("Амжилттай санхүүжилтийн хүсэлтийг татгалзсан!");
        onCancel();
      }
      onSuccess();
      reload();
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  var currentDate = new Date(data?.invoice?.paymentDate);
  currentDate.setDate(currentDate.getDate() + data?.lbfProduct?.buyerTermDays);

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initial}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column">
            <LoadingOverlay visible={loading} />
            <Grid mt="xs">
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХҮСЭЛТИЙН ЕРӨНХИЙ МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Код, огноо цаг: ">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {dateSecFormat(data?.createdAt)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт гаргасан ажилтан: ">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.requestedUser?.lastName ? data?.requestedUser?.lastName[0] : ""}
                      {data?.requestedUser?.lastName && "."}
                      {data?.requestedUser?.firstName || "-"}
                    </Text>
                    <Badge size="md" radius="sm" variant="outline" mt={2} color="indigo">
                      {data?.requestedUser?.email || "-"}
                    </Badge>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн төлөв: ">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(requestStatus || []).find((c: any) => c?.code === data?.lbfRequestStatus)?.color} 1px solid`,
                      color: `${(requestStatus || []).find((c: any) => c?.code === data?.lbfRequestStatus)?.color}`,
                    }}>
                    {(requestStatus || []).find((c: IReference) => c.code === data?.lbfRequestStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагчийн код:">
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {data?.requestedClientCif || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагчийн нэр:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.requestedBusiness?.partner?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.requestedBusiness?.partner?.businessName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.requestedBusiness?.partner?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт хамаарах бизнес:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.requestedBusiness?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.requestedBusiness?.profileName || "-"},
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.lbfProgram?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.lbfProgram?.name || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүнийн нэр:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.lbfProduct?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.lbfProduct?.name || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгч анкор эсэх:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.lbfProductType === "SUPPLIER_LED" && data.type === "SUPPLIER" ? "Тийм" : "Үгүй"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХҮСЭЛТ ШАЛГУУР ХАНГАХ БАЙДАЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт хүссэн дүн:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.requestedAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх дугаар, үлдэгдэл:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.invoice?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {tugrug(data?.invoice?.amountToPay || 0)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Зээл төлөх огноо:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.type === "SUPPLIER" ? dateFormat(data?.invoice?.paymentDate) : dateFormat(currentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн хэмжээ шалгуур:">
                  <Flex gap="xs" align="center">
                    {data?.finAmountAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.finMinAmount)} {"-"} {tugrug(data?.finMaxAmount)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:">
                  <Flex gap="xs" align="center">
                    {data?.balanceAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug((data?.requestedAmount / 10) * (data?.lbfProduct?.minOfInv / 10))} {"-"}{" "}
                      {tugrug((data?.requestedAmount / 10) * (data?.lbfProduct?.maxOfInv / 10))}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх Tenor:">
                  <Flex gap="xs" align="center">
                    {data?.tenorAcceptable ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.minTenor} {"-"} {data?.maxTenor}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх хугацаа:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(data?.invoice?.paymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Буцаан дуудах эсэх:">
                  <Switch
                    size="md"
                    disabled
                    onLabel={
                      data?.recourseTerm ? (
                        <Text color="indigo" fz={10}>
                          тийм
                        </Text>
                      ) : (
                        <Text color="indigo" fz={10}>
                          үгүй
                        </Text>
                      )
                    }
                    checked={true}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Буцаан дуудах огноо:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(data?.recourseDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Олголт хүлээн авах данс:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#317169">
                      {data?.type === "SUPPLIER" ? <>{data?.invoice?.supplierAccount?.number || "-"}</> : <>{data?.invoice?.buyerAccount?.number}</>},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.type === "SUPPLIER" ? (
                        <>
                          {data?.invoice?.supplierAccount?.name || "-"}, {data?.invoice?.supplierAccount?.bankName || "-"}
                        </>
                      ) : (
                        <>
                          {data?.invoice?.buyerAccount?.name}, {data?.invoice?.buyerAccount?.bankName || "-"}
                        </>
                      )}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШИЙДВЭРИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              {data?.lbfRequestStatus === "REQUESTED" ? (
                <>
                  <Grid.Col span={12}>
                    <CheckboxField name="finance_board" label="">
                      {values.finance_board ? "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан" : "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан"}
                    </CheckboxField>
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <RadioGroupField
                      label=""
                      withAsterisk={false}
                      name="approve"
                      options={(clientStaffLoginTypes || []).map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                    />
                  </Grid.Col>

                  {values.approve === "SUCCESS" && (
                    <Grid.Col span={4}>
                      <NumberCurrencyField
                        max={data?.requestedAmount >= data?.invoice?.amountToPay ? data?.invoice?.amountToPay : data?.requestedAmount}
                        name="approvedAmount"
                        label="Санхүүжилт олгох дүн:"
                        placeholder="Мөнгөн дүн"
                        required
                      />
                      <Text c="dimmed" fz="xs" fw={500} style={{ color: "#FA5252" }}>
                        {`Санхүүжилтийн хүсэлтийн дүн ${tugrug(data?.requestedAmount || 0)}, нэхэмжлэхийн үлдэгдэл ${tugrug(
                          data?.invoice?.amountToPay || 0,
                        )}. Та эдгээр дүнгээс өндөр дүн оруулах боломжгүй! `}
                      </Text>
                    </Grid.Col>
                  )}
                  <Grid.Col span={8}>
                    <TextareaField name="respondDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" withAsterisk={false} maxLength={255} />
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col span={12}>
                    <Checkbox
                      checked={true}
                      onChange={() => {}}
                      label={
                        <Text color="gray" fz="sm" fw={500}>
                          Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан
                        </Text>
                      }
                    />
                  </Grid.Col>

                  {data.isApproved && (
                    <Grid.Col span={4}>
                      <NumberInput
                        label="Санхүүжилт олгох дүн: "
                        value={data.approvedAmount}
                        hideControls
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                        formatter={(value: any) => (!Number.isNaN(parseFloat(value)) ? ` ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : " ")}
                        rightSection={<IconCurrencyTugrik style={{ width: 20, height: 20 }} stroke={0.8} />}
                        disabled
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col span={8}>
                    <Textarea label="Тайлбар: " value={data?.respondDesc} disabled />
                  </Grid.Col>
                </>
              )}
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between" mt={10}>
              <Button
                variant="default"
                onClick={() => {
                  onBack();
                }}>
                Буцах
              </Button>
              <Flex gap="md">
                {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REQUEST_RESPOND_SUPPLIER").length > 0 && (
                  <>
                    <Button
                      variant="filled"
                      color="red"
                      disabled={!values.finance_board || values.approve === "SUCCESS" || data?.lbfRequestStatus !== "REQUESTED"}
                      type="submit">
                      Татгалзах
                    </Button>
                    <Button
                      variant="filled"
                      disabled={!values.finance_board || values.approve === "FAILED" || data?.lbfRequestStatus !== "REQUESTED"}
                      type="submit">
                      Зөвшөөрөх
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
