import React from "react";
import { ActionIcon, Divider, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconFileText, IconMailForward, IconX } from "@tabler/icons";
import { PaymentContactAddressForm } from "../../../components/collection-payment-over-due/contact-address-form";
import { PaymentContactInformationList } from "../../../components/collection-payment-over-due/contact-information-list";
import useSwr from "swr";
import { OverdueApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";

type Props = {
  onCancel?: () => void;
  action: string[];
  reloadTable?: any;
};

export function CollectionPaymentOverdueTabs({ action, onCancel }: Props) {
  const [tabIndex, setTabIndex] = React.useState<string | null>("1");

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  const { data: contactData } = useSwr(`/collection/overdue/${action[1]}/contact_info`, async () => {
    try {
      if (action[1] === undefined) {
        return [];
      }

      let res = await OverdueApi.contactList(action && action[1]);
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <>
      <PageLayout
        title="Холбоо барих мэдээлэл"
        extra={[
          <ActionIcon onClick={() => onCancel && onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Divider mb="sm" />
        <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
          <Tabs.List>
            <Tabs.Tab icon={<IconFileText color={tabIndex === "1" ? "#317169" : "#44566C"} />} value="1">
              <Text size="sm" weight={500} c="#44566C">
                Холбоо барих мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab icon={<IconMailForward color={tabIndex === "2" ? "#317169" : "#44566C"} />} value="2">
              <Text size="sm" weight={500} c="#44566C">
                Хаягийн мэдээлэл
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">{tabIndex === "1" && <PaymentContactInformationList contactData={contactData} />}</Tabs.Panel>
          <Tabs.Panel value="2">{tabIndex === "2" && <PaymentContactAddressForm contactData={contactData} onCancel={onCancel} />}</Tabs.Panel>
        </Tabs>
      </PageLayout>
    </>
  );
}
