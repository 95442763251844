import React, { useRef } from "react";
import * as yup from "yup";
import { Button, CloseButton, Divider, Flex } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { Form, IFormRef } from "../../../components/form";
import { FormLayout } from "../../../components/layout";
import { SelectField } from "../../../components/form/select-field";
import useSwr from "swr";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { LbfProductApi } from "../../../apis";

const schema = yup.object({
  refCode: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  refCode: string;
};

type ISelect = {
  name: string;
  refCode: string;
};

export function FundingProgramRegisterForm({ onCancel }: { onCancel: () => void }) {
  const params = useParams();
  const navigate = useNavigate();
  const formRef =
    useRef<IFormRef<{ submit: () => any; validate: () => Promise<{ [key: string]: string }>; setFormData: (callback: (state: any) => any) => void } | any>>(
      null,
    );
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    refCode: "",
  });

  const { data: productData } = useSwr(`/api/lbf/product/select/${params.type || "BUYER"}`, async () => {
    try {
      let res = await LbfProductApi.select({ query: "", limit: "", type: params.type === "SUPPLIER" ? "SUPPLIER_LED" : "BUYER_LED", category: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    navigate(`/cf/funding-program/${params.type || "BUYER"}/${data.refCode}/new`);
    setLoading(false);
  };

  return (
    <FormLayout
      title="Зээл суурьтай SCF хөтөлбөр"
      subTitle={`Зээл суурьтай ${params.type === "SUPPLIER" ? "Supplier-LED" : "Buyer-LED"} бүтээгдэхүүн.`}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}
      my={0}>
      <Divider mb="xs" />
      <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {() => {
          return (
            <SelectField
              name="refCode"
              label="Санхүүжилтийн бүтээгдэхүүн"
              placeholder="сонгох"
              options={(productData || []).map((item: ISelect) => ({ label: item.name, value: item.refCode }))}
              searchable
            />
          );
        }}
      </Form>
      <Divider mt="xs" />
      <Flex direction="row" justify="space-between" mt={10}>
        <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button loading={loading} key={2} onClick={() => formRef.current?.submit()}>
          Үргэлжлүүлэх
        </Button>
      </Flex>
    </FormLayout>
  );
}
