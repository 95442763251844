import { Radio } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  spacing?: any;
  orientation?: "vertical" | "horizontal" | undefined;
  disabled?: boolean;
  withAsterisk?: boolean;
};

export function RadioField({ name, label, options = [], disabled = false, orientation, spacing, withAsterisk = true }: Props) {
  const { value, error } = useField(name);

  return (
    <Radio.Group orientation={orientation} spacing={spacing} withAsterisk={withAsterisk} label={label} value={value} error={error} required style={{ flex: 1 }}>
      {options.map((v: any, index: number) => (
        <Radio key={index} value={v.value} label={v.label} />
      ))}
    </Radio.Group>
  );
}
