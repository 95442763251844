import React from "react";
import { Avatar, Badge, Button, Divider, Drawer, Flex, Group, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { IPartnerSupplier } from "../../interfaces/IPartnerSupplier";
import { ColumnType, Table } from "../table";
import { AncorDetailSupplierForm } from "./supplier-form";
import noimage from "../../assets/no-image.png";
import { IconInfoSquare } from "@tabler/icons";

export function AncorDetailSupplierList({ data }: { data: any }) {
  const [action, setAction] = React.useState<string[]>([]);

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["opened", record.id]);
          break;
        }
      }
    },
  });

  return (
    <>
      <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БҮРТГЭЛТЭЙ SUPPLIER БИЗНЕС" mb="xs" />
      <Table name="ancor.detail.supplier.list" columns={columns} dataSource={data || []} />
      <Drawer
        opened={action[0] === "opened"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        size="60%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <AncorDetailSupplierForm action={action} />
      </Drawer>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IPartnerSupplier) => void }): ColumnType<IPartnerSupplier>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Бизнесийн төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return record.type === "BUYER" ? (
        <Badge size="md" radius="sm" variant="outline">
          {"Худалдан авагч"}
        </Badge>
      ) : (
        <Badge size="md" radius="sm" variant="outline">
          {"Нийлүүлэгч"}
        </Badge>
      );
    },
  },
  {
    title: "Бизнес Ref#",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <UnstyledButton>
          <Group>
            <Badge size="md" radius="sm" variant="outline" onClick={() => {}}>
              {record.refCode || "-"}
            </Badge>
          </Group>
        </UnstyledButton>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.profileName || "-"}
        </Text>
      );
    },
  },
  // {
  //   title: "SCF/DCF гэрээтэй",
  //   sorter: true,
  //   dataIndex: "businessSubSector",
  //   render: (record) => {
  //     return (
  //       <Text size="sm" weight={500} c="#44566C">
  //         {"-"}
  //       </Text>
  //     );
  //   },
  // },
  // {
  //   title: "Хөтөлбөр",
  //   sorter: true,
  //   dataIndex: "businessSubSector",
  //   render: (record) => {
  //     return (
  //       <Text size="sm" weight={500} c="#44566C">
  //         {record.programCount || "-"}
  //       </Text>
  //     );
  //   },
  // },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "partnerEmail",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.partnerEmail || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.partnerPhone || "-"}
        </Text>
      );
    },
  },
  // {
  //   title: "SCF/DCF хэрэглэгч",
  //   sorter: true,
  //   dataIndex: "finUser",
  //   render: (record) => {
  //     return (
  //       <>
  //         {
  //           <Group noWrap>
  //             <div>
  //               <Text size="sm" weight={500} c="#44566C">
  //                 {record?.finUser?.lastName} {record?.finUser?.firstName}
  //               </Text>
  //               <Flex gap={5} wrap="nowrap">
  //                 <Text size="sm" weight={500} c="#141517">
  //                   {record?.finUser?.username}
  //                 </Text>
  //               </Flex>
  //             </div>
  //           </Group>
  //         }
  //       </>
  //     );
  //   },
  // },
];
