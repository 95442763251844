import { Button, createStyles, Flex, Grid, Group, Image, Paper, Text, LoadingOverlay, Box } from "@mantine/core";
import Layout from "../../components/home/layout";
import LoginImage from "../../assets/icon.svg";
import BackgroundLine from "../../assets/background-line.svg";
import { Form } from "../../components/form";
import * as yup from "yup";
import React from "react";
import { TextField } from "../../components/form/text-field";
import { Link } from "react-router-dom";
import { AuthApi } from "../../apis";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({
  email: yup.string().email("Та и-мэйл хаягаа зөв оруулна уу!").required("Хэрэглэгчийн нэрээ оруулна уу"),
});

type IForm = {
  email: undefined | string;
};

export default function Forgot() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isSent, setIsSent] = React.useState<boolean>(false);

  const [data] = React.useState<IForm>({
    email: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      await AuthApi.forgot(values);

      Message.success("Нууц үг сэргээх имэйл амжилттай!");
      setIsSent(true);
      setLoading(false);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <Flex justify="space-between">
          <div className={classes.image}>
            <img src={LoginImage} alt={"svg"} />
          </div>

          <Paper withBorder className={classes.card}>
            <LoadingOverlay visible={loading} />
            <Text fz={40} color={"white"} weight={800}>
              НУУЦ ҮГ СЭРГЭЭХ
            </Text>

            {isSent ? (
              <Box mt={50}>
                <Text align="center" color="white" fw={500} fz={20}>
                  Таны бүртгэлтэй email хаяг руу нууц үг сэргээх холбоос илгээсэн.
                </Text>
                <Button component="a" href="/auth/login" gradient={{ from: "green", to: "#317169"  }} mt={100} variant="gradient" size="md" w="100%">
                  Нэвтрэх хуудасруу буцах
                </Button>
              </Box>
            ) : (
              <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
                {() => {
                  return (
                    <Grid mt={20} gutter={20}>
                      <Grid.Col h={100} span={12}>
                        <TextField
                          label="Хэрэглэгчийн email оруулна уу:"
                          variant="unstyled"
                          className={classes.input}
                          size="md"
                          name="email"
                          placeholder="ba***@mail.com..."
                        />
                      </Grid.Col>

                      <Grid.Col mt={20} span={12}>
                        <Group position="center">
                          <Text color="white">
                            Бүртгэлтэй бол
                            <Link to={"/auth/login"}>
                              <Text span c="blue" inherit fw={700} px={5}>
                                Нэвтрэх
                              </Text>
                            </Link>
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <Button variant="gradient" gradient={{ from: "green", to: "#317169" }} type="submit" size="md" w="100%">
                          Илгээх
                        </Button>
                      </Grid.Col>

                      <Grid.Col mt={50} span={12}>
                        <Group position="center">
                          <Text color="white">Баталгаажуулалт хийх заавартай танилцах</Text>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  );
                }}
              </Form>
            )}
          </Paper>
        </Flex>
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    marginTop: 8,
    maxWidth: "1000px",
    fontSize: 14,
    color: "white",
  },

  text: {
    marginTop: -5,
    fontSize: 64,
    fontWeight: 800,
    color: "white",
  },

  card: {
    maxWidth: 500,
    maxHeight: 800,
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "#ffffff19",
    cursor: "pointer",
    border: 0,
    borderRadius: 6,
    overflow: "hidden",
    padding: "35px 40px",
  },

  input: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    label: {
      color: theme.white,
    },
  },
  passwordInput: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    label: {
      color: theme.white,
    },
    borderRadius: 4,
    backgroundColor: "#E8F0Fe",
    height: 42,
  },
  image: {
    height: "800px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
