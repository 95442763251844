import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: {
  repaymentId: string;
  receiverUserId: string;
  isExistUser: boolean;
  phone: string;
  email: string;
  reminderType: string;
  reminderLevel: string;
  connectType: string;
  result: string;
  response: string;
  clientText: string;
  internalText: string;
}) => {
  return httpRequest.post(`/api/collection/reminder`, data);
};

export const select = async (data: any) => {
  return httpRequest.get(`/api/collection/reminder/staff/select`, data);
};

export const email = async (data: {
  clientText: string;
  compromisable: boolean;
  email: string;
  reminderType: string;
  reminderLevel: string;

  repaymentId: string;
  title: string;
  repaymentRefCode: string;
}) => {
  return httpRequest.post(`/api/collection/reminder/email`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/collection/reminder/staff/${id}`);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/reminder", { offset, filter });
};
