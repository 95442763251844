import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { parentId: string; code: string; name: string }) => {
  return httpRequest.post(`/api/program/reference/economical_sector`, data);
};
export const get = async (id: string) => {
  return httpRequest.get(`/api/program/reference/economical_sector/${id}`);
};
export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/program/reference/economical_sector", { offset, filter });
};
export const remove = async (id: string) => {
  return httpRequest.del(`/api/program/reference/economical_sector/${id}`);
};
