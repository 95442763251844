import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { Form } from "../form";
import { percent } from "../../utils";
import { Alert, Button, Divider, Flex, Grid, Text } from "@mantine/core";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberPercentField } from "../form/number-percent-field";
import { TextField } from "../form/text-field";
import { RadioGroupField } from "../form/radio-group-field";
import { SwitchField } from "../form/switch-field";
import { IconSend } from "@tabler/icons";

export type IBuyerFinanceFormData = {
  minInvAmount: number;
  maxOfInv: string;
  minOfInv: number;
  minTenor: number;
  maxTenor: number;
  minDays: number;
  maxDays: number;
  borrowerType: string;
  recourseDays: string;
  recourseTerm: boolean;
  buyerTerm: number;
  buyerTerminatePeriod: string;
  suppTerminatePeriod: string;
  productOverDueLimit: boolean;
  overDueLimitType: string;
  overDueLimitValue: string;
};

const schema = yup.object().shape({
  maxOfInv: yup.string().matches(percent, "Та дахин нэг шалгана уу!").typeError("Тоо оруулна уу.").required("Заавал бөглөнө!"),
  minOfInv: yup
    .number()
    .typeError("Тоо оруулна уу.")
    .when("maxOfInv", (maxOfInv, field) =>
      maxOfInv > 0 ? field.lessThan(maxOfInv, "Нэхэмжлэхийн Мах хувиас бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxTenor: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minTenor: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxTenor", (maxTenor, field) =>
      maxTenor > 0 ? field.lessThan(maxTenor, "Нэхэмжлэхийн Max тенороос бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxDays: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minDays: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxDays", (maxDays, field) =>
      maxDays > 0 ? field.lessThan(maxDays, "Та нэхэмжлэхийн Мax хугацаанаас бага утга оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  borrowerType: yup.string().required("Заавал бөглөнө!").nullable(),
  suppTerminatePeriod: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "SUPPLIER_ONLY") {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  buyerTerm: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  minInvAmount: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  buyerTerminatePeriod: yup.string().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  // suppTerminatePeriod: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
});

export function BuyerProductFinanceForm({ onSubmit: _onSubmit, action }: { onSubmit: (values: IBuyerFinanceFormData, type: boolean) => void; action: any }) {
  const navigate = useNavigate();
  const { borrowerTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [type, setType] = React.useState(false);
  const [data] = React.useState<IBuyerFinanceFormData>({
    borrowerType: "",
    minInvAmount: "",
    maxOfInv: "",
    minOfInv: "",
    minTenor: "",
    maxTenor: "",
    minDays: "",
    maxDays: "",
    recourseTerm: true,
    suppTerminatePeriod: "",
    productOverDueLimit: false,
    overDueLimitType: "",
    overDueLimitValue: "",
    recourseDays: "0",
    buyerTerm: "",
    buyerTerminatePeriod: "",
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          recourseTerm: true,
          recourseDays: "0",
          maxTenor: action[1].maxTenor ? parseInt(action[1].maxTenor, 10) : "",
          minTenor: action[1].minTenor ? parseInt(action[1].minTenor, 10) : "",
          maxDays: action[1].maxDays ? parseInt(action[1].maxDays, 10) : "",
          minDays: action[1].minDays ? parseInt(action[1].minDays, 10) : "",
        }
      : {}),
  });

  const onSubmit = (value: IBuyerFinanceFormData) => {
    _onSubmit(value, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, errors }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="lg">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="САНХҮҮЖИЛТ ОЛГОХ ҮЕИЙН ШАЛГАХ НӨХЦӨЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberCurrencyField
                  precision={2}
                  min={0}
                  max={999999999999}
                  name="minInvAmount"
                  label="Нэхэмжлэхийн доод үлдэгдэл:"
                  placeholder="Үнийн дүнг оруулна уу"
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="maxOfInv" label="Нэхэмжлэхийн Мах хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="minOfInv" label="Нэхэмжлэхийн Мin хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField
                  name="maxTenor"
                  label="Нэхэмжлэхийн Мах тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="minTenor"
                  label="Нэхэмжлэхийн Мin тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="maxDays"
                  label="Нэхэмжлэхийн Мax хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField
                  name="minDays"
                  label="Нэхэмжлэхийн Мin хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <RadioGroupField
                  label="Санхүүжилт авагч тал"
                  name="borrowerType"
                  options={(borrowerTypes || []).map((c: any) => ({
                    label: c.blName,
                    value: c.code,
                  }))}
                />
              </Grid.Col>
              {values.borrowerType === "SUPPLIER_ONLY" && (
                <>
                  {/* <Grid.Col span={12}>
                    <Divider
                      color="gray"
                      styles={{ label: { fontSize: 16, fontWeight: 500 } }}
                      my="xs"
                      label="ЗӨВХӨН НИЙЛҮҮЛЭГЧ, САНХҮҮЖИЛТ АВАХ ҮЕИЙН НӨХЦӨЛИЙГ ОРУУЛНА УУ 111."
                    />
                  </Grid.Col> */}

                  <Grid.Col span={4}>
                    <Flex direction="column" gap={3} mt={4}>
                      <Text fz={14} fw={500} c="#212529">
                        Буцаан дуудах эсэх:
                      </Text>
                      <SwitchField name="recourseTerm" size="lg" disabled />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="recourseDays"
                      label="Буцаан дуудах хугацаа:"
                      placeholder="Хоногийг оруулна уу"
                      disabled
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                      withAsterisk={false}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="suppTerminatePeriod"
                      label="Санхүүжилт зогсоох хугацаа"
                      placeholder="Хоногийг оруулна уу"
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>
                </>
              )}

              {values.borrowerType === "BUYER" && (
                <>
                  {/* <Grid.Col span={12}>
                    <Divider
                      color="gray"
                      styles={{ label: { fontSize: 16, fontWeight: 500 } }}
                      my="xs"
                      label="BUYER НЬ НЭХЭМЖЛЭХ ХУГАЦААГ ХОЙШЛУУЛЖ БУЙ НӨХЦӨЛИЙГ ОРУУЛНА 222."
                    />
                  </Grid.Col> */}

                  <Grid.Col span={4}>
                    <Flex direction="column" gap={3} mt={4}>
                      <Text fz={14} fw={500} c="#212529">
                        Буцаан дуудах эсэх:
                      </Text>
                      <SwitchField name="recourseTerm" size="lg" disabled />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="recourseDays"
                      label="Буцаан дуудах хугацаа:"
                      placeholder="Хоногийг оруулна уу"
                      disabled
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextField
                      name="buyerTerminatePeriod"
                      label="Санхүүжилт зогсоох хугацаа:"
                      placeholder="Хоногийг оруулна уу"
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="buyerTerm"
                      label="Худалдан авагчид өгөх хоног:"
                      placeholder="Хоногийг оруулна уу"
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={4}></Grid.Col>
                  <Grid.Col span={4}></Grid.Col>
                </>
              )}

              <Grid.Col span={12}>
                <Alert
                  styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Таны анхаарах зүйл">
                  Та бүтээгдэхүүний үндсэн мэдээллийг оруулж дуусаад "Үргэжлүүлэх" сонголтыг хийнэ үү.
                </Alert>
              </Grid.Col>
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/scf-dcf-product/scf/product")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" onClick={() => setType(false)}>
                  Хадгалах
                </Button>
                <Button rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />} type="submit" onClick={() => setType(true)}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
