import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { SfcDcfAccountTransactionList } from "./components/account/history";
import { SmeDetail } from "./components/ancor/detail";
import { Layout } from "./components/layout";
import { ScfFundingProvidedSupplierLedTabs } from "./components/scf-funding-provided";
import { ScfSupplierRequestTabs } from "./components/scf-supplier-led";
import Forgot from "./pages/auth/forgot";
import Login from "./pages/auth/login";
import { Verify } from "./pages/auth/verify";
import { B2bDashboard } from "./pages/b2b/dashboard";
import { AccountTabs } from "./pages/cf/account";
import { AccountBuyerDetail } from "./pages/cf/account-buyer/detail";
import { AccountSupplierDetail } from "./pages/cf/account-supplier/detail";
import { FundingProgram } from "./pages/cf/funding-program";
import { FundingProgramBuyerLedDetail } from "./pages/cf/funding-program-buyer/detail";
import { FundingProgramBuyerLedEdit } from "./pages/cf/funding-program-buyer/edit";
import { FundingProgramBuyerLedNew } from "./pages/cf/funding-program-buyer/new";
import { FundingProgramBuyerLedReview } from "./pages/cf/funding-program-buyer/review";
import { FundingProgramSupplierLedDetail } from "./pages/cf/funding-program-supplier/detail";
import { FundingProgramSupplierLedEdit } from "./pages/cf/funding-program-supplier/edit";
import { FundingProgramSupplierLedNew } from "./pages/cf/funding-program-supplier/new";
import { FundingProgramSupplierLedReview } from "./pages/cf/funding-program-supplier/review";
import { FundingRequestTabs } from "./pages/cf/funding-request";
import { CfLimitTab } from "./pages/cf/limit";
import { ProductTab } from "./pages/cf/product";
import { ProductBuyerLedEdit } from "./pages/cf/product-buyer/edit";
import { ProductBuyerLedNew } from "./pages/cf/product-buyer/new";
import { ProductSupplierLedEdit } from "./pages/cf/product-supplier/edit";
import { ProductSupplierLedNew } from "./pages/cf/product-supplier/new";
import { CfReceivableLimitTab } from "./pages/cf/receivable-limit";
import { Reference } from "./pages/cf/reference/list";
import { CollectionDashboard } from "./pages/collection/dashboard";
import { CollectionObservationDetailTabs } from "./pages/collection/observation/detail";
import { CollectionObservationList } from "./pages/collection/observation/list";
import { CollectionPaymentOverdueList } from "./pages/collection/overdue/list";
import { CollectionPaymentConditionList } from "./pages/collection/payment-condition/list";
import { CollectionPaymentReminder } from "./pages/collection/payment-reminder";
import { CollectionPaymentSettlementList } from "./pages/collection/settlement/list";
import { AncorCustomerDetail } from "./pages/customer/ancor-corporate/detail";
import { AncorCorporateList } from "./pages/customer/ancor-corporate/list";
import { CustomerBankAccount } from "./pages/customer/customer-account";
import { RequestTab } from "./pages/customer/request";
import { SmeTabs } from "./pages/customer/sme-onboarding";
import { DcfDashboard } from "./pages/dcf/dashboard";
import Homepage from "./pages/homepage";
import NoMatch from "./pages/noMatch";
import NoMatchInSection from "./pages/noMatchInSection";
import { ProFile } from "./pages/pro-file";
import { BuyerLedProgramDetail } from "./pages/scf-dcf/buyer-led-program/detail";
import { BuyerLedProgramEdit } from "./pages/scf-dcf/buyer-led-program/edit";
import { BuyerLedProgramList } from "./pages/scf-dcf/buyer-led-program/list";
import { BuyerLedProgramNew } from "./pages/scf-dcf/buyer-led-program/new";
import { BuyerLedProgramReview } from "./pages/scf-dcf/buyer-led-program/review";
import { ScfDcfDashboard } from "./pages/scf-dcf/dashboard";
import { ScfDcfReference } from "./pages/scf-dcf/reference";
import { ScfDcfReferenceLevel } from "./pages/scf-dcf/reference/level";
import { ScfDcfLimit } from "./pages/scf-dcf/scf-limit";
import { ScfProgramUsage } from "./pages/scf-dcf/scf-program-usage";
import { SupplierLedProgramDetail } from "./pages/scf-dcf/supplier-led-program/detail";
import { SupplierLedProgramEdit } from "./pages/scf-dcf/supplier-led-program/edit";
import { SupplierLedProgramList } from "./pages/scf-dcf/supplier-led-program/list";
import { SupplierLedProgramNew } from "./pages/scf-dcf/supplier-led-program/new";
import { SupplierLedProgramReview } from "./pages/scf-dcf/supplier-led-program/review";
import { ScfDashboard } from "./pages/scf/dashboard";
import { ScfFinancePotentialList } from "./pages/scf/finance-potential/list";
import { ScfFinanceRefundList } from "./pages/scf/finance-refund/list";
import { ScfFinanceRequest } from "./pages/scf/finance-request";
import { ScfFundingIntroductionTabs } from "./pages/scf/funding-introduction";
import { ScfFundingProvidedTabs } from "./pages/scf/funding-provided";
import { ScfFinanceTab } from "./pages/scf/limit";
import { ScfFinanceRepayment } from "./pages/scf/repayment";
import { AccountStatement } from "./pages/scfDcfProduct/account/statement";
import { Account } from "./pages/scfDcfProduct/account/tabs";
import { BuyerProductEdit } from "./pages/scfDcfProduct/buyer-product/edit";
import { BuyerProductList } from "./pages/scfDcfProduct/buyer-product/list";
import { BuyerProductNew } from "./pages/scfDcfProduct/buyer-product/new";
import { ScfDcfProductDashboard } from "./pages/scfDcfProduct/dashboard";
import { ScfDcfPProductFinance } from "./pages/scfDcfProduct/finance";
import { SupplierProductEdit } from "./pages/scfDcfProduct/supplier-product/edit";
import { SupplierProductList } from "./pages/scfDcfProduct/supplier-product/list";
import { SupplierProductNew } from "./pages/scfDcfProduct/supplier-product/new";
import { UserControlApprovelList } from "./pages/userControl/approval/list";
import { RoleDetail } from "./pages/userControl/role/detail";
import { RoleList } from "./pages/userControl/role/list";
import { RoleNew } from "./pages/userControl/role/new";
import { UserList } from "./pages/userControl/user/list";
import { VerifyPage } from "./pages/verify";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const { accessToken, sessionScope } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const init = React.useCallback(async () => {
    setLoading(true);
    if (accessToken) {
      const resInit = await GeneralApi.init();
      dispatch(initGeneral(resInit));
      if (sessionScope === "AUTHORIZED") {
        const auth = await AuthApi.me();
        dispatch(authMe(auth));
      }
    }

    setLoading(false);
  }, [accessToken, dispatch, sessionScope]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();
    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      {accessToken && sessionScope === "AUTHORIZED" ? (
        <>
          <Route index element={<Homepage />} />
          <Route path="/settings/profile" element={<ProFile />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="scf" element={<Layout />}>
            <Route index element={<ScfDashboard />} />
            <Route path="/scf/dashboard" element={<ScfDashboard />} />
            <Route path="/scf/request/:type?" element={<ScfFinanceRequest />} />
            <Route path="/scf/request/SUPPLIER/:id/:step" element={<ScfSupplierRequestTabs />} />

            <Route path="/scf/finance-refund-paid/:type?" element={<ScfFinanceRepayment />} />
            <Route path="/scf/limit/:type?" element={<ScfFinanceTab />} />
            <Route path="/scf/funding-instruction/:type?" element={<ScfFundingIntroductionTabs />} />
            <Route path="/scf/funding-provided/:type?" element={<ScfFundingProvidedTabs />} />
            <Route path="/scf/funding-provided/SUPPLIER/:id/:step" element={<ScfFundingProvidedSupplierLedTabs />} />
            <Route path="/scf/finance-invoice-refund" element={<ScfFinanceRefundList />} />
            <Route path="/scf/finance-invoice-potential" element={<ScfFinancePotentialList />} />
            <Route path="*" element={<NoMatchInSection />} />
          </Route>

          <Route path="cf" element={<Layout />}>
            <Route path="/cf/funding-request/:type?" element={<FundingRequestTabs />} />

            <Route path="/cf/account/:type?" element={<AccountTabs />} />
            <Route path="/cf/account/SUPPLIER/detail/:id" element={<AccountSupplierDetail />} />
            <Route path="/cf/account/BUYER/detail/:id" element={<AccountBuyerDetail />} />

            <Route path="/cf/funding-program/:type?" element={<FundingProgram />} />
            <Route path="/cf/funding-program/BUYER/:refCode/new" element={<FundingProgramBuyerLedNew />} />
            <Route path="/cf/funding-program/BUYER/:refCode/edit/:id/:type" element={<FundingProgramBuyerLedEdit />} />
            <Route path="/cf/funding-program/BUYER/:refCode/detail/:id/:type" element={<FundingProgramBuyerLedDetail />} />
            <Route path="/cf/funding-program/BUYER/:refCode/review/:id/:type" element={<FundingProgramBuyerLedReview />} />

            <Route path="/cf/funding-program/SUPPLIER/:refCode/new" element={<FundingProgramSupplierLedNew />} />
            <Route path="/cf/funding-program/SUPPLIER/:refCode/edit/:id/:type" element={<FundingProgramSupplierLedEdit />} />
            <Route path="/cf/funding-program/SUPPLIER/:refCode/detail/:id/:type" element={<FundingProgramSupplierLedDetail />} />
            <Route path="/cf/funding-program/SUPPLIER/:refCode/review/:id/:type" element={<FundingProgramSupplierLedReview />} />

            <Route path="/cf/product/:type?" element={<ProductTab />} />
            <Route path="/cf/product/BUYER/new" element={<ProductBuyerLedNew />} />
            <Route path="/cf/product/BUYER/edit/:id/:type" element={<ProductBuyerLedEdit />} />

            <Route path="/cf/product/SUPPLIER/new" element={<ProductSupplierLedNew />} />
            <Route path="/cf/product/SUPPLIER/edit/:id/:type" element={<ProductSupplierLedEdit />} />

            <Route path="/cf/reference" element={<Reference />} />
            <Route path="/cf/limit/:type?" element={<CfLimitTab />} />

            <Route path="/cf/receivable-limit/:type?" element={<CfReceivableLimitTab />} />

            <Route path="*" element={<NoMatchInSection />} />
          </Route>

          <Route path="dcf" element={<Layout />}>
            <Route index element={<DcfDashboard />} />
            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="b2b" element={<Layout />}>
            <Route index element={<B2bDashboard />} />
            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="collection" element={<Layout />}>
            <Route index element={<CollectionDashboard />} />
            <Route path="/collection/dashboard" element={<CollectionDashboard />} />
            <Route path="/collection/observation" element={<CollectionObservationList />} />
            <Route path="/collection/observation/detail/:id" element={<CollectionObservationDetailTabs />} />

            <Route path="/collection/payment-overdue" element={<CollectionPaymentOverdueList />} />
            <Route path="/collection/payment-settlement" element={<CollectionPaymentSettlementList />} />

            <Route path="/collection/payment-reminder" element={<CollectionPaymentReminder />} />
            <Route path="/collection/payment-condition" element={<CollectionPaymentConditionList />} />

            <Route path="*" element={<NoMatchInSection />} />
          </Route>

          <Route path="scf-dcf" element={<Layout />}>
            <Route index element={<ScfDcfDashboard />} />
            <Route path="/scf-dcf/dashboard" element={<ScfDcfDashboard />} />
            <Route path="/scf-dcf/usage/:type?" element={<ScfProgramUsage />} />

            <Route path="/scf-dcf/reference" element={<ScfDcfReference />} />
            <Route path="/scf-dcf/reference/:step" element={<ScfDcfReferenceLevel />} />

            <Route path="/scf-dcf/dcf-program" element={<BuyerLedProgramList />} />
            <Route path="/scf-dcf/dcf-program/:code/new" element={<BuyerLedProgramNew />} />
            <Route path="/scf-dcf/dcf-program/:code/edit/:id/:step" element={<BuyerLedProgramEdit />} />
            <Route path="/scf-dcf/dcf-program/:id/review/:step" element={<BuyerLedProgramReview />} />
            <Route path="/scf-dcf/dcf-program/:id/detail/:step" element={<BuyerLedProgramDetail />} />

            <Route path="/scf-dcf/scf-program" element={<SupplierLedProgramList />} />
            <Route path="/scf-dcf/scf-program/:code/new" element={<SupplierLedProgramNew />} />
            <Route path="/scf-dcf/scf-program/:code/edit/:id/:step" element={<SupplierLedProgramEdit />} />
            <Route path="/scf-dcf/scf-program/:id/review/:step" element={<SupplierLedProgramReview />} />
            <Route path="/scf-dcf/scf-program/:id/detail/:step" element={<SupplierLedProgramDetail />} />

            <Route path="/scf-dcf/limit/:type?" element={<ScfDcfLimit />} />

            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="scf-dcf-product" element={<Layout />}>
            <Route index element={<ScfDcfProductDashboard />} />
            <Route path="/scf-dcf-product/dashboard" element={<ScfDcfProductDashboard />} />
            <Route path="/scf-dcf-product/accounting/:type?" element={<Account />} />
            <Route path="/scf-dcf-product/accounting/:type/transaction-history/:id" element={<SfcDcfAccountTransactionList />} />
            <Route path="/scf-dcf-product/accounting/:type/statement/:id" element={<AccountStatement />} />

            <Route path="/scf-dcf-product/dcf-product" element={<BuyerProductList />} />
            <Route path="/scf-dcf-product/dcf-product/new" element={<BuyerProductNew />} />
            <Route path="/scf-dcf-product/dcf-product/edit/:id/:index" element={<BuyerProductEdit />} />

            <Route path="/scf-dcf-product/scf-product" element={<SupplierProductList />} />
            <Route path="/scf-dcf-product/scf-product/new" element={<SupplierProductNew />} />
            <Route path="/scf-dcf-product/scf-product/edit/:id/:index" element={<SupplierProductEdit />} />

            <Route path="/scf-dcf-product/finance/:type?" element={<ScfDcfPProductFinance />} />
            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="customer" element={<Layout />}>
            <Route index element={<AncorCorporateList />} />
            <Route path="/customer/anchor" element={<AncorCorporateList />} />
            <Route path="/customer/anchor/detail/:id" element={<AncorCustomerDetail />} />

            <Route path="/customer/request" element={<RequestTab />} />

            <Route path="/customer/sme-customer" element={<SmeTabs />} />
            <Route path="/customer/sme-customer/detail/:id" element={<SmeDetail />} />

            <Route path="/customer/b2b-accounting" element={<CustomerBankAccount />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="user" element={<Layout />}>
            <Route index element={<UserList />} />
            <Route path="/user/user-list" element={<UserList />} />
            <Route path="/user/rule-list" element={<UserControlApprovelList />} />

            <Route path="/user/role-list" element={<RoleList />} />
            <Route path="/user/role-list/new" element={<RoleNew />} />
            <Route path="/user/role-list/detail/:id" element={<RoleDetail />} />

            <Route path="*" element={<NoMatchInSection />} />
          </Route>
          <Route path="/auth/verify/:id" element={<Verify />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      ) : (
        <>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot" element={<Forgot />} />
          <Route path="/auth/verify/:id" element={<Verify />} />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </>
      )}
    </Routes>
  );
}

export default App;
