import React, { useId } from "react";
import { Avatar, Button, Divider, Flex, Grid, Group, Input, Modal, Select, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconListSearch, IconSearch } from "@tabler/icons";
import noImage from "../../assets/no-image.png";
import { ColumnType, Table } from "../table";
import { ObservationApi, ReminderApi } from "../../apis";
import { dateSecFormat } from "../../utils/date";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReminder } from "../../interfaces/IReminder";
import { IReference } from "../../models/General";
import useSwr from "swr";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { IMaskInput } from "react-imask";
import { useDebouncedState } from "@mantine/hooks";
import { CollectionPaymentForm } from "./form";
import { IAuth } from "../../interfaces/IAuth";

export function CollectionPaymentReminderList() {
  const [queryStaff, setQueryStaff] = useDebouncedState("", 600);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ listType: "OTHER", programId: "", profileName: "", regNumber: "" });
  const { reminderTypes, reminderResults, reminderLevels, reminderResponses, reminderConnectTypes, repaymentOverDueStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [programQuery] = React.useState("");
  const id = useId();

  const columns = useHeader({
    reminderResponses,
    reminderResults,
    reminderTypes,
    reminderConnectTypes,
    repaymentOverDueStatus,
    reminderLevels,
    onClick: (key, item) => {
      switch (key) {
        case "condition": {
          setAction(["condition", item as any]);
          break;
        }
      }
    },
  });

  const { data: programData } = useSwr(
    `/collection/observation/program_select?${programQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.program({ query: programQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ ...filters, profileName: queryStaff });
    }, 400);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [400, queryStaff]);

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <Grid mb="sm">
        {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
          <Grid.Col span={4}>
            <Input.Wrapper label="Хөтөлбөрийн нэрээр шүүх">
              <Select
                value={filters.query}
                onChange={(e) => {
                  setFilters({ ...filters, query: e });
                }}
                clearable
                searchable
                placeholder="Сонгох"
                data={(programData || [])?.map((c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />
            </Input.Wrapper>
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <Input.Wrapper label="Харилцагчийн нэрээр хайх">
            <Input
              onChange={(e) => setQueryStaff(e.currentTarget.value)}
              placeholder="Хайлт хийх"
              rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
            <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Divider mb="sm" />
      <Table name="collection-overdue-reminder-other.list" columns={columns} filters={filters} loadData={(data) => ReminderApi.list(data!)} />
      <Modal withCloseButton={false} opened={action[0] === "condition"} onClose={() => setAction([])} centered size="60%">
        <CollectionPaymentForm action={action} onCancel={onCancel} />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IReminder) => void;
  reminderResponses: any;
  reminderResults: any;
  reminderTypes: any;
  reminderConnectTypes: any;
  repaymentOverDueStatus: any;
  reminderLevels: any;
};

const useHeader = ({
  onClick,
  reminderResponses,
  reminderResults,
  reminderTypes,
  repaymentOverDueStatus,
  reminderConnectTypes,
  reminderLevels,
}: HeaderProps): ColumnType<IReminder>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Нэхэмжлэх" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("condition", record)}>
              <IconFileText color="#7956EF" />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "50px",
  },
  {
    title: "Сануулгын огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Санхүү ажилтан",
    sorter: true,
    dataIndex: "staff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.financeStaff?.avatar !== null ? record?.financeStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.financeStaff?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.financeStaff?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },

  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.repayment?.payerBusiness?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.payerBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.repayment?.payerBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнес",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.payerBusiness?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.repayment?.payerBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "overDueStatus",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {repaymentOverDueStatus.find((c: any) => c.code === record?.repayment?.overDueStatus)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөлт нэхэмжлэх",
    sorter: true,
    dataIndex: "repayment",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.repayment?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {dateSecFormat(record?.repayment?.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Холболт төрөл",
    sorter: true,
    dataIndex: "connectType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {reminderConnectTypes.find((item2: IReference) => item2?.code === record?.connectType)?.name || "-"}
        </Text>
      );
    },
  },

  {
    title: "Сануулгын төрөл",
    sorter: true,
    dataIndex: "reminderType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderTypes.find((item2: IReference) => item2?.code === record?.reminderType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сануулгын зэрэглэл",
    sorter: true,
    dataIndex: "reminderLevel",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderLevels.find((item2: IReference) => item2?.code === record?.reminderLevel)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Холбогдсон үр дүн",
    sorter: true,
    dataIndex: "result",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderResults.find((item2: IReference) => item2?.code === record?.result)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Амлалтын хариу",
    sorter: true,
    dataIndex: "response",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderResponses.find((c: any) => c.code === record.response)?.name || "-"}
        </Text>
      );
    },
  },
];
