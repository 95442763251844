import React from "react";
import { Avatar, Box, Divider, Flex, Grid, Group, Space, Tabs, Text, createStyles } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconFileInvoice, IconShieldLock } from "@tabler/icons";
import { PaymentInformationOpenInvoiceList } from "../../../components/payment-information/open-invoice-list";
import useSwr from "swr";
import { tugrug } from "../../../utils";
import { useParams } from "react-router";
import noimage from "../../../assets/no-image.png";
import { ObservationApi } from "../../../apis";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function CollectionObservationDetailTabs() {
  const params = useParams();

  const { classes } = useStyles();
  const [activeTab, setActiveTab] = React.useState<string>("OPEN");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data } = useSwr(`/api/collection/repayment_info/business/${params.id}`, async () => {
    let res = await ObservationApi.businessGet(`${params.id}`);
    return res;
  });

  const breadcrumbs = useBreadcrumb(`${data?.partner?.refCode}, ${data?.partner?.businessName}`);

  return (
    <>
      <PageLayout
        title={`${data?.refCode}, ${data?.profileName}`}
        subTitle="Компани нэхэмжлэх төлөлтийн дэлгэрэнгүй мэдээллийн жагсаалт"
        breadcrumb={breadcrumbs}>
        <Divider mb="sm" />
        <Grid gutter="md">
          <Grid.Col span={9}>
            <Box
              h="100%"
              sx={(theme) => ({
                border: `1px solid  ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.md,
                backgroundColor: theme.white,
              })}
              px={20}
              py={16}>
              <Grid columns={24} justify="space-between" align="flex-end">
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.normal?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#6ce65c"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.normal?.percent}px`, font: "" }}>
                      <Text fw={600} c="#5f7887">{`${tugrug(data?.repayments?.normal?.totalAmount)}`}</Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.normal?.name}`}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.oneThree?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#f59d34"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.oneThree?.percent}px` }}>
                      <Text fw={600} c="#5f7887">
                        {`${tugrug(data?.repayments?.oneThree?.totalAmount)}`}
                      </Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.oneThree?.name}`}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.fourThirty?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#f05220"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.fourThirty?.percent}px` }}>
                      <Text fw={600} c="#5f7887">
                        {`${tugrug(data?.repayments?.fourThirty?.totalAmount)}`}
                      </Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.fourThirty?.name}`}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.thirtyOneSixty?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#d34b1e"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.thirtyOneSixty?.percent}px` }}>
                      <Text fw={600} c="#5f7887">
                        {`${tugrug(data?.repayments?.thirtyOneSixty?.totalAmount)}`}
                      </Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.thirtyOneSixty?.name}`}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.sixtyOneNinety?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#af411b"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.sixtyOneNinety?.percent}px` }}>
                      <Text fw={600} c="#5f7887">
                        {`${tugrug(data?.repayments?.sixtyOneNinety?.totalAmount)}`}
                      </Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.sixtyOneNinety?.name}`}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex gap="xs" justify="center" align="center" direction="column" wrap="wrap">
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.overNinety?.totalCount} Нэхэмжлэх`}</Text>
                    <Flex
                      bg="#53200c"
                      gap="xs"
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                      style={{ width: "100%", height: `${data?.repayments?.overNinety?.percent}px` }}>
                      <Text fw={600} c="#5f7887">
                        {`${tugrug(data?.repayments?.overNinety?.totalAmount)}`}
                      </Text>
                    </Flex>
                    <Text fw={600} c="#5f7887">{`${data?.repayments?.overNinety?.name}`}</Text>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          </Grid.Col>
          <Grid.Col span={3}>
            <Box
              h="100%"
              sx={(theme) => ({
                border: `1px solid  ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.md,
                backgroundColor: theme.white,
              })}
              px={20}
              py={16}>
              <Flex gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
                <Group noWrap>
                  <Avatar size={64} src={data?.financeStaff?.avatar !== null ? `${data?.financeStaff?.avatar}` : noimage} />
                  <div>
                    <Text c="dimmed" weight={500}>
                      {data?.financeStaff?.lastName !== null ? data?.financeStaff?.lastName : "-"}
                    </Text>
                    <Flex gap={5} wrap="nowrap">
                      <Text size="sm" weight={500}>
                        {data?.financeStaff?.firstName !== null ? data?.financeStaff?.firstName : "-"}
                      </Text>
                    </Flex>
                  </div>
                </Group>
                <Flex gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                  <Text fw={600} c="#5f7887">
                    {"Төлөлт хэтэрсэн"} :
                  </Text>
                  <Text fw={700} c="#373A40">
                    {data?.repayments?.totalOverDue?.totalCount}
                  </Text>
                </Flex>
                <Flex gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                  <Text fw={600} c="#5f7887">
                    {"Нийт дүн"} :
                  </Text>
                  <Text fw={700} c="#373A40">
                    {tugrug(data?.repayments?.totalOverDue?.totalAmount)}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
        <Grid gutter="md">
          <Grid.Col>
            <Box px={20} py={16} className={classes.gridBox}>
              <Tabs h="100%" value={activeTab} onTabChange={(value: any) => setActiveTab(value)}>
                <Tabs.List>
                  {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_REPAYMENT").length > 0 && (
                    <Tabs.Tab icon={<IconFileInvoice color={activeTab === "OPEN" ? "#317169" : "#44566C"} />} value="OPEN">
                      <Text size="sm" weight={500} c="#44566C">
                        Нээлттэй нэхэмжлэх
                      </Text>
                    </Tabs.Tab>
                  )}
                  <Tabs.Tab icon={<IconShieldLock color={activeTab === "CLOSED" ? "#317169" : "#44566C"} />} value="CLOSED">
                    <Text size="sm" weight={500} c="#44566C">
                      Хаагдсан нэхэмжлэх
                    </Text>
                  </Tabs.Tab>
                </Tabs.List>
                <Space h="md" />
                {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_REPAYMENT").length > 0 && (
                  <Tabs.Panel value="OPEN">
                    <PaymentInformationOpenInvoiceList />
                  </Tabs.Panel>
                )}

                <Tabs.Panel value="CLOSED">
                  <></>
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Grid.Col>
        </Grid>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    to: "/collection/observation",
    label: "Анхаарах харилцагч",
  },
  {
    label: type,
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
  systemUserBtn: {
    height: "48px",
  },

  stars: { display: "flex", alignItems: "center", color: "#868e96", fontWeight: 700, fontSize: 14 },
  adminIcon: {
    color: theme.colors.red[8],
  },
  preInfo: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    color: theme.colors.gray[6],
  },
  preInfoIcon: {
    height: 24,
    width: 24,
    padding: 0,
  },
  preInfoButton: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    cursor: "pointer",
    color: theme.colors.gray[6],
    backgroundColor: "white",
    "&:hover": {
      color: theme.colors.indigo[8],
      backgroundColor: "white",
    },
  },
}));
