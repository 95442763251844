import React from "react";
import { Anchor, Avatar, Badge, Divider, Flex, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { RoleApi } from "../../../apis";
import { IconPhoto } from "@tabler/icons";
import { dateSecFormat } from "../../../utils/date";
import { IRole } from "../../../interfaces/IRole";

export function RoleList() {
  const breadcrumbs = useBreadcrumb();
  const { roles = [], departmentUnits } = useSelector((state: { general: IGeneral }) => state.general);
  const ref = React.useRef<ITableRef>(null);

  const columns = useHeader({
    roles,
    departmentUnits,
  });

  return (
    <PageLayout title="Эрхийн тохиргоо" subTitle="Системд ашиглах эрх, түүний тохиргооны жагсаалт" breadcrumb={breadcrumbs} extra={[]}>
      <Divider mb="lg" />
      <Table ref={ref} name="user-control-role.list" columns={columns} filters={{ query: "" }} loadData={(data) => RoleApi.list(data!)} />
    </PageLayout>
  );
}

const useHeader = ({ onClick }: any): ColumnType<IRole>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Ролийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Anchor href={`/user/role-list/detail/${record.id}`}>
          <Text size="sm" weight={500} c="#44566C">
            {record.name || "-"}
          </Text>
        </Anchor>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => (
      <Badge size="md" radius="sm" variant="outline">
        {record?.type || "-"}
      </Badge>
    ),
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Flex gap={8} align="center">
        <Avatar src={record?.regUser?.avatar}>
          <IconPhoto />
        </Avatar>
        <Text size="sm" weight={500} c="#44566C">
          {record?.regUser?.lastName} {record?.regUser?.firstName}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгч тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.userCount || 0}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record?.isActive ? (
        <Badge size="md" radius="sm" variant="outline" color="green">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge size="md" radius="sm" variant="outline" color="red">
          Идэвхгүй
        </Badge>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Эрхийн тохиргооний жагсаалт ",
  },
];
