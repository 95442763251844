import { MantineSize, Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string; label: string }[];
  clearable?: boolean;
  required?: boolean;
  searchable?: boolean;
  onSearchChange?: (e: string) => void | any;
  filter?: any;
  itemComponent?: any;
  maxDropdownHeight?: any;
  size?: MantineSize;
  value?: any;
};

export function SelectItemField({
  name,
  label,
  placeholder,
  noError = false,
  onChange: onChangeValue,
  options = [],
  disabled = false,
  clearable = false,
  required = false,
  searchable = false,
  onSearchChange = () => {},
  filter = () => {},
  itemComponent = false,
  maxDropdownHeight = false,
  size,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Select
      size={size}
      searchable={searchable}
      label={label}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onSearchChange={(e) => {
        onSearchChange && onSearchChange(e);
      }}
      onChange={(value) => {
        onChange && onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      required={required}
      clearable={clearable}
      data={options}
      style={{ flex: 1 }}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
      itemComponent={itemComponent && itemComponent}
      filter={filter && filter}
      maxDropdownHeight={maxDropdownHeight && maxDropdownHeight}
    />
  );
}
