import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Text } from "@mantine/core";
import { TextField } from "../form/text-field";
import { IUserControl } from "../../interfaces/IUserControl";
import { Message } from "../../utils/message";
import { HttpHandler } from "../../utils/http-handler";
import { UserApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import useUser from "../../hooks/user";
import { IconSend } from "@tabler/icons";
import { registerNumber } from "../../utils";
import { TextareaField } from "../form/textarea-field";

const schema = yup.object({
  departmentUnit: yup.string().required("Заавал бөглөнө үү!").max(100, "Хэтэрхий урт байна!").nullable(),
  employeeUnit: yup.string().required("Заавал бөглөнө үү!").max(100, "Хэтэрхий урт байна!").nullable(),
  familyName: yup.string().required("Заавал бөглөнө үү!").max(100, "Хэтэрхий урт байна!").nullable(),
  lastName: yup.string().required("Заавал бөглөнө үү!").max(100, "Хэтэрхий урт байна!").nullable(),
  firstName: yup.string().required("Заавал бөглөнө үү!").max(100, "Хэтэрхий урт байна!").nullable(),
  phone: yup
    .string()
    .matches(/^[0-9]{8}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
  email: yup.string().typeError("Заавал бөглөнө!").email("Цахим шуудан байна!").required("Заавал бөглөнө!"),
  registerNo: yup.string().matches(registerNumber, "Регистрийн дугаар буруу байна.").required("Заавал бөглөнө үү!").nullable(),
});

type IFormData = {
  familyName: string;
  lastName: string;
  firstName: string;
  registerNo: string;
  departmentUnit: string;
  employeeUnit: string;
  phone: string;
  phone2: string;
  email: string;
  email2: string;
  socialFbLink: string;
};

export function UserControlForm({ onCancel, payload, reloadTable }: { onCancel?: () => void; action: string[]; payload?: IUserControl; reloadTable?: any }) {
  const { user, reload } = useUser();
  const [loading, setLoading] = React.useState(false);
  const [sendType, setSendType] = React.useState<boolean>(false);

  const [data] = React.useState<IFormData>({
    familyName: "",
    lastName: "",
    firstName: "",
    registerNo: "",
    departmentUnit: "",
    employeeUnit: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    socialFbLink: "",

    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      if (payload) {
        await UserApi.update(payload?.id, {
          familyName: data.familyName,
          lastName: data.lastName,
          firstName: data.firstName,
          registerNo: data.registerNo,
          departmentUnit: data.departmentUnit,
          employeeUnit: data.employeeUnit,
          phone: data.phone,
          phone2: data.phone2,
          email: data.email,
          email2: data.email2,
          socialFbLink: data.socialFbLink,
          send: sendType,
        });
        setLoading(false);
        Message.success("Амжилттай хэрэглэгчийг засварласан");
      } else {
        await UserApi.create({ ...data, send: sendType });
        Message.success("Амжилттай хэрэглэгч нэмсэн!");
        setLoading(false);
      }

      reload();
      reloadTable();
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Divider />
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ҮНДСЭН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэсэн хэрэглэгч" required>
                    <Text color="indigo" fw={600}>
                      {user?.lastName || "-"} {user?.firstName || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэсэн огноо" required>
                    <Text color="indigo" fw={600}>
                      {`${dateTimeFormat(user?.createdAt)}`}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хэрэглэгчийн нэр" required>
                    <Text color="indigo" fw={600}>
                      {"-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="familyName" label="Ажилтны ургийн овог" placeholder="Ажилтны ургийн овог" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="lastName" label="Ажилтны эцэг эхийн нэр" placeholder="Ажилтны эцэг эхийн нэр" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="firstName" label="Ажилтны өөрийн нэр" placeholder="Ажилтны өөрийн нэр" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="registerNo" label="Ажилтны регистр №" placeholder="Ажилтны регистр №" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextareaField
                    name="departmentUnit"
                    label="Харъяалах нэгжийн нэр"
                    placeholder="Харъяалах нэгжийн нэр"
                    maxLength={100}
                    minRows={2}
                    maxRows={4}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextareaField name="employeeUnit" label="Албан тушаалын нэр" placeholder="Албан тушаалын нэр" maxLength={100} minRows={2} maxRows={4} />
                </Grid.Col>

                <Grid.Col span={12} mt="lg">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="phone" label="Утасны дугаар №1" placeholder="Утасны дугаар №1" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="phone2" label="Утасны дугаар №2" placeholder="Утасны дугаар №2" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="email" label="И-мэйл хаяг №1" placeholder="И-мэйл хаяг №1" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="email2" label="И-мэйл хаяг №2" placeholder="И-мэйл хаяг №2" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="socialFbLink" label="Facebook хаяг" placeholder="Facebook хаяг" withAsterisk={false} />
                </Grid.Col>
              </Grid>
              <Divider />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => onCancel && onCancel()}>
                  Болих
                </Button>
                <Group>
                  <Flex gap="md">
                    <Button type="submit">Хадгалах</Button>
                  </Flex>
                  <Flex gap="md">
                    <Button
                      onClick={() => setSendType(true)}
                      rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />}
                      color="teal"
                      type="submit">
                      Урилга илгээх
                    </Button>
                  </Flex>
                </Group>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
