import React from "react";
import useSwr from "swr";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { SectorApi } from "../../apis";
import { SelectField } from "../form/select-field";

export function Sector({ sectorId, index, type, setFieldValue, values }: { sectorId: string; index: number; type: string; setFieldValue: any; values: any }) {
  const { data: basicData } = useSwr(`/program/reference/economical_sector/select/${type}`, async () => {
    try {
      let res = await SectorApi.select({ parentId: "", level: "1", query: "", limit: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const { data: sectorData } = useSwr(`/program/reference/economical_sector/select/${type}/${sectorId}`, async () => {
    try {
      let res = await SectorApi.select({ parentId: sectorId, level: "2", query: "", limit: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <>
      <SelectField
        name={`programSector2Ids[${index}].id`}
        label={`Сектор / ${(basicData || []).find((c: any) => c.id === sectorId)?.name}`}
        placeholder="сонгох"
        options={(sectorData || [])
          .filter((c: any) => c.parentId === sectorId)
          .map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
        onChange={(item: any) => {
          setFieldValue(
            `programSector3Ids`,
            (item || []).map((item: any, index: number) => {
              return {
                id: values?.programSector3Ids && values?.programSector3Ids?.length >= 0 ? values?.programSector3Ids[index]?.id || "" : "",
              };
            }),
          );
        }}
        clearable
      />
    </>
  );
}
