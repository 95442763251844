import React from "react";
import { LoadingOverlay, Paper, Tabs, Text, createStyles } from "@mantine/core";
import { IconFileInvoice, IconFilePencil } from "@tabler/icons";
import { PageLayout } from "../../../components/layout";
import { AccountBuyerInformation } from "../../../components/account-buyer/information";
import { AccountBuyerHistory } from "../../../components/account-buyer/history";
import useSwr from "swr";
import { useParams } from "react-router";
import { LbfAccountApi } from "../../../apis";

export function AccountBuyerDetail() {
  const params = useParams();
  const { theme } = useStyle();
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = React.useState<string | null>("ACCOUNT");

  const { data, isLoading } = useSwr(`/api/program/buyer-led/${params.id}/detail`, async () => await LbfAccountApi.get(params.id!), {
    fallbackData: null,
  });

  return (
    <PageLayout
      title="Худалдан авагчийн  зээлийн дансны мэдээлэл"
      subTitle="Таны хүсэлтийн дагуу санхүүжилт олгосон зээлийн дансны жагсаалт. Та санхүүжилтээ эргэн төлөхдөө уг дансанд төлнө үү."
      bgColor={theme.colors.gray[0]}
      extra={[]}
      breadcrumb={breadcrumbs}>
      <LoadingOverlay visible={isLoading} />
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <Tabs.List>
            <Tabs.Tab value="ACCOUNT" icon={<IconFileInvoice color={tabKey === "ACCOUNT" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Зээлийн дансны мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="HISTORY" icon={<IconFilePencil color={tabKey === "HISTORY" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Төлөлтийн түүх
              </Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="ACCOUNT">{<AccountBuyerInformation data={data} />}</Tabs.Panel>
          <Tabs.Panel value="HISTORY">{<AccountBuyerHistory data={data?.payments} />}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: "Олгосон зээлийн данс",
    to: "/cf/account/BUYER",
  },
  { label: "Дэлгэрэнгүй" },
];
