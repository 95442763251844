import { Global, MantineTheme, MantineThemeOverride } from "@mantine/core";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          border: "0 solid #e5e7eb",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};
// theme.fn.linearGradient(270, "#001754", "#004c8b"),
export const themeConfig: MantineThemeOverride = {
  colors: {
    golomt: ["#eef6fc", "#bde0ff", "#8bc1fb", "#73aefa", "#1f70db", "#317169", "#317169", "#05357e", "#0a2c5f", "#031a3d"],
  },
  primaryColor: "golomt",
  components: {
    // Button: {
    //   // Subscribe to theme and component params
    //   styles: (theme: MantineTheme, params: ButtonStylesParams) => ({
    //     root: {
    //       height: 42,
    //       padding: "0 30px",
    //       backgroundColor: params.variant === "filled" ? theme.colors[params.color || theme.primaryColor][9] : undefined,
    //     },
    //   }),
    // },

    // Badge: {
    //   // Use raw styles object if you do not need theme dependency
    //   styles: {
    //     root: { borderWidth: 2 },
    //   },
    // },
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 15,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};
