import { Avatar, Badge, Flex, Group, Space, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IHistory } from "../../interfaces/IHistory";
import { IReference } from "../../interfaces/IReference";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import noimage from "../../assets/no-image.png";

type Props = {
  action: any;
  onCancel: () => void;
};

export function ScfFinanceRepaymnetHistoryList({ action }: Props) {
  const { paymentMethod, repaymentHistoryPaymentTypes, repaymentStatus, repaymentOverDueStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    paymentMethod,
    repaymentOverDueStatus,
    repaymentStatus,
    repaymentHistoryPaymentTypes,
    onClick: (key, item) => {},
  });

  return (
    <>
      <Space mt="xs" />
      <Table name="user.control.list" columns={columns} filters={{}} dataSource={action?.histories} />
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IHistory) => void;
  paymentMethod: any;
  repaymentOverDueStatus: IReference[];
  repaymentStatus: any;
  repaymentHistoryPaymentTypes: any;
};

const useHeader = ({ onClick, paymentMethod, repaymentOverDueStatus, repaymentStatus, repaymentHistoryPaymentTypes }: HeaderProps): ColumnType<IHistory>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.repaymentRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний лавлах",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.trxRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх эхний үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.firstAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээ төлбөрийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.trxAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх эцсийн үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.lastAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.creditAcc?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.creditAcc?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авсан дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.debitAcc?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авсан дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.debitAcc?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {paymentMethod.find((item: IReference) => item.code === record?.paymentMethod)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн төрөл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200 }}>
          {repaymentHistoryPaymentTypes.find((item: IReference) => item.code === record?.paymentType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {repaymentOverDueStatus.find((c: any) => c.code === record?.repaymentOverDueStatus)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color} 1px solid`,
            color: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color}`,
          }}>
          {(repaymentStatus || []).find((c: any) => c.code === record.repaymentStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөр төлсөн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.actionUser?.avatar !== null ? record?.actionUser?.avatar : noimage} />
          <div>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.actionUser?.lastName} {record?.actionUser?.firstName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];
