import { createStyles, Header as BaseHeader, Avatar, UnstyledButton, Group, Text, Menu, Modal, Box, Flex, Button, ActionIcon } from "@mantine/core";
import { IconLogout, IconChevronDown, IconKey, IconLogin, IconQuestionMark } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { authChange, authMe } from "../../store/Auth";
import { Message } from "../../utils/message";
import { AuthApi } from "../../apis";
import { ChangePin } from "../settings/change-pin";
import { Changepassword } from "../settings/change-password";
import { ReactComponent as Logo } from "../../assets/bogd-logo.svg";
import { ReactComponent as LogoWhite } from "../../assets/bogd-logo-teal.svg";
import { CommingSoon } from "../layout/comming-soon";
import noimage from "../../assets/no-image.png";
import HttpHandler from "../../utils/http-handler";

export const Header = () => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState<any>([]);

  const { user, sessionScope } = useSelector((state: any) => state.auth);

  const LogOut = async () => {
    try {
      await AuthApi.logout();

      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/auth/login");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <>
      <BaseHeader className={sessionScope !== "AUTHORIZED" ? classes.headerWhite : classes.header} height={{ base: 62, md: 68 }}>
        <div className={classes.row}>
          {sessionScope !== "AUTHORIZED" ? (
            <>
              <div className={classes.logo}><Logo /></div>
              <Flex gap="md" justify="flex-end" align="center" direction="row" wrap="wrap" mr={40}>
                <CommingSoon position="bottom" visible={true}>
                  <ActionIcon radius="xl" variant="outline" color="#317169">
                    <IconQuestionMark />
                  </ActionIcon>
                </CommingSoon>
                <CommingSoon position="bottom" visible={true}>
                  <Button variant="outline" color="#317169">
                    Заавар үзэх
                  </Button>
                </CommingSoon>
              </Flex>
            </>
          ) : (
            <>
              <Link to="/">
                <div className={classes.logo}>
                  <LogoWhite />
                </div>
              </Link>
              <Menu width={260} position="bottom-end" transition="pop-top-right">
                <Menu.Target>
                  <UnstyledButton className={classes.user}>
                    <Group>
                      <Avatar src={user?.avatar || noimage} radius="xl" />
                      <div style={{ flex: 1 }}>
                        <Group>
                          <Text size="sm" weight={500} c="#f3f5f7">
                            {user?.lastName !== null ? user?.lastName : ""} {user?.firstName !== null ? user?.firstName : "-"}
                          </Text>
                        </Group>
                        <Text size="sm" weight={500} c="#c3cad4">
                          {user?.email || "-"}
                        </Text>
                      </div>
                      <IconChevronDown color="white" size={14} stroke={1.5} />
                    </Group>
                  </UnstyledButton>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item onClick={() => setAction([true, "pin"])} icon={<IconKey size={20} color={theme.colors.yellow[6]} stroke={1.5} />}>
                    Пин код
                  </Menu.Item>
                  <Menu.Item onClick={() => setAction([true, "password"])} icon={<IconLogin size={20} color={"#317169"} stroke={1.5} />}>
                    Нууц үг
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item color="red" onClick={() => LogOut()} icon={<IconLogout size={20} stroke={1.5} />}>
                    Системээс гарах
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </>
          )}
        </div>
      </BaseHeader>
      <Modal opened={action[0]} onClose={() => setAction([])} centered withCloseButton={false}>
        <Box>
          {action[1] === "pin" && <ChangePin onClose={() => setAction([])} />}
          {action[1] === "password" && <Changepassword onClose={() => setAction([])} />}
        </Box>
      </Modal>
    </>
  );
};

export default Header;

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: "#317169",
    border: 0,
  },
  headerWhite: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: "white",
    border: 0,
  },
  logo: {
    display: "flex",
    height: "50px",
    justifyContent: "end",
    alignItems: "end",
    marginBottom: -2,
    paddingLeft: "40px",
  },
  subTitle: {
    display: "flex",
    fontSize: 20,
    fontWeight: 600,
    color: "red",
    marginBottom: "-5px",
  },
  row: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    zIndex: 10,
  },
  user: {
    padding: theme.spacing.xs,
    color: theme.black,
    "&:hover": {
      backgroundColor: "#e3181810",
    },
    borderColor: "#e60c0c",
  },
  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));
