import { PageLayout } from "../../../components/layout";
import { RoleForm } from "../../../components/role/form";

export function RoleNew() {
  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title="Шинээр роль үүсгэх" subTitle="Хэрэглэгчийн роль шинээр үүсгэх" breadcrumb={breadcrumbs} extra={[]}>
      <RoleForm action={[]} />
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Эрхийн тохиргоо",
    to: "/user/role-list",
  },
  { label: "Шинэ" },
];
