import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Paper, Text } from "@mantine/core";
import { OtpField } from "../form/otp-field";
import HttpHandler from "../../utils/http-handler";
import { AuthApi, FundingIntroductionApi } from "../../apis";
import { Message } from "../../utils/message";

const schema = yup.object().shape({
  pin: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
};

export function FundingIntroductionPin({
  action,
  onCancel,
  title,
  subTitle,
  reload,
}: {
  action: any[];
  onCancel: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    pin: "",
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin(data);

      let res = await FundingIntroductionApi.respond(action[2], {
        approve: action[1].approve,
      });
      reload();
      setLoading(false);

      if (action[1].approve) {
        Message.success("Таны үйлдэл амжилттай.");
      } else {
        Message.success("Амжилттай цуцлагдаа.");
      }
      onCancel && onCancel([]);

      if (res?.url) {
        window.open(`${res?.url}`);
      }
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <>
            <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel([])} />]}>
              <LoadingOverlay visible={loading} />
              <Paper>
                <Divider mb="sm" />
                <Grid>
                  <Grid.Col span={12}>
                    <Alert
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      radius="md"
                      color="indigo"
                      withCloseButton={false}
                      title="Таны анхааралд">
                      Та {`${action[3]?.scfRequestRef}`} санхүүжилтийн хүсэлтийг {action[1]?.approve ? "зөвшөөрөх" : "татгалзах"} гэж байна.
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <OtpField name="pin" label="Үйлдлийг баталгаажуулах бол ПИН кодоо оруулна уу." placeholder="" required />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text component="p" fz="sm" fw={500}>
                      Танд амжилт хүсье!
                    </Text>
                  </Grid.Col>
                </Grid>
                <Divider mb="sm" />
                <Flex direction="row" justify="space-between">
                  <Button variant="default" onClick={() => onCancel && onCancel([])}>
                    Болих
                  </Button>
                  <Flex gap="md">
                    <Button variant="outline" type="submit">
                      Хадгалах
                    </Button>
                  </Flex>
                </Flex>
              </Paper>
            </FormLayout>
          </>
        );
      }}
    </Form>
  );
}
