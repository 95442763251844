import React from "react";
import { ActionIcon, LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconArticle, IconClipboardText, IconCreditCard, IconFileInvoice, IconX } from "@tabler/icons";
import { ScfFinanceInvoiceForm } from "../../../components/scf-funding-request/invoice-form";
import { ScfFinanceRequestForm } from "../../../components/scf-funding-request/request-form";
import useSwr from "swr";
import { RequestApi } from "../../../apis";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { ScfFinanceDecisionForm } from "../../../components/scf-funding-request/decision-form";
import { LimitForm } from "../../../components/scf-funding-request/limit-form";
import { ScfFinanceDecisionDetail } from "../../../components/scf-funding-request/decision-detail";
import { FinanceRequestPinForm } from "../../../components/scf-funding-request/pin";

export const ScfFinanceRequestDetail = ({
  action,
  setAction,
  onCancel,
  reload,
}: {
  action: any;
  setAction: (e: any) => void;
  onCancel: () => void;
  reload: () => void;
}) => {
  const [tabIndex, setTabIndex] = React.useState<string>("1");
  const [pinCheck, setPinCheck] = React.useState<string[]>([]);
  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  const { data, isLoading } = useSwr(`/api/program/buyer-led/${action[1]}`, async () => {
    try {
      if (!action[1]) {
        return [];
      }
      let res = await RequestApi.get(action[1] as string);
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onSubmit = async (values: any) => {
    setPinCheck(["PIN", values, action[1]?.id]);
  };

  return (
    <>
      <PageLayout
        title={`Санхүүжих хүсэлт ${data?.refCode}`}
        extra={[
          <ActionIcon onClick={() => onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <LoadingOverlay visible={isLoading} />
          {data && (
            <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
              <Tabs.List>
                <Tabs.Tab icon={<IconFileInvoice color={tabIndex === "1" ? "#317169" : "#44566C"} />} value="1">
                  <Text size="sm" weight={500} c="#44566C">
                    Нэхэмжлэх
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab icon={<IconCreditCard color={tabIndex === "2" ? "#317169" : "#44566C"} />} value="2">
                  <Text size="sm" weight={500} c="#44566C">
                    Хүсэлт
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab icon={<IconClipboardText color={tabIndex === "3" ? "#317169" : "#44566C"} />} value="3">
                  <Text size="sm" weight={500} c="#44566C">
                    Лимит ашиглалт
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab icon={<IconArticle color={tabIndex === "4" ? "#317169" : "#44566C"} />} value="4">
                  <Text size="sm" weight={500} c="#44566C">
                    Шийдвэр
                  </Text>
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="1">{tabIndex === "1" && <ScfFinanceInvoiceForm onCancel={onCancel} action={data} setTabIndex={setTabIndex} />}</Tabs.Panel>
              <Tabs.Panel value="2">{tabIndex === "2" && <ScfFinanceRequestForm action={data} onCancel={onCancel} setTabIndex={setTabIndex} />}</Tabs.Panel>
              <Tabs.Panel value="3">
                {tabIndex === "3" && <LimitForm onCancel={onCancel} action={data} setTabIndex={setTabIndex} setAction={setAction} />}
              </Tabs.Panel>

              <Tabs.Panel value="4">
                {tabIndex === "4" &&
                  (data?.requestStatus === "REQUESTED" ? (
                    <ScfFinanceDecisionForm action={data} onCancel={onCancel} setTabIndex={setTabIndex} onSubmit={onSubmit} type={action && action[2]} />
                  ) : (
                    <ScfFinanceDecisionDetail action={data} onCancel={onCancel} setTabIndex={setTabIndex} setAction={setAction} />
                  ))}
              </Tabs.Panel>
            </Tabs>
          )}
        </Paper>
      </PageLayout>
      <Modal opened={pinCheck[0] === "PIN"} onClose={() => setPinCheck([])} withCloseButton={false}>
        <FinanceRequestPinForm
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={data}
          pinCheck={pinCheck}
          reload={reload}
          setPinCheck={setPinCheck}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
};
