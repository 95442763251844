import React from "react";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { percent } from "../../utils";
import { Form } from "../form";
import { Button, Divider, Flex, Grid, Switch, Text } from "@mantine/core";
import { TextField } from "../form/text-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberPercentField } from "../form/number-percent-field";
import { IconSend } from "@tabler/icons";
import { RadioGroupField } from "../form/radio-group-field";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";

export type ISupplierLedFinanceData = {
  recourseTerm: boolean;
  recourseDays: number;
  suppTerminatePeriod: number;
  productOverDueLimit: boolean;
  overDueLimitType: string;
  overDueLimitValue: number;
  minInvAmount: number;
  minOfInv: number;
  maxOfInv: number;
  minTenor: number;
  maxTenor: number;
  minDays: number;
  maxDays: number;
  borrowerType: string;

  buyerTerm: string;
};

const schema = yup.object().shape({
  recourseDays: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppTerminatePeriod: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minInvAmount: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  maxOfInv: yup.string().matches(percent, "Та дахин нэг шалгана уу!").typeError("Тоо оруулна уу.").required("Заавал бөглөнө!"),
  minOfInv: yup
    .number()
    .typeError("Тоо оруулна уу.")
    .when("maxOfInv", (maxOfInv, field) =>
      maxOfInv > 0 ? field.lessThan(maxOfInv, "Нэхэмжлэхийн Мах хувиас бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxTenor: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minTenor: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxTenor", (maxTenor, field) =>
      maxTenor > 0 ? field.lessThan(maxTenor, "Нэхэмжлэхийн Max тенороос бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxDays: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minDays: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxDays", (maxDays, field) =>
      maxDays > 0 ? field.lessThan(maxDays, "Та нэхэмжлэхийн Мax хугацаанаас бага утга оруулна уу!").required("Заавал бөглөнө!") : field,
    ),

  borrowerType: yup.string().required("Заавал бөглөнө!").nullable(),

  buyerTerm: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
});

export function ScfProductFinanceForm({ onSubmit: _onSubmit, action }: { onSubmit: (values: ISupplierLedFinanceData, type: boolean) => void; action: any }) {
  const navigate = useNavigate();
  const [type, setType] = React.useState(false);
  const { borrowerTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<ISupplierLedFinanceData>({
    recourseTerm: false,
    recourseDays: "",
    suppTerminatePeriod: "",
    productOverDueLimit: false,
    overDueLimitType: "",
    overDueLimitValue: "",
    minInvAmount: "",
    minOfInv: "",
    maxOfInv: "",
    minTenor: "",
    maxTenor: "",
    minDays: "",
    maxDays: "",
    borrowerType: "",

    buyerTerm: "",
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          maxTenor: action[1].maxTenor ? parseInt(action[1].maxTenor, 10) : "",
          suppTerminatePeriod: action[1].suppTerminatePeriod ? parseInt(action[1].suppTerminatePeriod, 10) : "",
          minTenor: action[1].minTenor ? parseInt(action[1].minTenor, 10) : "",
          maxDays: action[1].maxDays ? parseInt(action[1].maxDays, 10) : "",
          minDays: action[1].minDays ? parseInt(action[1].minDays, 10) : "",
          buyerTerm: action[1].minDays ? parseInt(action[1].buyerTerm, 10) : "",
        }
      : {}),
  });

  const onSubmit = (values: any) => {
    _onSubmit(values, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИХ ЭРХИЙН ТОХИРГОО" />
              </Grid.Col>
              <Grid.Col span={4}>
                <RadioGroupField
                  label="Зээлдэгч тал"
                  name="borrowerType"
                  options={(borrowerTypes || []).map((c: any) => ({
                    label: c.slName,
                    value: c.code,
                  }))}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              {/* borrowerType : "BUYER" */}
              <Grid.Col span={4}>
                <Flex direction="column" gap={3} mt={4}>
                  <Text fz={14} fw={500} c="#212529">
                    Буцаан дуудах эсэх:
                  </Text>
                  <Switch size="lg" disabled onLabel="Тийм" checked={true} />
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="recourseDays"
                  label="Буцаан дуудах хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="suppTerminatePeriod"
                  label="Санхүүжилт зогсоох хугацаа"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {values?.borrowerType === "BUYER" && (
                  <TextField
                    name="buyerTerm"
                    label="Худалдан авагчид өгөх хоног:"
                    placeholder="Хоногийг оруулна уу"
                    rightSection={
                      <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                        {"Хоног"}
                      </Text>
                    }
                  />
                )}
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={12} mt="lg">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="САНХҮҮЖИХ НЭХЭМЖЛЭХИЙН ХАНГАХ НӨХЦӨЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  name="minInvAmount"
                  label="Нэхэмжлэхийн доод үлдэгдэл:"
                  placeholder="Үнийн дүнг оруулна уу"
                  precision={2}
                  min={0}
                  max={999999999999}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="maxOfInv" label="Нэхэмжлэхийн Мах хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="minOfInv" label="Нэхэмжлэхийн Мin хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="maxTenor"
                  label="Нэхэмжлэхийн Мах тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="minTenor"
                  label="Нэхэмжлэхийн Мin тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="maxDays"
                  label="Нэхэмжлэхийн Мax хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="minDays"
                  label="Нэхэмжлэхийн Мin хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/scf-dcf-product/scf-product")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" onClick={() => setType(false)}>
                  Хадгалах
                </Button>
                <Button rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />} type="submit" onClick={() => setType(true)}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
