import React, { useRef } from "react";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { IconCashBanknote, IconFileText, IconHistory } from "@tabler/icons";
import { ProductSupplierLedInformationForm } from "../../../components/product-supplier/information-form";
import { ProductSupplierLedFinanceForm } from "../../../components/product-supplier/finance-form";
import { LbfProductApi } from "../../../apis";
import { useNavigate, useParams } from "react-router";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { LbfProductInformationData } from "../../../components/product-buyer/information-form";
import useSwr from "swr";
import { ITableRef } from "../../../components/table";
import { ProductSupplierLedHistoryList } from "../../../components/product-supplier/history-list";
import { ProductBuyerLedPinForm } from "../../../components/product-buyer/pin-form";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function ProductSupplierLedEdit() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const params = useParams();
  const ref = useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState((params.type as string) || "INFORMATION");
  const [action, setAction] = React.useState<string[]>([]);

  const { data, mutate, isLoading } = useSwr(`/api/lbf/supplier-led/product/${params.id}`, async () => await LbfProductApi.get(`${params.id}`), {
    fallback: { data: null },
  });

  const onSubmit = async (value: LbfProductInformationData, type: boolean) => {
    setLoading(true);
    try {
      await LbfProductApi.update(params.id as string, {
        name: value.name,
        resolutionNo: value.resolutionNo,
        description: value.description,
        minInvAmount: value.minInvAmount,
        minOfInv: value.minOfInv,
        maxOfInv: value.maxOfInv,
        minTenor: value.minTenor,
        maxTenor: value.maxTenor,
        financeMinLimit: value.financeMinLimit,
        minDays: value.minDays,
        maxDays: value.maxDays,
        bankProductTypeId: value.bankProductTypeId,
      });

      await mutate();
      if (type) {
        setTabKey("FINANCE");
        navigate(`/cf/product/SUPPLIER/edit/${params.id}/FINANCE`);
        Message.success("Бүтээгдэхүүнийг амжилттай засварласан");
      } else {
        navigate("/cf/product/SUPPLIER");
        Message.success("Бүтээгдэхүүнийг амжилттай засварлан хадгаллаа.");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onFinanceSubmit = async (value: any, type: string) => {
    if (type === "REVIEWED") {
      setAction(["update", value]);
    } else {
      await LbfProductApi.both(params.id as string, {
        borrowerType: value.borrowerType,
        suppInterestPercent: value.suppInterestPercent,
        suppInterestType: value.suppInterestType,
        suppInterestMinAmount: value.suppInterestMinAmount,
        suppDisbursementFee: value.suppDisbursementFee,
        suppAppFee: value.suppAppFee,
        recourseTerm: value.recourseTerm,
        recourseDays: value.recourseDays,
        suppTerminatePeriod: value.suppTerminatePeriod,
        toReview: false,

        buyerInterestPercent: value.buyerInterestPercent,
        buyerInterestType: value.buyerInterestType,
        buyerInterestMinAmount: value.buyerInterestMinAmount,
        buyerDisbursementFee: value.buyerDisbursementFee,
        buyerAppFee: value.buyerAppFee,
        buyerTermDays: value.buyerTermDays,
      });
      navigate("/cf/product/SUPPLIER");
      Message.success("Амжилттай хадгаллаа!");
    }
    await mutate();
  };

  const onTabChange = (_index: string) => {
    navigate(`/cf/product/SUPPLIER/edit/${params.id}/${_index}`);
    setTabKey(_index);
  };

  const reload = () => {
    ref.current?.reload();
  };

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout title="Supplier-LED SCF бүтээгдэхүүн" subTitle="Supplier-LED SCF бүтээгдэхүүнийг засварлах" breadcrumb={breadcrumbs} extra={[]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs value={tabKey} onTabChange={onTabChange}>
            <LoadingOverlay visible={loading || isLoading} />
            <Tabs.List>
              <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabKey === "INFORMATION" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
                <Tabs.Tab value="FINANCE" icon={<IconCashBanknote color={tabKey === "FINANCE" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Хүү, нөхцөл тохиргоо
                  </Text>
                </Tabs.Tab>
              )}

              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Системийн түүх
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="INFORMATION">{data && <ProductSupplierLedInformationForm onSubmit={onSubmit} action={["update", data]} />}</Tabs.Panel>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
              <Tabs.Panel value="FINANCE">{data && <ProductSupplierLedFinanceForm onSubmit={onFinanceSubmit} action={["update", data]} />}</Tabs.Panel>
            )}

            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PRODUCT_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Panel value="HISTORY">{data && <ProductSupplierLedHistoryList id={data?.id} />}</Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={action && action[0] === "update"} onClose={() => onCancel()} withCloseButton={false} centered>
        <ProductBuyerLedPinForm
          onCancel={onCancel}
          title="Хяналтад илгээх"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          reload={reload}
          type="Supplier-LED"
        />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: "Supplier-LED бүтээгдэхүүн",
    to: "/cf/product/SUPPLIER",
  },
  { label: "Засварлах" },
];
