import React from "react";
import { PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { BankProductApi } from "../../../apis";
import { Button, Flex, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IBankProductType } from "../../../interfaces/IBankProductType";
import { dateFormat } from "../../../utils/date";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons";
import { ReferenceForm } from "../../../components/reference/form";
import { Dialog } from "../../../utils/confirm-modal";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function Reference() {
  const breadcrumbs = useBreadcrumb();
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({
    user,
    onClick: (key, record) => {
      switch (key) {
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
        case "remove": {
          Dialog.confirm("Та банк бүтээгдэхүүнийг устгах гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await BankProductApi.remove(record.id);
                  Message.success("Амжилттай банк бүтээгдэхүүнийг устгасан.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Банк бүтээгдэхүүний жагсаалт"
        subTitle="Банкны бүтээгдэхүүний төрөл"
        breadcrumb={breadcrumbs}
        extra={[
          <Button
            size="sm"
            key={1}
            onClick={() => {
              setAction(["create"]);
            }}
            variant="outline"
            leftIcon={<IconCirclePlus />}>
            Төрөл
          </Button>,
        ]}>
        <Table
          ref={ref}
          name="lbf-reference-bank-product-type.list"
          columns={columns}
          filters={{ query: "" }}
          loadData={(data) => BankProductApi.list(data!)}
        />
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered size="lg">
        <ReferenceForm onCancel={onCancel} action={action} reload={reload} />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick, user }: { onClick: (key: string, record: IBankProductType) => void; user: any }): ColumnType<IBankProductType>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Засварлах" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.code || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үүссэн огноо",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateFormat(record?.createdAt)}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Зээл суурьтай санхүүжилт",
  },
  {
    label: "Банк бүтээгдэхүүний төрөл",
  },
];
