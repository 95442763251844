import React from "react";
import { Badge, Box, Divider, Flex, Grid, Modal, Tabs, Text } from "@mantine/core";
import { IconId, IconMail, IconPhone, IconUser, IconNotes } from "@tabler/icons";
import { PageLayout } from "../../components/layout";
import useUser from "../../hooks/user";
import { MeForm } from "../../components/profile/me-form";
import { ChangePinForm } from "../../components/profile/pin-change-form";
import { ChangePasswordForm } from "../../components/profile/password-change-form";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { AuthApi } from "../../apis";
import { Message } from "../../utils/message";
import HttpHandler from "../../utils/http-handler";
import { ImageUploadMe } from "../../components/upload/image-upload-me";

export function ProFile() {
  const breadcrumbs = useBreadcrumb();
  const { user, reload } = useUser();
  const [activeTab, setActiveTab] = React.useState<any>("PROFILE");
  const [passwordModal, setPasswordModal] = React.useState<any>([]);
  const { userStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const booleanStatus = (val: any) => {
    switch (val) {
      case true: {
        return (
          <Badge color="green" size="md" radius="sm" variant="outline">
            Тийм
          </Badge>
        );
      }
      case false: {
        return (
          <Badge color="red" size="md" radius="sm" variant="outline">
            Үгүй
          </Badge>
        );
      }
      default: {
        return (
          <Badge color="gray" size="md" radius="sm" variant="outline">
            {"Хоосон"}
          </Badge>
        );
      }
    }
  };

  const changeAvatar = async (e: any) => {
    try {
      await AuthApi.avatar({ avatar: e?.url });
      Message.success("Хэрэглэгчийн зураг амжилттай солигдлоо");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <PageLayout title="Миний мэдээлэл" subTitle="Миний мэдээлэл" breadcrumb={breadcrumbs} extra={[]}>
      <Grid gutter="md">
        <Grid.Col span={3}>
          <Box
            h="100%"
            sx={(theme) => ({
              border: `1px solid  ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.md,
              backgroundColor: theme.white,
            })}
            px={20}
            py={16}>
            <Flex align="center" direction="column" gap="md" justify="center">
              <Box sx={{ position: "relative" }}>
                <ImageUploadMe value={user?.avatar} onChange={(e) => changeAvatar(e)} />
              </Box>
              <Text fw={600} fz="xl" c="indigo">
                {`${user?.lastName} ${user?.firstName}` || "---"}
              </Text>
              <Text fw={700} fz="sm" c="gray">
                {user?.role?.name || "-"}
              </Text>

              {/* <Button fullWidth color="indigo" size="md" variant="light" leftIcon={<IconSettings size={20} />}>
                Тохиргоо хийх
              </Button> */}

              <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Холбоо барих" />
              <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                <Flex gap="sm">
                  <IconMail color="#317169" />
                  <Text fw={500} c="gray">
                    {user?.email || "-"}
                  </Text>
                </Flex>
                <Flex gap="sm" justify="start" align="start" w="100%">
                  <IconPhone color="#317169" />
                  <Text fw={500} c="gray">
                    {user?.phone || "-"}
                  </Text>
                </Flex>
                <Flex gap="sm" justify="start" align="start" w="100%">
                  <IconUser color="#317169" />
                  <Text fw={500} c="gray">
                    {user?.departmentUnit?.name || "-"}
                  </Text>
                </Flex>
                <Flex gap="sm" justify="start" align="start" w="100%">
                  <IconId color="#317169" />
                  <Text fw={500} c="gray">
                    {user?.employeeUnit?.name || "-"}
                  </Text>
                </Flex>
              </Flex>
              <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Статус төлөв" />
              <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                <Text fw={500} c="gray">
                  Хэрэглэгчийн статус:
                </Text>
                <Badge
                  size="md"
                  radius="sm"
                  variant="outline"
                  style={{
                    border: `${(userStatus || []).find((c: any) => c?.code === user?.userStatus)?.color} 1px solid`,
                    color: `${(userStatus || []).find((c: any) => c?.code === user?.userStatus)?.color}`,
                  }}>
                  {(userStatus || []).find((c: any) => c?.code === user?.userStatus)?.name}
                </Badge>
                <Text fw={500} c="gray">
                  И-мэйл баталгаажилт:
                </Text>
                {booleanStatus(user?.isEmailVerified)}
                <Text fw={500} c="gray">
                  Утас баталгаажилт:
                </Text>
                {booleanStatus(user?.isPhoneVerified)}
                <Text fw={500} c="gray">
                  ДАН систем баталгаажилт:
                </Text>
                {booleanStatus(user?.isDanVerified)}
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={9}>
          <Box
            h="100%"
            sx={(theme) => ({
              border: `1px solid  ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.md,
              backgroundColor: theme.white,
            })}
            px={20}
            py={16}>
            <Tabs value={activeTab} onTabChange={(value: string) => setActiveTab(value)} h="100%">
              <Tabs.List>
                <Tabs.Tab icon={<IconNotes color={activeTab === "PROFILE" ? "#317169" : "#44566C"} />} value="PROFILE">
                  <Text size="sm" weight={500} c="#44566C">
                    Үндсэн мэдээлэл
                  </Text>
                </Tabs.Tab>
                {/* <Tabs.Tab disabled icon={<IconReport color={activeTab === "EXP_HISTORY" ? "#317169" : "#44566C"} />} value="EXP_HISTORY">
                  <Tooltip color="blue" position="bottom" label="Тун удахгүй...">
                    <Text size="sm" weight={500} c="#44566C">
                      Эрхийн түүх
                    </Text>
                  </Tooltip>
                </Tabs.Tab>
                <Tabs.Tab disabled icon={<IconDevices color={activeTab === "SYSTEM_HISTORY" ? "#317169" : "#44566C"} />} value="SYSTEM_HISTORY">
                  <Tooltip color="blue" position="bottom" label="Тун удахгүй...">
                    <Text size="sm" weight={500} c="#44566C">
                      Системийн түүх
                    </Text>
                  </Tooltip>
                </Tabs.Tab> */}
              </Tabs.List>
              <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              <Tabs.Panel value="PROFILE">
                <MeForm changePassword={setPasswordModal} />
              </Tabs.Panel>
              <Tabs.Panel value="SYSTEM_HISTORY">
                <></>
              </Tabs.Panel>
              <Tabs.Panel value="EXP_HISTORY">
                <></>
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Grid.Col>
        <Modal centered withCloseButton={false} opened={passwordModal[0]} onClose={() => setPasswordModal([])}>
          <PageLayout title={passwordModal[1] === "PIN" ? "Пин код өөрчлөх" : "Нууц үг өөрчлөх"} extra={[]}>
            {passwordModal[1] === "PIN" ? (
              <ChangePinForm isEdit={user?.hasPin as boolean} onClose={() => setPasswordModal([])} reload={reload} />
            ) : (
              <ChangePasswordForm onClose={() => setPasswordModal([])} />
            )}
          </PageLayout>
        </Modal>
      </Grid>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Миний мэдээлэл",
  },
];
