import { Badge, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { IAccount } from "../../interfaces/IAccount";
import { IGeneral } from "../../interfaces/IGeneral";
import { ColumnType, Table } from "../table";

type Props = {
  data: any;
};

export function AncorDetailAccountList({ data }: Props) {
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    currencies,
    onClick: (key, item) => {},
  });

  return <Table name="ancor.detail.buyers.account.list" columns={columns} filters={{}} dataSource={data} pagination={false} />;
}

const useHeader = ({ onClick, currencies }: { onClick: (key: string, record: IAccount) => void; currencies: any }): ColumnType<IAccount>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Товч нэр",
    sorter: true,
    dataIndex: "status",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.shortName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Банк",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.bankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "programCount",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {"Тайлбар "}
        </Text>
      );
    },
  },
];
