import React from "react";
import { Button, LoadingOverlay, Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfProgramUsageBuyerList } from "../../../components/scf-program-usage/buyer-list";
import { ScfProgramUsageSupplierOnlyList } from "../../../components/scf-program-usage/supplier-only-list";
import { useNavigate, useParams } from "react-router";
import { IconDownload, IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { LimitUsageApi } from "../../../apis";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export const ScfProgramUsage = () => {
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "BUYER");
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onChange = (value: string) => {
    navigate(`/scf-dcf/usage/${value}`);
    setTabKey(value);
  };

  const onExport = async () => {
    setLoading(true);
    switch (tabKey) {
      case "BUYER": {
        let res = await LimitUsageApi.list({
          filter: {
            query: "",
            productType: "BUYER_LED",
            borrowerType: "",
            anchorPartnerId: "",
            anchorBusinessId: "",
            productId: "",
            excel: `${true}`,
          },
          offset: { limit: 1, page: 20 },
        });
        window.open(res.excelUrl as string, "_blank");
        break;
      }
      case "SUPPLIER": {
        let res = await LimitUsageApi.list({
          filter: {
            query: "",
            productType: "SUPPLIER_LED",
            borrowerType: "SUPPLIER_ONLY",
            anchorPartnerId: "",
            anchorBusinessId: "",
            productId: "",
            excel: `${true}`,
          },
          offset: { limit: 1, page: 20 },
        });
        window.open(res.excelUrl as string, "_blank");
        break;
      }
      case "BUYER-SUPPLIER": {
        let res = await LimitUsageApi.list({
          filter: {
            query: "",
            productType: "SUPPLIER_LED",
            borrowerType: "BUYER",
            anchorPartnerId: "",
            anchorBusinessId: "",
            productId: "",
            excel: `${true}`,
          },
          offset: { limit: 1, page: 20 },
        });
        window.open(res.excelUrl as string, "_blank");
        break;
      }
      default:
    }
    setLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Хөтөлбөрийн ашиглалт"
      subTitle="SCF/DCF хөтөлбөрийн ашиглалтын жагсаалт"
      breadcrumb={breadcrumbs}
      bgColor="#F8F9FA"
      extra={[
        <Button size="xs" key={0} onClick={() => onExport()} leftIcon={<IconDownload />} variant="outline">
          EXCEL
        </Button>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_LIMIT_USAGE_BL").length > 0 && (
              <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={tabKey === "BUYER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Buyer LED хөтөлбөр
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_LIMIT_USAGE_SL").length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Supplier LED supplier
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="BUYER-SUPPLIER" icon={<IconPackage color={tabKey === "BUYER-SUPPLIER" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Supplier LED buyer лимит
              </Text>
            </Tabs.Tab> */}
          </Tabs.List>
          <Space h={10} />

          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_LIMIT_USAGE_BL").length > 0 && (
            <Tabs.Panel value="BUYER">
              <ScfProgramUsageBuyerList />
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_LIMIT_USAGE_SL").length > 0 && (
            <Tabs.Panel value="SUPPLIER">
              <ScfProgramUsageSupplierOnlyList />
            </Tabs.Panel>
          )}
          {/* <Tabs.Panel value="BUYER-SUPPLIER">
            <ScfProgramUsageSupplierBothList />
          </Tabs.Panel> */}
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн хөтөлбөр" },
  {
    label: "Хөтөлбөрийн ашиглалт",
  },
];
