import React from "react";
import { Button, LoadingOverlay, Paper, Space, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { ScfDcfSupplierLimit } from "../../../components/sme-limit-supplier/list";
import { ScfDcfBuyerLimit } from "../../../components/sme-limit-buyer/list";
import { useNavigate, useParams } from "react-router";
import { IconDownload, IconShoppingCart, IconTruckLoading } from "@tabler/icons";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { SubLimitApi } from "../../../apis";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function ScfDcfLimit() {
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "SUPPLIER");
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onChange = (value: string) => {
    navigate(`/scf-dcf/limit/${value}`);
    setTabKey(value);
  };

  const onExport = async () => {
    setLoading(true);
    try {
      if (tabKey === "SUPPLIER") {
        let res = await SubLimitApi.list({
          filter: { productType: "BUYER_LED", programId: "", buyerId: "", supplierId: "", excel: `${true}` },
          offset: { page: 1, limit: 10 },
        });
        window.open(res.excelUrl as string, "_blank");
      } else {
        let res = await SubLimitApi.list({
          filter: { productType: "SUPPLIER_LED", programId: "", buyerId: "", supplierId: "", excel: `${true}` },
          offset: { page: 1, limit: 10 },
        });
        window.open(res.excelUrl as string, "_blank");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title={tabKey === "SUPPLIER" ? "Нийлүүлэгчийн бизнес лимит жагсаалт" : "Худалдан авагч бизнес лимит жагсаалт"}
      subTitle="Анкор бизнесийн найдвартай харилцаанд үндэслэн санхүүжилтийн лимит шийдвэрлэсэн бизнесүүд"
      breadcrumb={breadcrumbs}
      extra={[
        <Button size="xs" key={0} onClick={() => onExport()} leftIcon={<IconDownload />} variant="outline">
          EXCEL
        </Button>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SUB_LIMIT_USAGE_SL").length > 0 && (
              <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Нийлүүлэгч
                </Text>
              </Tabs.Tab>
            )}

            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SUB_LIMIT_USAGE_BL").length > 0 && (
              <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={tabKey === "BUYER" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Худалдан авагч
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Space h={10} />

          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SUB_LIMIT_USAGE_SL").length > 0 && (
            <Tabs.Panel value="SUPPLIER">
              <ScfDcfSupplierLimit />
            </Tabs.Panel>
          )}

          {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SUB_LIMIT_USAGE_BL").length > 0 && (
            <Tabs.Panel value="BUYER">
              <ScfDcfBuyerLimit />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн хөтөлбөр" },
  {
    label: "Дэд лимит ашиглалт",
  },
];
