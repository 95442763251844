import { Button, Divider, Flex, LoadingOverlay } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import React from "react";
import { PasswordField } from "../form/password-field";
import { useDispatch, useSelector } from "react-redux";
import { AuthApi } from "../../apis";
import { Message } from "../../utils/message";
import { authMe } from "../../store/Auth";
import HttpHandler from "../../utils/http-handler";

const schema = (hasPin: boolean) => {
  return yup.object({
    oldPin: hasPin ? yup.string().required("Хуучин пин код заавал бөглөх") : yup.string().nullable(),
    pin: yup.string().min(6, "6 тэмдэгттэй байх ёстой").max(6, "6 тэмдэгттэй байх ёстой").required("Пин код оруулана уу"),
    checkPin: yup
      .string()
      .min(6, "6 тэмдэгттэй байх ёстой")
      .max(6, "6 тэмдэгттэй байх ёстой")
      .required("Пин код оруулана уу")
      .oneOf([yup.ref("pin")], "Пин код таарахгүй байна"),
  });
};
type IForm = {
  oldPin: undefined | string;
  pin: undefined | string;
  checkPin: undefined | string;
};

export function ChangePin({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [data] = React.useState<IForm>({
    oldPin: undefined,
    pin: undefined,
    checkPin: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      if (user && user?.hasPin) {
        await AuthApi.updatePin({ oldPin: values?.oldPin, pin: values?.pin });
        setLoading(false);
        onClose();
        Message.success("Пин код амжилттай солигдлоо");
      } else {
        await AuthApi.createPin({ pin: values?.pin });
        setLoading(false);
        const auth = await AuthApi.me();
        dispatch(authMe(auth));
        onClose();
        Message.success("Пин код амжилттай үүслээ");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
      setLoading(false);
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema(user && user?.hasPin)}>
      {() => {
        return (
          <Flex direction="column" gap={"md"}>
            <LoadingOverlay visible={loading} />
            {user && user?.hasPin && <PasswordField name="oldPin" label="Хуучин пин код" placeholder="Хуучин пин код" />}
            <PasswordField name="pin" label="Шинэ пин код" placeholder="Шинэ пин код" />
            <PasswordField name="checkPin" label="Давтан пин код" placeholder="Давтан пин код" />
            <Divider />

            <Flex justify="space-between" align="center">
              <Button onClick={() => onClose()} variant="outline" color="gray">
                Болих
              </Button>
              <Button type="submit" variant="filled">
                Хадгалах
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
