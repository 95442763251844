import React, { useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { PageLayout } from "../../../components/layout";
import { ScfDashboardApi } from "../../../apis";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import useSwr from "swr";
import { useDebouncedValue } from "@mantine/hooks";
import { Divider, LoadingOverlay, SegmentedControl } from "@mantine/core";
import { IconCalendarEvent } from "@tabler/icons";
import { ScfDashboardStatic } from "./static";
import { ScfDashboardReal } from "./real";
import { useNavigate, useLocation } from "react-router";

export function ScfDashboard() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [value, setValue] = React.useState("static");
  const [date, setDate] = React.useState<Date | null>(null);
  const [debouncedDate] = useDebouncedValue(date, 600);
  const [rowDate, setRowDate] = React.useState<Date | null>(null);
  const [debouncedRowDate] = useDebouncedValue(date, 600);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [debouncedStartDate] = useDebouncedValue(date, 600);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [debouncedEndDate] = useDebouncedValue(date, 600);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/scf") && !location.pathname.includes("/scf/dashboard")) {
      navigate("/scf/dashboard", { replace: true });
    }
  }, [location, navigate]);

  const { data, isLoading } = useSwr(`/api/scf/dashboard/${debouncedDate}/${debouncedRowDate}/${debouncedStartDate}/${debouncedEndDate}`, async () => {
    try {
      let res = await ScfDashboardApi.get({
        date: date ? date.toISOString() : nowMoment.toISOString(),
        rowDate: debouncedRowDate ? debouncedRowDate.toISOString() : nowMoment.toISOString(),
        startDate: debouncedStartDate ? debouncedStartDate.toISOString() : nowMoment.toISOString(),
        endDate: debouncedEndDate ? debouncedEndDate.toISOString() : nowMoment.toISOString(),
      });

      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Тоон тойм"
      subTitle=""
      breadcrumb={breadcrumbs}
      extra={[
        <DatePicker
          key={1}
          value={date ? date : nowMoment}
          maxDate={nowMoment || date}
          onChange={setDate}
          placeholder="Өдөр сонгох"
          minDate={dayjs(nowMoment || date)
            .subtract(90, "days")
            .toDate()}
          rightSection={<IconCalendarEvent color="gray" />}
        />,
      ]}>
      <Divider />
      <SegmentedControl
        mt={3}
        mb={3}
        value={value}
        onChange={setValue}
        w={200}
        data={[
          { label: "Static", value: "static" },
          { label: "Real", value: "real" },
        ]}
        color="cyan[4]"
      />
      {value === "static" ? (
        <ScfDashboardStatic nowMoment={nowMoment} />
      ) : (
        <ScfDashboardReal
          data={data}
          rowDate={rowDate}
          setRowDate={setRowDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          nowMoment={nowMoment}
        />
      )}
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    label: "Дашбоард",
  },
];
