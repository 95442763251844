import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Badge, Box, Button, Checkbox, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { Message } from "../../utils/message";
import { ProgramApi } from "../../apis";
import { useParams } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../models/General";
import HttpHandler from "../../utils/http-handler";

const schema = yup.object({});

type IFormData = {
  coBusinessAreaDesc: string;
};

type Props = {
  title: string;
  subTitle: string;
  onCancel: () => void;
  action: any[];
  reload?: any;
};
export function SupplierProgramSuggestionForm({ title, onCancel, action, subTitle, reload }: Props) {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { networkDiscountTypes, networkPenaltyTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    coBusinessAreaDesc: "",
    smes: [],
    ...(action && action[0] === "request" ? action[1] : {}),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await ProgramApi.participantAdd(`${params.id}`, {
        smes: [{ smeBusinessId: action && action[1].id }],
      });
      reload();
      Message.success("Таны хүсэлт амжилттай санхүүжилтыг амжилттай хүссэн.");
      onCancel && onCancel();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="НЭХЭМЖЛЭХИЙГ САНХҮҮЖҮҮЛЭХЭД ШААРДЛАГАТАЙ МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Партнер нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {values.partner?.refCode || "-"} {values.partner?.businessName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бизнес нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {values.refCode || "-"} {values.profileName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчээс олгосон авлагын лимит:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.debtLimitAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Лимит эхлэх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(values.debtLimitStartDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Лимит дуусах огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(values.debtLimitEndDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Flex direction="column" gap={3} mt={4}>
                  <Text fz={14} fw={500} c="#212529">
                    Хугацаанаас өмнө хөнгөлдөг эсэх:
                  </Text>
                  <Checkbox checked={values.earlyDiscount} label={values.earlyDiscount ? "Тийм" : "Үгүй"} onChange={() => {}} />
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөнгөлөлтийн хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {values.discountPercent || "0"} {"%"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөнгөлөлт тооцдог арга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      <Badge size="md" radius="sm" variant="outline">
                        {networkDiscountTypes.find((item: IReference) => item.code === values.discountType)?.name}
                      </Badge>
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Flex direction="column" gap={3} mt={4}>
                  <Text fz={14} fw={500} c="#212529">
                    Хугацаанаас хойш алданги тооцдог эсэх:
                  </Text>
                  <Checkbox checked={values.countPenalty} label={values.countPenalty ? "Тийм" : "Үгүй"} onChange={() => {}} />
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Алданги хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {values.penaltyPercent || "0"} {"%"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Алданги арга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      <Badge size="md" radius="sm" variant="outline">
                        {networkPenaltyTypes.find((item: IReference) => item.code === values.penaltyType)?.name}
                      </Badge>
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ӨМНӨХ ТҮҮХЭНД ҮНДЭСЛЭН ОРУУЛАХ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Авлагын дундаж үлдэгдэл - 7 хоног:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.weekAvgDebtAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Авлагын дундаж үлдэгдэл - 2 дохь хоног:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.halfMonthAvgDebtAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Авлагын дундаж үлдэгдэл - сар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.monthAvgDebtAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Сарын дундаж татан авалт:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.monthAvgBidAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Сарын буцаалтын дундаж дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.monthAvgRefundAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Сарын дундаж орлого тушаалт:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(values.monthAvgPurchaseAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
            <Divider color="gray" mb="sm" mt="sm" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel && onCancel()}>
                Буцах
              </Button>
              <Flex gap="md">
                <Button type="submit" disabled={values.participantStatus !== "PENDING"}>
                  Зөвшөөрөх
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
