import { createStyles, Group, Paper, Text, ThemeIcon, SimpleGrid } from "@mantine/core";
import { IconArrowUpRight, IconArrowDownRight } from "@tabler/icons";
import { Link } from "react-router-dom";

export function B2bDashboard() {
  const { classes } = useStyles();

  const stats = [
    { title: "REVENUE", value: "$13,456", diff: 20, link: "/scf" },
    { title: "REVENUE", value: "$13,456", diff: -100, link: "/dcf" },
    { title: "REVENUE", value: "$13,456", diff: -1, link: "/b2b" },
  ].map((stat) => {
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Link to={stat.link}>
        <Paper withBorder p="md" radius="md" key={stat.link}>
          <Group position="apart">
            <div>
              <Text color="dimmed" transform="uppercase" weight={700} size="xs" className={classes.label}>
                {stat.title}
              </Text>
              <Text weight={700} size="xl">
                {stat.value}
              </Text>
            </div>
            <ThemeIcon
              color="gray"
              variant="light"
              sx={(theme) => ({ color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6] })}
              size={38}
              radius="md">
              <DiffIcon size={28} stroke={1.5} />
            </ThemeIcon>
          </Group>
          <Text color="dimmed" size="sm" mt="md">
            <Text component="span" color={stat.diff > 0 ? "teal" : "red"} weight={700}>
              {stat.diff}%
            </Text>{" "}
            {stat.diff > 0 ? "increase" : "decrease"} compared to last month
          </Text>
        </Paper>
      </Link>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        {stats}
      </SimpleGrid>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));
