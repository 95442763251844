import React from "react";
import { Box, Button, Divider, Flex, Grid, Input, Text, Radio } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import { TextareaField } from "../form/textarea-field";
import { CheckboxField } from "../form/checkbox-field";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";

const schema = yup.object({
  approvedAmount: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup.string().min(1, "Хэтэрхий бага утга байна!").required("Заавал бөглөнө үү!").nullable(),
  }),
  respondDesc: yup.string().required("Заавал бөглөнө!").nullable(),
  toDisburseDate: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
});

type IFormData = {
  finance: string;
  respondDesc: string;
  isApproved: boolean;
  approvedAmount: string;
  toDisburseDate: string;
  finance_board: boolean;
};

type Props = {
  action: any;
  onCancel: () => void;
  setTabIndex: any;
  setAction: (e: any) => void;
};

export function ScfFundingDecisionForm({ action, onCancel, setAction, setTabIndex }: Props) {
  const [data] = React.useState<IFormData>({
    isApproved: false,
    approvedAmount: "",
    toDisburseDate: "",
    respondDesc: "",
    finance: "",
    finance_board: true,
    ...action,
  });

  return (
    <>
      <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
        {({ values }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТ АВАГЧ ТАЛ" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч партнер:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.partnerName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.regNumber || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч бизнес:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.requestedBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн огноо:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {dateSecFormat(action?.respondedDate)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн ажилтан:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.respondedUser?.lastName} {action?.respondedUser?.firstName}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.program?.resolutionNo || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШИЙДВЭРИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Flex gap="xs" align="center">
                    <Radio checked={action?.isApproved === true && true} onChange={() => {}} label="Санхүүжилтийг зөвшөөрөх" />
                    <Radio checked={action?.isApproved === false && true} onChange={() => {}} label="Санхүүжилтийг татгалзах" />
                  </Flex>
                </Grid.Col>

                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.program?.disburseAcc?.number || "-"}, {action?.program?.disburseAcc?.name}, {action?.program?.disburseAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын дансны үлдэгдэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.program?.disburseAcc?.balance || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын данс шаардлага:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.program?.disburseAcc?.balanceAcceptable ? "Тийм" : "Үгүй"}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Гүйлгээний шимтгэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.trxFee || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Өргөдлийн хураамж:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.appFee || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголтын шимтгэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.disbursementFee || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Санхүүжилт олгох дүн:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.approvedAmount || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Олголт хийх, огноо:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {dateSecFormat(action?.toDisburseDate)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Эргэн төлөлтийн нэхэмжлэх:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.repaymentInv?.refCode || "-"}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Санхүүжилтийн шимтгэл:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.calculatedFeeAmount || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Банк шимтгэлийн дүн:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.bankFeeAmount || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Эргэн төлөх дүн:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {tugrug(action?.repaymentAmount || "0")}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}

                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Санхүүжилт авах данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.invoice?.senderAcc?.number}, {action?.invoice?.senderAcc?.name}, {action?.invoice?.senderAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="SCF орлого төвлөрүүлэх данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.program?.feeAcc?.number || "-"}, {action?.program?.feeAcc?.name}, {action?.program?.feeAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                {action?.isApproved === true && (
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Эргэн төлөх данс:">
                      <Box style={{ alignItems: "center" }}>
                        <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {action?.program?.repayAcc?.number || "-"}, {action?.program?.repayAcc?.name}, {action?.program?.repayAcc?.bankName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                )}
                <Grid.Col span={12}>
                  <CheckboxField name="finance_board" label="">
                    {values.finance_board ? "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан" : "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан"}
                  </CheckboxField>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="respondDesc" label="Тайлбар:" disabled placeholder="Тайлбар энд бичих" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button
                  variant="default"
                  onClick={() => {
                    setTabIndex("3");
                  }}>
                  Буцах
                </Button>
              </Flex>
            </Flex>
          );
        }}
      </Form>
    </>
  );
}
