import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Paper, Space, Tabs, Text } from "@mantine/core";
import { IconClipboard, IconClipboardList } from "@tabler/icons";
import { ReceivableAnchorList } from "../../../components/receivable-anchor/list";
import { ReceivableSmeLimit } from "../../../components/receivable-sme/list";

export function CfReceivableLimitTab() {
  const params = useParams();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "ANCHOR");

  const titles = (val: string) => {
    switch (val) {
      case "ANCHOR": {
        return "Хөтөлбөрийн лимит";
      }

      case "SME": {
        return "SME - ийн лимит";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/cf/receivable-limit/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  return (
    <>
      <PageLayout
        title={tabKey === "ANCHOR" ? "Хөтөлбөрийн лимитийн жагсаалт" : "SME - ийн лимитийн жагсаалт"}
        subTitle="Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр лимит ашиглалт"
        breadcrumb={breadcrumbs}
        extra={[]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
            <Tabs.List>
              <Tabs.Tab value="ANCHOR" icon={<IconClipboardList color={tabKey === "ANCHOR" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хөтөлбөрийн лимит
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="SME" icon={<IconClipboard color={tabKey === "SME" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  SME - ийн лимит
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Space h={10} />
            <Tabs.Panel value="ANCHOR">
              <ReceivableAnchorList />
            </Tabs.Panel>
            <Tabs.Panel value="SME">
              <ReceivableSmeLimit />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: type,
  },
];
