import { CloseButton, MantineSize, NumberInput } from "@mantine/core";
import { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import { IconCurrencyTugrik } from "@tabler/icons";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  noError?: boolean;
  hideControls?: boolean;
  classNames?: Partial<Record<BaseSelectStylesNames, string>> | undefined;
  onChangeCustom?: any;
  size?: MantineSize;
  min?: number;
  max?: number;
  icon?: any;
  iconWidth?: any;
  maxLength?: number;
  required?: boolean;
  formatter?: any;
  parser?: any;
  rightSection?: React.ReactNode;
  precision?: number;
  allowArrow?: boolean;
};

export function NumberCurrencyField({
  name,
  label,
  noError = false,
  hideControls = false,
  placeholder,
  disabled = false,
  classNames,
  onChangeCustom,
  size = "sm",
  min,
  max,
  icon,
  iconWidth,
  maxLength,
  required,
  allowArrow,
  precision,
}: Props) {
  const { value, error, onChange } = useField(name);
  return (
    <NumberInput
      maxLength={maxLength}
      iconWidth={iconWidth}
      max={max}
      min={min}
      size={size}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value || undefined}
      hideControls={hideControls}
      onChange={(v) => {
        onChange(v);
        onChangeCustom && onChangeCustom(v);
      }}
      classNames={classNames}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: error && noError && 0,
        },
      }}
      required={required}
      precision={value && precision}
      rightSection={
        !allowArrow && (
          <CloseButton
            onClick={() => {
              onChange("");
              onChangeCustom && onChangeCustom(undefined);
            }}
            variant="transparent"
            size={"sm"}
            color="gray.6"
          />
        )
      }
      icon={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
      formatter={(value: any) => (!Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "")}
    />
  );
}
