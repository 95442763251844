import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import dayjs from "dayjs";
import { TextareaField } from "../form/textarea-field";
import { tugrug } from "../../utils";
import { IconArrowRight } from "@tabler/icons";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../models/General";

const schema = yup.object({});

type IFormData = {
  name: number;
};

export function BuyerProductDetailInformationForm({ action, onSuccess, onCancel }: { action: any[]; onSuccess: () => void; onCancel: () => void }) {
  const { productRegistrationStatus, productStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    name: "",
    ...(action && action[0] === "DETAIL" ? action[1] : {}),
  });

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төрөл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.borrowerType === "SUPPLIER_ONLY" ? "Анкор нь Supplier роль" : "Анкор нь Buyer роль"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний ангилал:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.category === "INVOICE_BASED" ? "Нэхэмжлэх суурьтай" : "Захиалга суурьтай"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.regUser?.lastName || ""} {action[1]?.regUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dayjs(action[1]?.createdAt).format("YYYY-MM-DD HH:mm") || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(productRegistrationStatus || [])?.find((item: IReference) => item.code === action[1]?.registrationStatus)?.color}>
                        {(productRegistrationStatus || [])?.find((item: IReference) => item.code === action[1]?.registrationStatus)?.name}
                      </Badge>
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төлөв:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(productStatus || [])?.find((item: IReference) => item.code === action[1]?.productStatus)?.color}>
                        {(productStatus || [])?.find((item: IReference) => item.code === action[1]?.productStatus)?.name}
                      </Badge>
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртээгдэхүүний нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.name || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" disabled placeholder="Тайлбар оруулах" label="Тайлбар оруулах" withAsterisk={false} />
              </Grid.Col>

              <Grid.Col span={12} mt="lg">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="НЭХЭМЖЛЭХЭД ХАМААРАХ ТОХИРГОО" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн доод үлдэгдэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action[1]?.minInvAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мах хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.maxOfInv || "0"} {"%"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мin хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.minOfInv || "0"} {"%"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мах тенор:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.maxTenor || "0"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мin тенор:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.minTenor || "0"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мax хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.maxDays || "0"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн Мin хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action[1]?.minDays || "0"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between">
              <Button
                variant="default"
                onClick={() => {
                  onCancel();
                }}>
                Болих
              </Button>
              <Flex gap="md">
                <Button rightIcon={<IconArrowRight size={20} />} onClick={() => onSuccess()} type="submit">
                  Дараагийнх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
