import { Badge, Box, Button, Divider, Flex, Text } from "@mantine/core";
import { tugrug } from "../../utils";

type Props = {
  action: any;
  onTabChange: any;
};

export function ScfSupplierFinanceLimitList({ action, onTabChange }: Props) {
  return (
    <>
      <Divider color="gray" mt="xs" mb="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="Харилцагчийн лимит ашиглалт" />
      <Box sx={(theme) => ({ border: `1px solid ${theme.colors.gray[3]}` })}>
        <Flex gap={0} justify="space-between">
          <Box w="50%">
            <Flex direction="column">
              <Text
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                size="sm">
                {" "}
              </Text>
              <Text
                align="center"
                sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                size="sm"
                fw={600}
                color="indigo.6">
                Дүн
              </Text>
              <Text
                size="sm"
                sx={(theme) => ({ display: "flex", justifyContent: "center", alignItems: "center" })}
                align="center"
                h={50}
                fw={600}
                color="indigo.6">
                Тоо
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Баталсан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action[1]?.program?.programLimit || 0)}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Боломжит лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action[1]?.program?.availableAmount || 0)}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {"-"}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Лимит шаардлага
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {action[1]?.program?.limitAcceptable ? (
                  <Badge color="green" radius="xs">
                    {"Хангаж байна"}
                  </Badge>
                ) : (
                  <Badge color="red" radius="xs">
                    {"Хангахгүй байна"}
                  </Badge>
                )}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                -
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Ашигласан лимит
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action[1]?.program?.utilizedAmount || 0)}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action[1]?.program?.utilizedCount || 0}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Олголт хүлээж буй
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action[1]?.program?.toDisburseAmount || 0)}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action[1]?.program?.toDisburseCount || 0}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  backgroundColor: theme.colors.gray[1],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="#2a3635">
                Хүсэлт илгээсэн
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  borderLeft: `1px solid ${theme.colors.gray[2]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                })}
                h={50}
                fw={600}
                color="dimmed">
                {tugrug(action[1]?.program?.pendingAmount || 0)}
              </Text>
              <Text
                size="sm"
                align="center"
                sx={(theme) => ({ borderLeft: `1px solid ${theme.colors.gray[2]}`, display: "flex", justifyContent: "center", alignItems: "center" })}
                h={50}
                fw={600}
                color="dimmed">
                {action[1]?.program?.pendingCount || 0}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Divider color="gray" mt="md" mb="md" />
      <Flex direction="row" justify="space-between">
        <Button
          variant="default"
          onClick={() => {
            onTabChange("2");
          }}>
          Буцах
        </Button>
        <Flex gap="md">
          <Button
            variant="filled"
            onClick={() => {
              onTabChange("4");
            }}
            type="submit">
            Үргэлжлүүлэх
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
