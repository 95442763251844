import React from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { LbfProgramApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconFileText, IconPackage } from "@tabler/icons";
import { FundingProgramBuyerLedInformationForm, LbfProgramData } from "../../../components/funding-program-buyer/information-form";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { FundingProgramBuyerLedBusinessList } from "../../../components/funding-program-buyer/business-list";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingProgramBuyerLedEdit() {
  const params = useParams();
  const { id, type, refCode } = params;
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(type || "INFORMATION");
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, mutate } = useSwr(`/api/program/buyer-led/${params.id}`, async () => await LbfProgramApi.get(id as string));

  const onSubmit = async (value: LbfProgramData, type: boolean) => {
    setLoading(true);
    const event = new Date(value.resolutionDate);
    if (type && !value.businessId) {
      Message.error("Та анкорыг сонгон уу!");
    } else {
      try {
        await LbfProgramApi.update(id as string, {
          name: value?.name,
          resolutionNo: value?.resolutionNo,
          resolutionDate: event.toISOString(),
          description: value?.description,
          sector1Id: value?.sector1Id,
          sector2Id: value?.sector2Id,
          sector3Id: value?.sector3Id,
          businessId: value?.businessId,
          lbfProgramLimit: parseInt(value?.lbfProgramLimit),
          complete: type,
        });

        await mutate();

        if (type) {
          navigate(`/cf/funding-program/BUYER/${refCode}/edit/${id}/BUSINESS`);
          setTabIndex("BUSINESS");
        } else {
          navigate(`/cf/funding-program/BUYER`);
        }
        Message.success("Хөтөлбөрийн амжилттай засварлан хадгалсан!");
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }

    setLoading(false);
  };

  const onTabChange = (_index: string) => {
    navigate(`/cf/funding-program/BUYER/${refCode}/edit/${id}/${_index}`);
    setTabIndex(_index);
  };

  return (
    <PageLayout title="Хөтөлбөр засварлах" subTitle="Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр" breadcrumb={breadcrumbs} extra={[]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs value={tabIndex} onTabChange={onTabChange}>
          <LoadingOverlay visible={loading} />
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconFileText color={tabIndex === "INFORMATION" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Tab value="BUSINESS" icon={<IconPackage color={tabIndex === "BUSINESS" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Нийлүүлэгч бизнесүүд
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">{data && <FundingProgramBuyerLedInformationForm onSubmit={onSubmit} action={["update", data]} />}</Tabs.Panel>
          {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_SME_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tabs.Panel value="BUSINESS">
              {data && (
                <FundingProgramBuyerLedBusinessList
                  data={data}
                  onSuccess={async () => {
                    await mutate();
                  }}
                />
              )}
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Зээл суурьтай санхүүжилт",
  },
  {
    to: "/cf/funding-program/BUYER",
    label: "Buyer-LED хөтөлбөрийн жагсаалт",
  },
  {
    label: "Засварлах",
  },
];
