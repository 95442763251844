import React from "react";
import * as yup from "yup";
import { Button, Divider, Flex, Grid, Text } from "@mantine/core";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { SelectField } from "../form/select-field";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IconSend } from "@tabler/icons";
import { currencyRegex, percent, percentZero } from "../../utils";
import { useNavigate } from "react-router";
import { NumberPercentField } from "../form/number-percent-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { IReference } from "../../models/General";
import { IAuth } from "../../interfaces/IAuth";

const schema = yup.object({
  suppFee: yup.string().matches(percent, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppAppFee: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppCalculateDay: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppMinFee: yup.number().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppDisbursementFee: yup.string().matches(percent, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppFeeRule: yup.string().required("Заавал бөглөнө!").nullable(),
  suppPenaltyPercent: yup.string().required("Заавал бөглөнө!").nullable(),
  suppPenaltyType: yup.string().required("Заавал бөглөнө!").nullable(),

  buyerFee: yup
    .string()
    .matches(percent, "Та дахин шалгана уу!")
    .when("borrowerType", {
      is: "BUYER",
      then: yup.string().matches(percent, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
    }),
  buyerCalculateDay: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerAppFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerDisbursementFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(percentZero, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerMinFee: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(currencyRegex, "Та тоо оруулна уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerFeeRule: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerPenaltyPercent: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().matches(percentZero, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө үү!").nullable(),
  }),
  buyerPenaltyType: yup.string().when("borrowerType", {
    is: "BUYER",
    then: yup.string().required("Заавал бөглөнө үү!").nullable(),
  }),
});

export type ISupplierLedFeeData = {
  suppFee: number;
  suppAppFee: number;
  suppCalculateDay: number;
  suppMinFee: number;
  suppDisbursementFee: number;
  suppFeeRule: string;

  suppPenaltyPercent: string;
  suppPenaltyType: string;
};

export function ScfProductFeeForm({ onSubmit: _onSubmit, action }: { onSubmit: (values: ISupplierLedFeeData, type: boolean) => void; action: any }) {
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productSuppFeeRules, productBuyerPenaltyTypes, productBuyerFeeRules } = useSelector((state: { general: IGeneral }) => state.general);
  const [type, setType] = React.useState(false);
  const [data] = React.useState<ISupplierLedFeeData>({
    suppFee: "",
    suppAppFee: "",
    suppCalculateDay: "",
    suppMinFee: "",
    suppDisbursementFee: "",
    suppFeeRule: "",
    suppPenaltyPercent: "",
    suppPenaltyType: "",

    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = (values: ISupplierLedFeeData) => {
    _onSubmit(values, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧИЙН ТӨЛӨХ ШИМТГЭЛИЙН ТОХИРГОО" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <NumberPercentField
                    name="suppFee"
                    label="Нэхэмжлэх санхүүжүүлэх хүү:"
                    placeholder="Хувийг оруулна уу"
                    max={100}
                    min={0}
                    precision={2}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField
                    name="suppCalculateDay"
                    label="Хүү тооцох хоног:"
                    placeholder="Хоногийг оруулна уу"
                    rightSection={
                      <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                        {"Хоног"}
                      </Text>
                    }
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <NumberCurrencyField
                    name="suppAppFee"
                    label="Өргөдлийн хураамж:"
                    placeholder="Үнийн дүнг оруулна уу"
                    precision={2}
                    min={0}
                    max={999999999999}
                    required
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <NumberCurrencyField
                    name="suppMinFee"
                    label="Миннимум шимтгэлийн дүн:"
                    placeholder="Дүнг оруулна уу"
                    precision={2}
                    min={0}
                    max={999999999999}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <NumberPercentField
                    name="suppDisbursementFee"
                    label="Олголтын шимтгэл:"
                    placeholder="Дүнг оруулна уу"
                    max={100}
                    min={0}
                    precision={2}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    name="suppFeeRule"
                    label="Шимтгэл авах дүрэм:"
                    placeholder="Сонгох"
                    options={(productSuppFeeRules || [])
                      .filter((c) => c.code === "DISBURSEMENT")
                      .map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <NumberPercentField
                    name="suppPenaltyPercent"
                    label="Хугацаа хэтэрсний алданги:"
                    placeholder="Хувийг оруулна уу"
                    max={100}
                    min={0}
                    precision={2}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    name="suppPenaltyType"
                    label="Алданги тооцох дүрэм:"
                    placeholder="Сонгох"
                    options={(productBuyerPenaltyTypes || []).map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                {values.borrowerType === "BUYER" && (
                  <>
                    <Grid.Col span={12} mt="xs">
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ ШИМТГЭЛИЙН ТОХИРГОО" />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <NumberPercentField
                        name="buyerFee"
                        label="Худалдан авагчийн шимтгэл:"
                        placeholder="Хувийг оруулна уу"
                        max={100}
                        min={0}
                        precision={2}
                        required
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <TextField
                        name="buyerCalculateDay"
                        label="Шимтгэлийг тооцох хоног:"
                        placeholder="Хоногийг оруулна уу"
                        rightSection={
                          <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                            {"Хоног"}
                          </Text>
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <NumberCurrencyField
                        precision={2}
                        min={0}
                        max={999999999999}
                        name="buyerAppFee"
                        label="Өргөдлийн хураамж:"
                        placeholder="Үнийн дүнг оруулна уу"
                        required
                      />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <NumberPercentField
                        name="buyerDisbursementFee"
                        label="Олголтын шимтгэл:"
                        placeholder="Хувийг оруулна уу"
                        max={100}
                        min={0}
                        precision={2}
                        required
                      />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <NumberCurrencyField
                        precision={2}
                        min={0}
                        max={999999999999}
                        name="buyerMinFee"
                        label="Миннимум шимтгэлийн дүн:"
                        placeholder="Дүнг оруулна уу"
                        required
                      />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <SelectField
                        name="buyerFeeRule"
                        label="Шимтгэл авах дүрэм:"
                        placeholder="Сонгох"
                        options={(productBuyerFeeRules || [])
                          .filter((c: IReference) => c.code === "DISBURSEMENT")
                          .map((c) => ({
                            label: c.name,
                            value: c.code,
                          }))}
                      />
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <NumberPercentField
                        name="buyerPenaltyPercent"
                        label="Хугацаа хэтэрсний алданги:"
                        placeholder="Дүнг оруулна уу"
                        max={100}
                        min={0}
                        precision={2}
                        required
                      />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <SelectField
                        name="buyerPenaltyType"
                        label="Алданги тооцох дүрэм:"
                        placeholder="Сонгох"
                        options={(productBuyerPenaltyTypes || []).map((c) => ({
                          label: c.name,
                          value: c.code,
                        }))}
                      />
                    </Grid.Col>
                  </>
                )}
              </Grid>

              <Flex mt={20} direction="row" justify="space-between">
                <Button variant="default" onClick={() => navigate("/scf-dcf-product/scf-product")}>
                  Болих
                </Button>
                <Flex gap="md">
                  <Button onClick={() => setType(false)} variant="outline" type="submit" disabled={action && action[1].suppTerminatePeriod === null}>
                    Хадгалах
                  </Button>

                  {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isEdit).length > 0 && (
                    <Button
                      disabled={action && action[1].suppTerminatePeriod === null}
                      onClick={() => setType(true)}
                      rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />}
                      type="submit">
                      Хяналтад илгээх
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
