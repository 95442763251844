import { Paper } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { SmeList } from "../../../components/ancor/sme-list";

export const SmeTabs = () => {
  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title="SME харилцагчид" subTitle="SME харилцагчдын жагсаалт" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <SmeList />
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Харилцагч удирдлага",
  },
  {
    label: "SME харилцагчид",
  },
];
