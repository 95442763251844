import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { ProgramApi } from "../../../apis";
import useSwr from "swr";
import { LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { SupplierProgramMainForm } from "../../../components/supplier-led-program/main-form";
import { SupplierProgramPinForm } from "../../../components/supplier-led-program/pin-form";
import { SupplierProgramBuyerList } from "../../../components/supplier-led-program/buyer-list";
import { IconFileText, IconShoppingCart } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function SupplierLedProgramEdit() {
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const { id, step } = params;
  const navigate = useNavigate();
  const [index, setIndex] = React.useState<boolean>(false);
  const [tabIndex, setTabIndex] = React.useState(step || "1");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, mutate } = useSwr(`/api/program/supplier-led/${params.id}/edit`, async () => await ProgramApi.get(id as string));

  const onTabChange = (_index: string) => {
    navigate(`/scf-dcf/scf-program/${params.code}/edit/${params.id}/${_index}`);
    setTabIndex(_index);
  };

  const onMainSubmit = async (value: any) => {
    setIsLoading(true);
    try {
      if (index) {
        setAction(["pin", data, "update"]);
      } else {
        await ProgramApi.supplierUpdate(params.id as string, {
          type: "CURRENT_ASSET",
          productType: "SUPPLIER_LED",
          name: value.name,
          resolutionNo: value.resolutionNo,
          description: value.description,
          programSector1Ids: value?.programSector1Ids || [],
          programSector2Ids: (value?.programSector2Ids || []).map((c: any) => c?.id),
          programSector3Ids: (value?.programSector3Ids || []).map((c: any) => c?.id),
          disburseAccId: value.disburseAccId,
          repayAccId: value.repayAccId,
          feeAccId: value.feeAccId,
          businessId: value.businessId,
          finUserId: value?.anchor?.finUser?.id,
          rechargeable: value.rechargeable,
          programLimit: value.programLimit,
          toReview: index ? true : false,
        });
        navigate(`/scf-dcf/scf-program`);
        Message.success(`Та SCF хөтөлбөр амжилттай засварлан хадгаллаа.`);
      }

      mutate();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageLayout
        title="Supplier LED хөтөлбөр засах"
        subTitle={`Supplier LED хөтөлбөр бүртгэлийг засварлах,`}
        breadcrumb={breadcrumbs}
        bgColor="#F8F9FA"
        extra={[]}>
        <Paper radius="sm" p="md" withBorder key={1}>
          <LoadingOverlay visible={!data || isLoading} />
          {data && (
            <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
              <Tabs.List>
                <Tabs.Tab value="1" icon={<IconFileText color={tabIndex === "1" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Хөтөлбөрийн мэдээлэл
                  </Text>
                </Tabs.Tab>

                {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_SL" && c.isView).length > 0 && (
                  <Tabs.Tab value="2" icon={<IconShoppingCart color={tabIndex === "2" ? "#317169" : "#44566C"} />}>
                    <Text size="sm" weight={500} c="#44566C">
                      Худалдан авагч бизнесүүд
                    </Text>
                  </Tabs.Tab>
                )}
                {/* <Tabs.Tab value="3" icon={<IconReceiptTax color={tabIndex === "3" ? "#317169" : "#44566C"} />} disabled>
                  <Text size="sm" weight={500} c="#44566C">
                    Төлөлтийн хувиар
                  </Text>
                </Tabs.Tab> */}
              </Tabs.List>
              <Tabs.Panel value="1">
                {tabIndex === "1" && data && <SupplierProgramMainForm onSubmit={onMainSubmit} action={["update", data || {}]} setIndex={setIndex} />}
              </Tabs.Panel>

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Panel value="2">{tabIndex === "2" && <SupplierProgramBuyerList />}</Tabs.Panel>
              )}
            </Tabs>
          )}
        </Paper>
      </PageLayout>

      <Modal withCloseButton={false} opened={action[0] === "pin"} onClose={() => setAction([])} size={"md"} centered>
        <SupplierProgramPinForm
          action={action}
          onCancel={() => setAction([])}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
  {
    to: "/scf-dcf/scf-program",
    label: "Supplier LED хөтөлбөр",
  },
  {
    label: "Засварлах",
  },
];
