import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/product", { offset, filter });
};

export const create = async (data: {
  type: string;
  category: string;
  name: string;
  resolutionNo: string;
  fundType: string;
  description: string;
  createdAt?: string;
  refCode?: string;
  regUser?: any;
  registrationStatus?: string;
}) => {
  return httpRequest.post("/api/product", data);
};

export const supplier = async (data: { type: string; category: string; name: string; resolutionNo: string; description: string }) => {
  return httpRequest.post("/api/product", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/product/${id}`);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/product/${id}`, data);
};

export const funding = async (id: string, data: any) => {
  return httpRequest.put(`/api/product/${id}/funding`, data);
};

export const commission = async (id: string, data: any) => {
  return httpRequest.put(`/api/product/${id}/commission`, data);
};

export const active = async (id: string, data: { activeType: string; statusDesc: string }) => {
  return httpRequest.put(`/api/product/${id}/active`, data);
};

export const inactive = async (id: string, data: { inactiveType: string; statusDesc: string }) => {
  return httpRequest.put(`/api/product/${id}/inactive`, data);
};

export const toreview = async (id: string) => {
  return httpRequest.put(`/api/product/${id}/to_review`);
};

export const review = async (id: string, data: { review: boolean; reviewDesc: string }) => {
  return httpRequest.put(`/api/product/${id}/review`, data);
};

export const select = async (data: any) => {
  return httpRequest.get(`/api/product/select`, data);
};

export const fundingSupplier = async (id: string, data: any) => {
  return httpRequest.put(`/api/product/${id}/funding`, data);
};

export const account = async (data: { type: string; accountId: string }) => {
  return httpRequest.get(`/api/program/account_select`, data);
};
