import React from "react";
import { ActionIcon, Badge, Box, Button, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import { Form } from "../form";
import * as yup from "yup";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { IconDownload } from "@tabler/icons";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function ScfFinanceInvoiceForm({ onCancel, action, setTabIndex }: { action: any; onCancel: () => void; setTabIndex: any }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { invoiceOverdueStatus, invoiceStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  const onExport = (e: string) => {
    setLoading(true);
    window.open(e, "_blank");
    setLoading(false);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" mt="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ЕРӨНХИЙ МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invRefCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх илгээсэн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.senderUser?.lastName} {action?.invoice?.senderUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх баталсан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.confirmedUser?.lastName} {action?.invoice?.confirmedUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.invoice?.confirmedAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлсөн дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.invoice?.paidAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх үлдэгдэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.invoice?.amountToPay)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoiceStatus || []).find((c: any) => c?.code === action?.invoice?.invoiceStatus)?.color} 1px solid`,
                      color: `${(invoiceStatus || []).find((c: any) => c?.code === action?.invoice?.invoiceStatus)?.color}`,
                    }}>
                    {(invoiceStatus || []).find((c: IReference) => c.code === action?.invoice?.invoiceStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === action?.invoice?.paymentStatus)?.color} 1px solid`,
                      color: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === action?.invoice?.paymentStatus)?.color}`,
                    }}>
                    {(invoicePaymentStatus || []).find((c: IReference) => c.code === action?.invoice?.paymentStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === action?.invoice?.overdueStatus)?.color} 1px solid`,
                      color: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === action?.invoice?.overdueStatus)?.color}`,
                    }}>
                    {(invoiceOverdueStatus || []).find((c: any) => c?.code === action?.invoice?.overdueStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн нөхцөл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invPaymentTermDesc || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action?.invoice?.paymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн баримт:">
                  <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap">
                    {(action?.contractFiles || []).map((item: any, index: number) => {
                      return (
                        <Flex gap={5} justify="flex-start" align="center" direction="row" wrap="wrap" key={index}>
                          <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {`Файл ${index + 1}`}
                          </Text>
                          <ActionIcon variant="outline" style={{ border: `1px solid #4263EB` }} size={22}>
                            <IconDownload size={16} color="#4263EB" onClick={() => onExport(item.url)} />
                          </ActionIcon>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧ ТАЛЫН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнер нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.senderBusiness?.partner?.businessName || "-"
                        : action?.invoice?.receiverBusiness?.partner?.businessName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.senderBusiness?.regNumber || "-"
                        : action?.invoice?.receiverBusiness?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгч бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.senderBusiness?.profileName || "-"
                        : action?.invoice?.receiverBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчийн данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES" ? action?.invoice?.senderAcc?.number : action?.invoice?.receiverAcc?.number}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Борлуулалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.orderSalesCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES" ? action?.invoice?.senderFinUser?.firstName : action?.invoice?.receiverFinUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнерийн нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.receiverBusiness?.partner?.businessName
                        : action?.invoice?.senderBusiness?.partner?.businessName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.receiverBusiness?.regNumber || "-"
                        : action?.invoice?.senderBusiness?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагч бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES"
                        ? action?.invoice?.receiverBusiness?.profileName || "-"
                        : action?.invoice?.senderBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагчын данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES" ? action?.invoice?.receiverAcc?.number || "-" : action?.invoice?.senderAcc?.number || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.orderPurchaseCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invoice?.type === "SALES" ? action?.invoice?.receiverFinUser?.firstName : action?.invoice?.senderFinUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  onClick={() => {
                    setTabIndex("2");
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
