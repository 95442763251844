import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const ancor = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/client/invitation/anchor", { offset, filter });
};

export const onboard = async (data: {
  regNumber: string;
  businessName: string;
  send: boolean;
  firstName: string;
  // employeeUnitId: string;
  phone: string;
  email: string;
  isBuyer: boolean;
  isSupplier: boolean;
  toMessage: string;
}) => {
  return httpRequest.post("/api/client/invitation/onboarding", data);
};

export const create = async (data: any) => {
  return httpRequest.post("/api/client/invitation", data);
};

export const sme = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/client/invitation/sme", { offset, filter });
};

export const onboardingGet = async (id: string) => {
  return httpRequest.get(`/api/client/invitation/onboarding/${id}`);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/client/invitation/${id}`);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/client/invitation/onboarding/${id}`, data);
};
