import React, { useId, useRef } from "react";
import useSwr from "swr";
import { Avatar, Button, Checkbox, CloseButton, Divider, Drawer, Flex, Grid, Group, Input, Paper, Select, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconInfoSquare, IconListSearch, IconUserExclamation } from "@tabler/icons";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { ObservationApi } from "../../../apis";
import { tugrug } from "../../../utils";
import { useNavigate } from "react-router";
import { ICollection } from "../../../interfaces/ICollection";
import noimage from "../../../assets/no-image.png";
import { CollectionAssignedForm } from "../../../components/collection-payment-over-due/assigned-form";
import { Message } from "../../../utils/message";
import HttpHandler from "../../../utils/http-handler";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function CollectionObservationList() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [programQuery] = React.useState("");
  const [businessQuery] = React.useState("");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ programId: "", businessId: "", regNumber: "" });
  const [checked, setChecked] = React.useState<string[]>([]);
  const id = useId();

  const columns = useHeader({
    checked,
    user,
    onChangeChoose: (choose: boolean, record: any) => {
      let checkeds = checked;

      if (choose) {
        checkeds = [...checkeds, record];
      } else {
        checkeds = checkeds.filter((val: any) => val.id !== record.id);
      }
      setChecked(checkeds);
    },
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          navigate(`/collection/observation/detail/${item.id}`);
          break;
        }
        case "assign": {
          setAction(["assigned", item as any, item.id]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  const { data: programData } = useSwr(
    `/collection/observation/program_select?${programQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.program({ query: programQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const { data: businessData } = useSwr(
    `/collection/observation/business_select?${businessQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.business({ query: businessQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  return (
    <>
      <PageLayout
        title="Анхаарах харилцагч"
        subTitle="Эргэн төлөлтийн нэхэмжлэхэд хугацаа хэтрэлт үүссэн харилцагчдын жагсаалт"
        breadcrumb={breadcrumbs}
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_STAFF").length > 0 && (
              <Button onClick={() => setAction(["select-assigned", checked as any])} disabled={checked?.length === 0}>
                {"Ажилтан хувиарлах"}
              </Button>
            )}
          </div>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Grid mb="sm">
            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэрээр шүүх">
                  <Select
                    value={filters.programId}
                    onChange={(e) => {
                      setFilters({ ...filters, programId: e });
                    }}
                    clearable
                    searchable
                    placeholder="Сонгох"
                    data={(programData || [])?.map((c: any) => ({
                      label: c.name,
                      value: c.id,
                    }))}
                  />
                </Input.Wrapper>
              </Grid.Col>
            )}
            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
              <Grid.Col span={4}>
                <Input.Wrapper label="Бизнесийн нэрээр шүүх">
                  <Select
                    value={filters.businessId}
                    onChange={(e) => {
                      setFilters({ ...filters, businessId: e });
                    }}
                    clearable
                    searchable
                    placeholder="Сонгох"
                    data={(businessData || [])?.map((c: any) => ({
                      label: c.profileName,
                      value: c.id,
                    }))}
                  />
                </Input.Wrapper>
              </Grid.Col>
            )}
            <Grid.Col span={4}>
              <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
                <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Divider mb="sm" />
          <Table ref={ref} name="collection-observation.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.businnesslist(data!)} />
        </Paper>
        <Drawer
          opened={(action && action[0] === "assigned") || (action && action[0] === "select-assigned")}
          onClose={() => {
            setAction([]);
          }}
          position="right"
          withCloseButton={false}
          styles={{ drawer: { overflowY: "scroll" } }}
          size="80%">
          <PageLayout title="Төлөлт сануулсан тэмдэглэл" extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <Divider mb="sm" />
            <CollectionAssignedForm action={action} onCancel={onCancel} reload={reload} checked={checked} setChecked={setChecked} />
          </PageLayout>
        </Drawer>
      </PageLayout>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ICollection) => void;
  onChangeChoose: any;
  checked: any;
  user: any;
};

const useHeader = ({ onClick, onChangeChoose, checked, user }: HeaderProps): ColumnType<ICollection>[] => [
  {
    title: "Үйлдэл",
    render: (record, index) => {
      return (
        <Flex gap="sm">
          <UnstyledButton>
            <Checkbox
              checked={checked.find((f: any) => f.id === record.id) || false}
              onChange={(e) => {
                onChangeChoose(e.target.checked, record);
              }}
              disabled={record.hasCollectionStaff}
            />
          </UnstyledButton>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare color="#7956EF" />
            </Button>
          </Tooltip>

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION_STAFF").length > 0 &&
          record.hasCollectionStaff !== true ? (
            <Tooltip label="Томилох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("assign", record)}>
                <IconUserExclamation color="#7956EF" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Томилох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconUserExclamation color="#7956EF" stroke={0.8} />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.partner?.refCode}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.partner?.businessName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: "200px" }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.refCode}, {record?.regNumber}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.profileName}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Төлөлт хугацаа хэтэрсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right">
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {tugrug(record?.totalOverDue?.totalAmount)}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {`${record?.totalOverDue?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {`${record?.programCount} хөтөлбөр`}
        </Text>
      );
    },
  },
  {
    title: "Хариуцсан",
    sorter: true,
    dataIndex: "financeStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.collectionStaff?.avatar !== null ? record?.collectionStaff?.avatar : noimage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.collectionStaff?.lastName !== null ? record?.collectionStaff?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.collectionStaff?.firstName !== null ? record?.collectionStaff?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хугацаандаа",
    sorter: true,
    dataIndex: "normal",
    render: (record) => {
      return (
        <Group position="right">
          <div>
            <Text c="#1CEE3D">{tugrug(record?.normal?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.normal?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "1-3 хоног",
    sorter: true,
    dataIndex: "oneThree",
    render: (record) => {
      return (
        <Group position="right">
          <div style={{ width: 120 }}>
            <Text c="#FF9900">{tugrug(record?.oneThree?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.oneThree?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "4-30 хоног",
    sorter: true,
    dataIndex: "fourThirty",
    render: (record) => {
      return (
        <Group position="right">
          <div style={{ width: 120 }}>
            <Text c="#FF3D00">{tugrug(record?.fourThirty?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.fourThirty?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "31-60 хоног",
    sorter: true,
    dataIndex: "thirtyOneSixty",
    render: (record) => {
      return (
        <Group position="right">
          <div style={{ width: 120 }}>
            <Text c="#E03B07">{tugrug(record?.thirtyOneSixty?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.thirtyOneSixty?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "61-90 хоног",
    sorter: true,
    dataIndex: "sixtyOneNinety",
    render: (record) => {
      return (
        <Group position="right">
          <div style={{ width: 120 }}>
            <Text c="#BA350B">{tugrug(record?.sixtyOneNinety?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.sixtyOneNinety?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: " > 90 хоног",
    sorter: true,
    dataIndex: "overNinety",
    render: (record) => {
      return (
        <Group position="right">
          <div style={{ width: 120 }}>
            <Text c="#581B07">{tugrug(record?.overNinety?.totalAmount)}</Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {`${record?.overNinety?.totalCount} нэхэмжлэх`}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүү ажилтан",
    sorter: true,
    dataIndex: "financeStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.financeStaff?.avatar !== null ? record?.financeStaff?.avatar : noimage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.financeStaff?.lastName !== null ? record?.financeStaff?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.financeStaff?.firstName !== null ? record?.financeStaff?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    label: "Анхаарах харилцагч",
  },
];
