import React from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { Form } from "../form";
import { Alert, Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { TextField } from "../form/text-field";
import { SelectField } from "../form/select-field";
import { dateSecFormat } from "../../utils/date";
import { IAuth } from "../../interfaces/IAuth";
import { IReference } from "../../models/General";
import { TextareaField } from "../form/textarea-field";
import { IUser } from "../../interfaces/IUser";

export type ISupplierLedFormData = {
  type: string;
  category: string;
  name: string;
  resolutionNo: string;
  fundType: string;
  description: string;
  createdAt?: string;
  refCode?: string;
  regUser?: IUser;
  registrationStatus?: string | undefined;
};

const schema = yup.object({
  name: yup.string().max(255, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  category: yup.string().required("Заавал бөглөнө!").nullable(),
  fundType: yup.string().required("Заавал бөглөнө!").nullable(),
  resolutionNo: yup.string().max(45, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
});

export function ScfProductMainForm({ onSubmit: _onSubmit, action }: { action: any; onSubmit: (values: ISupplierLedFormData, type: boolean) => void }) {
  const nowMoment = new Date();
  const navigate = useNavigate();
  const [type, setType] = React.useState(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productTypes, productCategories, productFundTypes, productStatus, productRegistrationStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [data] = React.useState<ISupplierLedFormData>({
    type: "SUPPLIER_LED",
    category: "INVOICE_BASED",
    name: "",
    resolutionNo: "",
    description: "",
    fundType: "",
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = (values: ISupplierLedFormData) => {
    _onSubmit(values, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField name="refCode" label="Бүтээгдэхүүний код:" placeholder="Энд системээс авто үүсэх код гарна" disabled withAsterisk={false} />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  label="Бүтээгдэхүүний төрөл:"
                  name="type"
                  placeholder="Сонгох"
                  disabled
                  options={(productTypes || []).filter((c) => c.code === values.type).map((val) => ({ label: val.name, value: val.code }))}
                  withAsterisk={false}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  label="Бүтээгдэхүүний ангилал:"
                  name="category"
                  placeholder="Сонгох"
                  disabled
                  options={(productCategories || []).filter((c) => c.code === values.category).map((val) => ({ label: val.name, value: val.code }))}
                  withAsterisk={false}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {user?.lastName ? user.lastName[0] : "-"}. {user?.firstName ? user.firstName : "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action && action[0] === "update" ? dateSecFormat(action[1].createdAt) : dateSecFormat(nowMoment)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    {action && action[0] === "update" ? (
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(productRegistrationStatus || []).find((item: IReference) => item.code === action[1].registrationStatus)?.color}>
                        {(productRegistrationStatus || []).find((item: IReference) => item.code === action[1].registrationStatus)?.name}
                      </Badge>
                    ) : (
                      <Badge size="md" radius="sm" color="gray" variant="outline">
                        {"Түр төлөв"}
                      </Badge>
                    )}
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төлөв:">
                  <Box style={{ alignItems: "center" }}>
                    {action && action[0] === "update" ? (
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(productStatus || []).find((item: IReference) => item.code === action[1].productStatus)?.color}>
                        {(productStatus || []).find((item: IReference) => item.code === action[1].productStatus)?.name}
                      </Badge>
                    ) : (
                      <Badge size="md" radius="sm" color="red" variant="outline">
                        {"Идэвхгүй"}
                      </Badge>
                    )}
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="БАНКНЫ АЖИЛТНЫ БҮРТГЭХ МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField name="name" label="Бүртээгдэхүүний нэр:" placeholder="Бүтээгдэхүүнд тохирох нэрийг оруулна уу." />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="resolutionNo" label="Тушаалын дугаар:" placeholder="Баталсан тушаалын дугаар" />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  label="Эх үүсвэрийн төрөл:"
                  name="fundType"
                  placeholder="Сонгох"
                  options={(productFundTypes || []).map((val) => ({ label: val.name, value: val.code }))}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах" withAsterisk={false} />
              </Grid.Col>

              <Grid.Col span={12}>
                <Alert
                  styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Таны анхаарах зүйл">
                  Та бүтээгдэхүүний үндсэн мэдээллийг оруулж дуусаад "Үргэжлүүлэх" сонголтыг хийнэ үү.
                </Alert>
              </Grid.Col>
            </Grid>

            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/scf-dcf-product/scf-product")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" onClick={() => setType(false)}>
                  Хадгалах
                </Button>
                <Button type="submit" onClick={() => setType(true)}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
