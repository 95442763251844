import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Badge, Box, Button, Divider, Flex, Grid, Input, Text, Textarea } from "@mantine/core";
import { dateSecFormat } from "../../utils/date";
import { ILbfProduct } from "../../interfaces/ILbfProduct";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import useSwr from "swr";
import { BankProductApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { IAuth } from "../../interfaces/IAuth";

const schema = yup.object({});

export function ProductBuyerLedInformationDetail({ data, back, next }: { back: () => void; next: () => void; data: ILbfProduct }) {
  const { productCategories, programRegistrationStatus, productStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [value] = React.useState<any>({
    name: "",
  });

  const { data: bankProductData } = useSwr(`/api/lbf/reference/bank_product_type/select/BUYER-LED/detail`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REFERENCE").length > 0) {
      try {
        let res = await BankProductApi.select({ query: "", limit: 20 });
        return res;
      } catch (error) {
        Message.error((error as HttpHandler)?.message!);
      }
    }
    return [];
  });

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={value}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төрөл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.type || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний ангилал:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {(productCategories || []).find((item: IReference) => item?.code === data?.category)?.name}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.regUser?.lastName !== null ? <>{data?.regUser?.lastName[0]}.</> : ""} {data?.regUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(data?.createdAt)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.color}>
                      {(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төлөв:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(productStatus || []).find((item: IReference) => item.code === data?.lbfProductStatus)?.color}>
                      {(productStatus || []).find((item: IReference) => item.code === data?.lbfProductStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртээгдэхүүний нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.name || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Тушаалын дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.resolutionNo || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REFERENCE").length > 0 && (
                <Grid.Col span={4}>
                  <Input.Wrapper label="Банк бүтээгдэхүүн:">
                    <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600} mr={5}>
                        {(bankProductData || []).find((c: any) => c.id === data?.bankProductTypeId)?.name || "-"},
                      </Text>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {(bankProductData || []).find((c: any) => c.id === data?.bankProductTypeId)?.code || "-"}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
              )}
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <Textarea label="Тайлбар" value={data?.description} disabled />
              </Grid.Col>

              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШАЛГУУР НӨХЦӨЛҮҮД" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн доод үлдэгдэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.minInvAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн мах хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.maxOfInv || 0} %
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн min хувь:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.minOfInv || 0} %
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн мах тенор:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.maxTenor || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн min тенор:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.minTenor || 0}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн доод лимит:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.financeMinLimit || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн мах хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.maxDays || 0}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн min хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.minDays || 0}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => back()}>
                Болих
              </Button>
              <Button onClick={() => next()}>Дараагийнх</Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
