import React, { useRef } from "react";
import { ColumnType, ITableRef, Table } from "../table";
import { RequestApi } from "../../apis";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { Avatar, Badge, Button, Divider, Drawer, Flex, Grid, Group, Input, SegmentedControl, Select, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import { ScfFinanceRequestDetail } from "../../pages/scf/finance-request/detail-tabs";
import { dateSecFormat } from "../../utils/date";
import { tugrug } from "../../utils";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { IReference } from "../../models/General";
import { useDebouncedValue } from "@mantine/hooks";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

const data = [
  {
    name: "Бүгд",
    code: "",
    color: "yellow",
  },
  {
    name: "Buyer",
    code: "BUYER",
    color: "blue",
  },
  {
    name: "Supplier",
    code: "SUPPLIER",
    color: "green",
  },
];

export const ScfFundingRequestList = () => {
  const ref = useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { currencies, fundingInstructionStatus, requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [query, setQuery] = React.useState("");
  const [debouncedQuery] = useDebouncedValue(query, 600);
  const [status, setStatus] = React.useState("");
  const [debouncedStatus] = useDebouncedValue(status, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);

  const columns = useHeader({
    user,
    currencies,
    requestStatus,
    fundingInstructionStatus,
    tugrug,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record.id, "SUPPLIER"]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Input
            value={query}
            iconWidth={150}
            placeholder="хайх"
            maxLength={45}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Хайлт хийх"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <SegmentedControl
            size="xs"
            onChange={(e) => setBusiness(e)}
            value={business}
            data={(data || []).map((item: IReference, index: number) => {
              return {
                value: item.code || "",
                label: (
                  <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                    {item.name}
                  </Badge>
                ),
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            label=""
            value={status}
            data={[{ name: "Бүгд", code: "" }, ...(requestStatus || [])].map((c: IReference) => ({
              value: c.code,
              label: c.name,
            }))}
            iconWidth={150}
            placeholder="сонгох "
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Хүсэлт төлөв"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setStatus(e as string);
            }}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Divider mb={5} />
        </Grid.Col>
      </Grid>
      <Table
        ref={ref}
        name="scf.funding.request.supplier.list"
        columns={columns}
        filters={{ query: debouncedQuery, type: debouncedBusiness, status: debouncedStatus }}
        loadData={(data) => RequestApi.list(data!)}
      />
      <Drawer
        opened={action[0] === "detail"}
        onClose={() => {
          setAction([]);
        }}
        position="right"
        withCloseButton={false}
        styles={{ drawer: { overflowY: "scroll" } }}
        size="80%">
        <ScfFinanceRequestDetail action={action} onCancel={onCancel} setAction={setAction} reload={reload} />
      </Drawer>
    </>
  );
};

const useHeader = ({
  user,
  onClick,
  currencies,
  requestStatus,
  fundingInstructionStatus,
  tugrug,
}: {
  user: any;
  onClick: (key: string, record: IFundingRequest) => void;
  currencies: IReference[];
  requestStatus: IReference[];
  fundingInstructionStatus: any;
  tugrug: any;
}): ColumnType<IFundingRequest>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_BL" && c.isView).length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {dateSecFormat(record.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness?.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.requestedBusiness?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.requestedBusiness?.partner?.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.requestedBusiness?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.program?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.program?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.product?.name || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record?.product?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.requestedAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: IReference) => c.code === record.requestStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хоног",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.financePeriod || "0"}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт шимтгэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.calculatedFeeAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Банк шимтгэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.bankFeeAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.invAmountToPay || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.invCreatedAt)}
        </Text>
      );
    },
  },
  {
    title: "Баталсан огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record.invConfirmedDate)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              <Avatar src={record?.invConfirmedUser?.avatar !== null ? record?.invConfirmedUser?.avatar : noimage} />
              <div>
                <Text size="sm" weight={500} c="#44566C">
                  {"Buyer_user"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#317169">
                    {record?.invConfirmedUser?.firstName} {record?.invConfirmedUser?.lastName}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Харилцсан бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.profileName : record?.requestedBusiness?.profileName}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.refCode : record?.requestedBusiness?.refCode}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Харилцсан партнер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.businessName : record?.requestedBusiness?.partner?.businessName}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#317169">
                {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.refCode : record?.requestedBusiness?.partner?.refCode}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
              <div>
                <Text size="sm" weight={500} c="#44566C">
                  {"Supplier_user"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#317169">
                    {record?.requestedUser?.firstName} {record?.requestedUser?.lastName || "-"}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Funding Instruction",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          {record.fundingInstructionStatus !== null ? (
            <Badge size="md" radius="sm" variant="outline">
              {fundingInstructionStatus.find((item: IReference) => item.code === record.fundingInstructionStatus)?.name || "-"}
            </Badge>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    title: "Банк ажилтан",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <>
          {
            <Group noWrap>
              {record.respondedUser !== null ? (
                <Avatar src={record?.respondedUser?.avatar !== null ? record?.respondedUser?.avatar : noimage} />
              ) : (
                <Avatar src={noimage} />
              )}
              <div style={{ width: 150 }}>
                <Text size="sm" weight={500} c="#44566C">
                  {record?.respondedUser?.lastName || "-"}
                </Text>
                <Flex gap={5} wrap="nowrap">
                  <Text size="sm" weight={500} c="#44566C">
                    {record?.respondedUser?.firstName || "-"}
                  </Text>
                </Flex>
              </div>
            </Group>
          }
        </>
      );
    },
  },
  {
    title: "Олгох дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.approvedAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Шийдвэр огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record?.respondedDate)}
        </Text>
      );
    },
  },
  {
    title: "Олгох огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record?.toDisburseDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right">{tugrug(record.repaymentAmount || "0")}</Group>
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record?.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.repaymentInv?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Олгосон",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 100 }}>
          {dateSecFormat(record?.toDisburseDate)}
        </Text>
      );
    },
  },
];
