import { Accordion, Button, Divider, Grid, Input, LoadingOverlay, Paper, Switch, Table, Text, TextInput, Textarea, useMantineTheme } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import useSwr from "swr";
import { useNavigate, useParams } from "react-router";
import { RoleApi } from "../../../apis";
import { dateSecFormat } from "../../../utils/date";
import {
  IconBrandFirebase,
  IconCheck,
  IconFileSymlink,
  IconId,
  IconLicense,
  IconPlus,
  IconReceiptRefund,
  IconUserCircle,
  IconUsers,
  IconX,
} from "@tabler/icons";
import { IModules } from "../../../interfaces/IModules";
import { IMenus } from "../../../interfaces/IMenus";

export function RoleDetail() {
  const breadcrumbs = useBreadcrumb();
  const params = useParams();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { data, isLoading } = useSwr(`/api/supplier-led/product/${params?.id}`, async () => await RoleApi.get(`${params?.id}`), {
    fallback: { data: null },
  });

  return (
    <PageLayout
      title="Роль дэлгэрэнгүй"
      subTitle="Үүссэн роль дэлгэрэнгүй мэдээлэл"
      breadcrumb={breadcrumbs}
      extra={[
        <Button
          variant="outline"
          onClick={() => {
            navigate("/user/role-list");
          }}>
          {"Буцах"}
        </Button>,
      ]}>
      <Divider mb="lg" />
      {data && (
        <>
          <Paper>
            <LoadingOverlay visible={isLoading} />
            <Grid>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Роль бүртгэсэн: ">
                  <Text fz={"sm"} fw={600} color="blue.9">
                    {data?.regUser?.lastName || "-"}, {data?.regUser?.firstName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн огноо, цаг: ">
                  <Text fz={"sm"} fw={600} color="blue.9">
                    {dateSecFormat(data?.createdAt)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}></Grid.Col>

              <Grid.Col md={6} lg={4}>
                <TextInput value={data?.name} label="Нэр :" disabled />
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Textarea value={data?.description || ""} label="Тайлбар: " disabled />
              </Grid.Col>
              <Grid.Col md={6} lg={4}></Grid.Col>
            </Grid>
          </Paper>
          <Paper>
            {(data?.modules || []).map((item: IModules, index: number) => {
              return (
                <Paper shadow="sm" withBorder key={index} mt="xs">
                  <Accordion variant="filled" chevron={item?.hasModulePermissions && <IconPlus size={16} />} multiple>
                    <Accordion.Item key={item?.code} value={item?.code}>
                      <Accordion.Control
                        icon={
                          <>
                            {item?.code === "USER" && <IconUsers stroke={2} color="#44566C" />}
                            {item?.code === "CLIENT" && <IconUserCircle stroke={2} color="#44566C" />}
                            {item?.code === "PRODUCT" && <IconBrandFirebase stroke={2} color="#44566C" />}
                            {item?.code === "PROGRAM" && <IconId stroke={2} color="#44566C" />}
                            {item?.code === "SCF" && <IconLicense stroke={2} color="#44566C" />}
                            {item?.code === "LBF" && <IconFileSymlink stroke={2} color="#44566C" />}
                            {item?.code === "COLLECTION" && <IconReceiptRefund stroke={2} color="#44566C" />}
                          </>
                        }>
                        <Text size="md" weight={500} c="#44566C">
                          {item?.name} <span style={{ fontSize: 10, fontWeight: 500 }}>(Модулийн нэр)</span>
                        </Text>
                      </Accordion.Control>
                      {item?.hasModulePermissions && (
                        <Accordion.Panel>
                          <>
                            <Divider />
                            {(item?.menus || []).map((c: IMenus) => {
                              return (
                                <>
                                  <Text my={5} size="md" weight={500}>
                                    {c?.name} <span style={{ fontSize: 10, fontWeight: 600 }}>(Цэсний нэр)</span>
                                  </Text>
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "400px" }}>Үйлдэл</th>
                                        <th style={{ width: "140px" }}>View</th>
                                        <th style={{ width: "140px" }}>Create</th>
                                        <th style={{ width: "140px" }}>Edit</th>
                                        <th style={{ width: "140px" }}>Review</th>
                                        <th style={{ width: "140px" }}>Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(c?.permissions || []).map((row: any, ind: number) => {
                                        return (
                                          <tr key={`${index}_${ind}`}>
                                            <td>
                                              <Text size="sm" weight={500} c="#44566C">
                                                {row.name || "-"}
                                              </Text>
                                            </td>
                                            <td>
                                              {row.isView !== null ? (
                                                <Switch
                                                  checked={row.isView}
                                                  color="teal"
                                                  size="md"
                                                  label=""
                                                  thumbIcon={
                                                    row.isView ? (
                                                      <IconCheck style={{ width: 12, height: 12 }} color={theme.colors.teal[6]} stroke={3} />
                                                    ) : (
                                                      <IconX style={{ width: 12, height: 12 }} color={theme.colors.red[6]} stroke={3} />
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Text size="sm" weight={500} c="#44566C">
                                                  {"-"}
                                                </Text>
                                              )}
                                            </td>
                                            <td>
                                              {row.isCreate !== null ? (
                                                <Switch
                                                  checked={row.isCreate}
                                                  color="teal"
                                                  size="md"
                                                  label=""
                                                  thumbIcon={
                                                    row.isCreate ? (
                                                      <IconCheck style={{ width: 12, height: 12 }} color={theme.colors.teal[6]} stroke={3} />
                                                    ) : (
                                                      <IconX style={{ width: 12, height: 12 }} color={theme.colors.red[6]} stroke={3} />
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Text size="sm" weight={500} c="#44566C">
                                                  {"-"}
                                                </Text>
                                              )}
                                            </td>
                                            <td>
                                              {row.isEdit !== null ? (
                                                <Switch
                                                  checked={row.isEdit}
                                                  color="teal"
                                                  size="md"
                                                  label=""
                                                  thumbIcon={
                                                    row.isEdit ? (
                                                      <IconCheck style={{ width: 12, height: 12 }} color={theme.colors.teal[6]} stroke={3} />
                                                    ) : (
                                                      <IconX style={{ width: 12, height: 12 }} color={theme.colors.red[6]} stroke={3} />
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Text size="sm" weight={500} c="#44566C">
                                                  {"-"}
                                                </Text>
                                              )}
                                            </td>
                                            <td>
                                              {row.isReview !== null ? (
                                                <Switch
                                                  checked={row.isReview}
                                                  color="teal"
                                                  size="md"
                                                  label=""
                                                  thumbIcon={
                                                    row.isReview ? (
                                                      <IconCheck style={{ width: 12, height: 12 }} color={theme.colors.teal[6]} stroke={3} />
                                                    ) : (
                                                      <IconX style={{ width: 12, height: 12 }} color={theme.colors.red[6]} stroke={3} />
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Text size="sm" weight={500} c="#44566C">
                                                  {"-"}
                                                </Text>
                                              )}
                                            </td>
                                            <td>
                                              {row.isDelete !== null ? (
                                                <Switch
                                                  checked={row.isDelete}
                                                  color="teal"
                                                  size="md"
                                                  label=""
                                                  thumbIcon={
                                                    row.isDelete ? (
                                                      <IconCheck style={{ width: 12, height: 12 }} color={theme.colors.teal[6]} stroke={3} />
                                                    ) : (
                                                      <IconX style={{ width: 12, height: 12 }} color={theme.colors.red[6]} stroke={3} />
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Text size="sm" weight={500} c="#44566C">
                                                  {"-"}
                                                </Text>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                  <Divider />
                                </>
                              );
                            })}
                          </>
                        </Accordion.Panel>
                      )}
                    </Accordion.Item>
                  </Accordion>
                </Paper>
              );
            })}
          </Paper>
        </>
      )}
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Эрхийн тохиргоо",
    to: "/user/role-list",
  },
  { label: "Дэлгэрэнгүй" },
];
