import React from "react";
import { LoadingOverlay, Paper, Tabs, Text, createStyles } from "@mantine/core";
import { IconCashBanknote, IconHistory } from "@tabler/icons";
import { PageLayout } from "../../../components/layout";
import { AccountSupplierInformation } from "../../../components/account-supplier/information";
import { AccountSupplierHistory } from "../../../components/account-supplier/history";
import { LbfAccountApi } from "../../../apis";
import useSwr from "swr";
import { useParams } from "react-router";

export function AccountSupplierDetail() {
  const { theme } = useStyle();
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const [tabKey, setTabKey] = React.useState<string | null>("ACCOUNT");

  const { data, isLoading } = useSwr(`/api/program/supplier-led/${params.id}/detail`, async () => await LbfAccountApi.get(params.id!), {
    fallbackData: null,
  });

  return (
    <PageLayout
      title="Нийлүүлэгчийн зээлийн дансны мэдээлэл"
      subTitle="Таны хүсэлтийн дагуу санхүүжилт олгосон зээлийн дансны жагсаалт. Та санхүүжилтээ эргэн төлөхдөө уг дансанд төлнө үү."
      bgColor={theme.colors.gray[0]}
      extra={[]}
      breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <LoadingOverlay visible={isLoading} />
        <Tabs value={tabKey} onTabChange={setTabKey}>
          <Tabs.List>
            <Tabs.Tab value="ACCOUNT" icon={<IconCashBanknote color={tabKey === "ACCOUNT" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Зээлийн дансны мэдээлэл
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="HISTORY" icon={<IconHistory color={tabKey === "HISTORY" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Төлөлтийн түүх
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="ACCOUNT">{<AccountSupplierInformation data={data} />}</Tabs.Panel>
          <Tabs.Panel value="HISTORY">{<AccountSupplierHistory data={data?.payments} />}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: "Олгосон зээлийн данс",
    to: "/cf/account/SUPPLIER",
  },
  { label: "Дэлгэрэнгүй" },
];
