import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const businnesslist = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/observation/business", { offset, filter });
};

export const businessGet = async (id: string) => {
  return httpRequest.get(`/api/collection/observation/business/${id}`);
};

export const business = async (data: any) => {
  return httpRequest.get("/api/collection/observation/business_select", data);
};

export const program = async (data: any) => {
  return httpRequest.get("/api/collection/observation/program_select", data);
};

export const history = async (data: any) => {
  return httpRequest.get("/api/collection/observation/repayment/reminder_history", data);
};

export const repaymentList = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/observation/repayment", { offset, filter });
};

export const assign = async (data: { userId: string; user2Id: string; businessIds: any; description: string }) => {
  return httpRequest.post("/api/collection/observation/staff_assign", data);
};

export const repayment = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/collection/observation/repayment/activity_log", { offset, filter });
};

export const reminder = async (data: any) => {
  return httpRequest.get("/api/collection/observation/repayment/reminder_history", data);
};
