import { Box, Divider, Flex, Space, Text, createStyles } from "@mantine/core";
import { LbfRequest } from "../../models/LbfRequest";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateFormat } from "../../utils/date";
import { tugrug } from "../../utils";

export function FundingRequestBuyerSolution({ data, onBack }: { data: LbfRequest; onBack: () => void }) {
  const { classes } = useStyles();

  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);

  return (
    <>
      <Divider color="gray" mt="xs" mb="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТ ОЛГОХ ШИЙДВЭР" />
      <Box sx={(theme) => ({ border: `1px solid ${theme.colors.gray[3]}` })}>
        <Flex gap={0} justify="space-between">
          <Box w="10%">
            <Flex direction="column">
              <Text className={classes.header}> </Text>
              <Text className={classes.numberStyle}>1</Text>
              <Text className={classes.numberStyle}>2</Text>
              <Text className={classes.numberStyle}>3</Text>
              <Text className={classes.numberStyle}>4</Text>
              <Text className={classes.numberStyle}>5</Text>
              <Text className={classes.numberStyle}>6</Text>
              <Text className={classes.numberStyle}>7</Text>
              <Text className={classes.numberStyle}>8</Text>
              <Text className={classes.numberStyle}>9</Text>
              <Text className={classes.numberStyle}>10</Text>
              <Text className={classes.numberStyle}>11</Text>
              <Text className={classes.numberStyle}>12</Text>
              <Text className={classes.numberStyle}>13</Text>
              <Text className={classes.numberStyle}>14</Text>
              <Text className={classes.numberStyle}>15</Text>
              <Text className={classes.numberStyle}>16</Text>
              <Text className={classes.numberStyle}>17</Text>
              <Text className={classes.numberStyle}>18</Text>
              <Text className={classes.numberStyle}> </Text>
              <Text className={classes.numberStyle}>19</Text>
              <Text className={classes.numberStyle}>20</Text>
              <Text className={classes.numberStyle}>21</Text>
              <Text className={classes.numberStyle}>22</Text>
              <Text className={classes.numberStyle}> </Text>
              <Text className={classes.numberStyle}>23</Text>
              <Text className={classes.numberStyle}>24</Text>
              <Text className={classes.numberStyle}>25</Text>
              <Text className={classes.numberStyle}>26</Text>
            </Flex>
          </Box>

          <Box w="100%">
            <Flex direction="column">
              <Text className={classes.header}>Үзүүлэлт</Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Санхүүжилтийн хүсэлтийн код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Шийдвэрийн дугаар: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Санхүүжилтийн төрөл: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Нэхэмжлэх дугаар: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээлийн дүн: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээл авах шимтгэлийн дүн: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээлийн хугацаа: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Бүтээгдэхүүний төрөл: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Бүтээгдэхүүний код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Салбарын код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээлийн валют: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Төлөх давтамж: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Олголтын шимтгэл төлөх данс: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Сарын хүү: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Групп код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зорилго код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Дэд зорилго код: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээл төлөх өдөр: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Нийлүүлэгчийн мэдээлэл "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Байгууллагын регистр: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Байгууллагын нэр: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Нийлүүлэгчийн харилцах данс: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээлийн шугамын дугаар: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Худалдан авагчийн мэдээлэл "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Худалдан авагчийн регистр: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Худалдан авагчийн нэр: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Худалдан авагчийн харилцах данс: "}
              </Text>
              <Text className={classes.title}>
                <Space mr={10} />
                {"Зээлийн шугамын дугаар: "}
              </Text>
            </Flex>
          </Box>
          <Box w="100%">
            <Flex direction="column">
              <Text className={classes.header}>Утга</Text>
              <Text className={classes.item}>{data?.refCode || "-"}</Text>
              <Text className={classes.item}>{data?.resolutionRef || "-"}</Text>
              <Text className={classes.item}>{data?.lbfProductType === "BUYER_LED" ? "BL" : "SL" || "-"}</Text>
              <Text className={classes.item}>{data?.invoice?.refCode || "-"}</Text>
              <Text className={classes.item}>{tugrug(data?.approvedAmount || 0)}</Text>
              <Text className={classes.item}>{0}</Text>
              <Text className={classes.item}>{data?.period || 0}</Text>
              <Text className={classes.item}>{"CLA"}</Text>
              <Text className={classes.item}>{data?.lbfProduct?.bankProductType?.code || "-"}</Text>
              <Text className={classes.item}>{data?.requestedBusiness?.branch || "-"}</Text>
              <Text className={classes.item}>{(currencies || []).find((c: IReference) => c.code === data?.currency)?.name || "-"}</Text>

              <Text className={classes.item}>{"M"}</Text>
              <Text className={classes.item}>
                {data?.type === "BUYER" ? (
                  <>
                    {data?.invoice?.buyerAccount?.name || "-"}, {data?.invoice?.buyerAccount?.number || "-"}
                  </>
                ) : (
                  <>
                    {data?.invoice?.supplierAccount?.name || "-"}, {data?.invoice?.supplierAccount?.number || "-"}
                  </>
                )}
              </Text>
              <Text className={classes.item}>{"1"}</Text>
              <Text className={classes.item}>{data?.lbfProgram?.sector1?.code || "-"}</Text>
              <Text className={classes.item}>{data?.lbfProgram?.sector2?.code || "-"}</Text>
              <Text className={classes.item}>{data?.lbfProgram?.sector3?.code || "-"}</Text>
              <Text className={classes.item}>{dateFormat(data?.paymentDate)}</Text>

              <Text className={classes.item}> </Text>
              <Text className={classes.item}>{data?.invoice?.supplier?.regNumber || "-"}</Text>
              <Text className={classes.item}>{data?.invoice?.supplier?.profileName || "-"}</Text>
              <Text className={classes.item}>
                {data?.invoice?.supplierAccount?.name || "-"}, {data?.invoice?.supplierAccount?.number || "-"}
              </Text>
              <Text className={classes.item}>{"-"}</Text>
              <Text className={classes.item}> </Text>
              <Text className={classes.item}>{data?.invoice?.buyer?.regNumber || "-"}</Text>
              <Text className={classes.item}>{data?.invoice?.buyer?.profileName || "-"}</Text>
              <Text className={classes.item}>
                {data?.invoice?.buyerAccount?.name || "-"}, {data?.invoice?.buyerAccount?.number || "-"}
              </Text>
              <Text className={classes.item}>{data?.resolutionNo || "-"}</Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    borderBottom: `1px solid #E9ECEF`,
    backgroundColor: "#F1F3F5",
    borderLeft: `1px solid #E9ECEF`,
    alignItems: "center",
    height: 50,
    fontWeight: 500,
    fontSize: 15,
    color: "#2a3635",
  },

  numberStyle: {
    display: "flex",
    justifyContent: "center",
    borderBottom: `1px solid #E9ECEF`,
    alignItems: "center",
    height: 50,
    fontWeight: 500,
    color: "#44566C",
    fontSize: 15,
  },

  title: {
    display: "flex",
    borderBottom: `1px solid #E9ECEF`,
    borderLeft: `1px solid #E9ECEF`,
    alignItems: "center",
    height: 50,
    fontWeight: 500,
    color: "#44566C",
    fontSize: 15,
  },

  item: {
    display: "flex",
    justifyContent: "center",
    borderBottom: `1px solid #E9ECEF`,
    borderLeft: `1px solid #E9ECEF`,
    alignItems: "center",
    height: 50,
    fontWeight: 500,
    color: "#44566C",
    fontSize: 15,
  },
}));
