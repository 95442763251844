import React, { useId, useRef } from "react";
import { Avatar, Badge, Button, CloseButton, Divider, Flex, Grid, Group, Input, Modal, Paper, Select, Text, Tooltip } from "@mantine/core";
import { IconAlarm, IconId, IconInfoSquare, IconListSearch, IconMailForward } from "@tabler/icons";
import noImage from "../../../assets/no-image.png";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { ObservationApi, OverdueApi } from "../../../apis";
import { tugrug } from "../../../utils";
import { PageLayout } from "../../../components/layout";
import { PaymentMessageForm } from "../../../components/collection-payment-over-due/payment-message-form";
import { IOverdue } from "../../../interfaces/IOverdue";
import { dateSecFormat } from "../../../utils/date";
import { PaymentReminderForm } from "../../../components/collection-payment-over-due/paymet-form";
import { CollectionPaymentOverdueTabs } from "./tabs";
import { useSelector } from "react-redux";
import { IGeneral } from "../../../interfaces/IGeneral";
import { CollectionPaymentOverdueInvoiceTabs } from "./invoice-tabs";
import useSwr from "swr";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";
import { IMaskInput } from "react-imask";
import { IAuth } from "../../../interfaces/IAuth";
import { IPermissions } from "../../../interfaces/IPermissions";

export function CollectionPaymentOverdueList() {
  const breadcrumbs = useBreadcrumb();
  const ref = useRef<ITableRef>(null);
  const [programQuery] = React.useState("");
  const [businessQuery] = React.useState("");
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilters] = React.useState<any>({ programId: "", businessId: "", regNumber: "" });
  const { repaymentOverDueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const id = useId();

  const columns = useHeader({
    repaymentOverDueStatus,
    user,
    onClick: (key, item) => {
      switch (key) {
        case "payment": {
          setAction(["payment", item as any]);
          break;
        }
        case "message": {
          setAction(["message", item as any]);
          break;
        }

        case "repayment_invoice": {
          setAction(["repayment_invoice", item as any]);
          break;
        }
        case "contact": {
          setAction(["contact", item.id]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  const { data: businessData } = useSwr(
    `/collection/observation/business_select?${businessQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.business({ query: businessQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const { data: programData } = useSwr(
    `/collection/observation/program_select?${programQuery}&limit`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0) {
        try {
          let res = await ObservationApi.program({ query: programQuery });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
    {},
  );

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilters({ ...filters, regNumber: e });
    }
  };

  return (
    <PageLayout title="Төлөлтийн мэдээлэл" subTitle="Харилцагч байгууллагын SCF эргэн төлөлтийн мэдээллийн жагсаалт" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Grid mb="sm">
          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
            <Grid.Col span={4}>
              <Input.Wrapper label="Хөтөлбөрийн нэрээр шүүх">
                <Select
                  value={filters.programId}
                  onChange={(e) => {
                    setFilters({ ...filters, programId: e });
                  }}
                  clearable
                  searchable
                  placeholder="Сонгох"
                  data={(programData || [])?.map((c: any) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
          )}
          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OBSERVATION").length > 0 && (
            <Grid.Col span={4}>
              <Input.Wrapper label="Бизнесийн нэрээр шүүх">
                <Select
                  value={filters.businessId}
                  onChange={(e) => {
                    setFilters({ ...filters, businessId: e });
                  }}
                  clearable
                  searchable
                  placeholder="Сонгох"
                  data={(businessData || [])?.map((c: any) => ({
                    label: c.profileName,
                    value: c.id,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
          )}
          <Grid.Col span={4}>
            <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
              <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        <Divider mb="sm" />
        <Table ref={ref} name="collection-overdue-api.list" columns={columns} filters={filters} loadData={(data) => OverdueApi.list(data!)} />
      </Paper>
      <Modal withCloseButton={false} opened={action[0] === "message"} onClose={() => setAction([])} size="60%" centered>
        <PaymentMessageForm action={action} onCancel={onCancel} reload={reload} />
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "payment"} onClose={() => setAction([])} centered size="60%">
        <PageLayout title="Төлөлт сануулсан тэмдэглэл" extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
          <Divider mb="sm" />
          <PaymentReminderForm action={action} onCancel={onCancel} reload={reload} />
        </PageLayout>
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "repayment_invoice"} onClose={() => setAction([])} centered size="80%">
        <CollectionPaymentOverdueInvoiceTabs action={action} onCancel={onCancel} />
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "contact"} onClose={() => setAction([])} centered size="60%">
        <CollectionPaymentOverdueTabs action={action} onCancel={onCancel} />
      </Modal>
    </PageLayout>
  );
}

type HeaderProps = {
  user: any;
  onClick: (key: string, record: IOverdue) => void;
  repaymentOverDueStatus: any;
};

const useHeader = ({ onClick, repaymentOverDueStatus, user }: HeaderProps): ColumnType<IOverdue>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tooltip label="Сануулга тэмдэглэх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("payment", record)}>
                <IconAlarm color="#7956EF" />
              </Button>
            </Tooltip>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tooltip label="Сануулах мэйл илгээх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("message", record)}>
                <IconMailForward color="#7956EF" />
              </Button>
            </Tooltip>
          )}
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("repayment_invoice", record)}>
              <IconInfoSquare color="#7956EF" />
            </Button>
          </Tooltip>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tooltip label="Холбоо барих" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("contact", record)}>
                <IconId color="#7956EF" />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "ЭТН",
    sorter: true,
    dataIndex: "scfRequestRefCode",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 140 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {dateSecFormat(record.createdAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "payerBusiness",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.payerBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.payerBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "payerBusiness",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.payerBusiness?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.payerBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хөтөлбөр",
    sorter: true,
    dataIndex: "program",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 200 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.program?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.program?.name || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge radius="sm" variant="outline" c="#7956EF" color="violet">
          {record?.payerBusiness?.regNumber || "0"}
        </Badge>
      );
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "amountToPay",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.amountToPay || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хугацаа хэтэрсэн хоног",
    sorter: true,
    dataIndex: "overdueDays",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.overdueDays || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "overDueStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${repaymentOverDueStatus.find((c: any) => c.code === record.overdueStatus)?.color} 1px solid`,
            color: `${repaymentOverDueStatus.find((c: any) => c.code === record.overdueStatus)?.color}`,
          }}>
          {repaymentOverDueStatus.find((c: any) => c.code === record.overdueStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Санхүүжсэн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record.repaymentAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт авсан огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт хийх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Санхүү ажилтан",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.payerStaff?.avatar !== null ? record?.payerStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {(record?.payerStaff?.lastName !== null && record?.payerStaff?.lastName) || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {(record?.payerStaff?.firstName !== null && record?.payerStaff?.firstName) || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Санхүү и-мэйл",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.payerStaff?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүү утас",
    sorter: true,
    dataIndex: "payerStaff",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.payerStaff?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    sorter: true,
    dataIndex: "invCreatedAt",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: 140 }}>
            <Text size="sm" weight={500} c="#7956EF">
              {record.invRefCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {dateSecFormat(record.invCreatedAt)}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Худ-авагч бизнес",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.invReceiverBusiness?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.invReceiverBusiness?.profileName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "invReceiverBusiness",
    render: (record) => {
      return (
        <Group>
          <div>
            <Text size="sm" weight={500} c="#7956EF">
              {record?.invReceiverBusiness?.partner?.refCode || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#44566C">
                {record?.invReceiverBusiness?.partner?.businessName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Худалдан авагчийн санхүү",
    sorter: true,
    dataIndex: "invReceiverStaff",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.invReceiverStaff?.avatar !== null ? record?.invReceiverStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.invReceiverStaff?.lastName !== null ? record?.invReceiverStaff?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.invReceiverStaff?.firstName !== null ? record?.invReceiverStaff?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Хариуцсан",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.collectionStaff?.avatar !== null ? record?.collectionStaff?.avatar : noImage} size={48} />
          <div>
            <Text size="sm" weight={500} c="#44566C">
              {record?.collectionStaff?.lastName !== null ? record?.collectionStaff?.lastName : "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.collectionStaff?.firstName !== null ? record?.collectionStaff?.firstName : "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Эргэн төлөлт, хяналт",
  },
  {
    label: "Хугацаа хэтрэлт",
  },
];
