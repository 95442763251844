import { createStyles } from "@mantine/core";
import { ReactComponent as Star } from "../../assets/star.svg";

const StarMap = ({ reviewScore }: { reviewScore?: any }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {[...Array(5)].map((el, i) =>
        i < reviewScore ? (
          <div key={i} className={classes.yellow}>
            <Star />
          </div>
        ) : (
          <div key={i} className={classes.gray}>
            <Star />
          </div>
        ),
      )}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    height: "28px",
    padding: "5px",
  },
  yellow: {
    color: theme.colors.yellow[5],
  },
  gray: {
    color: theme.colors.gray[2],
  },
}));

export default StarMap;
