import React from "react";
import { ActionIcon, Divider, Tabs, Text } from "@mantine/core";
import { PageLayout } from "../../../components/layout";
import { IconAlarm, IconMailForward, IconNotes, IconUserExclamation, IconX } from "@tabler/icons";
import { CollectionOverdueMailForm } from "../../../components/collection-overdue/mail-form";
import { CollectionOverdueHistoryForm } from "../../../components/collection-overdue/history";
import { CollectionOverdueActiviteLogList } from "../../../components/collection-overdue/activite-log";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

type Props = {
  onCancel?: () => void;
  action: any[];
  reloadTable?: any;
};

export function CollectionPaymentOverdueInvoiceTabs({ action, onCancel }: Props) {
  const [tabIndex, setTabIndex] = React.useState<string | null>("1");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const onTabChange = (_index: any) => {
    setTabIndex(_index);
  };

  return (
    <>
      <PageLayout
        title={`Эргэн төлөлтийн нэхэмжлэх ${action[1]?.refCode}`}
        extra={[
          <ActionIcon onClick={() => onCancel && onCancel()} key={1}>
            <IconX />
          </ActionIcon>,
        ]}>
        <Divider mb="sm" />
        <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
          <Tabs.List>
            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
              <Tabs.Tab icon={<IconNotes color={tabIndex === "1" ? "#317169" : "#44566C"} />} value="1">
                <Text size="sm" weight={500} c="#44566C">
                  Үйл явцын лог
                </Text>
              </Tabs.Tab>
            )}

            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
              <Tabs.Tab icon={<IconMailForward color={tabIndex === "2" ? "#317169" : "#44566C"} />} value="2">
                <Text size="sm" weight={500} c="#44566C">
                  Имэйл мэдэгдэл
                </Text>
              </Tabs.Tab>
            )}

            {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
              <Tabs.Tab icon={<IconAlarm color={tabIndex === "3" ? "#317169" : "#44566C"} />} value="3">
                <Text size="sm" weight={500} c="#44566C">
                  Сануулга түүх
                </Text>
              </Tabs.Tab>
            )}

            <Tabs.Tab icon={<IconUserExclamation color={tabIndex === "4" ? "#317169" : "#44566C"} />} value="4">
              <Text size="sm" weight={500} c="#44566C">
                Авлага хариуцсан
              </Text>
            </Tabs.Tab>
          </Tabs.List>

          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tabs.Panel value="1">{tabIndex === "1" && <CollectionOverdueActiviteLogList action={action} />}</Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tabs.Panel value="2">{tabIndex === "2" && <CollectionOverdueMailForm action={action} />}</Tabs.Panel>
          )}

          {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
            <Tabs.Panel value="3">{tabIndex === "3" && <CollectionOverdueHistoryForm action={action} />}</Tabs.Panel>
          )}

          <Tabs.Panel value="4">{tabIndex === "4" && <></>}</Tabs.Panel>
        </Tabs>
      </PageLayout>
    </>
  );
}
